<template>
    <div>
        <div style="background: #F0F0F5;">
            <div class="container" style="margin-top: 115px">
                <div class="row">
                    <div class="col-md-12" style="padding-top:10px">
                        <div class="row rowp15">
                            <div class="col-md-12">
                                <input style="font-family:Roboto;color: #c4c4c4;width: 966px;"
                                       type="text"
                                       class="form-control"
                                       v-model="searchText"
                                       placeholder="Search your orders here" />
                            </div>
                            <!--<div class="col-md-3">
                                <button type="button"
                                        style="font-family:Roboto;width: 149px;margin-left:7rem"
                                        class="btn btn-info btn-w">
                                    Search Orders
                                </button>
                            </div>-->
                        </div>
                        <div v-if="loading" class="loading">
                        </div>
                        <div v-else>
                            <div class="row myorderlist font align-item-center" :v-if="ShowItems" v-for="(od, index) in filterBy(orderDetails, searchText)" :key="index">
                                <div class="col-md-3">
                                    <table class="table" style="margin-bottom:6px;">
                                        <tr class="orderrow" :key="'orderrow'+index" style="border: none;">
                                            <td class="itm-details">
                                                <p style="font-size: 14px;font-weight:400;margin-bottom:0;margin-top:0.5rem;font-family:Roboto;">
                                                    Order No: {{ od.KartInvoiceNo }}
                                                </p>
                                                <span class="sp-gray" style="font-size:14px;font-weight:300;color:#111;font-family:Roboto;">
                                                    Total No. of Boxes: {{ od.BoxQty }}
                                                </span><br />
                                                <p class="sp-gray" style="font-size: 14px;font-weight:400;color:#111;margin-top:6px;color:#78787C;font-family:Roboto;">
                                                    {{ formatDate(od.KartDate) }}
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-3">
                                    <div class="row">
                                        <div class="col-12 ml-auto mr-auto ">
                                            <p style="font-size: 14px;font-weight:400;margin-top:1rem;color:#78787C;font-family:Roboto;">
                                                Invoice Date: <span style="color:#000000"> {{ od.OrderDocDate }}</span>
                                            </p>
                                            <p style="font-size: 14px;font-weight:400;margin-top:50px;color:#78787C;font-family:Roboto;">
                                                Invoice No.:<span style="color:#000000"> {{ od.OrderDocNo }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="row">
                                        <div class="col-12 ml-auto mr-auto">
                                            <p style="font-size:14px;font-weight:400;margin-top:4rem;margin-bottom:50px;font-family:Roboto;"> {{ od.KartNetAmount }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <p style="cursor: pointer;font-size: 18px;font-weight:400;margin-bottom:0;margin-top:3.5rem;text-decoration:none;font-family:Roboto;"
                                       class="sp-blue"
                                       @click="nav('/returnCart', od.OrderDocNo, od.KartInvoiceNo, od.KartNetAmount, od.BoxQty, od.OrderDocDate)">
                                        <span><i style="font-size:16px;color: #000000;" class="fa">&#xf021;</i></span>Return
                                    </p>
                                </div>
                                <div class="col-md-2">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    </div>
</template>

<script>
    import Vue2Filters from "vue2-filters";
    import helper from "../helper";
    import axios from "axios";

    export default {
        mixins: [Vue2Filters.mixin],
        name: "itemReturn",
        components: {
        },
        data() {
            return {
                loading: false,
                title: "Item Return",
                orderDetails: [],
                TempProduct: [],
                ShowItems: false,
                users: [],
                searchText: "",
                OrderDocNo: ""
            };
        },
        created: function () {
            this.getOrderDetails();
        },
        computed: {

        },
        methods: {
            nav(p, oid, inv, amt, qty, invdt) {
                localStorage.setItem("orderno", oid)
                localStorage.setItem("kartinvno", inv)
                localStorage.setItem("kartinvamt", amt)
                localStorage.setItem("kartinvqty", qty)
                localStorage.setItem("kartinvdt", invdt)
                this.$router.push({
                    path: p
                });
            },
            async getOrderDetails() {
                this.loading = true;
                var l = localStorage.getItem("userdata");
                if (l != "") {
                    var userData = JSON.parse(l);
                    var self = this;
                    const requestBody = { OrderCustCode: userData.CustomerCode, OrderDocNo: 0, ShowOrderStatusOnly: "0" };
                    const response = await axios.post(helper.URL() + 'ordermasterdetails', requestBody);
                    const result = response.data;
                    self.orderDetails = [];
                    if (result.Status == true) {
                        if (result.Data && result.Data.length > 0) {
                            self.orderDetails = result.Data[0];
                        }
                        else {
                            Swal.fire('Alert!', "Response not found!!!", 'error');
                        }
                    }
                    self.loading = false;
                }
            },
            formatDate(date) {
                const d = new Date(date);
                var month = "" + (d.getMonth() + 1);
                var day = "" + d.getDate();
                var year = d.getFullYear();

                if (month.length < 2) month = "0" + month;
                if (day.length < 2) day = "0" + day;

                return [day, month, year].join("-");
            }
        },
        watch: {

        }
    };
</script>
<style scoped>
    .loading {
        background: transparent url('../assets/img/loading.gif') center no-repeat;
        height: 400px;
        width: 400px;
        margin: 0 auto;
    }

    .row img {
        max-height: 15em;
        width: 100%;
    }

    .lts {
        font-size: 12px;
    }

    .row h3 {
        cursor: pointer;
    }

    .hom-box {
        background: #FFFFFF;
        border: 1px solid #C5C7D0;
        box-shadow: 0px 2px 8px 0px #00000026;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 10px 15px 15px 15px;
        height: 60px;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: left;
    }

    .spanmyicon {
        width: 68px !important;
        border: 1px solid #C5C7D0
    }

    .carouselbox {
        background-color: #fff;
        padding: 5px 20px 5px 20px;
    }

    .carousel-control-prev {
        justify-content: normal;
        color: #000;
    }

    .carousel-control-next {
        justify-content: right;
        color: #000;
    }

    .carouselinner {
        margin: 5px 25px 5px 25px;
        width: 100%;
    }

    #carousel-card .card {
        border: 0.5px solid #FFF !important;
        text-align: center;
    }

        #carousel-card .card:hover {
            border: 0.5px solid #00AEEF !important;
        }

    #carousel-card .card-title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
    }

    .img-fluid {
        width: 124px !important;
    }

    .homesmbanner1 {
        background-image: url("../assets/img/home_smbanner_1.png");
        background-repeat: no-repeat;
        width: 679px;
        height: 313px;
        margin-right: 5px;
    }

    .homesmbanner2 {
        background-image: url("../assets/img/home_smbanner_2.png");
        background-repeat: no-repeat;
        width: 679px;
        height: 313px;
        margin-left: 5px;
    }

    .texthomesmbanner {
        padding: 75px 75px 10px 75px;
        background: -moz-linear-gradient(top, rgba(255,255,255,0) 2%, rgba(255,255,255,0) 12%, rgba(255,255,255,0.55) 40%, rgba(255,255,255,1) 63%, rgba(255,255,255,1) 75%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 2%,rgba(255,255,255,0) 12%,rgba(255,255,255,0.55) 40%,rgba(255,255,255,1) 63%,rgba(255,255,255,1) 75%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255,255,255,0) 2%,rgba(255,255,255,0) 12%,rgba(255,255,255,0.55) 40%,rgba(255,255,255,1) 63%,rgba(255,255,255,1) 75%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    #ordertbl {
    }

        #ordertbl input {
            width: 35px;
            padding: 0px;
            text-align: center;
        }

        #ordertbl img {
            width: 58px;
            height: 58px;
            border: solid 1px #00AEEF;
            margin: 10px;
        }

        #ordertbl tr th {
            text-align: center;
            font-size: 12px;
            font-family: Roboto;
            font-style: normal;
        }

    .orderrow {
        background: #fff;
        font-size: 14px;
        font-family: Roboto;
        font-style: normal;
        border-top: solid 2px #E5E5E5;
    }

    .tabletitle {
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #00AEEF;
        padding: 5px;
    }

    .pqty {
        color: #00AEEF;
        text-align: center;
    }

    .orderrowbtn {
        border-top: none;
        background: #fff;
    }

        .orderrowbtn .btn-info {
            background-color: #0dcaf0;
            position: relative;
            top: -20px;
        }

    .sidebar {
        background: #fff;
        margin-top: 15px;
        padding: 15px;
        font-family: Roboto;
    }

    .mov {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        display: flex;
        align-items: center;
        line-height: 50px;
    }


    .freebox {
        background: #E4E7ED;
        border-radius: 4px;
        padding: 4px;
        font-family: 'Roboto';
        font-size: 21px;
    }

    .freeg {
        color: #13e748;
        font-weight: 600;
    }

    .sb-title {
        border-bottom: solid 1px #000;
    }

        .sb-title td {
            font-weight: 800;
        }

    .sb-table {
        font-family: Roboto;
        font-size: 14px;
        line-height: 35px;
    }

        .sb-table td:nth-child(2) {
            text-align: right;
        }

    .totalrow {
        line-height: 55px;
        border-top: dashed 1px #000;
        border-bottom: dashed 1px #000;
        font-weight: 800;
    }

    .btnbig {
        width: 100%;
        margin: 20px 0px;
    }

    .myorderlist {
        background: #fff;
        font-family: Roboto;
        margin-bottom: 5px;
    }

    .sp-blue {
        color: #00AEEF;
    }

    .sp-gray {
        color: #9e9c9c;
    }

    .table-container::-webkit-scrollbar {
        width: 0;
        background-color: transparent;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 10px;
    }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: grey;
        }
</style>
