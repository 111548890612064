<template>
    <div style=" overflow-x: hidden;">
        <div style="padding-top: 30px; padding-left: 17px; background-color: #F0F0F5">
            <div><h1 class="title" style="font-size:48px;color:#000;margin-top:50px;margin-bottom:15px; text-align: center;">About Us</h1></div>
            <div style="padding-left:100px;padding-right:100px" class="row">

            </div>
        </div>
    </div>
</template>

<script>
</script>

<style scoped>
</style>