import Vue from 'vue';
import Vuex from 'vuex';

//Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        groupId: null
    },
    mutations: {
        setGroupId(state, groupId) {
            state.groupId = groupId;
        }
    }
});
