<template>
    <div class="container" style="width: 1089px;">
        <div class="navigationTop" style="margin-top: 115px; padding: 20px; padding: 5px; margin-left: 15px;">
            <a href="#" @click="navigateToHomeUser">Home</a>
            <span>&gt;</span>
            <a href="#">Quick Order</a>
        </div>
        <div class="rowFastmove" >
            <div class="column1-Fm" style="height: 370px; overflow-x: auto; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">
                <h4 class="column1-heading" style="font-weight: 700; line-height: 1.5px; font-size: 16px; margin-top: 20px; color: #454545; margin-left: 13px;">
                    Your Top 5 Fast Moving Products
                </h4>
                <div>
                    <template v-for="(p, index) in top5Products" :key="index">
                        <table class="sub-row" id="top5table" style="height:64px;">
                            <tr class="orderrow" id="'top5Products' + index" style="display: flex; justify-content: space-between; flex: 1; width: 923px;">
                                <td class="sub-column1" style="flex: 2; max-width: 320px;padding:0px 16px;">
                                    <div class="sub-column" style="width:238px;">
                                        <div class="">
                                            <img style="width: 100%; max-width: 73px; max-height: 55px; margin-top: -11px; background-color: #f3f3f3; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);" :src="'data:image/png;base64,' + p.CustomerLinkItemImageData" ratio="1" @click="productClick(p, p.CustomerLinkItemCode)">
                                        </div>
                                        <div class="sub-column-text-top5" style="text-align:left; white-space: nowrap; text-overflow: ellipsis;">
                                            <p class="centeredfm" style="font-weight: bold; font-size: 10px; color: #737373; margin-bottom: 5px;">
                                                {{ p.CustomerLinkItemName }}
                                            </p>
                                            <p class="centeredfm" style="color: #737373; margin-bottom: -2px; ">
                                                Product Code: {{ p.CustomerLinkItemCode }}
                                            </p>
                                            <p class="centeredfm" style="color: #737373; margin-bottom: 15px; ">
                                                {{ p.CustomerLinkItemBoxQty }} pc/Box I Unit Price:
                                                <span style="text-decoration: line-through">&#x20b9;{{ p.CustomerLinkItemPrice }}</span>
                                                <span style="font-size: 11px; font-weight: 600"> &nbsp;&#x20b9;{{ p.CustomerLinkItemDP }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td class="sub-column2" style="flex: 0.15; display: flex; justify-content: center; margin-top: 25px;">
                                    <div class="columnBox" style="text-align: center; margin-bottom: 42px; width: 113px">
                                        <div class="counter-container" style="display: flex; align-items: center; padding: 1px;">
                                            <span class="counter-label" style="color: #737373; font-size: 8px; margin-right: 3px;">Box Qty.</span>
                                            <div class="counter" style="border: 1px solid #737373; border-radius: 5px; padding: 1px; align-items: center;">
                                                <Minplus :value="p.BoxOrderQty" v-model.number="p.BoxOrderQty" :index="index" :min="0" :max="99999" @changeevent="kartBoxChangeTop5" style="cursor: pointer;">
                                                    <span style="cursor: pointer; font-size: 8px;">+</span>
                                                </Minplus>
                                            </div>
                                        </div>
                                        <span class="totalpcs" style="color: #454545; font-size: 9px; margin-top: 3px;">Total: {{ p.PcsOrderQty }} Pcs</span>
                                    </div>
                                </td>
                                <td class="sub-column3" style="text-align: left; flex: 1; margin-top: -3px; white-space:nowrap">
                                    <p style="color: #454545; font-size: 10px; margin-bottom: 3px; line-height: 1.4;">
                                        Order Value: &#x20b9; {{ getOrderVal(p).toLocaleString() }}
                                    </p>
                                    <p style="color: #454545; font-size: 10px; margin-bottom: 3px; line-height: 1.4;">
                                        Avg Monthly PO Qty (Pcs) {{ p.CustomerLinkItemAvgSalesQty }}
                                    </p>
                                    <p style="color: #454545; font-size: 10px; line-height: 1.4;">
                                        Last Order on: {{ p.LastPurchasedDate }} / {{ p.LastPurchasedQuantity }} Pcs
                                    </p>
                                </td>
                                <td class="sub-column4 align-left" style="flex: 0.2; max-width: 180px; padding: 8px;">
                                    <div class="custom-button-containerRemark" style="font-weight: normal !important; display: flex; flex-direction: column; align-items: center;">
                                        <button type="button" :class="getRemarkClass(p)" @click="remarkTop5Popup(index)" style="text-decoration: none; color: #454545; font-size: 8px; padding: 4px 8px;">Add Remark</button>
                                        <button class="styled-button-top5" @click="addToKart(p)" type="button" style="text-decoration: none; color: #fff; font-size: 8px; padding: 7px 16px; width: 61px; height: 24px;">
                                            Add <i class="fa" style="padding-top: 1px;">&nbsp;&nbsp;&#xf07a;</i>
                                        </button>
                                    </div>
                                </td>

                            </tr>
                        </table>
                    </template>
                </div>
            </div>
            <div class="column2-Fm" style="height: 370px; overflow-x: auto; box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);">
                <img src="../assets/img/ImageCartPage1.png" class="fit-imagefm" />
            </div>
        </div>
        <div class="parent-container">
            <div class="column-75" style="flex:4">
                <div style="margin-top: -14px;">
                    <div class="custom-columnsearch"
                         style=" margin-left: 21px; height: 70px; width:774px;font-family:Inter">
                        <div class="custom-search-container" style="font-family: Inter">
                            <select class="custom-dropdown" v-model="selectedItemGroupId" @change="getCustProductsByGroup(selectedItemGroupId)">
                                <option v-for="(ig, index) in itemgroup" :key="index" :value="ig.ItemGroupId">
                                    {{ ig.ItemGroupName }}
                                </option>
                            </select>
                            <input type="text"
                                   class="custom-search-input"
                                   placeholder="Search for products" v-model="searchText" />
                            <button class="custom-frequently-ordered-btn" :class="buttonColorFrequent" @click="handleFrequentOrderButtonClick" style="font-family: Inter">
                                Frequently Ordered
                            </button>
                            <button class="custom-last-ordered-btn" :class="buttonColor" @click="handleLastOrderButtonClick" style="font-family: Inter">
                                Last Ordered
                            </button>
                            <div id="popup" class="popup" v-if="showPopup">
                                <ul>
                                    <li v-for="product in filteredProducts" :key="product.id" @click="selectProduct(product)">
                                        {{ product.CustomerLinkItemName }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="parent" :class="getParentDivClass">
                    <div id="row2" class="row2style" v-if="filteredPopupProducts.length > 0" style="width: 776px; font-family: Inter; height: 141px;">
                        <template v-for="(a, index) in filteredPopupProducts" :key="index">
                            <table class="sub-row" id="filteredPopupProducts" style="font-family: 'Inter', sans-serif; height: 140px;">
                                <tr class="orderrow" id="'filteredPopupProducts' + index" style="display: flex; justify-content: space-between; width:929px;overflow-x:hidden">
                                    <td class="sub-column1" style="flex: 1; max-width: 326px; padding: 20px 8px; background: #F3F3F3; flex-wrap: wrap;padding-left:0px;padding-right:40px">
                                        <div class="sub-column" style="display: flex; align-items: center;">
                                            <div class="columnImgQO" style="width: 96px; margin-right: 10px;">
                                                <img style="width: 100%; max-width: 96px; max-height: 96px; background-color: #f3f3f3; margin-top: 4px;" class="custom-image" :src="'data:image/png;base64,' + a.CustomerLinkItemImageData" alt="Image Description" ratio="1" @click="productClick(a, a.CustomerLinkItemCode)">
                                            </div>
                                            <div class="custom-content" style="line-height: 1; font-size: 12px; flex: 1; line-height: 1.5; margin-top: -20px;">
                                                <h4 style="color: #454545; margin-bottom: 3px; font-size: 14px; font-weight: bold; font-family: 'Inter'; overflow: hidden; text-overflow: ellipsis;">{{ a.CustomerLinkItemName }}</h4>
                                                <p style="font-weight: normal; color: #454545; margin-bottom: 3px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Product Code: {{ a.CustomerLinkItemCode }}</p>
                                                <p style="font-weight: normal; color: #454545; margin-bottom: 3px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ a.CustomerLinkItemBoxQty }} pc/Box I Unit Price: <span class="strikethrough" style="font-weight: normal; color: #454545">&#x20b9;{{ a.CustomerLinkItemPrice }}</span><span class="highlight" style="color: #454545;font-size:12px">&nbsp; &#x20b9;{{ a.CustomerLinkItemDP }}</span></p>
                                                <p style="font-weight: normal; color: #454545; margin-bottom: 0; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Avg Monthly PO Qty (Pcs) <span class="highlight" style="color: #2ca9df">&nbsp;{{ a.CustomerLinkItemAvgSalesQty }}</span></p>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="sub-column2" style="flex: 0.7; display: flex; justify-content: center; align-items: center; background: #F3F3F3;font-family:Inter;">
                                        <div class="custom-column" style="flex: 1; display: flex; justify-content: center;margin-top: -30px;">
                                            <div class="custom-columnBox" style="display: flex; flex-direction: column; align-items: flex-start; width: 278px; margin-top: -3px;margin-left: 7px; ">
                                                <div class="custom-counter-box" style="margin-bottom: 0;">
                                                    <div style="display: flex; align-items: center;">
                                                        <span class="custom-counter-label" style="font-weight: normal; color: #454545; margin-left: 36px; font-size: 12px;">Box Qty.:</span>
                                                        <div class="custom-counter" style="display: flex; align-items: center; border: 1px solid #737373; border-radius: 5px; width: 163px; margin-left: 12px;">
                                                            <Minplus :value="a.BoxOrderQty" v-model.number="a.BoxOrderQty" :index="index" :min="0" :max="99999" @changeevent="kartBoxChange" style="cursor: pointer;"></Minplus>
                                                        </div>
                                                    </div>
                                                    <div style="display: flex; flex-direction: column; align-items: flex-start; margin-top: 8px; width: 376px;">
                                                        <span style="font-size: 12px; font-weight: normal; color: #454545; margin-bottom: 8px; margin-bottom: 21px; margin-left: 120px; margin-top: -14px;">Total: {{ a.PcsOrderQty }} Pcs</span>
                                                        <div class="custom-counter-row" style="display: flex; flex-direction: row; margin-left: 30px; margin-top: -14px;">
                                                            <label class="custom-checkbox-label" style="font-weight: normal; color: #454545; font-size: 13px;">
                                                                <input type="checkbox" class="custom-tick-box" name="OrderType" id="TenderOrder" v-model="a.TenderOrder" />
                                                                Tender Order
                                                            </label>
                                                            <label class="custom-checkbox-label" style="font-weight: normal; color: #454545; font-size: 12px;">
                                                                <input type="checkbox" class="custom-tick-box" name="OrderType" id="FirstOrder" v-model="a.FirstOrder" />
                                                                1st Time Order
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="sub-column3" style="text-align: left; flex: 1; background:#f3f3f3">
                                        <div class="custom-column" style="display: flex; flex-direction: column; align-items: flex-end; height: 100%; margin-left: 5px; ">
                                            <div class="custom-columnButton" style="display: flex; flex-direction: column; align-items: flex-end; background: #F3F3F3; overflow-wrap: break-word; margin-left: -12px; ">
                                                <div class="custom-order-value" style="font-weight: normal; color: #454545; font-size: 12px;margin-left:-12px">
                                                    Order Value: &#x20b9; {{ getOrderVal(a).toLocaleString() }}
                                                </div>
                                                <div class="custom-button-containerRemark" style="font-weight: normal; margin-left: 23px;">
                                                    <button type="button" :class="getRemarkClass(a)" @click="remarkPopup(index)" style="text-decoration: none; color: #454545; font-size: 10px;">Add Remark</button>
                                                </div>
                                                <div class="custom-button-containerQ" style="margin-top:-11px">
                                                    <button @click="addToKart(a)" style="text-decoration: none; color: #454545; font-size: 10px; padding: 8px 24px;color:#fff;background:#2ca9df;border:none;border-radius:4px;margin-top:-5px">
                                                        Add &nbsp;<i class="fa">&#xf07a;</i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </template>
                    </div>
                    <div :class="getRow3Class" v-if="KartProducts.length > 0 || (filteredProducts.length === 0 && KartProducts.length === 0)" style="width:87%">
                        <div>
                            <h4 class="column1-heading2" style="font-weight: 700; line-height: 1.5px; margin-left: 20px; font-size: 16px; margin-top: 20px; color: #454545; ">
                                Your Cart
                            </h4>
                            <template v-for="(k, index) in KartProducts" :key="index">
                                <table class="sub-row" id="karttable" style="padding: 0px 10px; height: auto; width: 90%; display: flex; flex-wrap: wrap;">
                                    <tr class="orderrow" id="'kartProducts' + index" style="display: flex; justify-content: space-between; width: 100%;">
                                        <td class="sub-column1" style="flex: 1; max-width: 280px; padding: 4px 40px; padding-left: 0px;">
                                            <div class="sub-column" style="width: 100%; display: flex;">
                                                <div class="sub-column-image" style="flex: 1;">
                                                    <img style="width: 80px; height: 70px; background-color: #f3f3f3;" :src="'data:image/png;base64,' + k.CustomerLinkItemImageData" ratio="1" @click="productClick(k, k.CustomerLinkItemCode)">
                                                </div>
                                                <div class="sub-column-text" style="text-align: left; margin-top: 5px; flex: 1;">
                                                    <h4 class="centeredfm" style="font-weight: bold; font-family: Inter; font-size: 12px; color: #454545;  overflow: hidden; text-overflow: ellipsis; width: 177px;">
                                                        {{ k.CustomerLinkItemName }}
                                                    </h4>
                                                    <p class="centeredfm" style="color: #454545; font-size: 10px; font-family: Inter; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                                                        Product Code: {{ k.CustomerLinkItemCode }}
                                                    </p>
                                                    <p class="centeredfm" style="color: #454545; font-size: 10px; font-family: Inter;">
                                                        {{ k.CustomerLinkItemBoxQty }} pc/Box I Unit Price:
                                                        <span style="text-decoration: line-through; font-size: 10px;">&#x20b9;{{ k.CustomerLinkItemPrice }}</span>
                                                        <span style="font-size: 12px; font-weight: 600;"> &nbsp;&#x20b9;{{ k.CustomerLinkItemDP }}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="sub-column2" style="flex: 0.4; justify-content: center; background: #fff; width: 238px;margin-top:10px">
                                            <div class="columnBox" style="text-align: center; margin-bottom: 30px; margin-left: -12px;">
                                                <div class="counter-container" style="display: flex; flex-direction: row; align-items: center; margin-left: -50px;">
                                                    <span class="counter-label" style="color: #454545; font-size: 10px; margin-right: 3px;font-family:Inter">Box Qty.</span>
                                                    <div class="counter" style="border: 1px solid #454545; border-radius: 5px; padding: 1px;">
                                                        <Minplus :value="k.BoxOrderQty" v-model.number="k.BoxOrderQty" :index="index" :min="1" :max="99999" @changeevent="kartBoxChangeCart" style="cursor: pointer;"></Minplus>
                                                    </div>
                                                </div>
                                                <span class="totalpcs" style="color: #454545; font-size: 10px; margin-top: 3px; margin-left: 60px; font-family: Inter;">Total: {{ k.PcsOrderQty }} Pcs</span>
                                            </div>
                                        </td>
                                        <td class="sub-column3" style="text-align: left; flex: 0.6; margin-top: 5px; background: #fff; width: 275px; font-family: Inter;white-space:nowrap">
                                            <p style="color: #454545; line-height: 1.2; font-size: 11px; width: 152px; margin-left: -63px;">
                                                Order Value: &#x20b9; {{ getOrderVal(k).toLocaleString() }}
                                            </p>
                                            <p style="color: #454545; line-height: 1.2; font-size: 11px; width: 152px; margin-left: -63px; ">
                                                Avg Monthly PO Qty (Pcs) {{ k.CustomerLinkItemAvgSalesQty }}
                                            </p>
                                            <p style="color: #454545; line-height: 1.2; font-size: 11px; width: 152px; margin-left: -63px;">
                                                Order Type: {{ k.TenderOrder }} {{ k.FirstOrder }}
                                            </p>
                                        </td>
                                        <td class="sub-column4 align-left" style="background:#fff;font-family:Inter">
                                            <div class="custom-button-containerRemark" style="font-weight: normal !important; margin-left: 5px; margin-top: 0px;">
                                                <button type="button" :class="getRemarkClass(k)" @click="remarkKartPopup(index)" style="font-size: 10px; text-decoration: none; color: #454545; width: 101px; margin-left: 10px; text-align: center;border:1px solid #737373">Remark</button>
                                                <a class="big-buttonVRemove" style="font-size: 10px; text-decoration: none; cursor: pointer; display: block; margin-top: 10px; width: 101px; margin-left:10px; text-align: center; padding: 5px 13px;" @click="removeFromKart(index)">Remove</a>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </template>
                        </div>
                    </div>
                    <div v-else>
                        <!-- Placeholder content or any other fallback when both arrays are empty -->
                    </div>
                </div>
            </div>
            <div class="column-25" style="margin-left:-181px;">
                <div class="custom-columnmov" style="background: #fff;margin-top: -18px; margin-right: 10px; padding: 10px; flex: 1; margin-left: 1px; ">
                    <div class="custom-columnprice" style="background-color: #fff">
                        <div class="custom-mov" style="font-family: Inter">M. O. V: &#x20b9; {{ userdata.CustomerMOV }}</div>
                        <div class="custom-freeshipping" style="font-family: Inter">
                            &#x20b9; {{ addMore }} more to get free shipping
                        </div>
                        <div class="custom-underline" style="margin-top: 10px"></div>
                    </div>
                    <h4 style="font-family: Inter;color:#454545;font-weight:bold;">Price Details</h4>
                    <div class="custom-underline"></div>


                    <div class="custom-rowPD" style="display: flex">
                        <div class="custom-columnPrice" style="flex: 1; padding: 0px; box-sizing: border-box;">
                            <p style="font-family: Inter; color: #454545;">Total Amount</p>
                            <p style="font-family: Inter; color: #454545;">Transportation Charge</p>
                            <p style="font-family: Inter; color: #454545;">Tax Amount (GST)</p>
                            <p style="font-family: Inter; color: #454545;">Round Off</p>
                            <p style="font-weight: 600; font-family: Inter;">Order Total</p>
                            <div class="custom-underline"></div>
                            <p style="font-weight: 600; color: #454545; font-family: Inter;">Total Savings</p>
                            <div class="custom-underline"></div>
                            <p style="color: #454545; font-family: Inter">Discount Applied</p>
                            <p style="color: #454545; font-family: Inter">Transportation Charge</p>
                            <div class="custom-underline"></div>
                            <p style="font-weight: 600; color: #454545; font-family: Inter">Total</p>

                        </div>

                        <div class="custom-columnDetails" style="flex: 1; text-align: right;padding: 0px; box-sizing: border-box;">
                            <p style="font-family: Inter">&#x20b9; {{ totalAmount }}</p>
                            <p style="font-family: Inter">&#x20b9; {{ Transportation }}</p>
                            <br />
                            <p style="font-family: Inter">&#x20b9; {{ TaxAmount }}</p>
                            <br />
                            <p style="font-family: Inter">&#x20b9; {{ RoundOff }}</p>
                            <p style="font-weight: 600; font-family: Inter;">&#x20b9; {{ NetAmount }}</p>
                            <div class="custom-underline"></div>
                            <p style="color: #454545; font-family: Inter;">&#x20b9; {{ Discount }}</p>
                            <div class="custom-underline"></div>
                            <p style="color: #454545; font-family: Inter">&#x20b9; {{ STransportation }}</p>
                            <br />
                            <p style="color: #454545; font-family: Inter">&#x20b9; {{ Savings }}</p>
                            <br />      <div class="custom-underline"></div>
                            <p style="color: #454545; font-family: Inter;">&#x20b9; {{ totalAmount }}</p>

                        </div>
                    </div>

                    <div class="custom-button-Cartcontainer">
                        <button class="custom-buttonCart" @click="placeOrderClick">Place Order</button>
                    </div>

                    <h4 style="color:#454545;font-weight:bold">Account Summary</h4>
                    <div class="custom-underline"></div>
                    <div class="custom-rowPD" style="display: flex;">
                        <div class="custom-columnAccount" style="flex: 1">

                            <p>Current Order Value</p>
                            <p>Previous Dues</p>
                            <div class="custom-underline"></div>
                            <p style="font-weight: 600">Total Due</p>
                            <div class="custom-underline"></div>
                        </div>

                        <div class="custom-columnSummary" style="flex: 1; text-align: right;">

                            <br />
                            <p style="color: #454545;font-family:Inter">&#x20b9; {{ NetAmount }}</p>
                            <p style="color: #454545;font-family:Inter">&#x20b9; {{ custPreviousDues }}</p>



                            <div class="custom-underline"></div>
                            <p style="font-weight: 600">&#x20b9; {{ custTotalDue }}</p>
                            <div class="custom-underline"></div>

                        </div>
                    </div>
                    <div class="custom-rowPD" style="margin-top: -0px">
                        <div class="custom-columnItemsBoxes">
                            <p style="color: #454545;font-family:Inter">No. of Items: {{ KartProducts.length }}</p>
                            <p style="color: #454545;font-family:Inter">No. of Boxes: {{ getTotalBox() }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="remarkAllProduct">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title">Add Remark</h4>
                                    <button type="button" class="btn btn-sm btn-danger" @click="remarkAllProduct=false">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body" style="text-align: center;">
                                    <textarea v-model="RemarkAllProductText" class="form-control"></textarea>
                                    <br />
                                    <button class="btn btn-info  btn-w btn-sm" @click="addRemarkToProduct"> Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div v-if="remarkTop5">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title">Add Remark</h4>
                                    <button type="button" class="btn btn-sm btn-danger" @click="remarkTop5=false">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body" style="text-align: center;">
                                    <textarea v-model="RemarkTop5Text" class="form-control"></textarea>
                                    <br />
                                    <button class="btn btn-info  btn-w btn-sm" @click="addRemarkToTop5Product"> Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div v-if="remarkKart">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title" style="margin:0 auto"> Remark</h4>
                                    <button type="button" class="btn btn-sm" @click="remarkKart=false">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body" style="text-align: center;">
                                    <textarea v-model="RemarkKartText" class="form-control"></textarea>
                                    <br />
                                    <button class="btn btn-info  btn-w btn-sm" @click="addRemarkToKartProduct" style="margin-left: -29px;"> Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    import helper from '../helper';
    import Swal from 'sweetalert2';
    import Vue2Filters from 'vue2-filters'
    import Minplus from "../v-minusplusfield";

    export default {
        mixins: [Vue2Filters.mixin],
        name: "quickOrder",
        components: {
            Minplus
        },
        data() {
            return {
                showPopup: false,
                filteredPopupProducts: [],
                buttonColor: 'default-color',
                buttonColorFrequent: 'default-color',
                buttonClickCount: 0,
                frequentButtonClickCount: 0,
                searchText: "",
                allProducts: [],
                top5Products: [],
                KartProducts: [],
                freeshipping: false,
                totalAmount: 0,
                addMore: 0,
                STransportation: 0,
                Discount: 0,
                Savings: 0,
                Transportation: 0,
                TaxAmount: 0,
                RoundOff: 0,
                NetAmount: 0,
                custTotalDue: 0,
                custAddressList: [],
                custAddressListB: [],
                custAddressListS: [],
                custAddressB: "",
                custAddressS: "",
                selectedCustAddressIdB: 0,
                selectedCustAddressIdS: 0,
                custTrspChrgPerBox: 0,
                itemgroup: [],
                isProductMenuOpen: false,
                selectedItemGroupId: 0,
                TenderOrder: false,
                FirstOrder: false,
                remarkAllProduct: false,
                remarkAllProductIndex: 0,
                RemarkAllProductText: "",
                remarkTop5: false,
                remarkTop5Index: 0,
                RemarkTop5Text: "",
                remarkKart: false,
                remarkKartIndex: 0,
                RemarkKartText: ""
            };
        },
        created: async function () {
            this.selectProductGroup();
            this.loadUserData();
            this.getCustAddress();
            this.getCustAllProducts();
            this.getCustTop5Products();
            this.calcTotalKart();
        },
        computed: {
            filteredProducts() {
                const searchText = this.searchText.toLowerCase().trim();
                if (searchText) {
                    return this.allProducts.filter(a => {
                        const nameMatch = a.CustomerLinkItemName.toLowerCase().includes(searchText);
                        const codeMatch = a.CustomerLinkItemCode.toLowerCase().includes(searchText);
                        return nameMatch || codeMatch;
                    });
                } else {
                    return {};
                }
            },
            getRow3Class() {
                if (Object.keys(this.filteredProducts).length === 0 && this.searchText.trim().length === 0) {
                    return 'yourcartrowwithoutallproducts';
                } else {
                    return 'yourcartrow';
                }
            },
            getParentDivClass() {
                if (Object.keys(this.filteredProducts).length === 0 || this.filteredProducts.length === 0) {
                    return 'parent-div';
                } else {
                    return 'parent-div-withAllProduct';
                }
            }
        },
        watch: {
            searchText() {
                const searchText = this.searchText.toLowerCase().trim();
                this.showPopup = searchText !== '';
                if (!searchText) {
                    this.filteredPopupProducts = [];
                }
            }
        },
        mounted() {
        },
        methods: {
            navigateToHomeUser(event) {
                event.preventDefault();
                this.$router.push('/homeUser');
            },
            selectProduct(product) {
                this.filteredPopupProducts = [product];
                this.showPopup = false;
            },
            handleLastOrderButtonClick() {
                if (this.buttonClickCount === 0) {
                    this.getCustomerLastOrder();
                    this.buttonColor = 'last-ordered-color';
                    this.buttonColorFrequent = 'user-data-color';
                    this.buttonClickCount++;
                    this.frequentButtonClickCount = 0;
                } else if (this.buttonClickCount === 1) {
                    this.loadUserData();
                    this.buttonColor = 'user-data-color';
                    this.buttonClickCount--;
                }
            },
            handleFrequentOrderButtonClick() {
                if (this.frequentButtonClickCount === 0) {
                    this.getCustomerFrequentOrder();
                    this.buttonColorFrequent = 'last-ordered-color';
                    this.buttonColor = 'user-data-color';
                    this.frequentButtonClickCount++;
                    this.buttonClickCount = 0;
                } else if (this.frequentButtonClickCount === 1) {
                    this.loadUserData();
                    this.buttonColorFrequent = 'user-data-color';
                    this.frequentButtonClickCount--;
                }
            },
            async getCustomerLastOrder() {
                try {
                    var l = localStorage.getItem("userdata")
                    if (l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { custcode: userData.CustomerCode, itemgroupid: 0 }
                        const response = await axios.post(helper.URL() + 'customerlastorder', requestBody);
                        const result = response.data;
                        self.KartProducts = [];
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                result.Data.forEach(element => {
                                    self.KartProducts.push(element)
                                });
                                self.calcTotalKartWithoutStore();
                            }
                        }
                        else {
                            Swal.fire('Alert!', "Response not found!!!", 'error');
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            async getCustomerFrequentOrder() {
                try {
                    var l = localStorage.getItem("userdata")
                    if (l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { custcode: userData.CustomerCode, itemgroupid: 0 }
                        const response = await axios.post(helper.URL() + 'customerfrequentorder', requestBody);
                        const result = response.data;
                        self.KartProducts = [];
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                result.Data.forEach(element => {
                                    element.BoxOrderQty = 0
                                    element.PcsOrderQty = 0
                                    element.Remark = ''
                                    self.KartProducts.push(element)
                                });
                            }
                        }
                        else {
                            Swal.fire('Alert!', "Response not found!!!", 'error');
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            async getCustAddress() {
                try {
                    var l = localStorage.getItem("userdata")
                    if (l != null && l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { custcode: userData.CustomerCode }
                        const response = await axios.post(helper.URL() + 'selectcustomeraddress', requestBody);
                        const result = response.data;
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                self.custAddressList = response.data.Data[0];
                                const filteredDataB = self.custAddressList.filter(item => item.AddressType === 'B');
                                const filteredDataS = self.custAddressList.filter(item => item.AddressType === 'S');
                                const custAddressB = filteredDataB.map(item => item.AddressDetails);
                                const custAddressS = filteredDataS.map(item => item.AddressDetails);
                                self.custAddressListB = filteredDataB;
                                self.custAddressListS = filteredDataS;
                                if (custAddressB.length > 0) {
                                    self.custAddressB = custAddressB.shift();
                                } else {
                                    self.custAddressB = "";
                                }
                                if (custAddressS.length > 0) {
                                    self.custAddressS = custAddressS.shift();
                                } else {
                                    self.custAddressS = "";
                                }
                                self.selectedCustAddressIdB = filteredDataB.map(item => item.AddressIdB);
                                self.selectedCustAddressIdS = filteredDataS.map(item => item.AddressIdS);
                                self.getCustTrspCharge();
                            } else {
                                Swal.fire('Alert!', "Response not found!!!", 'error');
                            }
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            async getCustTrspCharge() {
                try {
                    var self = this;
                    const requestBody = { CustAddressId: self.selectedCustAddressIdB }
                    const response = await axios.post(helper.URL() + 'gettransportationcharge', requestBody);
                    const result = response.data;
                    if (result.Status == true) {
                        if (result.Data && result.Data.length > 0) {
                            self.custTrspChrgPerBox = response.data.Data[0][0].TcPricePerBox;
                            self.calcTotalKart();
                        } else {
                            self.calcTotalKart();
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            getRemarkClass(item) {
                if (item.Remark != '' && item.Remark !== undefined) {
                    return 'btn btn-default btn-mydark btn-dsm'
                } else {
                    return 'btn btn-default btn-dsm'
                }
            },
            remarkPopup(i) {
                if (this.filteredProducts[i].Remark == "") {
                    this.RemarkAllProductText = ""
                } else {
                    this.RemarkAllProductText = this.filteredProducts[i].Remark
                }
                this.remarkAllProductIndex = i
                this.remarkAllProduct = true
            },
            addRemarkToProduct() {
                this.filteredProducts[this.remarkAllProductIndex].Remark = this.RemarkAllProductText
                this.remarkAllProduct = false
            },
            remarkTop5Popup(i) {
                if (this.top5Products[i].Remark == "") {
                    this.RemarkTop5Text = ""
                } else {
                    this.RemarkTop5Text = this.top5Products[i].Remark
                }
                this.remarkTop5Index = i
                this.remarkTop5 = true
            },
            addRemarkToTop5Product() {
                this.top5Products[this.remarkTop5Index].Remark = this.RemarkTop5Text
                this.remarkTop5 = false
            },
            remarkKartPopup(i) {
                if (this.KartProducts[i].Remark == "") {
                    this.RemarkKartText = ""
                } else {
                    this.RemarkKartText = this.KartProducts[i].Remark
                }
                this.remarkKartIndex = i
                this.remarkKart = true
            },
            addRemarkToKartProduct() {
                this.KartProducts[this.remarkKartIndex].Remark = this.RemarkKartText
                this.remarkKart = false
            },
            async selectProductGroup() {
                try {
                    var l = localStorage.getItem("userdata")
                    if (l != null && l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { custcode: userData.CustomerCode };
                        const response = await axios.post(helper.URL() + 'selectcustomerproductgroup', requestBody);
                        const result = response.data;
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                self.itemgroup = [];
                                const groupSize = 6;
                                for (let i = 0; i < result.Data[0].length; i += groupSize) {
                                    self.itemgroup.push(result.Data[0].slice(i, i + groupSize)); // Access the inner array
                                }
                                self.itemgroup = [{ ItemGroupId: 0, ItemGroupName: "All Products" }]
                                result.Data[0].forEach(element => {
                                    self.itemgroup.push(element)
                                });
                            }
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            closeDropdowns() {
                this.isProductMenuOpen = false;
            },
            openDropdown(menu) {
                this[menu + 'MenuOpen'] = true;
            },
            placeOrderClick() {
                this.$router.push({ name: 'productCart' })
            },
            getTotalBox() {
                var t = 0
                this.KartProducts.forEach(p => {
                    t = t + p.BoxOrderQty
                });
                return t
            },
            removeFromKart(index) {
                this.KartProducts.splice(index, 1);
                this.calcTotalKart()
            },
            formattedRoundOff(roundoff) {
                const roundeOff = Math.ceil(roundoff * 100) / 100;
                return roundeOff.toLocaleString('en-IN', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            },
            getOrderVal(p) {
                var rval = 0
                rval = p.PcsOrderQty * p.CustomerLinkItemDP
                return rval.toFixed(2)
            },
            getTax(p) {
                var rval = 0
                rval = (p.PcsOrderQty * p.CustomerLinkItemDP / 100) * p.CustomerLinkItemTaxPer;
                return rval.toFixed(2);
            },
            getTotal(p) {
                var rval = 0
                var t = p.PcsOrderQty * p.CustomerLinkItemDP
                var tax = (t / 100) * p.CustomerLinkItemTaxPer;
                rval = t + tax
                return rval.toFixed(2)
            },
            kartBoxChangeTop5(value, i) {
                var bqty = this.top5Products[i].CustomerLinkItemBoxQty;
                this.top5Products[i].BoxOrderQty = value;
                this.top5Products[i].PcsOrderQty = value * bqty;
            },
            kartBoxChangeCart(value, i) {
                var bqty = this.KartProducts[i].CustomerLinkItemBoxQty;
                this.KartProducts[i].BoxOrderQty = value;
                this.KartProducts[i].PcsOrderQty = value * bqty
                this.updateKart(this.KartProducts[i]);
            },
            kartBoxChange(value, i) {
                var bqty = this.filteredPopupProducts[i].CustomerLinkItemBoxQty;
                this.filteredPopupProducts[i].BoxOrderQty = value;
                this.filteredPopupProducts[i].PcsOrderQty = value * bqty;
            },
            calcTotalKart() {
                var taKart = 0;
                var disKart = 0;
                var trtotalKart = 0;
                var ttaxKart = 0;
                this.KartProducts.forEach(p => {
                    var t = p.PcsOrderQty * p.CustomerLinkItemDP;
                    taKart += t;
                    var clMRP = p.PcsOrderQty * p.CustomerLinkItemPrice;
                    disKart += clMRP - t;
                    trtotalKart += p.BoxOrderQty * this.custTrspChrgPerBox;
                    ttaxKart += (t / 100) * p.CustomerLinkItemTaxPer;
                });

                this.totalAmount = (taKart).toFixed(2);
                this.Discount = (disKart).toFixed(2);
                this.TaxAmount = (ttaxKart).toFixed(2);

                var taddmore = (this.userdata.CustomerMOV - (taKart) - (trtotalKart));
                this.freeshipping = taddmore <= 0;
                this.addMore = taddmore;

                var nettratotal = 0;
                if (this.freeshipping) {
                    this.STransportation = (trtotalKart).toFixed(2);
                    this.Transportation = 0;
                    this.Savings = (trtotalKart + disKart).toFixed(2);
                } else {
                    this.STransportation = 0;
                    this.Transportation = (trtotalKart).toFixed(2);
                    nettratotal = trtotalKart;
                    this.Savings = (disKart).toFixed(2);
                }

                var troundoff = (taKart + ttaxKart + nettratotal);
                var r = Math.round(troundoff);
                var tempr = r - troundoff;
                this.RoundOff = this.formattedRoundOff(tempr);
                this.NetAmount = r;
                this.custTotalDue = this.custPreviousDues + this.NetAmount;

                this.storeKart();
            },
            calcTotalKartWithoutStore() {
                var taKart = 0;
                var disKart = 0;
                var trtotalKart = 0;
                var ttaxKart = 0;
                this.KartProducts.forEach(p => {
                    var t = p.PcsOrderQty * p.CustomerLinkItemDP;
                    taKart += t;
                    var clMRP = p.PcsOrderQty * p.CustomerLinkItemPrice;
                    disKart += clMRP - t;
                    trtotalKart += p.BoxOrderQty * this.custTrspChrgPerBox;
                    ttaxKart += (t / 100) * p.CustomerLinkItemTaxPer;
                });

                this.totalAmount = (taKart).toFixed(2);
                this.Discount = (disKart).toFixed(2);
                this.TaxAmount = (ttaxKart).toFixed(2);

                var taddmore = (this.userdata.CustomerMOV - (taKart) - (trtotalKart));
                this.freeshipping = taddmore <= 0;
                this.addMore = taddmore;

                var nettratotal = 0;
                if (this.freeshipping) {
                    this.STransportation = (trtotalKart).toFixed(2);
                    this.Transportation = 0;
                    this.Savings = (trtotalKart + disKart).toFixed(2);
                } else {
                    this.STransportation = 0;
                    this.Transportation = (trtotalKart).toFixed(2);
                    nettratotal = trtotalKart;
                    this.Savings = (disKart).toFixed(2);
                }

                var troundoff = (taKart + ttaxKart + nettratotal);
                var r = Math.round(troundoff);
                var tempr = r - troundoff;
                this.RoundOff = this.formattedRoundOff(tempr);
                this.NetAmount = r;
                this.custTotalDue = this.custPreviousDues + this.NetAmount;

                //this.storeKart();
            },
            productClick(productDetails, productCode) {
                localStorage.setItem("SelectedProductDetails", JSON.stringify(productDetails))
                this.$router.push({ name: 'product', params: { productCode } })
            },
            loadUserData() {
                var l = localStorage.getItem("userdata")
                if (l != null && l != "") {
                    var userData = JSON.parse(l);
                    this.userdata = userData;
                    this.custPreviousDues = this.userdata.CustomerDue
                    this.CustomerName = this.userdata.CustomerName
                }
                this.KartProducts = [];
                var k = localStorage.getItem("Kart");
                if (l != "" && k != "" && k !== null) {
                    var kobj = JSON.parse(k)
                    if (kobj.CustId == this.userdata.CustomerCode) {
                        this.KartProducts = kobj.items
                    }
                }
            },
            addToKart(p) {
                this.$nextTick(() => {
                    if (p.BoxOrderQty > 0) {
                        var bqty = p.CustomerLinkItemBoxQty
                        p.PcsOrderQty = p.BoxOrderQty * bqty
                        var found = false
                        var foundindex = 0
                        this.KartProducts.forEach((element, index) => {
                            if (element.CustomerLinkItemCode == p.CustomerLinkItemCode) {
                                found = true
                                foundindex = index
                            }
                        });
                        if (found) {
                            this.KartProducts[foundindex].BoxOrderQty += p.BoxOrderQty
                            this.KartProducts[foundindex].PcsOrderQty += p.PcsOrderQty
                        } else {
                            this.KartProducts.push(JSON.parse(JSON.stringify(p)))
                        }
                        this.calcTotalKart();
                        this.storeKart()
                        Swal.fire({
                            type: 'success', title: 'Successfully Added.',
                            showConfirmButton: false, timer: 1000
                        }).then((result) => {
                            if (result.dismiss === Swal.DismissReason.timer) {
                                $("#new_reminder").modal("hide");
                            }
                        });
                        this.searchText = "";
                        this.filteredPopupProducts = [];
                    } else {
                        Swal.fire({
                            type: 'error', title: 'Please enter required quantity.',
                            showConfirmButton: false, timer: 1000
                        }).then((result) => {
                            if (result.dismiss === Swal.DismissReason.timer) {
                                $("#new_reminder").modal("hide");
                            }
                        });
                    }
                });
            },
            updateKart(p) {
                var bqty = p.CustomerLinkItemBoxQty
                p.PcsOrderQty = p.BoxOrderQty * bqty
                var found = false
                var foundindex = 0
                this.KartProducts.forEach((element, index) => {
                    if (element.CustomerLinkItemCode == p.CustomerLinkItemCode) {
                        found = true
                        foundindex = index
                    }
                });
                if (found) {
                    this.KartProducts[foundindex].BoxOrderQty = p.BoxOrderQty
                    this.KartProducts[foundindex].PcsOrderQty = p.PcsOrderQty
                }
                this.calcTotalKart();
                this.storeKart()
                Swal.fire({
                    type: 'success', title: 'Successfully Updated.',
                    showConfirmButton: false, timer: 1000
                }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.timer) {
                        $("#new_reminder").modal("hide");
                    }
                });
            },
            storeKart() {
                var j = { CustId: this.userdata.CustomerCode, items: this.KartProducts }
                localStorage.setItem("Kart", JSON.stringify(j))
            },
            async getCustTop5Products() {
                try {
                    var l = localStorage.getItem("userdata")
                    if (l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { custcode: userData.CustomerCode, itemgroupid: 0 }
                        const response = await axios.post(helper.URL() + 'customerlinkedtopfiveproductsbysales', requestBody);
                        const result = response.data;
                        self.top5Products = [];
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                result.Data[0].forEach(element => {
                                    element.BoxOrderQty = 0
                                    element.PcsOrderQty = 0
                                    element.Remark = ''
                                    self.top5Products.push(element)
                                });
                            }
                        }
                        else {
                            Swal.fire('Alert!', "Response not found!!!", 'error');
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            async getCustAllProducts() {
                try {
                    var l = localStorage.getItem("userdata")
                    if (l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { custcode: userData.CustomerCode, itemgroupid: 0 }
                        const response = await axios.post(helper.URL() + 'customerlinkedproducts', requestBody);
                        const result = response.data;
                        self.allProducts = [];
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                result.Data.forEach(element => {
                                    element.BoxOrderQty = 0
                                    element.PcsOrderQty = 0
                                    element.Remark = ''
                                    self.allProducts.push(element)
                                });
                            }
                        }
                        else {
                            Swal.fire('Alert!', "Response not found!!!", 'error');
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            async getCustProductsByGroup(selectedGroupId) {
                try {
                    var l = localStorage.getItem("userdata")
                    if (l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { custcode: userData.CustomerCode, itemgroupid: selectedGroupId }
                        const response = await axios.post(helper.URL() + 'customerlinkedproducts', requestBody);
                        const result = response.data;
                        self.allProducts = [];
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                result.Data.forEach(element => {
                                    element.BoxOrderQty = 0
                                    element.PcsOrderQty = 0
                                    self.allProducts.push(element)
                                });
                            }
                        }
                        else {
                            Swal.fire('Alert!', "Response not found!!!", 'error');
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }
    };
</script>

<style scoped>
    .styled-button-top5 {
        background-color: #2ca9df;
        border-radius: 3px;
        border: none;
        color: white;
        padding: 10px 27px;
        text-align: center;
        text-decoration: none;
        display: flex;
        font-size: 14px;
        cursor: pointer;
        margin-left: 0px;
        margin-top: 3px;
        height: 30px;
        width: 76px;
    }
        .styled-button-top5:focus {
            outline: none
        }

    .sub-column-text-top5 {
        margin-top: 10px;
        flex: 1;
        margin-left: 10px;
        font-weight: 500;
        color: #454545;
        font-size: 10px;
    }
    /* Popup Styling for product search*/
    .popup {
        position: absolute;
        top: calc(100% + 5px); 
        left: 0;
        width: 103.55%;
        margin-left: -13px;
        max-height: 200px;
        overflow-y: auto;
        background-color: #fff;
        border: 1px solid #ccc;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        padding: 10px;
    }

        .popup ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        .popup li {
            padding: 5px 10px;
            cursor: pointer;
        }

            .popup li:hover {
                background-color: #f5f5f5;
            }


    .default-color {
        background-color: #2CA9DF;
    }

    .last-ordered-color {
        background-color: blue;
    }

    .user-data-color {
        background-color: #2CA9DF;
    }

    .btn-mydark {
        color: rgb(54, 54, 54) !important;
        background-color: #a8ddf4 !important;
        color: rgb(54, 54, 54) !important;
    }

    .big-buttonVRemove {
        display: inline-block;
        font-size: 13px;
        background-color: #fff;
        color: #454545;
        text-decoration: none;
        text-align: center;
        border-radius: 5px;
        margin-top: 10px;
        margin-left: 30px;
        border: 1px solid #737373;
        width: 91px;
        padding: 3px 8px;
    }

    .align-right {
        justify-content: flex-end;
    }

    .align-center {
        align-items: center;
    }

    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .parent-div {
        padding: 20px;
        margin-bottom: -140px;
    }

    .parent-div-withAllProduct {
        padding: 20px;
        margin-bottom: -244px;
        width: 92.5%
    }

    .rowFastmove {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        margin-bottom: 20px;
        gap: 15px;
        margin-left: 10px;
    }

    .column1-Fm {
        width: 75%;
        background-color: #fff;
        flex: 1;
        height: 183px;
        padding: 1px
    }

    .column1-Fm-row3 {
        width: 98.5%;
        background-color: #fff;
        flex: 1;
        height: 100%;
        padding: 1px;
        margin-left: 21px;
        margin-top: 100px;
        overflow-x: auto
    }

    .yourcartrow {
        width: 91%;
        flex: 1;
        height: auto;
        padding: 1px;
        margin-left: 1px;
        margin-top: 100px;
    }

    .yourcartrowwithoutallproducts {
        width: 86%;
        max-height: 687px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        padding: 1px;
        margin-top: 10px;
        overflow-x: auto;
    }

    .cart-container {
        width: 75%;
        background-color: #fff;
        flex: 1;
        height: 183px;
        padding: 1px
    }

    .column2-Fm {
        width: 23%;
        height: 183px;
    }

    .sub-column {
        flex: 1;
        margin-bottom: 5px;
    }

    .sub-row {
        display: flex;
        background-color: #fff;
    }

    .sub-column {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .sub-column1 {
        flex: 0 0 36%;
        display: flex;
    }

    .sub-column-image {
        flex: 0 0 auto;
        background-color: #f3f3f3;
        padding-top: 5px;
    }

    .sub-column-text {
        flex: 1;
        margin-left: 10px;
        font-size: 14px;
        font-weight: 500;
        color: #454545;
        margin-top: 5px;
    }

    table.sub-row {
        width: 100%;
        border-collapse: collapse;
    }

        table.sub-row td {
            padding: 10px;
            border-bottom: 1px solid #2ca9df;
            display: table-cell;
        }

    .counter-label {
        margin-bottom: 5px;
    }

    counter-label {
        margin-right: 10px; /* Add some spacing between the label and the counter */
    }

    .counter {
        display: flex;
        align-items: center;
    }

        .counter span {
            cursor: pointer;
            padding: 5px;
        }

    .totalpcs {
        /*margin-left: 116px;*/
        margin-left: 85px;
        font-size: 12px;
        width: 150px;
    }

    .styled-button {
        background-color: #2ca9df;
        border: none;
        color: white;
        padding: 10px 27px;
        text-align: center;
        text-decoration: none;
        display: flex;
        font-size: 14px;
        cursor: pointer;
        margin-top: 47px
    }

    .fit-imagefm {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media screen and (max-width: 768px) {
        .fit-imagefm {
            width: 100%;
        }
    }

    .sub-column {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .sub-column-image {
        flex: 0 0 auto;
        background-color: #f3f3f3;
    }

    .sub-column-text {
        flex: 1;
        margin-left: 10px;
        font-size: 14px;
        font-weight: 500;
    }

    .product-table {
        margin-bottom: 20px;
    }

    .custom-row {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-left: 10px;
        align-items: flex-start;
    }

    .custom-columnsearch {
        background-color: #fff;
        padding: 20px 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        margin: -4px;
        margin-left: 30px;
    }

    @media (max-width: 600px) {
        .rowFastmove {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .columnBox {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 290px;
    }

    .counter {
        margin-right: 55px;
    }

    .totalpcs {
        margin-right: 119px;
    }

    .container12 {
        display: flex;
        align-items: center; /* Vertically center the items */
    }

    .counter {
        display: flex;
        align-items: center;
    }

    .counter-value {
        font-size: 18px;
        background-color: #fff;
        width: 128px;
        padding: 6px;
        text-align: center;
    }

    .minus-btn {
        cursor: pointer;
        font-size: 20px;
        padding: 5px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: #eee;
    }

    .plus-btn {
        cursor: pointer;
        font-size: 20px;
        padding: 4px;
        background-color: #eee;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .minus-btn:hover {
        background-color: #eee;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .plus-btn:hover {
        background-color: #eee;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .custom-column-new {
        display: flex;
        width: 75%;
        margin: auto 0;
        background: #F5F5F5
    }

    .custom-search-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .custom-dropdown {
        width: 150px;
        padding: 10px;
        margin-right: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: #2ca9df;
        color: #fff;
        border: 1px solid #737373;
    }

    .custom-search-input {
        flex-grow: 1;
        margin: 0 10px;
        padding: 9px;
        margin-left: 0;
        border: 1px solid #737373;
    }


    .custom-search-button {
        background-color: #007bff;
        border: none;
        color: #fff;
        padding: 10px 10px;
        cursor: pointer;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-left: -5px;
        margin-left: -10px;
        margin-right: 36px;
        height: 40px;
    }

    .custom-frequently-ordered-btn {
        border: none;
        color: #454545;
        padding: 10px 10px;
        cursor: pointer;
        margin-right: 5px;
        border-radius: 5px;
        border: 1px solid #454545;
        margin-right: 20px;
    }

    .custom-frequently-ordered-btn {
        color: #fff;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
    }

        .custom-frequently-ordered-btn.selected {
            background-color: blue;
            color: #fff;
        }

    .custom-last-ordered-btn {
        color: #fff;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        border-radius:5px
    }
  
   
        .custom-last-ordered-btn.selected {
            background-color: #007bff; 
            color: #fff;
        }


    .custom-column-new {
        display: flex;
        width: 74.6%;
        background-color: #eee;
        margin: auto;
        flex-direction: row; /* Display columns in a row */
        margin-top: 20px;
        margin-left: 10px;
        padding: 10px 9px;
    }

        .custom-column-new > .custom-column {
            flex: 1;
            height: 160px;
            margin: 0px;
            padding: 0px 0px;
            color: #000;
            font-weight: bold;
            text-align: center;
        }

    .custom-image {
        max-width: 70%;
        max-height: 100%;
        object-fit: contain;
        margin-top: 15px;
        background-color: #f3f3f3;
    }

    .custom-content {
        text-align: left;
        line-height: 20px;
    }

        .custom-content span {
            font-size: 14px;
            font-weight: 600;
        }

            .custom-content span.strikethrough {
                text-decoration: line-through;
            }

            .custom-content span.highlight {
                color: #454545;
                font-weight: bold;
                font-size: 16px;
            }

    .custom-columnBox {
        display: flex;
        align-items: center;
    }

    .custom-counter {
        margin-left: 25px;
    }

    .custom-counter-box {
        flex: 1;
        padding-right: 20px;
        width: 240px;
    }

    .custom-counter-label {
        margin-top: 10px;
        display: block;
        font-weight: bold;
        margin-left: 25px;
    }

    .custom-counter {
        display: flex;
        align-items: center;
        border: 1px solid #737373;
        border-radius: 5px;
        margin-bottom: 10px;
        width: 150px;
        margin-left: 47px;
        margin-top: 10px;
    }


  

    .custom-counter span {
        display: inline-block;
        padding: 5px;
        cursor: pointer;
    }

    .custom-minus-btn,
    .custom-plus-btn {
        font-weight: bold;
    }

    .custom-counter-row {
        display: flex;
        align-items: center;
        margin-top: 15px;
        width: 300px;
    }

    .custom-checkbox-label {
        margin-right: -1px;
    }

        .custom-checkbox-label input[type="checkbox"] {
            margin-right: 1px;
        }

    .custom-checkbox-label {
        margin-right: 20px;
        display: flex;
        align-items: center;
    }

        .custom-checkbox-label input[type="checkbox"] {
            margin-right: 5px;
            font-size: 12px;
        }

    .custom-columnButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        background-color: #eee;
        padding: 10px;
    }

    .custom-order-value {
        margin-bottom: 10px;
        font-weight: bold;
        margin-left: 15px;
    }

    .custom-button-containerRemark,
    .custom-button-containerQ {
        margin-bottom: 20px;
        margin-left: 40px;
    }

    .custom-big-buttonQ {
        background-color: #007bff;
        color: #fff;
        padding: 8px 20px;
    }

    .custom-big-buttonRemark {
        background-color: #fff;
        color: #000;
        margin-bottom: 40px;
        padding: 5px;
        border-radius: 5px;
        border: 1px solid #454545;
    }

        .custom-big-buttonRemark:hover,
        .custom-big-buttonQ:hover {
            background-color: #0056b3;
        }

    .custom-minus-btn {
        background-color: #ccc;
        padding: 4px 6px;
        border-radius: 3px;
    }

    .custom-plus-btn {
        background-color: #ccc;
        padding: 4px 6px;
        border-radius: 3px;
    }

    .custom-mov {
        font-size: 16px;
    }

    .custom-mov {
        font-size: 16px;
    }

    .custom-freeshipping {
        font-size: 16px;
        color: #2ca9df;
        font-weight: 700;
        margin-top: 10px;
    }

    .custom-underline {
        border-bottom: 1px solid #afafaf;
        margin-bottom: 5px;
    }

    .custom-rowPD {
        display: flex;
        /*align-items: center;*/
    }

    .custom-columnPrice,
    .custom-columnSummary,
    .custom-columnAccount .custom-columnDetails {
        flex: 50%;
    }

    .custom-columnPrice,
    .custom-columnSummary,
    .custom-columnAccount .custom-columnDetails {
        flex: 50%;
    }

        .custom-columnPrice h4,
        .custom-columnDetails h4,
        .custom-columnSummary h4,
        .custom-columnAccount h4 {
            font-weight: 600;
        }

        .custom-columnPrice h4,
        .custom-columnDetails h4,
        .custom-columnSummary h4,
        .custom-columnAccount h4 {
            font-weight: 600;
        }

    .custom-button-Cartcontainer {
        display: flex;
        justify-content: center;
        margin-top: -5px;
    }

    .custom-buttonCart {
        padding: 10px 69px;
        background-color: #2ca9df;
        color: white;
        font-weight: bold;
        border: none;
        cursor: pointer;
        margin-bottom: 0px;
        margin-top: 30px;
    }

    .align-left {
        text-align: left;
    }

    .button-container {
        display: flex;
        justify-content: flex-start;
        margin-top: 10px
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 10px;
    }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: grey;
        }


    .parent-div {
        width: 94%
    }



    .sub-column1 {
        flex: 2;
    }

    .sub-column-image {
        width: 100px;
        height: 90px;
        background-color: #f3f3f3;
    }

    .product-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .sub-column-text {
        margin-top: 10px;
    }

    .product-name {
        font-weight: bold;
        font-size: 14px;
        color: #737373;
    }

    .product-info {
        color: #737373;
    }

    .original-price {
        text-decoration: line-through;
    }

    .discounted-price {
        font-size: 16px;
        font-weight: 600;
    }


    .parent-container {
        display: flex;
    }

    .column-75 {
        flex: 3;
        display: flex;
        flex-direction: column;
    }

    .column-25 {
        flex: 1;
    }




    .row2style {
        height: 165px;
        width: 90.5%;
        overflow-x: auto;
        flex: 0 0 auto;
        margin-top: 5px;
        margin-bottom: -85px;
        background: rgb(243, 243, 243);
        -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        margin-left: 1px;
        white-space: nowrap;
    }

    .custom-dropdown:focus,
    .custom-search-input:focus,
    .custom-frequently-ordered-btn:focus,
    .custom-last-ordered-btn:focus {
        outline: none;
    }



    .navigationTop {
        display: flex;
        align-items: center;
        padding: 10px
    }

        .navigationTop a {
            text-decoration: none;
            color: #454545;
        }

            .navigationTop a:hover {
                color: #2ca9df; /* Change the color on hover to your desired color */
            }

        .navigationTop span {
            margin: 0 5px;
            color: #454545;
        }

</style>