import App from './App.vue'
import router from './router'
import axios from 'axios'
import { createApp } from 'vue';
import 'sweetalert2/dist/sweetalert2.min.js';
import store from './store';

const app = createApp(App);

app.config.globalProperties.$http = axios;
app.config.productionTip = false;
axios.defaults.withCredentials = true;

app.use(router);
app.use(store)
app.component('App', App);
app.mount('#app');

window.Swal = window.Sweetalert2;
