<template>
    <div>
        <div class="modal-container">
            <div class="modal-header" style="display: flex; justify-content: space-between;">
                <h6 class="title">Quotation Request</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>{{ selectedItem }}</p>
                <div class="form-group">
                    <!--<label for="name">Name <span class="text-danger">*</span></label>-->
                    <input type="text" v-model="name" class="form-control" id="name" placeholder="Name">
                </div>

                <div class="form-group">
                    <!--<label for="phone">Phone <span class="text-danger">*</span></label>-->
                    <input type="text" v-model="phone" class="form-control" id="phone" placeholder="Phone">
                </div>

                <div class="form-group">
                    <!--<label for="email">Email <span class="text-danger">*</span></label>-->
                    <input type="text" v-model="email" class="form-control" id="email" placeholder="Email">
                </div>

                <div class="form-group">
                    <!--<label for="message">Message</label>-->
                    <!--<p>{{ selectedItem }}</p>-->
                    <textarea v-model="message" class="form-control"></textarea>
                </div>

                <p class="text-danger">{{ Error }}</p>

                <div align="center" style="margin-top: 27px;">
                    <button style="width: 200px; color: #fff; font-size: 14px; font-family: Inter; padding-top: 8px; padding-bottom: 8px;background: #2CA9DF" type="button" class="btn btn-info btn-" @click="sendDetails()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import helper from '../helper'
    export default {
        name: 'quote',
        data() {
            return {
                name: "",
                phone: "",
                message: "",
                email: "",
                Error: "",
                title: 'quote',
                selectedItem: ''
            }
        },
        created: function () {

        },
        mounted() {
            this.selectedItem = localStorage.getItem("selectedItem");
        },
        methods: {
            async sendDetails() {
                try {
                    var self = this
                    self.Error = ""
                    if (self.name == "") {
                        self.Error = "* Name Can't be empty!";
                        return false
                    }
                    var phoneno = /^\d{10}$/;
                    if (!self.phone.match(phoneno)) {
                        self.Error = "* Phone Number should be valid!";
                        return false
                    }
                    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(self.email)) {
                    } else {
                        self.Error = "* Email should be valid!";
                        return false
                    }
                    if (self.message == "") {
                        self.Error = "* Message Can't be empty!";
                        return false
                    }
                    const requestBody = { name: this.name, phone: this.phone, email: this.email, message: this.message, product: this.selectedItem }
                    const response = await axios.post(helper.URL() + 'quotationmail', requestBody);
                    const result = response.data;
                    if (result.Status == true) {
                        alert("Details Submitted Successfully!!!")
                        self.name = ""
                        self.phone = 0
                        self.email = ""
                        self.message = ""
                        self.selectedItem = ""
                    } else {
                        Swal.fire('Alert!', "Response not found!!!", 'error');
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }
    }
</script>

<style scoped>
    .modal-container {
        background: rgba(211, 211, 211, 0.8);
        background:#F3F3F3;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 500px;
        width: 100%;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        border: 4px solid rgba(253, 253, 253, 0.31);
    }



    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        color:#737373;
        font-family:Inter
    }

    .title {
        font-size: 24px;
        color: ##737373;
        margin-bottom: 0;
        font-family: Inter
    }

    .modal-body {
        margin-top: -17px;
        border-radius:10px
    }
    .modal-header .close {
        margin-top: -2px;
    }
    .form-group {
        margin-bottom: 20px;
    }

    .form-control {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ddd;
        border-radius: 5px;
    }

        .form-control:focus {
            outline: none;
            border-color: #007bff;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }

    .text-danger {
        color: #ff0000;
    }

    .btn-submit {
        width: 200px;
        color: #fff;
        font-size: 14px;
        font-family: Inter, sans-serif;
        padding: 8px;
        background-color: #2CA9DF;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
    }

        .btn-submit:hover {
            background-color: #0056b3;
        }

    /* Additional styles for responsiveness */
    @media (max-width: 480px) {
        .modal-container {
            max-width: 100%;
        }

        .form-control {
            font-size: 14px;
        }

        .btn-submit {
            width: 100%;
        }
    }
</style>
