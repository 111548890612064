<template>
    <div>
        <div class="container">
            <!-- **********************************************start of slider part************************************************ -->
            <div style=" margin-top: 40px; width: 1019px;margin-left: 36px;" id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner" style="padding:0px">
                    <div class="carousel-item active">
                        <img class="d-block w-100"
                             src="../assets/img/Banner_1.png"
                             alt="First slide"
                             style="width: 1256px; height: 223px; margin-top: 40px; " />
                    </div>
                    <div class="carousel-item">
                        <img class="d-block w-100"
                             src="../assets/img/Banner_2.png"
                             alt="Second slide"
                             style="width: 1256px; height: 223px; margin-top: 40px;" />
                    </div>
                    <div class="carousel-item">
                        <img class="d-block w-100"
                             src="../assets/img/Banner_3.png"
                             alt="Third slide"
                             style="width: 1256px; height: 223px; margin-top: 40px; " />
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev" style="margin-left: -0px; margin-top: 43px; width: fit-content; ">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next" style="margin-right: -0px; margin-top: 43px; width: fit-content; ">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
            <!-- **********************************************End of slider part************************************************************** -->
            <div style="background: #F5F5F5; width: 1017px; margin-left: 37px;">
                <div style="background: #fff; margin-top: 20px; padding-left: 40px; padding-right: 40px; padding-top: 20px; padding-bottom: 20px;">
                    <!--**************************** starting of circle******************************************************* -->
                    <div style="background-color: #fff; ">
                        <div class="row" style="display: flex;padding:0">
                            <div id="carouselExampleControls1" class="carousel slide" v-if="itemgroup.length">
                                <div class="carousel-inner" style="padding: 0px; ">
                                    <div class="carousel-item" :class="{ active: index==0 }" v-for="(product,index) in itemgroup" :key="index">
                                        <div id="carousel-card" class="row  align-items-center justify-content-center">
                                            <div class="col-md-2" v-for="item in product" :key="item.ItemGroupId">
                                                <div class="hom-boxcircle" @click="handleProductClick(item.ItemGroupId)">
                                                    <!--<img class="img-fluid mx-auto d-block" alt="100%x280" src="static/assets/img/p1.png">-->
                                                    <div class="spanmyicon" style="margin-top: 5px">
                                                        <img class="circleimg" src="../assets/img/surgicalgown.png" style="margin-top: -31px; margin-left: 19px;" />
                                                    </div>
                                                    <p style="width:131px;text-align:center;margin-left:0px">{{item.ItemGroupName}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a class="carousel-control-prev" href="#carouselExampleControls1" role="button" data-slide="prev" style="margin-left: -20px; width: fit-content; height: fit-content; margin-top: 58px">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#carouselExampleControls1" role="button" data-slide="next" style="margin-right: -20px; width: fit-content; height: fit-content; margin-top: 58px ">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Next</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div v-if="products.length > 0">
                        <div v-for="(row, rowIndex) in Math.ceil(products.length / 6)" :key="rowIndex" class="row">
                            <div v-for="(item, itemIndex) in products.slice(rowIndex * 6, (rowIndex * 6) + 6)" :key="itemIndex" class="col-md-4 col-lg-2 col-sm-6" style="background:#fff">
                                <div class="hom-box" style="padding: 2px; text-align: center; box-shadow: 0 5px 4px #d6d4d4; width: 149px; height: 262px; display: flex; flex-direction: column; align-items: center; position: relative; border-radius: 8px; margin-bottom: 20px; margin-left: -14px; ">
                                    <div class="spanmyicon12">
                                        <img style="cursor: pointer; background: #eee; width: 129px; height: 146px; border-radius: 0px; padding: 8px; margin-left: 17px; margin-left: 5px; width: 93%; height: 100%; " :src="'data:image/png;base64,' + item.CustomerLinkItemImageData " ratio="1">
                                    </div>
                                    <div style="color: rgba(69, 69, 69, 1);
                font-weight: bold;
                margin-top: 10px;
                line-height: 1.2;
                font-size: 9px;
                width: 115px;
                text-transform: uppercase;

                font-family: inherit;

            " class="product-title">{{ item.ItemNameAndCode }}</div>
                                    <div style="position: absolute;
                bottom:10px;
                left: 52%;
                transform: translateX(-50%);
                /* Add any other styles for the button here */
                ">
                                        <button class="btnGetQuote" @click="handleQuoteClick(item.ItemNameAndCode)">Get Quote</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div v-else>
                        <p>{{ NoProducts }}</p>
                    </div>
                </div>
                <div style="background: #fff;margin-top:20px;padding:10px 40px 20px 40px">
                    <div>
                        <br />
                        <h4 class="textsoln" style="padding-bottom:40px">
                            We are the one-stop solution for all your surgical gown, drapes &
                            packs products
                        </h4>
                    </div>
                    <div class="row" style="padding: 5px 0px 0px 0px;justify-content: space-between;">
                        <div class="col-md-4 col-lg-2 col-sm-6">
                            <div class="hom-box1"
                                 style="border-radius: 50%; height: 145px; width: 145px">
                                <div class="spanmyicon2">
                                    <div clas="circle" style="margin-bottom: 25px">
                                        <img src="../assets/img/Group.png" />
                                        <br />
                                        Surgeon Care
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-2 col-sm-6">
                            <div class="hom-box1"
                                 style="border-radius: 50%; height: 145px; width: 145px">
                                <div class="spanmyicon2">
                                    <div clas="circle" style="margin-bottom: 25px">
                                        <img src="../assets/img/Group(1).png" />
                                        <br />
                                        Ortho Care
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-2 col-sm-6">
                            <div class="hom-box1"
                                 style="border-radius: 50%; height: 145px; width: 145px">
                                <div class="spanmyicon2">
                                    <div clas="circle" style="margin-bottom: 25px">
                                        <img src="../assets/img/Group(2).png" />
                                        <br />
                                        Cardio Care
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-2 col-sm-6">
                            <div class="hom-box1"
                                 style="border-radius: 50%; height: 145px; width: 145px">
                                <div class="spanmyicon2">
                                    <div clas="circle" style="margin-bottom: 25px">
                                        <img src="../assets/img/Group(3).png" />
                                        <br />
                                        Ortho Care
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-2 col-sm-6">
                            <div class="hom-box1"
                                 style="border-radius: 50%; height: 145px; width: 145px">
                                <div class="spanmyicon2">
                                    <div clas="circle" style="margin-bottom: 25px">
                                        <img src="../assets/img/Group(4).png" />
                                        <br />
                                        Gynaec Care
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-2 col-sm-6">
                            <div class="hom-box1"
                                 style="border-radius: 50%; height: 145px; width: 145px">
                                <div class="spanmyicon2">
                                    <div clas="circle" style="margin-bottom: 25px">
                                        <img src="../assets/img/Group(5).png" />
                                        <br />
                                        Neuro Care
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="padding: 15px 0px 15px 0px">
                        <div class="col-md-4 col-lg-2 col-sm-6">
                            <div class="hom-box1"
                                 style="border-radius: 50%; height: 145px; width: 145px">
                                <div class="spanmyicon2">
                                    <div clas="circle" style="margin-bottom: 25px">
                                        <img src="../assets/img/Group(6).png" />
                                        <br />
                                        URO Care
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-2 col-sm-6">
                            <div class="hom-box1"
                                 style="border-radius: 50%; height: 145px; width: 145px">
                                <div class="spanmyicon2">
                                    <div clas="circle" style="margin-bottom: 25px">
                                        <img src="../assets/img/Group(7).png" />
                                        <br />
                                        <div style="display: flex; flex-direction: column;margin-top:3px;margin-bottom:-4px">
                                            <span style="font-size: 14px; line-height: 12px">General Surgery</span>
                                            <span style="font-size: 14px; line-height: 12px">Care</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-2 col-sm-6">
                            <div class="hom-box1"
                                 style="border-radius: 50%; height: 145px; width: 145px">
                                <div class="spanmyicon2">
                                    <div clas="circle" style="margin-bottom: 25px">
                                        <img src="../assets/img/Group(8).png" />
                                        <br />
                                        Transplant Care
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-2 col-sm-6">
                            <div class="hom-box1"
                                 style="border-radius: 50%; height: 145px; width: 145px">
                                <div class="spanmyicon2">
                                    <div clas="circle" style="margin-bottom: 25px">
                                        <img src="../assets/img/Group(9).png" />
                                        <br />
                                        Ophtha Care
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-2 col-sm-6">
                            <div class="hom-box1"
                                 style="border-radius: 50%; height: 145px; width: 145px">
                                <div class="spanmyicon2">
                                    <div clas="circle" style="margin-bottom: 25px">
                                        <img src="../assets/img/Group(10).png" />
                                        <br />
                                        ENT Care
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-2 col-sm-6">
                            <div class="hom-box1"
                                 style="border-radius: 50%; height: 145px; width: 145px">
                                <div class="spanmyicon2">
                                    <div clas="circle" style="margin-bottom: 25px">
                                        <img src="../assets/img/Group(11).png" />
                                        <br />
                                        Safty Care
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="background: #fff;margin-top:20px;padding:10px 40px 0px 40px">
                    <div style="background:#fff;">
                        <div class="textsoln1">
                            <h4 style="color:rgba(69, 69, 69, 1);font-family: Inter;margin-top:15px;">
                                We are serving lakhs of customers with their wholesale buying needs,
                                in India and 15+ other countries.
                            </h4>
                        </div>
                        <div class="row">
                            <div class="col homtbox lbar" style="text-align: center;margin-top:16px">
                                <h4 style="color: #2ca9df;font-family: Inter;font-weight: 500;font-size: 24px;line-height: 1.2;width: 100%;">
                                    15+
                                </h4>
                                <p style="color: #454545;font-family: Inter;font-size: 14px;line-height: 1.2;width: 100%;">
                                    Medical specialties catered to
                                </p>
                            </div>
                            <div class="col homtbox lbar" style="text-align: center;margin-top:16px">
                                <h4 style="color: #2ca9df;font-family: Inter;font-weight: 500;font-size: 24px;line-height: 1.2;width: 100%;">
                                    300+
                                </h4>
                                <p style="color: #454545;font-family: Inter;font-size: 14px;line-height: 1.2;width: 100%;">
                                    Active channel partners globally
                                </p>
                            </div>
                            <div class="col homtbox lbar" style="text-align: center;margin-top:16px">
                                <h4 style="color: #2ca9df;font-family: Inter;font-weight: 500;font-size: 24px;line-height: 1.2;width: 100%;">
                                    15+
                                </h4>
                                <p style="color: #454545;font-family: Inter;font-size: 14px;line-height: 1.2;width: 100%;">
                                    Countries being <br />exported to
                                </p>
                            </div>
                            <div class="col homtbox lbar" style="text-align: center;margin-top:16px">
                                <h4 style="color: #2ca9df;font-family: Inter;font-weight: 500;font-size: 21px;line-height: 1.2;width: 100%;">
                                    50K+
                                </h4>
                                <p style="color: #454545;font-family: Inter;font-size: 14px;line-height: 1.2;width: 100%;">
                                    Reaching healthcare professionals daily
                                </p>
                            </div>
                            <div class="col homtbox" style="text-align: center;margin-top:16px">
                                <h4 style="color: #2ca9df;font-family: Inter;font-weight: 500;font-size: 24px;">
                                    10+
                                </h4>
                                <p style="color: #454545; font-family: Inter; font-size: 14px">
                                    Years of experience
                                </p>
                            </div>
                        </div>
                    </div>
                    <br />
                </div>
                <div class="partner" style="background: #fff;margin-top:20px; margin-bottom: 20px">
                    <div class="carouselboxbanner">
                        <div class="carouselboxbannerbg">
                            <h1 style="font-size: 16px; background: rgba(233, 39, 41, 1); color: #ffffff; width: 134px; height: 34px; border-radius: 8px; text-align: center; line-height: 34px; font-family: Inter; font-weight: 500; opacity: 1; margin-top: 41px">
                                Partner with us
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="quoteModal" tabindex="-1" role="dialog" aria-labelledby="quoteModalLabel" aria-hidden="true" style=" background-color: rgba(0, 0, 0, 0.4); margin-top: 64px;margin:0 auto">
                <div class="modal-dialog" role="document">
                    <div class="modal-content" style="border-radius: 10px;margin-top:50px ">
                        <div class="modal-body" style=" background: rgba(255, 255, 255, 0.80);border-radius:10px">
                            <quote></quote>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    import helper from '../helper'
    import quote from './quote.vue';
    export default {
        name: 'homeUser',
        components: {
            quote
        },
        data() {
            return {
                hideo: true,
                products: [],
                itemgroup: [],
                Error: "",
                loadingProducts: false,
                CustomerCode: "",
                CustomerName: "",
                ReportData: null,
                ReportDataContentType: "",
                customImageMaxSize: 10,
                CampaignImage: "",
                NoProducts: "",
                selectedItem: ''
            }
        },
        created: async function () {
            this.NoProducts = "";
        },
        beforeMount: async function () {
            this.selectProductGroup();
            //this.loaduserdata();
        },
        methods: {
            async selectProducts(groupid) {
                try {
                    var self = this;
                    const requestBody = { itemgroupid: groupid }
                    const response = await axios.post(helper.URL() + 'selectproducts', requestBody);
                    const result = response.data;
                    self.products = [];
                    this.NoProducts = "";
                    if (result.Status == true) {
                        self.hideo = false
                        if (result.Data[0].length > 0) {
                            result.Data[0].forEach(element => {
                                self.products.push(element)
                            });
                        }
                        else {
                            this.NoProducts = "No products available for this group.";
                        }
                    }
                    else {
                        Swal.fire('Alert!', "Response not found!!!", 'error');
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            async selectProductGroup() {
                try {
                    var self = this;
                    const requestBody = { itemgroupid: 0 };
                    const response = await axios.post(helper.URL() + 'selectproductgroup', requestBody);
                    const result = response.data;
                    if (result.Status == true) {
                        if (result.Data && result.Data.length > 0) {
                            self.itemgroup = [];
                            const groupSize = 6;
                            for (let i = 0; i < result.Data[0].length; i += groupSize) {
                                self.itemgroup.push(result.Data[0].slice(i, i + groupSize)); // Access the inner array
                            }
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            handleProductClick(groupid) {
                this.selectProducts(groupid);
            },
            handleQuoteClick(itemNameAndCode) {
                this.selectedItem = itemNameAndCode;
                localStorage.setItem("selectedItem", this.selectedItem);
                $('#quoteModal').modal('show');
            }
        }
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .carousel-control-prev .carousel-control-prev-icon,
    .carousel-control-next .carousel-control-next-icon {
        filter: invert(50%);
    }

    .row {
        padding: 0
    }

        .row img {
            max-height: 15em;
        }

    .lts {
        font-size: 12px;
    }

    .row h3 {
        cursor: pointer;
    }

    .hom-box {
        background: #ffffff;
        /*   box-shadow: #94DCFB 0px 0px 10px;*/
        box-sizing: border-box;
        border-radius: 8px;
        /* padding: 30px 15px; */

        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        line-height: 15px;
        letter-spacing: 0px;
        text-align: center;
        color: #454545;
        height: 212px;
    }

    .spanmyicon {
        /* height: 80px; */
        height: auto;
        display: grid;
        place-items: center;
        align-items: end;
        border-radius: 50%;
    }

        .spanmyicon img {
            margin-top: 4px;
            margin-bottom: 7.7px;
        }

        .spanmyicon:hover {
            height: auto;
            display: grid;
            place-items: center;
            align-items: end;
            border-radius: 50%;
            /*border: 2px solid rgba(239, 174, 174, 1);*/
        }

    .carouselboxbanner {
        background-image: url("../assets/img/FooterArtBoard.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 148px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .carouselboxbannerbg {
        text-align: center;
        color: #fff;
        height: 274px;
        text-align: center;
        padding: 80px 0px;
    }

    .partner {
        width: 100%;
        height: auto;
        overflow: hidden;
    }

    .carouselbox {
        background-color: #fff;
        padding: 5px 20px 5px 20px;
    }

    .carousel-control-prev {
        justify-content: normal;
        color: #000;
    }

    .carousel-control-next {
        justify-content: right;
        color: #000;
    }

    .carouselinner {
        margin: 5px 25px 5px 25px;
        width: 100%;
    }

    #carousel-card .card {
        border: 0.5px solid #fff !important;
        text-align: center;
    }

        #carousel-card .card:hover {
            border: 0.5px solid #00aeef !important;
        }

    #carousel-card .card-title {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
    }

    .homtbox {
        padding: 25px;
    }

    .lbar {
        border-right: solid 2px #00aeef;
    }

    .img-fluid {
        width: 124px !important;
    }

    .homesmbanner1 {
        background-image: url("../assets/img/home_smbanner_1.png");
        background-repeat: no-repeat;
        width: 679px;
        height: 313px;
        margin-right: 5px;
    }

    .homesmbanner2 {
        background-image: url("../assets/img/home_smbanner_2.png");
        background-repeat: no-repeat;
        width: 679px;
        height: 313px;
        margin-left: 5px;
    }

    .texthomesmbanner {
        padding: 75px 75px 10px 75px;
        background: -moz-linear-gradient( top, rgba(255, 255, 255, 0) 2%, rgba(255, 255, 255, 0) 12%, rgba(255, 255, 255, 0.55) 40%, rgba(255, 255, 255, 1) 63%, rgba(255, 255, 255, 1) 75% ); /* FF3.6-15 */
        background: -webkit-linear-gradient( top, rgba(255, 255, 255, 0) 2%, rgba(255, 255, 255, 0) 12%, rgba(255, 255, 255, 0.55) 40%, rgba(255, 255, 255, 1) 63%, rgba(255, 255, 255, 1) 75% ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient( to bottom, rgba(255, 255, 255, 0) 2%, rgba(255, 255, 255, 0) 12%, rgba(255, 255, 255, 0.55) 40%, rgba(255, 255, 255, 1) 63%, rgba(255, 255, 255, 1) 75% ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }

    .new-user,
    .exi-user {
        font-size: 24px;
        color: #000;
    }

    .req-for-reg,
    .sign-in {
        font-family: Roboto;
        font-size: 18px;
        font-weight: 400;
        background-color: #707071;
        padding: 10px 30px;
    }

    .btn-sign-in {
        font-size: 16px;
        font-family: Roboto;
        background-color: #00aeef !important;
        border-radius: 4px;
        padding: 12px 25px;
    }

    .homtbox p {
        font-weight: 500;
        font-family: Roboto;
    }

    .btn-cont-us {
        font-family: Roboto;
        font-size: 18px;
        font-weight: 500;
        border-radius: 4px;
        padding: 10px 65px;
        background: #00aaef !important;
    }

    /* *******************************************newly addedd css but some changes are done in top also********************************************************** */
    .container {
        height: auto;
        width: 1440px;
        padding-left: -1px;
        border-radius: 0px;
        background-color: #F5F5F5;
    }

    .hom-boxcircle {
        height: 123px;
        width: 105px;
        margin-top: 40px;
        border-radius: 0px;
        text-align: center;
        margin-left: 10px;
    }

    .circleimg {
        height: 83px;
        width: 83px;
        border-radius: 50%;
        margin-left: 30px;
        border: 3px solid rgba(243, 243, 243, 1);
    }

        .circleimg:hover {
            border: 2px solid #EFAEAE;
        }

    .hom-box {
        box-sizing: border-box;
        border-radius: 5px;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        line-height: 15px;
        letter-spacing: 0px;
        text-align: center;
        color: #000;
        height: 212px;
        display: grid;
        /*  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;*/
        /*  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;*/
        /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;*/
        height: 290px;
        width: 162px;
    }




    .hom-box1 {
        background: #ffffff;
        border: 1px solid #fff;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        line-height: 15px;
        letter-spacing: 0px;
        text-align: center;
        color: #000;
        height: 212px;
        display: grid;
        box-shadow: #94DCFB 0px 0px 10px;
    }

    .btnGetQuote {
        height: 20px;
        background-color: rgba(168, 221, 244, 1);
        background: rgb(44, 169, 223);
        color: #fff;
        border: none;
        font-family: Inter, sans-serif;
        cursor: pointer;
        margin-top: 10px;
        height: 20px;
        font-size: 12px;
        width: 96px;
        height: 25px;
        font-weight: 400
    }



    .product-title {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 1);
        width: 156px; /* Set a fixed width */
        /*   height: 30px;*/
        padding: 0 10px;
        /*margin-top: -4px;*/
        margin-bottom: 4px;
        text-transform: uppercase;
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #454545;
        font-size: 10px;
        /*font-weight: bold;*/
    }





    @media (min-width: 992px) {
        .hom-box {
            color: rgba(69, 69, 69, 1);
            margin-bottom: 10px;
            font-family: Inter;
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: center;
        }
    }

    @media (max-width: 500px) {
        .hom-box {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            width: 150px;
            align-items: center;
        }
    }

    .lbar {
        border-right: none;
    }

    .circle {
        margin-top: 27px;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        overflow: hidden;
        margin-left: 4px;
    }

        .circle img {
            margin-top: 40px;
            margin-bottom: 5px;
        }

    .textsoln {
        display: flex;
        flex-direction: column;
        height: 15px;
        width: 809px;
        margin-left: 1px;
        border-radius: 0px;
        margin-top: -6px;
        height: 15px;
        width: 809px;
        color: rgba(69, 69, 69, 1);
        font-family: "Inter", sans-serif;
    }

    .textsoln1 {
        display: flex;
        flex-direction: column;
        height: 15px;
        width: 809px;
        margin-left: 1px;
        border-radius: 0px;
        margin-top: -6px;
        height: 15px;
        width: 809px;
        color: rgba(69, 69, 69, 1);
        font-family: "Inter", sans-serif;
        width: 900px;
    }

    .spanmyicon12 {
        height: auto;
        display: grid;
        place-items: center;
        align-items: end;
        padding: 0;
        margin: 0;
        object-fit: contain;
        margin-bottom: 14px;
        height: 146px;
        width: 162px;
        display: flex;
    }

        .spanmyicon12 img {
            width: 100%;
            height: 100%;
            padding: 5px;
            margin-top: -4px;
        }


    .spanmyicon2 {
        height: auto;
        display: grid;
        place-items: center;
        align-items: end;
        border-radius: 50%;
    }

        .spanmyicon2 img {
            margin-top: 4px;
            margin-bottom: 7.7px;
        }



    .homtbox h4 {
        font-size: 24px;
    }

    .homtbox p {
        font-size: 14px;
        line-height: 1.2;
    }




    .carouselboxbanner-overlay {
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }



    .carouselboxbanner h1 {
        font-size: 16px;
        background: rgba(233, 39, 41, 1);
        color: #ffffff;
        width: 134px;
        height: 34px;
        border-radius: 8px;
        text-align: center;
        line-height: 34px;
        font-family: Inter;
        font-weight: 500;
    }

    .carousel-control-prev1 {
        justify-content: normal;
        background-color: black;
        /*height: 32px;
        width: 16px;*/
    }

    .carousel-control-next1 {
        justify-content: right;
        background-color: black;
        /*height: 32px;
        width: 16px;*/
    }

    .carousel {
        /* Set the height and width of the carousel */
        height: 200;
        width: 100%;
    }

    .carousel-inner {
        /* Set the height of the carousel inner container */
        height: 100%;
        padding-top: 15px
    }

    .carousel-item {
        text-align: center;
    }

        .carousel-item img {
            /* Set the image width to 100% and height to auto to ensure it scales correctly */
            width: 100%;
            height: auto;
        }


    .carousel-container {
        width: 1108px;
        margin-top: 45px;
        /* Add any additional CSS properties as needed */
    }

    .carousel-caption {
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        padding: 10px;
    }

    .carousel-indicators {
        /* Add styles for the indicators */
        /* Example styles: */
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
    }

        .carousel-indicators li {
            /* Add styles for each indicator */
            /* Example styles: */
            background-color: #fff;
            border: 1px solid #000;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            margin: 0 5px;
            cursor: pointer;
        }

            .carousel-indicators li.active {
                /* Add styles for the active indicator */
                /* Example styles: */
                background-color: #000;
            }

    .modal-dialog {
        -webkit-transform: none !important;
        transform: none !important;
    }
</style>
