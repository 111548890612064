<template>
    <div>
        <div class="container">
            <div class="navigationTop" style="padding-top: 20px; margin-left: -15px; margin-top: 109px;">
                <a href="#" @click="navigateToHomeUser">Home</a>
                <span>&gt;</span>
                <a href="#" @click="navigateToMyReturn">My Return</a>
                <span>&gt;</span>
                <a href="#">Return Policy</a>
            </div>
        </div>
        <div class="container" style="background:#fff;">
            <h2>Return Goods Policy</h2>
            <div class="section">
                <p><strong>1. Authorization</strong></p>
                <div class="subsection">
                    <ul>
                        <li>  1.1.	All products that any dealer/customer/end user ('Customer') wishes to return, can only be returned to Amaryllis Healthcare Pvt. Ltd ('Amaryllis Healthcare') on an affirmative authorization by Amaryllis Healthcare and in accordance with any contract/agreement/invoice terms entered into with Amaryllis Healthcare.</li>
                        <li> 1.2.	 A product may only be returned after a Return Goods Authorizations ('RGAs') has been provided by Amaryllis Healthcare in writing for such product. Each  RGA  is only valid for a period of 30 days from the date of its issuance. In the event an RGA lapses, Customer will have to apply for a fresh RGA. RGA's may be obtained by writing to its customer service at customercare@amaryllisheathcare.com.</li>
                        <li>  1.3.	Upon receipt of the RGA, subject to the inspection of the products and confirmation from Amaryllis Healthcare that such products are in salable condition and are suitable for re-stocking, non?defective products may be returned to Amaryllis.</li>
                        <li>   1.4.	Issuance of an RGA number does not guarantee credit. Credit issuance is dependent on confirmed receipt/review of returned products and is subject to the other terms of this policy.</li>
                        <li>   1.5.	In the event any of the products supplied by Amaryllis Healthcare to the Customer are ascertained to be damaged, the Customer is required to obtain an authorization from Amaryllis Healthcare for return of such damaged products.</li>
                        <li>  1.6.	Any Products that have been returned despite not having received an RGA by Amaryllis Healthcare, or if found to be defective and suitable for re-stocking (despite the Customer having declared to the contrary) may be sent back to the Customer at their own expense or destroyed by Amaryllis Healthcare's at its sole discretion. The return of the Products will be subject to additional charges without any credit being issued to Customer.</li>
                        <li> 1.7.	This policy is subject to any contract / agreement / invoice terms that may be entered into between the Customer and Amaryllis Healthcare.</li>
                        <li> 1.8.	Do not return any product, before receiving confirmation of RGA from our team. In case any product is returned without such confirmation, we do not guarantee any credit or refund.</li>

                    </ul>
                </div>
            </div>

            <div class="section">
                <p><strong>2. Policy with regard to Damaged/Defective products</strong></p>
                <div class="subsection">
                    <p>2.1.	Damaged Products...</p>
                    <ul>
                        <li>   2.1.1.	Any damages or shortages in the products delivered by Amaryllis Healthcare must be endorsed by the    Customer on the carrier's freight bill or bill of lading and be countersigned by the carrier. The damaged products must remain in the original carton, in the event any subsequent inspection is required by the carrier and / or Amaryllis Healthcare.</li>
                        <li>  2.1.2.	The Customer shall notify Amaryllis Healthcare of any damage or product shortages identified within two (2) business days of receipt of such product.</li>
                        <li> 2.1.3.	The Customer shall notify any damage to products and /or shortages to Amaryllis Healthcare in writing to Amaryllis Healthcare Customer Service at customercare@amaryllishealthcare.com .</li>
                        <li> 2.1.4.	Amaryllis Healthcare shall not be liable for, nor be under any obligation to accept return, process credit or arrange for product replacement in the event the carrier's freight bill or bill of lading is not counter-signed by the carrier as required, or if there is any delay, over and above the two (2) days prescribed, in notifying Amaryllis Healthcare of any damage or shortages in products supplied.</li>
                        <li> 2.1.5.	It is clarified that Amaryllis Healthcare shall not be liable for any damage to the product in transit to the Customer or caused on account of any actions / in actions by the carrier / transportation agency.</li>
                    </ul>
                </div>
                <div class="subsection">
                    <p>2.2. Defective products...</p>
                    <ul>
                        <li>   2.2.1.	The Customer shall notify Amaryllis Healthcare in writing of any manufacturing defect in any product supplied within two (2) business days of receipt of such product. Amaryllis Healthcare shall not be liable in the event there is any delay by the Customer in notifying any defect.</li>
                        <li>  2.2.2.	Upon Amaryllis Healthcare acknowledging the defect in the product supplied, the Customer may return such products at Amaryllis Healthcare's expenses for replacement.</li>

                    </ul>
                </div>
                <div class="subsection">
                    <p>2.3. Products Shipped in Error by Amaryllis Healthcare...</p>
                    <ul>

                        <li>
                            2.3.1.	The Customer shall notify Amaryllis Healthcare of any shipping errors or disputes within two (2) business days of receipt of the product.
                        </li>
                        <li> 2.3.2.	Products shipped in error by Amaryllis Healthcare are freely returnable for full credit, provided that such returns are made within thirty (30) days of receipt of such products.</li>
                    </ul>


                </div>
            </div>

            <div class="section">
                <p><strong>3. Conditions for Return</strong></p>
                <div class="subsection">
                    <ul>
                        <li>3.1.1 The following products are not liable to returned to Amaryllis Healthcare by the Customers:</li>
                        <li>  3.1.2    Hazardous products, or products containing hazardous materials.</li>
                        <li>3.1.3    Products made to customer specifications.</li>
                        <li> 3.1.4    Products not liable to be returned as per the contract / agreement / invoice terms.</li>
                        <li> 3.1.5    Products returned in altered or damaged packaging, or in packaging other than original packaging.</li>
                        <li>3.1.6    Refrigerated products, or products requiring refrigeration.</li>
                        <li>3.1.7    Products in unsalable units of measure where product cannot be resold.</li>
                        <li> 3.1.8    When return of such products is prohibited by under law.</li>
                        <li> 3.1.9    Products with less than 3 months shelf life remaining based on expiration dates.</li>
                        <li>3.1.10   Third party vendor products that require a vendor return authorization. These Products will be subject to the vendor's return policy and applicable fees</li>
                    </ul>

                </div>
            </div>

            <div class="section">
                <p><strong>4. Return Procedure</strong></p>
                <div class="subsection">
                    <p> After obtaining an RGA, each return order must include the following information:</p>
                    <ul>

                        <li>4.1.1 Customer's name, address and account number.</li>
                        <li> 4.1.2 RGA number.</li>
                        <li> 4.1.3 Original PO number or original Amaryllis Healthcare invoice number.</li>

                        <li> 4.1.4 Lot number and expiration dates where applicable.</li>
                        <li>4.1.5 Proof of Photos to be attached.</li>
                    </ul>

                </div>
            </div>

            <div class="section">
                <p><strong>5. Limitation of liability</strong></p>
                <div class="subsection">
                    <p>Amaryllis Healthcare shall only be liable to the extent of the price of the products supplied to the Customer.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'returnPolicy',
        data() {
            return {
                title: 'Return Policy'
            }
        },
        created: function () {
        },
        methods: {
            navigateToHomeUser(event) {
                event.preventDefault();
                this.$router.push('/homeUser');
            },
            navigateToMyReturn(event) {
                event.preventDefault();
                this.$router.push('/myReturn');
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .container {
        width: 1020px; /* Adjust this value to your desired width */
        margin: 0 auto; /* Center the container horizontally */
        padding: 10px; /* Add padding for spacing */
    }


    h2 {
        text-align: center;
    }

    .section {
        margin-left: 20px;
    }

    .subsection {
        /*margin-left: 40px;*/
        padding: 6px;
        padding-right: 41px;
    }


    * {
        font-family: Inter;
        color: #454545;
        /* width:100%;*/
        height: 100%;
        text-align: left
    }



    ol, ul {
        list-style: none;
        padding-left: 0;
    }


    .navigationTop a {
        text-decoration: none;
        color: #454545;
        font-family: Inter;
        font-size: 12px
    }

        .navigationTop a:hover {
            color: #2ca9df;
            font-family: Inter;
            font-size: 12px
        }

    .navigationTop span {
        margin: 0 5px;
        color: #454545;
        font-family: Inter;
        font-size: 12px
    }
</style>