<template>
    <div class="container" style="height:700px;background:#F5F5F5">
        <div style="padding-top: 30px; padding-left: 17px; background-color: #F5F5F5; max-width: 1000px; margin: 0 auto;">
            <div><h1 class="title" style="font-size:18px;color:#454545;margin-top:15px;margin-bottom:15px;font-family:Inter;margin-left:10px">Contact Us</h1></div>
            <div style=" margin-left: -3px;" class="row">
                <div class="col-md-9 row off-add" style="padding-right: 50px;">
                    <div class="col-md-6" style="padding:20px">
                        <h3 style="font-size: 14px; font-weight: normal; color: #454545; text-decoration: underline #000; font-family: Inter; margin-bottom: 10px;">CORPORATE OFFICE:</h3>
                        <p></p>
                        <p class="styledPara" style="font-size: 14px; margin-bottom: 5px;">BANGALORE</p>
                        <p class="styledPara" style="font-size: 14px; margin-bottom: 5px;">Amaryllis Healthcare Private Limited.</p>
                        <p class="styledPara" style="font-size: 14px; margin-bottom: 5px;">#301, Lyon Square, 14th 'B' Cross, 7th Main, 6th Sector, HSR Layout, Bangalore - 560102, Karnataka, INDIA.</p>
                    </div>
                    <div class="col-md-6" style="margin-top: -30px;padding:20px">
                        <h3 style="font-size: 14px; font-weight: normal; color: #454545; text-decoration: underline #000; font-family: Inter; margin-bottom: 10px;">REGISTERED OFFICE:</h3>
                        <p></p>
                        <p class="styledPara" style="font-size: 14px; margin-bottom: 5px;">BANGALORE</p>
                        <p class="styledPara" style="font-size: 14px; margin-bottom: 5px;">Amaryllis Healthcare Private Limited.</p>
                        <p class="styledPara" style="font-size: 14px; margin-bottom: 5px;">#273/A Bommasandra Industrial Area, Bangalore - 560099, Karnataka, INDIA.</p>
                    </div>
                    <div class="col-md-12" style="background: #fff; padding: 30px; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);">
                        <p class="styledPara" style="font-size: 14px; margin-bottom: 5px;">Landline : 080-27831600</p>
                        <p class="styledPara" style="font-size: 14px; margin-bottom: 5px;">Mobile : +91 9169 338 338</p>
                        <p class="styledPara" style="font-size: 14px; margin-bottom: 0px;">Email : <a href="#" style="color: #00aeef;">info@amaryllishealthcare.com</a></p>
                    </div>
                </div>

                <div class="col-md-3 cont-form" style=" margin-left: 4px; background: #fff; margin-bottom: -2px; padding: 20px; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);">
                    <div class="form-group">
                        <!--<label for="email" style="font-family: Inter; font-size: 14px; color: #454545;">Name <span class="text-danger">*</span></label>-->
                        <input type="text" v-model="name" class="form-control" id="email" placeholder="Name">
                    </div>

                    <div class="form-group">
                        <!--<label for="email" style="font-family: Inter; font-size: 14px; color: #454545;">Phone <span class="text-danger">*</span></label>-->
                        <input type="text" v-model="phone" class="form-control" id="email" placeholder="Phone">
                    </div>

                    <div class="form-group">
                        <!--<label for="email" style="font-family: Inter; font-size: 14px; color: #454545;">Email <span class="text-danger">*</span></label>-->
                        <input type="text" v-model="email" class="form-control" id="email" placeholder="Email">
                    </div>

                    <div class="form-group">
                        <!--<label for="email" style="font-family: Inter; font-size: 14px; color: #454545;">Message </label>-->
                        <textarea v-model="message" class="form-control" placeholder="Message"></textarea>
                    </div>
                    <p class="text-danger" style="font-family: Inter; font-size: 14px; color: #454545; margin-top: 14px;">{{Error}}</p>
                    <div align="center" style="margin-top: 27px;">
                        <button style="width: 200px; color: #fff; font-size: 14px; font-family: Inter; padding-top: 8px; padding-bottom: 8px; margin-bottom: 19px;" type="button" class="btn btn-info btn-" @click="senddetails()"> Submit</button>
                    </div>
                </div>

            </div>
            <p> </p>
            <br />

        </div>


    </div>
</template>


<script>
    import axios from 'axios';
    import helper from '../helper'
    export default {
        name: 'contactUs',
        data() {
            return {
                name: "",
                phone: "",
                message: "",
                email: "",
                Error: "",
                title: 'ContactUs'
            }
        },
        created: function () {
        },
        methods: {
            async senddetails() {
                var self = this
                self.Error = ""
                if (self.name == "") {
                    self.Error = "* Name Can't be empty!";
                    return false
                }
                var phoneno = /^\d{10}$/;
                if (!self.phone.match(phoneno)) {
                    self.Error = "* Phone Number should be valid!";
                    return false
                }
                if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(self.email)) {

                } else {
                    self.Error = "* Email should be valid!";
                    return false
                }
                if (self.message == "") {
                    self.Error = "* Message Can't be empty!";
                    return false
                }
                const requestBody = { name: this.name, phone: this.phone, email: this.email, message: this.message }
                const response = await axios.post(helper.URL() + 'contactusmail', requestBody);
                const result = response.data;
                if (result.Status == true) {
                    alert("Details Submitted Successfully!!!")
                    self.name = ""
                    self.phone = 0
                    self.email = ""
                    self.message = ""
                } else {
                    Swal.fire('Alert!', "Response not found!!!", 'error');
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    body {
        overflow-x: hidden;
    }

    .title {
        font-family: Roboto;
        font-size: 38px;
        font-style: normal;
        font-weight: 700;
        line-height: 100px;
        letter-spacing: 0.025em;
        text-align: center;
    }

    .row img {
        max-height: 15em;
        width: 100%;
    }

    .lts {
        font-size: 12px;
    }

    .row h3 {
        cursor: pointer;
    }

    .head-border {
        font-size: 24px;
        font-weight: normal;
        color: #000;
        text-decoration: none;
    }

        .head-border::after {
            content: '';
            border-bottom: 5px solid #00aeef;
            width: 100px;
            display: block;
            width: 107px;
            height: 15px;
            margin-left: 2px;
        }

    /* .off-add p {
        font-size: 20px;
        color: #000;
    }*/

    .cont-form label {
        font-size: 20px;
        color: #000;
    }

        .cont-form label span.text-danger {
            color: #f00 !important;
        }

    .cont-form input {
        margin-top: 5px;
        margin-bottom: 13px;
    }

    .cont-form textarea {
        margin-top: 5px;
    }

    .styledPara {
        font-family: Inter;
        font-size: 14px;
        color: #454545
    }
</style>
