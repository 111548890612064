<template>
    <div class="container" style="background: #F3F3F3; height: 1350px;">
        <div class="navigationTop" style="margin-top:115px;margin-left:31px;font-size:12px">
            <a href="#" @click="navigateToHomeUser">Home</a>
            <span>&gt;</span>
            <a href="#">Cart</a>
        </div>
        <h4 style=" margin-left: 41px; font-weight: bold; font-size: 14px; margin-bottom: -9px; margin-top: 1px;"> Delivery Address</h4>
        <div class="container-row" style="padding-left: 35px; padding-right: 35px; margin-top: 7px;height:200px">
            <div class="columnQO-1" style="width: 74%; background-color: #fff;">
                <div class="address-column" style="margin-bottom: 20px;">
                    <h4 style="margin-left: 0; font-family: 'Inter', sans-serif; color: #454545; font-size: 14px;">Billing Address</h4>
                    <div class="input-group">
                        <div class="input-group" @mouseover="openDropdownB" @mouseleave="closeDropdownB">
                            <input type="text" class="form-control" v-model="custAddressB" :placeholder="custAddressB" aria-label="Text input with dropdown button" style="font-family: 'Inter', sans-serif; color: #454545; font-size: 12px; border: 1px solid #ccc; border-radius: 5px;">
                            <ul class="dropdown-menu" :class="{ 'show': isDropdownOpenB }">
                                <li><a class="dropdown-item" href="#" v-for="(c, index) in custAddressListB" :key="index" @click="addressClickB(c)" style="font-family: 'Inter', sans-serif; color: #333; font-size: 12px;">{{ c.AddressDetails }}</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="form-group" style="display: flex; align-items: center; margin-top: 10px;">
                        <div class="col-leg-2" style="flex: 1;">
                            <input type="text" class="form-control" v-model="poReference" :placeholder="'Enter PO Reference No...'" style="font-family: 'Inter', sans-serif; color: #454545; font-size: 12px; border: 1px solid #ccc; border-radius: 5px;">
                        </div>
                    </div>
                </div>
                <!--****************************2******************************************************-->
                <div class="address-column" style="margin-bottom: 20px;">
                    <h4 style="font-family: 'Inter', sans-serif; color: #454545; font-size: 14px;">Shipping Address</h4>
                    <div class="input-group" @mouseover="openDropdownS" @mouseleave="closeDropdownS">
                        <input type="text" class="form-control" v-model="custAddressS" :placeholder="custAddressS" aria-label="Text input with dropdown button" style="font-family: 'Inter', sans-serif; color: #454545; font-size: 12px; border: 1px solid #ccc; border-radius: 5px;">
                        <ul class="dropdown-menu" :class="{ 'show': isDropdownOpenS }">
                            <li><a class="dropdown-item" href="#" v-for="(c, index) in custAddressListS" :key="index" @click="addressClickS(c)" style="font-family: 'Inter', sans-serif; color: #454545; font-size: 14px;">{{ c.AddressDetails }}</a></li>
                        </ul>
                    </div>
                    <div class="form-group" style="display: flex; align-items: center; margin-top: 10px;">
                        <div class="col-leg-2" style="flex: 1;">
                            <input type="text" class="form-control" v-model="poRemark" :placeholder="'Enter PO Remark...'" style="font-family: 'Inter', sans-serif; color: #454545; font-size: 12px; border: 1px solid #ccc; border-radius: 5px;">
                        </div>
                    </div>
                </div>
            </div>
            <div class="columnQO-2" style="flex-grow: 1">
                <img src="../assets/img/ImageCartPage1.png" class="fit-image" />
            </div>
        </div>
        <div>
            <div class="col-lg-9 row2" style="margin-left: -6px; margin-bottom: 0px; padding-left: 41px; padding-right: 24px;">
                <div style="display: flex; flex-direction: row; width: 100%; background: rgba(168, 221, 244, 1); padding: 10px 10px; margin: 0px;height:40px">
                    <h4 style="flex: 2; margin: 0; color: #454545;font-family:Inter;">Products in Cart</h4>
                    <div style="flex: 2; ">&nbsp;</div>
                    <p style="flex: 1; text-align: right; margin: 0; color: #454545; text-align: center; font-family: Inter; margin-right: 14px;">No. of Items:{{ KartProducts.length }}</p>
                    <p style="flex: 1; text-align: right; margin: 0; color: #454545; font-family:Inter">No. of Boxes:{{ getTotalBox() }}</p>
                </div>
                <div class="yourcartrow" style="height: 779px; overflow-x: hidden;">
                    <div style="width: fit-content; overflow-x: auto;">
                        <!--<template v-for="(k, index) in KartProducts" :key="index">
                            <table class="sub-row" id="KartTable" style="padding: 0px 10px;">
                                <tr class="orderrow" id="'KartProducts' + index" style="display: flex; justify-content: space-between;">
                                    <td class="sub-column1" style="flex: 2; width: 110px;padding:0px 16px;">
                                        <div class="sub-column">
                                            <div class="sub-column-image">
                                                <img style="width: 75px; height: 75px;background-color: #f3f3f3;" :src="'data:image/png;base64,' + k.CustomerLinkItemImageData" ratio="1" @click="productClick(k, k.CustomerLinkItemCode)">
                                            </div>
                                            <div class="sub-column-text" style="text-align:left;margin-top:5px">
                                                <h4 class="centeredfm" style="font-weight: bold; font-size: 12px; color: #454545;">
                                                    {{ k.CustomerLinkItemName }}
                                                </h4>
                                                <p class="centeredfm" style="color: #454545;font-size:11px">
                                                    Product Code: {{ k.CustomerLinkItemCode }}
                                                </p>
                                                <p class="centeredfm" style="color: #454545;font-size:11px">
                                                    {{ k.CustomerLinkItemBoxQty }} pc/Box I Unit Price:
                                                    <span style="text-decoration: line-through">&#x20b9;{{ k.CustomerLinkItemPrice }}</span>
                                                    <span style="font-size: 12px; font-weight: 600"> &nbsp;&#x20b9;{{ k.CustomerLinkItemDP }}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </td>

                                    <td class="sub-column2" style="flex: 0.5; display: flex; justify-content: center;width:422px">
                                        <div class="columnBox" style="text-align: center; margin-bottom: 46px;">
                                            <div class="counter-container" style="display: flex; flex-direction: row; align-items: center;">
                                                <span class="counter-label" style="color: #737373; font-size: 12px; margin-right: 5px;">Box Qty.</span>
                                                <div class="counter" style="border: 1px solid #454545; border-radius: 5px; padding: 1px;">
                                                    <Minplus :value="k.BoxOrderQty" v-model.number="k.BoxOrderQty" :index="index" :min="0" :max="99999" @changeevent="kartBoxChange" style="cursor: pointer;"></Minplus>
                                                </div>
                                            </div>
                                            <span class="totalpcs" style="color: #454545; font-size: 12px; margin-top: 5px; margin-left: 30px">Total: {{ k.PcsOrderQty }} Pcs</span>
                                        </div>
                                    </td>

                                    <td class="sub-column3" style="text-align: left; flex: -1; margin-top: 5px; font-family: 'Inter', sans-serif;">
                                        <p style="color: #454545; line-height: 1.2; font-size: 11px; text-align: left; margin-left: 10px ">
                                            Order Value: &#x20b9; {{ getOrderVal(k).toLocaleString() }}
                                        </p>
                                        <p style="color: #454545; line-height: 1.2; font-size: 11px; text-align: left; margin-left: 10px ">
                                            Avg Monthly PO Qty (Pcs) {{ k.CustomerLinkItemAvgSalesQty }}
                                        </p>
                                        <p style="color: #454545; line-height: 1.2; font-size: 11px; text-align: left; margin-left: 10px ">
                                            Order Type: {{ k.TenderOrder }} {{ k.FirstOrder }}
                                        </p>
                                    </td>

                                    <td class="sub-column4 align-left" style="font-family: 'Inter', sans-serif;">
                                        <div class="custom-button-containerRemark" style="font-weight: normal !important; margin-left: 5px;">
                                            <button type="button" :class="getRemarkClass(k)" @click="remarkKartPopup(index)" style="text-decoration: none; color: #454545; border: 1px solid #757575; font-size: 10px; padding: 4px; margin-left: 10px; font-family: Inter; width: 73px;">Remark</button>
                                            <a class="big-buttonVRemove" style="text-decoration: none; cursor: pointer; display: block; margin-left: 6px; text-decoration: none; cursor: pointer; display: block; margin-left: 9px; margin-top: 20px; font-size: 10px; font-family: Inter; padding-left: 7px; width: 74px; margin-top: 20px; font-size: 10px; border: 1px solid #737373; border-radius: 4px;" @click="removeFromKart(index)">Remove</a>
                                        </div>
                                    </td>

                                </tr>
                            </table>
                        </template>-->
                        <template v-for="(k, index) in KartProducts" :key="index">
                            <table class="sub-row" id="karttable" style="height: auto; width: 91%; display: flex; flex-wrap: wrap;">
                                <tr class="orderrow" id="'kartProducts' + index" style="display: flex; justify-content: space-between; width: 100%;">
                                    <td class="sub-column1" style="flex: 1; max-width: 280px; padding: 4px 40px; padding-left: 0px;">
                                        <div class="sub-column" style="width: 100%; display: flex;">
                                            <div class="sub-column-image" style="flex: 1;">
                                                <img style="width: 80px; height: 70px; background-color: #f3f3f3;" :src="'data:image/png;base64,' + k.CustomerLinkItemImageData" ratio="1" @click="productClick(k, k.CustomerLinkItemCode)">
                                            </div>
                                            <div class="sub-column-text" style="text-align: left; margin-top: 5px; flex: 1;">
                                                <h4 class="centeredfm" style="font-weight: bold; font-family: Inter; font-size: 12px; color: #454545;  overflow: hidden; text-overflow: ellipsis; width: 177px;">
                                                    {{ k.CustomerLinkItemName }}
                                                </h4>
                                                <p class="centeredfm" style="color: #454545; font-size: 10px; font-family: Inter; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                                                    Product Code: {{ k.CustomerLinkItemCode }}
                                                </p>
                                                <p class="centeredfm" style="color: #454545; font-size: 10px; font-family: Inter;">
                                                    {{ k.CustomerLinkItemBoxQty }} pc/Box I Unit Price:
                                                    <span style="text-decoration: line-through; font-size: 10px;">&#x20b9;{{ k.CustomerLinkItemPrice }}</span>
                                                    <span style="font-size: 12px; font-weight: 600;"> &nbsp;&#x20b9;{{ k.CustomerLinkItemDP }}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <!--<td class="sub-column2" style="flex: 0.6; justify-content: center; background: #fff; width: 238px; margin-top: 10px; margin-left: 6%;">
                                        <div class="columnBox" style="text-align: center; margin-bottom: 30px; margin-left: -12px;">
                                            <div class="counter-container" style="display: flex; flex-direction: row; align-items: center; margin-left: -50px;">
                                                <span class="counter-label" style="color: #454545; font-size: 10px; margin-right: 3px;font-family:Inter">Box Qty.</span>
                                                <div class="counter" style="border: 1px solid #454545; border-radius: 5px; padding: 1px;">
                                                    <Minplus :value="k.BoxOrderQty" v-model.number="k.BoxOrderQty" :index="index" :min="1" :max="99999" @changeevent="kartBoxChange" style="cursor: pointer;"></Minplus>
                                                </div>
                                            </div>
                                            <span class="" style="color: #454545; font-size: 10px; margin-top: 3px; margin-left: -60px; font-family: Inter;">Total: {{ k.PcsOrderQty }} Pcs</span>
                                        </div>
                                    </td>-->
                                    <td class="sub-column2" style="flex: 0.5; display: flex; justify-content: center; width:238px">
                                        <div class="columnBox" style="text-align: center; margin-bottom: 46px;">
                                            <div class="counter-container" style="display: flex; flex-direction: row; align-items: center;">
                                                <span class="counter-label" style="color: #737373; font-size: 12px; margin-right: 5px;">Box Qty.</span>
                                                <div class="counter" style="border: 1px solid #454545; border-radius: 5px; padding: 1px;">
                                                    <Minplus :value="k.BoxOrderQty" v-model.number="k.BoxOrderQty" :index="index" :min="0" :max="99999" @changeevent="kartBoxChange" style="cursor: pointer;"></Minplus>
                                                </div>
                                            </div>
                                            <span class="totalpcs" style="color: #454545; font-size: 12px; margin-top: 5px; margin-left: 30px">Total: {{ k.PcsOrderQty }} Pcs</span>
                                        </div>
                                    </td>
                                    <td class="sub-column3" style="text-align: left; flex: 0.8; margin-top: 5px; background: #fff; width: 275px; font-family: Inter;white-space:nowrap">
                                        <p style="color: #454545; line-height: 1.2; font-size: 11px; width: 152px; margin-left: 0%;">
                                            Order Value: &#x20b9; {{ getOrderVal(k).toLocaleString() }}
                                        </p>
                                        <p style="color: #454545; line-height: 1.2; font-size: 11px; width: 152px; margin-left: 0%; ">
                                            Avg Monthly PO Qty (Pcs) {{ k.CustomerLinkItemAvgSalesQty }}
                                        </p>
                                        <p v-if="k.TenderOrder && k.FirstOrder" style="color: #454545; line-height: 1.2; font-size: 11px; width: 152px; margin-left: 0%;">Order Type: TenderOrder and FirstOrder</p>
                                        <p v-else-if="k.TenderOrder" style="color: #454545; line-height: 1.2; font-size: 11px; width: 152px; margin-left: 0%;">Order Type: Tender Order</p>
                                        <p v-else-if="k.FirstOrder" style="color: #454545; line-height: 1.2; font-size: 11px; width: 152px; margin-left: 0%;">Order Type: First Order</p>
                                    </td>
                                    <td class="sub-column4 align-left" style="background:#fff;font-family:Inter">
                                        <div class="custom-button-containerRemark" style="font-weight: normal !important; margin-left: -80px; margin-top: 0px;">
                                            <button type="button" :class="getRemarkClass(k)" @click="remarkKartPopup(index)" style="font-size: 10px; text-decoration: none; color: #454545; width: 101px; margin-left: 10px; text-align: center;border:1px solid #737373">Remark</button>
                                            <a class="big-buttonVRemove" style="font-size: 10px; text-decoration: none; cursor: pointer; display: block; margin-top: 10px; width: 101px; margin-left:10px; text-align: center; padding: 5px 13px;" @click="removeFromKart(index)">Remove</a>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </template>
                    </div>
                    </div>
                </div>
            <div class="col-lg-3 columnprice" style="background-color: #fff; margin-top: 16px; height: 820px; width: 259px; margin-left: -10px; padding: 10px; ">
                <div class="mov" style="font-family:Inter;color:#454545">M. O. V : &#x20b9; {{ userdata.CustomerMOV }}</div>
                <div class="freeshipping" v-if="!freeshipping"> Add &#x20b9;{{ addMore }} more to get free shipping</div>
                <div class="underline" style="margin-top:7px"></div>
                <div class="rowPD">
                    <div class="columnPrice">
                        <h4 style="font-family: Inter; color: #454545;font-family: Inter; font-size: 14px;">Price Details</h4>
                        <div class="underline"></div>
                        <p style="font-family: Inter; color: #454545; font-family: Inter; font-size: 14px;">Total Amount</p>
                        <p style="font-family:Inter;color:#454545;font-family: Inter; font-size: 14px;">Transportation Charge</p>
                        <p style="font-family:Inter;color:#454545;font-family: Inter; font-size: 14px;">Tax Amount (GST)</p>
                        <p style="font-family:Inter;color:#454545;font-family: Inter; font-size: 14px;"> Round Off</p>
                        <div class="underline"></div>
                        <p style="font-weight: 600; font-family: Inter; color: #454545;font-size: 14px;">Order Total</p>
                        <div class="underline"></div>
                        <p style="font-weight: 600; font-family: Inter; color: #454545;font-size: 14px;">Total Savings</p>
                        <div class="underline"></div>
                        <p style="font-family: Inter; color: #454545;font-size: 14px;">Discount Applied</p>
                        <p style="font-family: Inter; color: #454545;font-size: 14px;">Transportation Charge</p>
                        <div class="underline"></div>
                        <p style="font-weight: 600; font-family: Inter; color: #454545;font-size: 14px;">Total</p>
                        <div class="underline"></div>
                    </div>
                    <div class="columnDetails">
                        <h4 style="font-family: Inter; color: #454545;font-size: 14px; font-family: Inter; font-size: 14px;">&nbsp;</h4>
                        <div class="underline"></div>
                        <p style="font-family:Inter;color:#454545;font-family: Inter; font-size: 14px;">&#x20b9; {{ totalAmount }}</p>
                        <p style="font-family:Inter;color:#454545;font-family: Inter; font-size: 14px;">&#x20b9; {{ Transportation }}</p>
                        <p style="font-family:Inter;color:#454545;font-family: Inter; font-size: 14px;">&#x20b9; {{ TaxAmount }}</p>
                        <p style="font-family:Inter;color:#454545;font-family: Inter; font-size: 14px;">&#x20b9; {{ RoundOff }}</p>
                        <div class="underline"></div>
                        <p style="font-weight: 600; font-family: Inter; color: #454545; font-family: Inter; font-size: 14px;">&#x20b9; {{ NetAmount }}</p>
                        <div class="underline"></div>
                        <p style="font-weight: 600; font-family: Inter; color: #454545; font-family: Inter; font-size: 14px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                        <div class="underline"></div>
                        <p style="font-family: Inter; color: #454545; font-family: Inter; font-size: 14px;">&#x20b9; {{ Discount }}</p>
                        <p style="font-family: Inter; color: #454545; font-family: Inter; font-size: 14px;">&#x20b9; {{ STransportation }}</p>
                        <div class="underline"></div>
                        <p style="font-weight: 600; font-family: Inter; color: #454545; font-family: Inter; font-size: 14px;">&#x20b9; {{ Savings }}</p>
                        <div class="underline"></div>
                    </div>
                </div>
                <div class="button-Cartcontainer">
                    <button class="buttonCart" @click="placeOrder">Confirm Order</button>
                </div>
                <div class="rowPD">
                    <div class="columnAccount">
                        <h4 style="font-family: Inter; color: #454545; font-family: Inter; font-size: 14px;">Account Summary</h4>
                        <div class="underline"></div>
                        <p style="font-family: Inter; color: #454545; font-family: Inter; font-size: 14px;">Current Order Value</p>
                        <p style="font-family:Inter;color:#454545">Previous Dues</p>

                        <div class="underline"></div>
                        <p style="font-weight: 600; font-family: Inter; color: #454545; font-family: Inter; font-size: 14px;">Total Due</p>
                        <div class="underline"></div>
                    </div>
                    <div class="columnSummary">
                        <h4 style="font-family: Inter; color: #454545; font-family: Inter; font-size: 14px;">&nbsp;</h4>
                        <div class="underline"></div>
                        <p style="font-family: Inter; color: #454545; font-family: Inter; font-size: 14px;">&#x20b9; {{ NetAmount }}</p>
                        <br />
                        <p style="font-family: Inter; color: #454545; font-family: Inter; font-size: 14px;">&#x20b9; {{ custPreviousDues }}</p>

                        <div class="underline"></div>
                        <p style="font-weight: 600; font-family: Inter; color: #454545; font-family: Inter; font-size: 14px;">&#x20b9; {{ custTotalDue }}</p>
                        <div class="underline"></div>
                    </div>
                </div>
                <div class="rowPD" style="margin-top: 20px;">
                    <div class="columnItemsBoxes">
                        <p style="font-family: Inter; color: #454545; font-family: Inter; font-size: 14px;">No. of Items: {{ KartProducts.length }}</p>
                        <p style="font-family: Inter; color: #454545; font-family: Inter; font-size: 14px;">No. of Boxes: {{ getTotalBox() }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="remarkKart">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title" style=" margin: 0 auto;">Remark</h4>
                                    <button type="button" class="btn btn-sm" @click="remarkKart=false">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body" style="text-align: center;">
                                    <textarea v-model="RemarkKartText" class="form-control"></textarea>
                                    <br />
                                    <button class="btn btn-info  btn-w btn-sm" @click="addRemarkToKartProduct" style=" margin-left: -29px;"> Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import Minplus from "../v-minusplusfield";
    import axios from 'axios';
    import helper from '../helper';
    import Swal from 'sweetalert2';

    export default {
        name: "productCart",
        components: {
            Minplus
        },
        data() {
            return {
                isDropdownOpenB: false,
                isDropdownOpenS: false,
                noOfBoxes: 0,
                custTotalDue: 0,
                transportationCharge: 0,
                selectedAddress: "",
                KartProducts: [],
                itemgrouptext: "All Products",
                itemgroupid: 0,
                TenderOrder: false,
                FirstOrder: false,
                KartDetTenderOrder: "0",
                KartDetFirstOrder: "0",
                CustomerName: "",
                custAddressList: [],
                custAddressListB: [],
                custAddressListS: [],
                custAddressB: "",
                custAddressS: "",
                selectedCustAddressIdB: 0,
                selectedCustAddressIdS: 0,
                custTrspChrgPerBox: 0,
                userdata: {},
                freeshipping: false,
                totalAmount: 0,
                addMore: 0,
                STransportation: 0,
                Discount: 0,
                Savings: 0,
                Transportation: 0,
                TaxAmount: 0,
                RoundOff: 0,
                NetAmount: 0,
                remarkKart: false,
                remarkKartIndex: 0,
                RemarkKartText: "",
                poReference: "",
                poRemark: ""
            };
        },
        created: function () {
            this.getCustAddress();
            this.loadUserData();
            this.calcTotal();
        },
        beforeMount: async function () {
            this.loadUserData();
        },
        methods: {
            navigateToHomeUser(event) {
                event.preventDefault();
                this.$router.push('/homeUser');
            },
            getRemarkClass(item) {
                if (item.Remark != '' && item.Remark !== undefined) {
                    return 'btn btn-default btn-mydark btn-dsm'
                } else {
                    return 'btn btn-default btn-dsm'
                }
            },
            remarkKartPopup(i) {
                if (this.KartProducts[i].Remark == "") {
                    this.RemarkKartText = ""
                } else {
                    this.RemarkKartText = this.KartProducts[i].Remark
                }
                this.remarkKartIndex = i
                this.remarkKart = true
            },
            addRemarkToKartProduct() {
                this.KartProducts[this.remarkKartIndex].Remark = this.RemarkKartText
                this.remarkKart = false
            },
            productClick(productDetails, productCode) {
                localStorage.setItem("SelectedProductDetails", JSON.stringify(productDetails))
                this.$router.push({ name: 'product', params: { productCode } })
            },
            async placeOrder() {
                try {
                    const requestBody = {}
                    var self = this;
                    var k = localStorage.getItem("Kart");
                    if (k != "") {
                        requestBody.KartCustCode = this.userdata.CustomerCode
                        requestBody.KartTotalAmount = this.totalAmount
                        requestBody.KartTotalDisc = this.Discount
                        requestBody.KartTotalShipping = this.Transportation
                        requestBody.KartTotalTax = this.TaxAmount
                        requestBody.KartRoundOff = this.RoundOff
                        requestBody.KartNetAmount = this.NetAmount
                        requestBody.KartAddressIdB = this.selectedCustAddressIdB
                        requestBody.KartAddressIdS = this.selectedCustAddressIdS
                        requestBody.KartPoReference = this.poReference
                        requestBody.KartPoRemark = this.poRemark
                    } else {
                        Swal.fire('Alert!', "Cart is empty!!!", 'error');
                        return false
                    }
                    if (this.selectedCustAddressIdB == "") {
                        Swal.fire('Alert!', "Billing address is missing, thus can't place the order. Please contact MSO team!!!", 'error');
                        return false
                    }
                    if (this.selectedCustAddressIdS == "") {
                        Swal.fire('Alert!', "Shipping address is missing, thus can't place the order. Please contact MSO team!!!", 'error');
                        return false
                    }
                    if (this.NetAmount == "" || this.NetAmount == 0) {
                        Swal.fire('Alert!', "Something went wrong, Please try again!!!", 'error');
                        return false
                    }
                    const response = await axios.post(helper.URL() + 'addtokartmaster', requestBody);
                    const result = response.data;
                    if (result.Status == true) {
                        if (result.Data && result.Data.KartInvoiceNo.length > 0) {
                            var InvoiceNo = result.Data.KartInvoiceNo;
                            try {
                                await self.insertKartDetails(InvoiceNo);
                                self.KartProducts = [];
                                self.storeKart();
                                self.$router.push({
                                    name: 'confirmation',
                                    params: {
                                        InvoiceNo,
                                        TotalSaving: self.Savings,
                                        AddressS: self.custAddressS,
                                        AddressB: self.custAddressB
                                    }
                                });
                            } catch (error) {
                                await axios.post(helper.URL() + 'rollbackkartmaster', { InvoiceNo: InvoiceNo });
                                Swal.fire('Alert!', "Order is not placed, Please try again.", 'error');
                            }
                        } else {
                            Swal.fire('Alert!', "Order is not placed!!!", 'error');
                        }
                    } else {
                        self.calcTotal();
                    }
                } catch (error) {
                    Swal.fire('Alert!', error, 'error');
                }
            },
            async insertKartDetails(InvoiceNo) {
                const requestBody = {}
                var self = this;
                var k = localStorage.getItem("Kart");
                if (k != "") {
                    var kobj = JSON.parse(k)
                    var items = []
                    kobj.items.forEach(element => {
                        element.KartDetOrderVal = self.getOrderVal(element)
                        element.KartDetTaxAmount = self.getTax(element)
                        element.KartDetTotalAmount = self.getTotal(element)
                        element.KartDetTenderOrder = element.TenderOrder == true ? "1" : "0";
                        element.KartDetFirstOrder = element.FirstOrder == true ? "1" : "0";
                        items.push(element)
                    });
                    requestBody.KartInvoiceNo = InvoiceNo
                    requestBody.items = items
                    requestBody.items = requestBody.items.map(item => {
                        return { ...item, CustomerLinkItemImageData: "" };
                    });
                } else {
                    Swal.fire('Alert!', "Cart is empty!!!", 'error');
                    return false
                }
                const response = await axios.post(helper.URL() + 'insertkartdetails', requestBody);
                const result = response.data;
            },
            openDropdownB() {
                this.isDropdownOpenB = true;
            },
            closeDropdownB() {
                this.isDropdownOpenB = false;
            },
            openDropdownS() {
                this.isDropdownOpenS = true;
            },
            closeDropdownS() {
                this.isDropdownOpenS = false;
            },
            addressClickB(c) {
                var self = this
                self.custAddressB = c.AddressDetails
                self.selectedCustAddressIdB = c.AddressId
                this.isDropdownOpenB = false;
                self.getCustTrspCharge();
            },
            addressClickS(c) {
                var self = this
                self.custAddressS = c.AddressDetails
                self.selectedCustAddressIdS = c.AddressId
                this.isDropdownOpenS = false;
                self.getCustTrspCharge();
            },
            getTotalBox() {
                var t = 0
                this.KartProducts.forEach(p => {
                    t = t + p.BoxOrderQty
                });
                return t
            },
            removeFromKart(index) {
                this.KartProducts.splice(index, 1);
                this.calcTotal()
            },
            formattedRoundOff(roundoff) {
                const roundeOff = Math.ceil(roundoff * 100) / 100;
                return roundeOff.toLocaleString('en-IN', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            },
            getOrderVal(p) {
                var rval = 0
                rval = p.PcsOrderQty * p.CustomerLinkItemDP
                return rval.toFixed(2)
            },
            getTax(p) {
                var rval = 0
                rval = (p.PcsOrderQty * p.CustomerLinkItemDP / 100) * p.CustomerLinkItemTaxPer;
                return rval.toFixed(2);
            },
            getTotal(p) {
                var rval = 0
                var t = p.PcsOrderQty * p.CustomerLinkItemDP
                var tax = (t / 100) * p.CustomerLinkItemTaxPer;
                rval = t + tax
                return rval.toFixed(2)
            },
            kartBoxChange(value, i) {
                var bqty = this.KartProducts[i].CustomerLinkItemBoxQty
                this.KartProducts[i].BoxOrderQty = value;
                this.KartProducts[i].PcsOrderQty = value * bqty
                if (this.KartProducts[i].BoxOrderQty > 0) {
                    this.calcTotal()
                }
            },
            async getCustAddress() {
                try {
                    var l = localStorage.getItem("userdata")
                    if (l != null && l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { custcode: userData.CustomerCode }
                        const response = await axios.post(helper.URL() + 'selectcustomeraddress', requestBody);
                        const result = response.data;
                        self.custAddressList = [];
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                self.custAddressList = result.Data[0];
                                const filteredDataB = self.custAddressList.filter(item => item.AddressType === 'B');
                                var filteredDataS = self.custAddressList.filter(item => item.AddressType === 'S');
                                if (filteredDataS.length == 0) {
                                    filteredDataS = self.custAddressList.filter(item => item.AddressType === 'B');
                                }
                                const custAddressB = filteredDataB.map(item => item.AddressDetails);
                                var custAddressS = filteredDataS.map(item => item.AddressDetails);
                                self.custAddressListB = filteredDataB;
                                self.custAddressListS = filteredDataS;
                                if (custAddressB.length > 0) {
                                    self.custAddressB = custAddressB.shift();
                                } else {
                                    self.custAddressB = "";
                                }
                                if (custAddressS.length > 0) {
                                    self.custAddressS = custAddressS.shift();
                                } else {
                                    self.custAddressS = "";
                                }
                                self.selectedCustAddressIdB = filteredDataB.map(item => item.AddressIdB);
                                self.selectedCustAddressIdS = filteredDataS.map(item => item.AddressIdS);
                                self.getCustTrspCharge();
                            } else {
                                Swal.fire('Alert!', "Response not found!!!", 'error');
                            }
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            async getCustTrspCharge() {
                try {
                    var self = this;
                    const requestBody = { CustAddressId: self.selectedCustAddressIdS }
                    const response = await axios.post(helper.URL() + 'gettransportationcharge', requestBody);
                    const result = response.data;
                    if (result.Status == true) {
                        if (result.Data && result.Data.length > 0) {
                            self.custTrspChrgPerBox = response.data.Data[0][0].TcPricePerBox;
                            self.calcTotal();
                        } else {
                            self.calcTotal();
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            calcTotal() {
                var ta = 0
                var self = this
                var dis = 0
                var trtotal = 0
                var ttax = 0
                this.KartProducts.forEach(p => {
                    var t = p.PcsOrderQty * p.CustomerLinkItemDP
                    ta = ta + t
                    var clMRP = p.PcsOrderQty * p.CustomerLinkItemPrice
                    dis = dis + (clMRP - t)
                    trtotal = trtotal + p.BoxOrderQty * self.custTrspChrgPerBox
                    ttax = ttax + (t / 100) * p.CustomerLinkItemTaxPer;
                });


                self.totalAmount = ta.toFixed(2)
                self.Discount = dis.toFixed(2)
                self.TaxAmount = ttax.toFixed(2)
                var taddmore = ((self.userdata.CustomerMOV - ta) - trtotal)

                if (taddmore <= 0) {
                    self.freeshipping = true
                    self.addMore = taddmore
                } else {
                    self.freeshipping = false
                    self.addMore = taddmore
                }
                //if freeshipping saving will add


                var nettratotal = 0
                if (self.freeshipping) {
                    self.STransportation = trtotal.toFixed(2)
                    self.Transportation = 0
                    var ss = trtotal + dis
                    self.Savings = ss.toFixed(2)
                } else {
                    self.STransportation = 0
                    self.Transportation = trtotal.toFixed(2)
                    nettratotal = trtotal
                    self.Savings = dis.toFixed(2)
                }

                var troundoff = ta + ttax + nettratotal
                var r = Math.round(troundoff)
                var tempr = r - troundoff
                self.RoundOff = this.formattedRoundOff(tempr)
                self.NetAmount = r
                self.custTotalDue = self.custPreviousDues + self.NetAmount
                this.storeKart();
            },
            storeKart() {
                var j = { CustId: this.userdata.CustomerCode, items: this.KartProducts }
                localStorage.setItem("Kart", JSON.stringify(j))
            },
            loadUserData() {
                var l = localStorage.getItem("userdata")
                if (l != null && l != "") {
                    var userData = JSON.parse(l);
                    this.userdata = userData;
                    this.custPreviousDues = this.userdata.CustomerDue
                    this.CustomerName = this.userdata.CustomerName
                }
                var k = localStorage.getItem("Kart");
                if (l != "" && k != "" && k !== null) {
                    var kobj = JSON.parse(k)
                    if (kobj.CustId == this.userdata.CustomerCode) {
                        this.KartProducts = kobj.items
                    }
                }
            }
        }
    };
</script>

<style scoped>

    body {
        font-family: Inter;
        color: #454545
    }

    .dropdown-menu {
        display: none;
        position: absolute;
        z-index: 1000;
    }

    .show {
        display: block;
    }

    .container-row {
        display: flex;
        flex-direction: row;
        gap: 15px;
        grid-template-columns: 75%;
        margin-top: 100px;
        padding: 10px;
        margin-top: 110px;
        height: 180px;
    }

    .columnQO-1 {
        display: flex;
        flex-direction: row;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }

    .address-column {
        flex-basis: 50%;
        padding: 30px;
    }

    .address-details {
        margin-bottom: 10px;
        border-right: 2px solid #afafaf;
    }

        .address-details input[type="radio"] {
            margin-right: 10px;
        }

    .columnQO-2 {
        background-color: #eaeaea;
    }

    .columnQO-2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25%;
        position: relative;
    }



    .fit-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media screen and (max-width: 768px) {
        .fit-image {
            width: 100%;
        }
    }



    .row2 {
        display: flex;
        flex-wrap: wrap;
        padding: 16px;
        margin-left: 1px;
    }

    .columnproduct {
        display: flex;
        flex: 70%;
        flex-wrap: wrap;
    }

    .columnprice {
        flex: 25%;
        padding: 10px;
    }

    .sub-column {
        flex: 25%;
        padding: 5px;
    }

    .counter-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 250px;
         /*height: 150px;*/ 
    }

    .counter-label {
        font-size: 12px;
        padding: 5px;
        /*margin-bottom: 8px;*/
    }

    .counter {
        display: flex;
        align-items: center;
    }

    .counter-value {
        font-size: 18px;
        background-color: #fff;
        width: 128px;
        padding: 6px;
        text-align: center;
    }

    .minus-btn {
        cursor: pointer;
        font-size: 20px;
        padding: 5px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: #eee;
    }

    .plus-btn {
        cursor: pointer;
        font-size: 20px;
        padding: 4px;
        background-color: #eee;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .minus-btn:hover {
        background-color: #eee;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .plus-btn:hover {
        background-color: #eee;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .counter-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 8px 16px;
    }

    .order-value {
        font-size: 14px;
        text-align: right;
        padding-right: 10px;
    }

    .button-containerRemark {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-left: 40px;
    }

    .big-buttonRemark {
        display: inline-block;
        padding: 5px 20px;
        font-size: 13px;
        background-color: #eee;
        color: #454545;
        text-decoration: none;
        text-align: center;
        border-radius: 5px;
    }

    .button-containerVRemark {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-left: 40px;
    }

    .big-buttonVRemark {
        display: inline-block;
        padding: 4px 10px;
        font-size: 13px;
        background-color: #fff;
        color: #454545;
        text-decoration: none;
        text-align: center;
        border-radius: 5px;
        width: 167px;
        margin-left: 27px;
        margin-top: 10px;
        border: 1px solid #737373;
    }

    .big-buttonVRemove {
        display: inline-block;
        font-size: 13px;
        background-color: #fff;
        color: #454545;
        text-decoration: none;
        text-align: center;
        border-radius: 5px;
        margin-top: 10px;
        margin-left: 30px;
        border: 1px solid #737373;
        width: 91px;
        padding: 3px 8px;
    }

    @media (max-width: 768px) {
        .address-column {
            flex-basis: 100%;
        }

        .address-details label {
            margin-left: 20px;
        }

        .address-details p {
            margin-left: 20px;
        }
    }

    @media (max-width: 850px) {
        .address-column {
            flex-basis: 100%;
        }

        .address-details label {
            margin-left: 20px;
        }

        .address-details p {
            margin-left: 20px;
        }
    }


    @media screen and (max-width: 600px) {
        .columnproduct {
            flex: 100%;
        }

        .columnprice {
            flex: 100%;
        }

        .sub-column {
            flex: 100%;
        }
    }

    .container12 {
        display: flex;
        align-items: center; /* Vertically center the items */
    }

    .counter-label {
        margin-right: 10px; /* Add some spacing between the label and the counter */
    }

    .columnQO-1 {
        width: 500px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    @media screen and (max-width: 800px) {
        .columnproduct {
            display: flex;
            flex-direction: column;
        }

        .columnprice {
            flex: 100%;
        }

        .sub-column {
            flex: 100%;
        }

        .columnButton {
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            line-height: 2.5rem;
        }

        .button-containerRemark {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-left: -40px;
        }

        .columnBox {
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        .counter {
            margin-right: 55px;
        }

        .totalpcs {
            margin-right: 119px;
        }

        .centered {
            text-align: center;
        }

        .centered-image {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        .itemHead {
            font-size: 14px;
            text-align: center;
            padding: 0px;
        }

        .centered-container {
            display: flex;
            justify-content: center;
            background-color: #a8ddf4;
        }

        .columnQO-1 {
            width: 100%;
            display: flex;
            height: auto;
        }

        .address-column {
            margin-bottom: 10px;
            flex: 1;
        }

        .address-details {
            margin-left: 0;
            flex: 1;
        }

        .fit-image {
            max-width: 100%;
        }




        .container-row {
            height: auto;
        }

        .rowforprice {
            display: flex;
            justify-content: space-between;
        }

        .columnforprice {
            flex-basis: 50%;
        }

        .price,
        .details {
            margin-top: 0;
            margin-bottom: 5px;
            text-align: center;
            font-weight: bold;
        }

        .price-value,
        .details-value {
            text-align: center;
        }
    }

    .mov {
        font-size: 16px;
    }

    @media screen and (max-width: 768px) {
        .mov {
            font-size: 14px;
            text-align: center;
        }
    }

    @media screen and (max-width: 480px) {
        .mov {
            font-size: 12px;
            text-align: center;
        }
    }

    .freeshipping {
        font-size: 16px;
        color: #2ca9df;
        font-weight: 700;
        margin-top: 10px;
        font-family: Inter
    }

    @media screen and (max-width: 768px) {
        .freeshipping {
            font-size: 14px;
            text-align: center;
        }
    }

    @media screen and (max-width: 480px) {
        .freeshipping {
            font-size: 12px;
            text-align: center;
        }
    }

    .rowPD {
        display: flex;
        align-items: center;
        margin-top: -7px
    }

    .columnPrice, .columnSummary, .columnAccount
    .columnDetails {
        flex: 50%;
    }

    .columnDetails, .columnSummary {
        text-align: right;
    }

        .columnPrice h4,
        .columnDetails h4, .columnSummary h4, .columnAccount h4 {
            font-weight: 600;
        }

    .underline {
        border-bottom: 1px solid #afafaf;
        margin-bottom: 10px;
    }

    .columnPrice p,
    .columnDetails p {
        margin: 0;
        padding-bottom: 5px;
        flex: 50%;
    }

    .button-Cartcontainer {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .buttonCart {
        width: 400px;
        padding: 5px;
        background-color: #2ca9df;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-bottom: 40px;
        margin-top: 30px;
        font-family: Inter;
        font-size: 14px
    }



    .col-lg-9.row2::-webkit-scrollbar {
        width: 8px; /* Adjust the width of the scrollbar */
    }

    .col-lg-9.row2::-webkit-scrollbar-track {
        background: #f1f1f1; /* Set the background color of the scrollbar track */
    }

    .col-lg-9.row2::-webkit-scrollbar-thumb {
        background: #888; /* Set the color of the scrollbar thumb */
        border-radius: 4px; /* Add border radius to the scrollbar thumb */
    }

        .col-lg-9.row2::-webkit-scrollbar-thumb:hover {
            background: #555; /* Set the color of the scrollbar thumb on hover */
        }

    .parent-containerother {
        display: flex;
        justify-content: center; /* Aligns the table container horizontally */
    }

    .yourcartrow {
        width: 87%;
        flex: 1;
        height: 786px;
        padding: 1px;
        margin-left: -1px;
    }

    .table-container {
        display: flex;
        flex-wrap: wrap;
    }

    #ordertbl {
        width: 100%;
        table-layout: fixed;
    }

    .orderrow {
        border-bottom: 1px solid #ccc;
        flex-grow: 1;
    }

    .sub-row {
        display: flex;
        background-color: #fff;
    }

    table.sub-row {
        width: 100%;
        border-collapse: collapse;
    }

        table.sub-row td {
            padding: 10px;
            border-bottom: 1px solid #2ca9df;
            display: table-cell;
        }

    .table-container::-webkit-scrollbar {
        width: 0;
        background-color: transparent;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 10px;
    }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: grey;
        }



    .sub-column {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .sub-column-image {
        margin-right: 10px;
    }

    .sub-column-text {
        flex: 1;
        text-align: left;
    }


    .sub-column-text {
        flex: 1;
        text-align: left;
    }

        .sub-column-text h4 {
            font-size: 10px;
            color: #454545;
            font-family: "Inter", sans-serif;
        }

        .sub-column-text p {
            font-size: 10px;
            color: #454545;
            font-family: "Inter", sans-serif;
        }

        .sub-column-text span {
            font-size: 12px;
            font-weight: 600;
            font-family: "Inter", sans-serif;
        }


    .navigationTop {
        display: flex;
        align-items: center;
        padding: 10px;
        font-family: inter
    }

        .navigationTop a {
            text-decoration: none;
            color: #454545;
            font-family: inter
        }

            .navigationTop a:hover {
                color: #2ca9df;
                font-family: inter /* Change the color on hover to your desired color */
            }

        .navigationTop span {
            margin: 0 5px;
            color: #454545;
            font-family: inter
        }


    .btn.focus, .btn:focus, .btn:hover {
        color: #454545;
        text-decoration: none;
    }
</style>