<template>
    <div>
        <div class="container">
            <div class="navigationTop" style="margin-top: 110px; margin-left: 24px;padding:20px">
                <a href="#" @click="navigateToHomeUser">Home</a>
                <span>&gt;</span>
                <a href="#">Product</a>
            </div>
        </div>
      
        <div class="containerPD" style="display: flex; width: 1025px; margin-top: -100px; margin-bottom: 0px;">
            <div class="column1" style=" margin-top: 106px;">
                <div class="image" style="width:435px;height:370px">
                    <img :src="'data:image/png;base64,' + ProductDetails.CustomerLinkItemImageData" style="width:336px">
                </div>
                <div class="image-boxes">
                    <a href="your-link-here">
                        <div class="image-box" :style="{ backgroundImage: 'url(data:image/png;base64,' + ProductDetails.CustomerLinkItemImageData + ')' }"></div>
                    </a>
                    <a href="your-link-here">
                        <div class="image-box" :style="{ backgroundImage: 'url(data:image/png;base64,' + ProductDetails.CustomerLinkItemImageData + ')' }"></div>
                    </a>
                    <a href="your-link-here">
                        <div class="image-box" :style="{ backgroundImage: 'url(data:image/png;base64,' + ProductDetails.CustomerLinkItemImageData + ')' }"></div>
                    </a>
                    <a href="your-link-here">
                        <div class="image-box" :style="{ backgroundImage: 'url(data:image/png;base64,' + ProductDetails.CustomerLinkItemImageData + ')' }"></div>
                    </a>
                    <a href="your-link-here">
                        <div class="image-box" :style="{ backgroundImage: 'url(data:image/png;base64,' + ProductDetails.CustomerLinkItemImageData + ')' }"></div>
                    </a>
                    <a href="your-link-here">
                        <div class="image-box" :style="{ backgroundImage: 'url(data:image/png;base64,' + ProductDetails.CustomerLinkItemImageData + ')' }"></div>
                    </a>
                </div>
                <div class="button-container">
                    <a href="#/"
                       class="big-button"
                       style="text-decoration: none" @click="addToKart(ProductDetails)">
                        Add to Cart &nbsp;<i style="font-size: 24px" class="fa">&#xf07a;</i>
                    </a>
                </div>
            </div>
            <div class="column2">
                <div class="product-details" style="font-size: 14px;margin-left:10px">
                    <h3 style="font-family: Inter; color: #454545">{{ ProductDetails.CustomerLinkItemName }}</h3>
                    <hr />
                    <p class="productcode" style="font-family: Inter; color: #454545">Product Code: {{ ProductDetails.CustomerLinkItemCode }}</p>
                    <p class="unit-price" style="font-family: Inter; color: #454545">
                        Special Unit Price:
                        <span style="text-decoration: line-through; font-family: Inter; color: #454545">&#x20b9;{{ ProductDetails.CustomerLinkItemPrice }} </span>
                        <span style="font-weight: bold; font-size: 14px; color: #454545">&nbsp; &#x20b9;{{ ProductDetails.CustomerLinkItemDP }} </span>
                        {{ ProductDetails.CustomerLinkItemPrice !== 0 ? (((ProductDetails.CustomerLinkItemPrice - ProductDetails.CustomerLinkItemDP) / ProductDetails.CustomerLinkItemPrice) * 100).toFixed(2) : 0 }}% Off
                    </p>
                    <hr />
                </div>
                <div class="offers-container" style="margin-top: -25px;color:#454545;margin-left:8px" v-if="ProductDetails.ItemPartnerOffer">
                    <p style="font-family:Inter;color:#454545;font-size: 14px;">Available Offers</p>
                    <p style="font-family:Inter;color:#454545;font-size: 14px;">
                        {{ ProductDetails.ItemPartnerOffer }} <span class="offer-link" style="font-family:Inter;color:#454545;font-size: 14px;"> on your next purchase</span> <a href="know-more-link-here" style="color: #2ca9df; text-decoration: none;font-family:Inter;font-size: 14px;">Know More</a> <button class="add-remarks-button" style="font-family:Inter;margin-left: 131px; font-size: 12px;padding: 3px 5px;border:1px solid #ccc">Add Remarks</button>
                    </p>
                    <hr style="margin-bottom:30px" />
                </div>
                <div class="order-details" style="margin-top:-36px">
                    <div class="row">
                        <div class="col-md-6 custom-box-qty" style="color: #454545; font-family: Inter; margin-left: -2%;">
                            <div style="text-align: left; margin-left: 1px; margin-top: 10px;">
                                <span class="caption custom-box-qty-text" style="color: #454545; font-family: Inter; font-size: 14px;">
                                    Box Qty. &nbsp;
                                    <span class="custom-border">
                                        <Minplus :value="ProductDetails.BoxOrderQty"
                                                 v-model.number="ProductDetails.BoxOrderQty"
                                                 :min="0"
                                                 :max="99999"
                                                 @changeevent="updateBoxOrderQty"
                                                 style="cursor: pointer;">
                                        </Minplus>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6" style="font-size: 14px; color: #454545; font-family: Inter; margin-left: -4%;">
                            <div style="text-align: right; margin-top: 10px;">
                                <p class="caption">Qty Per Box: {{ ProductDetails.CustomerLinkItemBoxQty }} Pcs</p>
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col" style="display: flex; align-items: flex-start; justify-content: left; height: 100%; margin-bottom: 20px; margin-left: .5%;">
                            <label class="checkbox-label" style="font-size: 13px;">
                                <input type="checkbox" name="OrderType" id="TenderOrder" v-model="ProductDetails.TenderOrder" style="margin-right: 6px; margin-top: 0px;" />
                                <span class="order-text">Tender Order</span>
                            </label>
                        </div>
                        <div class="col" style="display: flex; align-items: center; justify-content: center; height: 100%; margin-bottom: 20px; margin-left: 14px;">
                            <label class="checkbox-label" style="font-size: 13px;">
                                <input type="checkbox" name="OrderType" id="FirstOrder" v-model="ProductDetails.FirstOrder" style="margin-right: 6px; margin-top: -2px;" />
                                <span class="order-text">1st Time Order</span>
                            </label>
                        </div>
                        <div class="col custom-button-containerRemark custom-remark-style" style="text-align: end;">
                            <button type="button" :class="getRemarkClass(ProductDetails)" @click="remarkPopup" class="custom-add-remark-button" style="font-size: 13px; padding: 2px 5px; margin-top: 9px; ">Add Remark</button>
                        </div>
                    </div>

                    <div class="order-container" style="display: flex;">
                        <div class="order-row" style="margin-left: -0.5%;">
                            <div class="order-item" style="flex: 1;">
                                <p style="text-align: left; font-family: Inter; font-size: 14px;">Order Value</p>
                                <p style="display: flex; align-items: center; font-family: Inter; font-size: 12px;">
                                    &#x20b9;&nbsp;<input disabled type="number" class="form-control" :value="getOrderVal(ProductDetails).toLocaleString()" style="background:#fff;font-size:12px;padding:0;text-align:center" />
                                </p>
                            </div>
                            <div class="order-item" style="margin-top: -15px; flex: 1;">
                                <p style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; text-align: center; font-family: Inter; color: #454545; font-size: 14px;">Tax Percentage</p>
                                <p style="display: flex; align-items: center; font-family: Inter; font-size: 12px;"><input disabled type="number" class="form-control" :value="ProductDetails.CustomerLinkItemTaxPer" style="text-align: center; background: #fff; font-size: 12px; padding:0;" />%</p>
                            </div>
                            <div class="order-item" style="margin-top: -15px; flex: 1;">
                                <p style="text-align: left; font-family: Inter; color: #454545; text-align: center; font-size: 14px;">Tax Amount</p>
                                <p style="display: flex; align-items: center; font-family: Inter; color: #454545; text-align: center; font-size: 12px;">&#x20b9;&nbsp;<input disabled type="number" class="form-control" :value="getTax(ProductDetails)" style="padding: 4px; text-align: center; background: #fff; font-size: 12px; padding:0;" /></p>
                            </div>
                            <div class="order-item" style="margin-top: -15px; flex: 1;">
                                <p style="text-align: left; font-family: Inter; color: #454545; text-align: right; font-size: 13px;">Total Amount</p>
                                <p style="display: flex; align-items: center; font-family: Inter; color: #454545; font-size: 12px;">&#x20b9;&nbsp;<input disabled type="number" class="form-control" :value="getTotal(ProductDetails)" style="background: #fff; font-size: 12px; padding:0;text-align:center" /></p>
                            </div>
                        </div>
                    </div>


                </div>
                <div class="prodet" style="margin-top: -10px; font-family: Inter; color: #454545; font-size: 12px;">
                    <h3 style="font-family: Inter; color: #454545; margin-left: 6px; font-size: 16px;">Product Details</h3>
                    <p style="margin-top: -10px; font-size: 14px;">
                        <span style="font-family: Inter; color: #454545;">Brand:</span> {{ ProductDetails.ItemBrand }}
                    </p>
                    <p style="font-size: 14px;"><span style="font-family: Inter; color: #454545;">Material:</span> {{ ProductDetails.ItemMaterialType }}</p>
                    <p style="font-size: 14px;"><span style="font-family: Inter; color: #454545;">Product Colour:</span> {{ ProductDetails.ItemColor }} </p>
                    <p style="font-size: 14px;"><span style="font-family: Inter; color: #454545;">Product Type:</span> {{ ProductDetails.ItemProductType }}</p>
                </div>

                <hr style="margin-top: -5px;margin-left:10px" />
                <div class="resource" style="margin-top: -19px; margin-left: 6px;">
                    <h3 style="font-family: Inter; color: #454545; font-size: 16px;">Resource</h3>
                </div>

            </div>
            <hr />

            <div v-if="SimilarProducts.length > 0" style="background: #fff;width: 100%;margin-top: 20px;margin-bottom: 20px;margin-left: 40px;margin-right: 40px;padding-left: 40px;padding-right: 40px;padding-bottom: 20px;padding-top: -20px;">
                <h4 class="textsoln" style="padding-top: 8px; margin-bottom: -3px">
                    Similar products
                </h4>
                <br />
                <div id="carouselExampleControls2" class="carousel slide" v-if="SimilarProducts.length > 0">
                    <div class="carousel-inner" style="padding: 0px; ">
                        <div class="carousel-item" :class="{ active: index==0 }" v-for="(productsimilar,index) in SimilarProducts" :key="index">
                            <div id="carousel-card" class="row  align-items-center justify-content-center" style="padding-top:10px">
                                <div class="col-md-2" v-for="item in productsimilar" :key="item.ItemCode">
                                    <div class="hom-box" style="padding: 2px;
                                                text-align: center;
                                                box-shadow: 0 5px 4px #d6d4d4;
                                                width: 150px;
                                                height: 300px;
                                                display: flex;
                                                flex-direction: column;
                                                align-items: center;
                                                position: relative;">
                                        <div class="spanmyicon14">
                                            <div>
                                                <img style="background: #eee; width: 100%; height: 150px; border-radius: 0px; cursor: pointer;" :src="'data:image/png;base64,' + item.CustomerLinkItemImageData " ratio="1">
                                            </div>
                                        </div>
                                        <div style="color: rgba(69, 69, 69, 1);
                                            font-weight: bold;
                                            margin-top: 20px;
                                            line-height: 1.2;
                                            font-size:9px;
                                            text-transform:uppercase;">
                                            {{ item.CustomerLinkItemName }}
                                        </div>
                                        <div style="color: rgba(69, 69, 69, 1);
                                        font-size: 10px;
                                        margin-top: 4px;">
                                            Product Code: {{ item.ItemCode }}
                                        </div>
                                        <div>
                                            <div style="display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        margin-top: 1px;">
                                                <span style="justify-content: space-between; align-items: center;">
                                                    <span style="width: 35px; text-align: left">
                                                        <span style="font-weight: bold">
                                                            &#8377;
                                                            {{ item.CustomerLinkItemDP }}&nbsp;
                                                        </span><span style="text-decoration: line-through">
                                                            &nbsp;&#8377;
                                                            {{ item.CustomerLinkItemPrice }}
                                                        </span><span> &nbsp;</span>
                                                        {{ item.CustomerLinkItemPrice !== 0 ? (((item.CustomerLinkItemPrice - item.CustomerLinkItemDP) / item.CustomerLinkItemPrice) * 100).toFixed(2) : 0 }}% Off
                                                    </span>
                                                </span>
                                                <div style="position: absolute;
                                            bottom:10px;
                                            left: 44%;
                                            transform: translateX(-50%);"> <button class="btnAddKartP" @click="addToKart(item)">Add to Cart</button></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleControls2" role="button" data-slide="prev" style="margin-left: -30px; width: fit-content; height: fit-content; margin-top: 100px">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleControls2" role="button" data-slide="next" style="margin-right: -30px; width: fit-content; height: fit-content; margin-top: 100px ">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
        </div>
        <div v-if="remarkAllProduct">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title">Add Remark</h4>
                                    <button type="button" class="btn btn-sm btn-danger" @click="remarkAllProduct=false">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body" style="text-align: center;">
                                    <textarea v-model="RemarkAllProductText" class="form-control"></textarea>
                                    <br />
                                    <button class="btn btn-info  btn-w btn-sm" @click="addRemarkToProduct"> Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import Minplus from "../v-minusplusfield";
    import axios from 'axios';
    import helper from '../helper';
    import Swal from 'sweetalert2';
    export default {
        name: 'product',
        components: {
            Minplus
        },
        data() {
            return {
                title: 'Product',
                ProductDetails: {},
                KartProducts: [],
                userdata: {},
                SimilarProducts: [],
                itemgroup: [],
                isProductMenuOpen: false,
                isProfileMenuOpen: false,
                CustomerName: "",
                CustomerCode: "",
                SimilarProductsLength: 0,
                TenderOrder: false,
                FirstOrder: false,
                remarkAllProduct: false,
                RemarkAllProductText: ""
            }
        },
        mounted() {
            this.getProductDetails();
            this.loadUserData();
            this.getSimilarProducts();
            this.selectProductGroup();
        },
        beforeMount: function () {
            
        },
        methods: {
            addRemarkToProduct() {
                this.ProductDetails.Remark = this.RemarkAllProductText
                this.remarkAllProduct = false
            },
            remarkPopup() {
                if (this.ProductDetails.Remark == "") {
                    this.RemarkAllProductText = ""
                } else {
                    this.RemarkAllProductText = this.ProductDetails.Remark
                }
                this.remarkAllProduct = true
            },
            getRemarkClass(item) {
                if (item.Remark != '' && item.Remark !== undefined) {
                    return 'btn btn-default btn-mydark btn-dsm'
                } else {
                    return 'btn btn-default btn-dsm'
                }
            },
            async selectProductGroup() {
                try {
                    var l = localStorage.getItem("userdata")
                    if (l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { custcode: userData.CustomerCode };
                        const response = await axios.post(helper.URL() + 'selectcustomerproductgroup', requestBody);
                        const result = response.data;
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                self.itemgroup = [];
                                const groupSize = 6;
                                for (let i = 0; i < result.Data[0].length; i += groupSize) {
                                    self.itemgroup.push(result.Data[0].slice(i, i + groupSize)); // Access the inner array
                                }
                                self.itemgroup = [{ ItemGroupId: 0, ItemGroupName: "All Products" }]
                                result.Data[0].forEach(element => {
                                    self.itemgroup.push(element)
                                });
                            }
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            closeDropdowns() {
                this.isProductMenuOpen = false;
                this.isProfileMenuOpen = false;
            },
            navigateToHomeUser(event) {
                event.preventDefault();
                this.$router.push('/homeUser');
            },
            navigateToDashBoard(event) {
                event.preventDefault();
                this.$router.push('/dashBoard');
            },
            openDropdown(menu) {
                this[menu + 'MenuOpen'] = true;
            },
            productClick(groupId, groupName) {
                this.$router.push({ name: 'homeProducts', params: { groupId, groupName } });
            },
            highlightItem(event) {
                event.target.style.backgroundColor = '#007bff';
            },
            unhighlightItem(event) {
                event.target.style.backgroundColor = '#ffffff';
            },
            toggleMenu(menuType) {
                if (menuType == 'Product') {
                    this.isProductMenuOpen = !this.isProductMenuOpen;
                    this.isProfileMenuOpen = false;
                } else {
                    this.isProfileMenuOpen = !this.isProfileMenuOpen;
                    this.isProductMenuOpen = false;
                }
            },
            navigateToLogout(event) {
                event.preventDefault();
                localStorage.removeItem("userdata");
                localStorage.removeItem("Kart");
                this.$router.push('/home');
            },
            loadUserData() {
                var l = localStorage.getItem("userdata")
                if (l != "") {
                    var userData = JSON.parse(l);
                    this.userdata = userData;
                    this.custPreviousDues = this.userdata.CustomerDue
                    this.CustomerName = this.userdata.CustomerName
                }
                var k = localStorage.getItem("Kart");
                if (l != "" && k != "" && k !== null) {
                    var kobj = JSON.parse(k)
                    if (kobj.CustId == this.userdata.CustomerCode) {
                        this.KartProducts = kobj.items
                    }
                }
            },
            getProductDetails() {
                var pd = localStorage.getItem("SelectedProductDetails")
                this.ProductDetails = {};
                if (pd != "") {
                    this.ProductDetails = JSON.parse(pd);
                    //console.log(JSON.stringify(this.ProductDetails))
                }
            },
            addToKart(p) {
                if (p.BoxOrderQty > 0) {
                    var found = false
                    var foundindex = 0
                    this.KartProducts.forEach((element, index) => {
                        if (element.CustomerLinkItemCode == p.CustomerLinkItemCode) {
                            found = true
                            foundindex = index
                        }
                    });
                    if (found) {
                        this.KartProducts[foundindex].BoxOrderQty += p.BoxOrderQty
                        this.KartProducts[foundindex].PcsOrderQty += p.PcsOrderQty
                    } else {
                        this.KartProducts.push(JSON.parse(JSON.stringify(p)))
                    }
                    this.storeKart();
                    this.$router.push('/productCart');
                } else {
                    Swal.fire('Alert!', "Enter Qty to add.", 'error');
                }
            },
            storeKart() {
                var j = { CustId: this.userdata.CustomerCode, items: this.KartProducts }
                localStorage.setItem("Kart", JSON.stringify(j))
                Swal.fire('Alert!', "Successfully Added.", 'info');
            },
            boxChange: function (itemCode) {
                var bqty = this.ProductDetails.CustomerLinkItemBoxQty;
                this.ProductDetails.PcsOrderQty = this.ProductDetails.BoxOrderQty * bqty;
            },
            updateBoxOrderQty(value) {
                this.ProductDetails.BoxOrderQty = value;
                this.boxChange(this.ProductDetails.CustomerLinkItemCode);
            },
            getOrderVal(p) {
                var rval = 0
                rval = p.PcsOrderQty * p.CustomerLinkItemDP
                return rval.toFixed(2)
            },
            getTax(p) {
                var rval = 0
                rval = (p.PcsOrderQty * p.CustomerLinkItemDP / 100) * p.CustomerLinkItemTaxPer;
                return rval.toFixed(2);
            },
            getTotal(p) {
                var rval = 0
                var t = p.PcsOrderQty * p.CustomerLinkItemDP
                var tax = (t / 100) * p.CustomerLinkItemTaxPer;
                rval = t + tax
                return rval.toFixed(2)
            },
            async getSimilarProducts() {
                try {
                    var l = localStorage.getItem("userdata")
                    if (l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { custcode: userData.CustomerCode, itemcode: this.$route.params.productCode }
                        const response = await axios.post(helper.URL() + 'customersimilarproducts', requestBody);
                        const result = response.data;
                        self.SimilarProducts = [];
                        this.SimilarProductsLength = 0;
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                this.SimilarProductsLength = result.Data.length;
                                const groupSize = 6;
                                for (let i = 0; i < result.Data.length; i += groupSize) {
                                    self.SimilarProducts.push(result.Data.slice(i, i + groupSize));
                                }
                            }
                        }
                        else {
                            //Swal.fire('Alert!', "Response not found!!!", 'error');
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }
    }
</script>

<style scopped>
    .btn-mydark {
        color: rgb(54, 54, 54) !important;
        background-color: #a8ddf4 !important;
        color: rgb(54, 54, 54) !important;
    }
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
    .custom-button-containerRemark,
    .custom-button-containerQ {
        margin-bottom: 20px;
        margin-left: 40px;
    }
    .single-item-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
    /*Styles for Menu*/
    .navigation {
        height: 55px;
        background: #2CA9DF;
        margin: 0 auto;
    }

    .nav-container {
        max-width: 1000px;
        margin: 0 auto;
    }

    .brand {
        float: left;
        line-height: 55px;
        font-weight: 600;
        float: left;
        font-size: 13px;
    }

    .nav-mobile {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        background: transparent;
        height: 55px;
        width: 70px;
    }

    .nav-list {
        list-style-type: none;
        padding: 0;
    }

        .nav-list > li {
            display: inline-block;
            margin-right: 10px;
        }

        .nav-list a {
            text-decoration: none;
            color: #333;
        }

    .hover-menu {
        display: none;
    }

    .navbar-dropdown:hover .hover-menu {
        display: block;
    }

    .navbar-dropdown ul {
        display: none;
    }

        .navbar-dropdown ul.open {
            display: block;
        }

    .navbar-dropdown li a {
        background: #2581DC;
        cursor: pointer;
    }

    .navbar-dropdown {
        position: absolute;
        display: none;
        z-index: 1;
        background: #2CA9DF;
        color: #fff;
    }

    .navbar-dropdown {
        position: static;
    }

    .open .submenu {
        display: block;
    }

    .open {
        display: block !important;
    }

    @media screen and (min-width:800px) {
        .nav-list {
            display: block !important;
        }
    }
    /*End of Menu style*/
    .textsimilar {
        display: flex;
        flex-direction: column;
        height: 15px;
        width: 809px;
        margin-left: -350px;
        border-radius: 0px;
        margin-top: -6px;
        height: 15px;
        width: 809px;
        color: rgba(69, 69, 69, 1);
        font-family: "Inter", sans-serif;
    }
    .carousel-control-prev .carousel-control-prev-icon,
    .carousel-control-next .carousel-control-next-icon {
        filter: invert(50%);
    }
    .containerPD {
        margin: 0 auto;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
  
        justify-content: center;
        display: flex;
        justify-content: space-between;
    }

    .containerSM {
        background: #fff;
        padding: 20px;
        margin: 0 auto;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: 0px;
        justify-content: center;
    }
    .containerSMSingle {
        background: #fff;
        padding: 20px;
        margin: 0 auto;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: 0px;
        width: min-content;
        justify-content: center;
    }
    .column1 {
        flex-basis: 100%;
        padding: 40px;
        box-sizing: border-box;
        background-color: #eee;
        flex: 1;
        display: flex;
        flex-direction: column;
        width: calc(50% - 10px);
    }

    .image {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 497px;
        width: 498px;
        /*background-image: url("../assets/img/Artboard.jpg");*/
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 18px;
        margin: 0 auto;
        justify-content: center;
        margin-bottom: 40px;
        background-color: rgba(243, 243, 243, 1);
    }

    .image-boxes {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        
    }

    .image-box {
        flex-basis: 16.66%;
        width: 54px;
        height: 54px;
       /* background-image: url("../assets/img/Artboard.jpg");*/
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin: 10px;
        padding: 2px;
        border-radius: 5px;
        background-color: #fff;
        border: 1px solid rgba(185, 185, 185, 1);
    }

    .button-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-top: 40px;
    }

    .big-button {
        display: inline-block;
        padding: 10px 20px;
        font-size: 18px;
        background-color: #2ca9df;
        color: #fff;
        text-decoration: none;
        width: 432px;
        text-align: center;
    }

    .column2 {
        flex-basis: 100%;
        padding: 10px;
        box-sizing: border-box;
        background-color: blueviolet;
        flex: 1;
        display: flex;
        flex-direction: column;
        width: calc(50% - 10px);
    }

    .product-details {
        margin-bottom: 20px;
    }

    .column2 hr {
        border: none;
        border-top: 1px solid #ccc;
        margin: 20px 0;
    }

    .column2 p {
        /* font-size: 16px; */
        margin-bottom: 10px;
    }

    .column2 span {
        display: inline-block;
    }

    .offer-link {
        display: inline-block;
        margin-right: 10px;
    }

        .offer-link a {
            margin-left: 5px;
            text-decoration: none;
        }

    .add-remarks-button {
        margin-left: 169px;
    }

    .add-remarks-button {
        background-color: #fff;
        color: #454545;
        border: none;
        padding: 5px 20px;
        font-size: 14px;
        cursor: pointer;
        border: 1px solid #737373;
        border-radius: 5px;
        padding-left: 7px;
        padding-right: 7px;
        text-align: center;
        margin-left: 258px;
    }

        .add-remarks-button:hover {
            background-color: #2ca9df;
            color: #fff;
        }

        .add-remarks-button:focus {
            outline: none;
        }

        .add-remarks-button:active {
            transform: translateY(1px);
        }

    /* Default styles */
    .order-details {
        padding: 10px;
        /* Add your additional styles for the order details container */
        font-family: Arial, sans-serif;
        font-size: 14px;
        color: #333333;
    }

    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* Add your styles for the row container */
    }

    .col {
        flex: 1;
        margin-right: 10px;
        justify-content: space-between;
    }

        .col p {
            /* Add your styles for the <p> element inside .col */
            margin: 0;
            font-size: 14px;
            color: #000;
            opacity: 0.8;
        }



    .checkbox-label {
        display: flex;
        align-items: center;
        margin-top: 15px;
        color: #454545;
        font-weight: 100;
        justify-content: left;
    }

        .checkbox-label input[type="checkbox"] {
            margin-right: 10px;
        }

    .order-container {
        display: flex;
        flex-wrap: wrap;
      
    }

    .order-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .order-item {
      
        justify-items: center;
        border-right: 1px solid #ccc;
       height:100%;
        padding:5px;
        text-align: center;
    }

        .order-item:last-child {
            border-right: none;
        }

    .delivery-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .delivery-date,
    .location {
        flex: 1;
    }

    .location {
        text-align: right;
    }

    .prodet {
        padding: 10px;
        border-radius: 5px;
    }

        .prodet h3 {
            margin: 0;
            padding-bottom: 10px;
            font-size: 18px;
            font-weight: bold;
        }

        .prodet p {
            margin: 0;
            padding: 5px;
        }

        .prodet span {
            font-weight: normal;
        }

    .specifications {
        padding: 10px;
    }

        .specifications h3 {
            margin: 0;
            padding-bottom: 10px;
            font-size: 18px;
            font-weight: bold;
        }

        .specifications p {
            margin: 0;
            padding: 5px;
        }

        .specifications span {
            font-weight: normal;
        }

    .resource {
        margin-bottom: 20px;
    }

        .resource h3 {
            font-size: 18px;
            font-weight: bold;
            color: #333;
            margin-left: 9px;
        }

    /* Responsive styles */
    @media only screen and (max-width: 600px) {
        .row {
            flex-direction: column;
        }

        .col {
            margin-bottom: 10px;
        }

        .order-item {
            flex-basis: 100%;
        }
    }

    @media screen and (max-width: 768px) {
        .add-remarks-button {
            font-size: 14px;
            padding: 8px 16px;
            margin-left: 153px;
        }

        .quantity-btn {
            width: 20px;
        }

        .product-details h3 {
            font-size: 17px;
            text-align: center;
            font-weight: 600;
        }

        .order-item {
            flex-basis: 100%;
            border-right: none;
        }

        .prodet {
            text-align: center;
        }

            .prodet h3 {
                font-size: 16px;
                text-align: center;
            }

            .prodet p {
                display: block;
                text-align: center;
            }

        .specifications {
            text-align: center;
        }

            .specifications h3 {
                font-size: 16px;
                text-align: center;
            }

            .specifications p {
                display: block;
                text-align: center;
            }

        .checkbox-labe {
            text-align: center;
        }

        .order-text {
            text-align: center;
        }

        .resource h3 {
            font-size: 16px;
        }

        .row {
            flex-direction: row;
        }

        .col {
            margin-bottom: 10px;
        }

        .order-item {
            flex-basis: 20%;
        }
    }

    @media (min-width: 768px) {
        .image-boxes {
            flex-wrap: nowrap;
            overflow-x: auto;
        }

        .image-box {
            flex-basis: auto;
            width: 54px;
            height: 54px;
        }

        .column1,
        .column2 {
            flex-basis: 50%;
        }
    }

    @media only screen and (max-width: 600px) {
        .image-box {
            flex-basis: 33.33%;
            width: 80px;
            height: 80px;
        }

        .column2 {
            font-size: 14px;
        }

            .column2 span {
                display: block;
                margin-left: 0;
                margin-top: 5px;
            }

        .offers-container {
            text-align: center;
        }

        .offer-link,
        a,
        .add-remarks-button {
            display: block;
            margin: 10px auto;
        }

        .productname {
            font-size: 18px;
            text-align: center;
        }

        .productcode {
            font-size: 14px;
            text-align: center;
        }

        .unit-price {
            font-size: 16px;
            text-align: center;
        }

        .caption {
            font-size: 16px;
            text-align: center;
        }

        .checkbox-label {
            font-size: 16px;
            text-align: center;
        }
    }

    @media screen and (max-width: 480px) {
        .add-remarks-button {
            font-size: 12px;
            padding: 6px 12px;
        }
    }

    .caption {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .quantity-btn {
        display: inline-block;
        padding: 6px 10px;
        font-size: 14px;
        background-color: #eee;
        color: #454545;
        border: none;
        cursor: pointer;
    }

    .quantity-input {
        width: 110px;
        padding: 5px;
        border: 1px solid #ccc;
    }

        .quantity-input:focus {
            outline: none;
        }

    .quantity-btn:focus {
        outline: none;
    }

    .order-item p {
        margin-right: 8px;
        justify-content: right;
    }
    .hom-box {
        box-sizing: border-box;
        border-radius: 5px;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        line-height: 15px;
        letter-spacing: 0px;
        text-align: center;
        color: #000;
        height: auto;
        display: grid;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        margin-bottom: 5px
    }
    .hom-box14 {
        box-sizing: border-box;
        border-radius: 5px;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        line-height: 15px;
        letter-spacing: 0px;
        text-align: center;
        color: #000;
        height:auto;
        display: grid;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        margin-bottom:5px
    }
    .hom-box14-SingleBox {
        box-sizing: border-box;
        border-radius: 5px;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        line-height: 15px;
        letter-spacing: 0px;
        text-align: center;
        color: #000;
        height: auto;
        display: grid;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        margin-bottom: 5px
    }
    .spanmyicon14 {
        /* height: 80px; */
        height: auto;
        display: grid;
        place-items: center;
        align-items: end;
        border-radius: 50%;
        /* border: 2px solid rgb(194, 34, 34); */
    }

        .spanmyicon14 img {
            margin-top: 4px;
            margin-bottom: 7.7px;
            width: 133px;
            margin-top: -6px;
        }


    .btnAddKartP {
        background: rgba(44, 169, 223, 1);
        color: white;
        border: none;
        cursor: pointer;
        margin-bottom: 5px;
        font-size: 11px;
        padding: 5px;
        width: 85px;
        margin-left: 19px;
    }

    .product-title {
        display: inline-block;
        white-space: normal;
        background: rgba(255, 255, 255, 1);
        /* width: 142px; */
        width: 100%;
        height: 30px;
    }

    .btnGetQuote {
        /* width: 82px; */
        height: 20px;
        background-color: rgba(168, 221, 244, 1);
        color: rgba(69, 69, 69, 1);
        border: none;
        font-family: Inter, sans-serif;
        font-size: 16px;
        cursor: pointer;
        margin-top: 10px;
        height: 20px;
        opacity: 0.89;
        font-size: 14px;
    }

    .column1 {
        background-color: #fff;
        margin-top: 100px
    }

    .column2 {
        background-color: #fff;
        margin-top: 106px
    }


    .custom-box-qty {
        margin-top: -10px;
        font-family: 'Inter', sans-serif;
        color:#454545
    }

    .custom-box-qty-text {
        font-family: 'Inter', sans-serif;
        color: #454545
    }

    .custom-border {
        border: 1px solid #ccc;
        border-radius: 5px;
        font-family: 'Inter', sans-serif;
    }


    .custom-remark-style {
        margin-top: -10px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-weight: normal !important;
    }

    .custom-add-remark-button {
        text-decoration: none;
        color: #454545;
        margin-top: 26px;
        font-family: 'Inter', sans-serif;
        font-size: 12px;
    }


    /*.navigationTop {
        display: flex;
        align-items: center;
        padding: 10px
    }

        .navigationTop a {
            text-decoration: none;
            color: #454545;
        }

            .navigationTop a:hover {
                color: #2ca9df;*/ /* Change the color on hover to your desired color */
            /*}

        .navigationTop span {
            margin: 0 5px;
            color: #454545;
        }*/

    .order-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        border-bottom: 1px solid #ccc; /* Add a border-bottom to create the line */
    }

    /* Adjust other styles as needed */
    .order-item {
        flex: 1;
        margin-top: -15px;
    }

    .image-box {
        width: 49px;
        height: 53px;
        background-size: cover; /* Maintain aspect ratio and cover the container */
        background-repeat: no-repeat;
        background-position: center;
    }


    .navigationTop {
        display: flex;
        align-items: center;
        padding: 10px;
        font-family: Inter;
        font-size: 12px
    }

        .navigationTop a {
            text-decoration: none;
            color: #454545;
            font-family: Inter;
            font-size: 12px
        }

            .navigationTop a:hover {
                color: #2ca9df;
                font-family: Inter;
                font-size: 12px
            }

        .navigationTop span {
            margin: 0 5px;
            color: #454545;
            font-family: Inter;
            font-size: 12px
        }





</style>