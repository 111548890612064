<script setup>
    import HeaderApp from "./components/headerApp.vue";
    import FooterApp from "./components/footerApp.vue";
</script>
<template>
    <div id="app">
        <!-- ======= Header ======= -->
        <header>
            <div class="wrapper">
                <HeaderApp :isNotLogedIn="isNotLogedIn" />
            </div>
        </header>
        <main id="main" class="main-bg">
            <div>
                <router-view v-slot="{ Component }" @updateImagePopup="updateImagePopup">
                    <transition>
                        <component :is="Component" />
                    </transition>
                </router-view>
            </div>
        </main>
        <!-- ======= Footer ======= -->
        <footer>
            <div>
                <FooterApp :isFooter="isFooter" />
            </div>
        </footer>
    </div>
</template>

<script>
    import axios from 'axios';
    import helper from './helper'
    export default {
        name: 'App',
        data() {
            return {
                notification: false,
                itemgroup: [],
                CustomerName: '',
                showImagePopup: false
            }
        },
        mounted: function () {
            
        },
        created: function () {
            if (!this.isNotLogedIn()) {
                this.LoadUserData();
                this.GetCustItemGroup();
                this.GetNotification();
            }
        },
        methods: {
            updateImagePopup(value) {
                this.showImagePopup = value;
                const mainElement = document.getElementById('main');
                if (value == true) {
                    mainElement.classList.remove('main-bg');
                    mainElement.classList.add('overlay-container');
                } else {
                    mainElement.classList.remove('overlay-container');
                    mainElement.classList.add('main-bg');
                }
            },
            GetCustItemGroup() {
                try {
                    var l = localStorage.getItem("userdata")
                    var self = this;
                    if (l != "") {
                        var udata = JSON.parse(l)
                        const requestBody = { CustomerCode: udata[0].CustomerCode, itemgroupid: '0' };
                        const response = axios.post(helper.URL() + 'getitemgroup', requestBody);
                        const result = response.data;
                        if (result.status == "200") {
                            if (result.length > 0) {
                                self.itemgroup = [{ ItemGroupId: 0, ItemGroupName: "All Products" }]
                                result.forEach(element => {
                                    self.itemgroup.push(element)
                                });
                            }
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            NaveHomepage() {
                var ls = localStorage.getItem("userdata");
                if (ls == "" || ls === undefined || ls == "null" || ls === null) {
                    this.nav("/home")
                } else {
                    this.nav("/homeUser")
                }
            },
            Logout() {
                localStorage.removeItem("userdata");
                localStorage.removeItem("Kart");
                this.isBlurred = false;
                this.nav("/home");
            },
            isFooter() {
                if (this.$route.path == '/login') {
                    return false
                }
                return true
            },
            Navigate(p) {
                this.$router.push(p)
            },
            isQuickOrder() {
                if (this.$route.path == '/quickorder') {
                    return true
                }
            },
            isNotLogedIn() {
                if (this.$route.path == '/contactUs' || this.$route.path == '/login' || this.$route.path == '/registerNew' || this.$route.path == '/forgotPassword'
                    || this.$route.path == '/home' || this.$route.path == '/' || this.$route.path == '/aboutUs') {
                    return true
                }
            },
            LoadUserData() {
                var l = localStorage.getItem("userdata")
                if (l != "") {
                    var u = JSON.parse(l)
                    this.userdata = u
                }
            }
        }
    }
</script>

<style>
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
     
    }
    .overlay-container {
        position: relative;
    }

        .overlay-container::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.8);
            z-index: 9997;
        }
    .main-bg {
        background: #F5F5F5;
    }
    .moveInUp-enter-active {
        animation: fadeIn 2s ease-in;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        50% {
            opacity: 0.5;
        }

        100% {
            opacity: 1;
        }
    }

    .moveInUp-leave-active {
        animation: moveInUp .3s ease-in;
    }

    @keyframes moveInUp {
        0% {
            transform: translateY(0);
        }

        100% {
            transform: translateY(-400px);
        }
    }

    #navbar a, .navbar a:focus {
        border-bottom: none !important;
        color: #000;
    }

    #navbar > ul > li {
        padding: 0px 0 0px 60px
    }

    .myds {
        background: #DDDEE5;
        color: #000;
        padding: 0px 5px;
        font-family: Roboto;
        font-size: 14px;
    }

    .b-count {
        position: absolute;
        right: -71px;
        top: -0.75em;
        content: attr(data-count);
        padding: 0.5em;
        border-radius: 5px;
        line-height: .9em;
        color: white;
        background: #00AEEF;
        text-align: center;
        min-width: 2em;
        font: bold 12px sans-serif;
    }

    .R-count {
        position: absolute;
        right: -116px;
        top: -0.75em;
        content: attr(data-count);
        padding: 0.5em;
        border-radius: 5px;
        line-height: .9em;
        color: white;
        background: rgba(255,0,0,.75);
        text-align: center;
        min-width: 2em;
        font: bold 12px sans-serif;
    }

    .homepage {
        cursor: pointer;
    }

    .notificationbox {
        position: absolute;
        z-index: +1000000;
        /*right: 150px;
        top: 70px;*/
        right: 106px;
        top: 64px;
        width: 480px;
        /*height: 400px;*/
        max-height: 400px; 
        overflow-y: auto;
    }

        .notificationbox:before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            left: 360px;
            top: -30px;
            border: 20px solid transparent;
            border-bottom: 20px solid #2c3e50;
        }

    ul.list-group > li:nth-of-type(odd) {
        background-color: #e9e9f9;
    }
</style>

