<template>
    <div>
        <div style="background: rgb(240, 240, 245); margin-top: 10px; display: flex; justify-content: center; align-items: center; height: auto;padding:50px">
            <div class="row font" style="margin-bottom: -2rem; margin-right: 1rem;">
                <div class="container" style=" margin-top: 1rem; margin-bottom: 4rem">
                    <div class="navigationTop" style="padding-top: 20px; margin-left: 45px;">
                        <a href="#" @click="navigateToHomeUser">Home</a>
                        <span>&gt;</span>
                        <a href="#" @click="navigateToKyc">Kyc</a>
                        <span>&gt;</span>
                        <a href="#">Add Address</a>
                    </div>
                    <div class="row justify-content-center" >
                        <div class="col-md-9" style="width: 100%; ">
                            <div class="flex-container" >
                                <form @submit.prevent="formSubmit">
                                    <div class="items" style="color: #454545; font-family: Inter; font-size: 14px;">
                                        <label for="option" style="color: #454545; font-family: Inter; font-size: 14px;border-radius:10px">Select An Option:</label>
                                        <select class="form-select" aria-label="Default select example" v-model="AddressType" style="font-family: Inter; border-radius: 5px; outline: none; font-size: 14px; ">
                                            <option value="B">Bill To</option>
                                            <option value="S">Ship To</option>
                                            <option value="O">Other</option>
                                        </select>
                                        <label for="cityName" class="col-form-label pr-3">City</label>
                                        <input type="text" class="form-control" id="AddressCity" placeholder="" v-model="AddressCity" style="color: #454545; font-size: 14px;">
                                        <label for="stateName" class="col-form-label pr-3" style="color: #454545; font-size: 14px;">State</label>
                                        <input type="text" class="form-control" id="AddressState" placeholder="" v-model="AddressState" style="color: #454545; font-size: 14px;">
                                        <label for="gstNumber" class="col-form-label pr-3" style="color: #454545; font-size: 14px;">Gst Number</label>
                                        <input type="text" class="form-control" id="AddressGstNo" placeholder="" v-model="AddressGstNo">
                                        <label for="transpoterAdd" class="col-form-label pr-3" style="color: #454545; font-size: 14px;">Transporter</label>
                                        <input type="text" class="form-control" id="AddressTransporter" placeholder="" v-model="AddressTransporter">
                                        <label for="address" style="color: #454545; font-size: 14px;">Address</label>
                                        <textarea class="form-control" id="AddressDetails" rows="3" placeholder="" v-model="AddressDetails"></textarea>
                                        <a style="color: #454545; font-size: 14px;">
                                            Default Address&nbsp;&nbsp;<input type="checkbox" id="AddressDefault" name="AddressDefault" v-model="AddressDefault">
                                        </a>
                                        <br>
                                        <button class="btn btn-primary" style="width: 294px; font-family: Inter; font-size: 14px;background:#00AEEF;color:#fff;outline:none;border:none">Save Changes</button>
                                        <br>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import helper from '../helper'
    import axios from "axios";
    import Swal from "sweetalert2";
    export default {
        name: 'addAddress',
        data() {
            return {
                AddressCustCode: "",
                AddressDetails: "",
                AddressCity: "",
                AddressState: "",
                AddressGstNo: "",
                AddressLeadTime: "",
                AddressTransporter: "",
                AddressDefault: "",
                AddressType: "B"
            }
        },
        created: function () {
        },
        methods: {
            navigateToHomeUser(event) {
                event.preventDefault();
                this.$router.push('/homeUser');
            },
            navigateToKyc(event) {
                event.preventDefault();
                this.$router.push('/kycDetails');
            },
            async formSubmit() {
                try {
                    this.Errors = ""
                    var l = localStorage.getItem("userdata")
                    if (l != "") {
                        const userData = JSON.parse(l)
                        if (userData.CustomerEkartCode == null || userData.CustomerEkartCode == "") {
                            Swal.fire('Alert!', "Data not found in EKart Master", 'error');
                        } else { 
                            const requestBody = {
                                AddressCustCode: userData.CustomerEkartCode, AddressDetails: this.AddressDetails, AddressCity: this.AddressCity,
                                AddressState: this.AddressState, AddressGstNo: this.AddressGstNo, CustomerEmailId: this.CustomerEmailId,
                                CustomerPassword: this.CustomerPassword, CustomerGstNo: this.CustomerGstNo, AddressTransporter: AddressTransporter,
                                AddressDefault: this.AddressDefault, AddressType: this.AddressType
                            };
                            const response = await axios.post(helper.URL() + 'inserttmpcustomeraddress', requestBody);
                            const result = response.data;
                            if (result.Status == true) {
                                if (result.CustomerCode != "") {
                                    this.AddressCustCode = ""
                                    this.AddressDetails = ""
                                    this.AddressCity = ""
                                    this.AddressState = ""
                                    this.AddressGstNo = ""
                                    this.AddressTransporter = ""
                                    this.AddressDefault = ""
                                    this.AddressType = ""
                                    Swal.fire('Info!', "Saved Successfully!!!", 'success');
                                } else {
                                    Swal.fire('Info!', "Submit Failed!!!", 'failed');
                                }
                            } else {
                                Swal.fire('Alert!', "Response not found!!!", 'error');
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
      @import url('https://fonts.googleapis.com/css?family=PT+Sans');

      * {
          padding-top: 10px;
      }

      .row font {
          background-color: #E5E5E5;
      }

      .flex-container {
          align-items: center;
          display: flex;
          height: auto;
          flex-direction: row;
          justify-content: center;
          flex-wrap: wrap;
          margin-right: .1rem;
          /* border:1px solid red; */
          width: 500px;
          margin-left: 18rem;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          border-radius: 18px;
      }

      .flex-item1,
      .flex-item2 {
          margin-left: .1rem;
          flex-grow: 1;
          width: 185px;
          /* margin-right: .1rem; */
          padding-left: 0rem;
          padding-right: 1rem;
          margin-left: 0rem;
      }

      .flex-item3 {
          padding-left: 1rem;
          margin-left: 1rem;
      }


      .items {
          display: flex;
          flex-direction: column;
      }

      .card .nameDetails {
          margin: 0;
          padding-top: 10%;
          font-size: 21px;
          height: 25px;
          align-items: center;
          font-weight: 400;
          line-height: 24.61px;
          font: 'Roboto';
      }

      .card {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          width: 375px;
          margin-top: 4rem;
          padding-top: 4rem;
          /* align-items: center; */
          border-radius: 20px;
          margin-bottom: 4rem;
          margin-left: 13rem;
      }

      img {
          position: absolute;
          width: 180px;
          height: 180px;
          left: 8px;
          top: 48px;
          border-radius: 50%;
      }

      .changeImage {
          color: #00AEEF;
          font-size: 18px;
          padding-top: 10px;
      }

      label {
          width: 294px;
      }



      h2 {
          position: absolute;
          width: 158px;
          height: 42px;
          left: 604px;
          top: 143px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 36px;
          line-height: 42px;
          text-align: center;
          color: #000000;
      }

      .profileName h3 {
          /* Sumitra Sinha */
          position: absolute;
          width: 130px;
          height: 25px;
          left: 221px;
          top: 116px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 21px;
          line-height: 25px;
          /* identical to box height */
          text-align: center;
          color: #000000;
      }

      .profileName a {
          color: #333;
          /* Change Profile Image */

          position: absolute;
          width: 153px;
          height: 19px;
          left: 221px;
          top: 209px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          /* identical to box height */
          text-align: center;
          color: #00AEEF;
      }

      a:hover {
          /* color: #da5767; */
          text-decoration: none;
      }

      .card {
          /* border: 0.40rem solid #f8f9fa; */
          /* top: 10%; */
          top: 1%
      }

      .form-control textarea {
          background: #fff;
          background-color: #f8f9fa;
          padding: 20px;
          padding: 25px 8px;
          margin-bottom: 1.3rem;
          border: 1px solid #c5c7d0;
          display: inline-block;
          vertical-align: top;
      }

          .form-control textarea:focus {
              outline: 0;
              box-shadow: none;
              color: #000000;
              background-color: #ffffff;
          }



      .form-control label {
          width: 38px;
          height: 16px;
          left: 399px;
          top: 488px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #323338;
      }


      input {
          margin-right: 2rem;
      }




      .addressDiv {
          width: 90%;
          height: 200px;
          border: 1px solid;
          border-radius: 2px;
          padding-left: 0px;
          border: 1px solid #c5c7d0;
          /* overflow:hidden; */
          /* scroll-behavior: auto; */
          overflow-y: auto;
      }

      .addressTitle1 {
          width: 90%;
          height: 200px;
          border: 1px solid;
          border-radius: 2px;
          padding-left: 0px;
          border: 1px solid #c5c7d0;
          /* overflow:hidden; */
          /* scroll-behavior: auto; */
      }

      .addressHeading1 {
          width: 100%;
          height: 30px;
          border-bottom: 1px solid #c5c7d0;
          color: #78787C;
          padding-top: 0px;
          padding-left: 10px;
      }


      .addressHeading {
          width: 100%;
          height: 30px;
          border-bottom: 1px solid #c5c7d0;
          color: #78787C;
          padding-top: 0px;
          padding-left: 10px;
      }


      .addressName {
          color: #323338;
          padding-bottom: 3px;
      }

      .addressParagraph {
          padding-top: 1px;
          margin-top: -11px;
          font-size: 13px;
          color: #323338;
          font-weight: 480;
          margin-bottom: 0px
      }




      .plusSign {
          /* + */

          width: 27px;
          height: 56px;
          left: 1151px;
          top: 892px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 900;
          font-size: 48px;
          line-height: 56px;
      }


          .plusSign a {
              /* + */

              color: #78787C;
          }

      .addAddress {
          width: 300px;
          height: 100px;
          left: 1095px;
          top: 951px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          align-items: center;
      }


          .addAddress a {
              color: #78787C;
          }




      .table-hover {
          /* Rectangle 1162 */

          box-sizing: border-box;
          left: 0%;
          right: 1%;
          top: 0%;
          bottom: 0%;
          background: #FFFFFF;
          border-radius: 13px;
          margin-left: 5rem;
          margin-top: 4rem;
          border-collapse: collapse;
          border-radius: 1rem !important;
          overflow: hidden;
          border: 1px solid #C5C7D0;
      }

          .table-hover tr,
          td,
          span,
          i {
              /* Line 113 */
              left: 0.35%;
              right: 5%;
              top: 33.74%;
              bottom: 66.26%;
              border-bottom: #333;
          }



      .table {
          /* table box */

          box-sizing: border-box;
          width: 256px;
          height: 167px;
          left: 397px;
          top: 856px;
          border-bottom: #C5C7D0;
          background: #FFFFFF;
          border: 1px solid #ced4da;
          border-radius: 4px;
          color: #ced4da;
          float: left;
          margin-left: 2rem;
      }

          .table tr th {
              /* Default */

              width: 39px;
              height: 14px;
              left: 410px;
              top: 862px;
              padding-bottom: 5px;
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              text-align: left;
              border: .5px solid #ced4da;
              /* identica to box height */
          }


          .table td {
              /* Amit Jaiswal Shyam jeneral Enterprises Old Post Office Road, Patna PATNA, BIHAR 848101 India Phone number:993967091 */
              width: 256px;
              left: 410px;
              top: 892px;
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              border-top: none;
              border-bottom: none;
              color: #323338;
          }

      .plusSign {
          /* + */

          width: 27px;
          height: 56px;
          left: 1151px;
          top: 892px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 900;
          font-size: 48px;
          line-height: 56px;
          text-align: center;
          margin-left: 6rem;
      }

          .plusSign a {
              /* + */
              color: #78787C;
          }


      .addAddress {
          /* Add Address */
          /* width: 139px;
    height: 28px;
    left: 1095px;
    top: 951px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    align-items: center; */
          /* Add Address */


          width: 139px;
          height: 28px;
          left: 1090px;
          top: 1266px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 28px;
          color: #78787C;
          text-align: center;
          /* margin-right:-4.5rem !important; */

          margin-left: 2.5rem !important
      }

          .addAddress a {
              /* Add Address */
              color: #78787C;
          }


      .tableBottom {
          /* Request to Edit | Remove */

          width: 151px;
          height: 14px;
          left: 410px;
          top: 1004px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          /* identical to box height */

          color: #00AEEF;
      }

      .form-control input {
          /* Rectangle 171 */

          box-sizing: border-box;
          position: absolute;
          justify-content: space-between;
          width: 200px;
          height: 48px;
          left: 397px;
          top: 506px;
          margin-left: 4rem;
          background: #FFFFFF;
          opacity: 0.4;
          border: 1px solid #707070;
          border-radius: 4px;
      }

      .form-control:focus {
          color: #000000;
          background-color: #ffffff;
          outline: 0;
          box-shadow: none;
      }

      .btn {
          padding: 0.6rem 1.2rem;
          background-color: hsl(209, 9%, 46%);
          width: 400px;
      }

      .btn-primary:hover {
          border-color: #6c757d;
          background-color: rgb(120, 120, 124);
          transition: .3s;
          width: 400px;
      }

    .navigationTop a {
        text-decoration: none;
        color: #454545;
        font-family: Inter;
        font-size: 12px
    }

        .navigationTop a:hover {
            color: #2ca9df;
            font-family: Inter;
            font-size: 12px
        }

    .navigationTop span {
        margin: 0 5px;
        color: #454545;
        font-family: Inter;
        font-size: 12px
    }

</style>
