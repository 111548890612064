<template>
    <div>
        <div class="container">

            <div class="navigationTop" style="margin-top: 115px; padding: 20px; padding: 5px; margin-left: 40px;">
                <a href="#" @click="navigateToHomeUser">Home</a>
                <span>&gt;</span>
                <a href="#">Dashboard</a>
            </div>
        </div>
      
        <!-- First row -->
        <div class="container" style="width: 1053px; margin-left: 143px; display: flex; justify-content: center; margin: 0 auto; margin-top: -111px;">
            <div style="margin-top: 20px; border: 1px solid #ccc; padding: 10px; margin-right:20px; width: 100%; margin-bottom: 40px; margin-top: 145px; border-radius: 10px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) ">
                <!--<h4 class="chart-heading" style="margin-left: -11px; margin-right: -11px;">Sandhi - Progress Tracker</h4>-->
                <h4 class="chart-heading" style="margin-left: -11px; margin-right: -11px;">Target-Achievement Analysis</h4>
                <div class="custom-title">
                    <p v-html="bottomTitle1"></p><br />
                    <p v-html="bottomTitle2"></p><br />
                    <p v-html="bottomTitle3"></p><br />
                    <p v-html="bottomTitle4"></p><br />
                </div>
                <!--<table class="peracheive">
                    <thead>
                        <tr>
                            <th>
                                Percentage of Achievement: <span style="color: #2CA9DF; background:#F3F3F3">{{ sandhiAchievementPercentage }} %</span>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                Incentives: <span style="color: #2CA9DF; background: #F3F3F3;">{{ customerSandhiIncentives }}</span>
                            </th>
                        </tr>
                    </thead>
                </table>-->
                <canvas id="progressTracker" style="width:100%;height:200px"></canvas>
            </div>
            <!-- Chart 2 -->
            <div class="secondchartdesign">
                <h4 class="chart-heading">Total Purchase</h4>
                <canvas id="myChart2" style="width: 100%;height:100%;margin-top:8px" ></canvas>
            </div>
            <!-- Chart 3 -->
            <div class="thirdchartdesign">
                <h4 class="chart-heading">Pending PO</h4>
                <canvas id="myChart3" style="width: 100%;"></canvas>
            </div>
            <!-- Chart 4 -->
            <div class="fourthchartdesign">
                <h4 class="chart-heading">Payment Outstanding</h4>
                <canvas id="myChart4" style="width: 100%;"></canvas>
            </div>
        </div>
        <!-- Second row -->
        <div class="container" style="width: 1026px; display: flex; justify-content: center;margin-top:-140px;">
            <!-- Chart 5 -->
            <div class="chart-container" style="box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); border-radius: 10px; height: 310px; margin-left: 45px;">
                <h4 class="chart-heading" style="margin-left: -21px; margin-right: -21px; padding: 12px; margin-top: -20px; }">Purchase History</h4>
                <canvas id="myChart5"></canvas>
            </div>
            <!-- Chart 6 -->
            <div class="chart-container" style="box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); border-radius: 10px; height: 310px">
                <div>
                    <div style="position:relative;">
                        <h4 class="chart-heading" style="margin-left: -21px; margin-right: -21px; text-align:left">Product Wise Purchase[Top 5 selling]</h4>
                        <select class="selectChartType" ref="selectElement" v-model="selectedOption" @change="loadProductWisePurchaseChart(selectedOption)">
                            <option v-for="(p, index) in dropDown" :value="p" :key="index" style="cursor: pointer; border: none; background: transparent; color: #2CA9DF; text-align: right; ">{{ p.Text }}</option>
                        </select>
                    </div>
                    <canvas id="myChart6"></canvas>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Chart from 'chart.js/auto';
    import 'chartjs-plugin-datalabels';
    import helper from "../helper";
    import axios from "axios";

    export default {
        data() {
            return {
                progressTrackerInstance: null,
                chartInstance: null,
                pieChartInstance: null,
                pieChartTwoInstance: null,
                chartInstanceTwo: null,
                chartInstanceThree: null,
                barChart: [],
                chart: [],
                pieChartPendingPo: [],
                pieChartPendingPayment: [],
                barHistoryChart: [],
                barProductChart: [],
                Products: "",
                targetLabels: "",
                labels: "",
                bottomTitle1: "",
                bottomTitle2: "",
                bottomTitle3: "",
                bottomTitle4: "",
                chartType: "1",
                chartTypeText: "Value",
                selectedIndex: -1,
                selectedOption: { Type: "1", Text: "Value" },
                dropDown: [
                    { Type: "1", Text: "Value" },
                    { Type: "2", Text: "Quantity(In Boxes)" }
                ],
                showDropdown: false,
                customerSandhiTarget: 0,
                sandhiAchievement: 0,
                sandhiAchievementPercentage: 0,
                customerSandhiIncentives: 0,
                totalPurchase: 0
            };
        },
        mounted() {
            this.loadUserData();
            this.createBarChart();
            //this.createProgressTrackerSandhi();
            this.createDistributorTarget();
            this.createPoPieChart();
            this.createPaymentPieChart();
            this.createPurchaseHistoryChart();
            this.createProductWisePurchaseChart();
        },
        methods: {
            navigateToHomeUser(event) {
                event.preventDefault();
                this.$router.push('/homeUser');
            },
            loadUserData() {
                var l = localStorage.getItem("userdata")
                if (l != "") {
                    var userData = JSON.parse(l);
                    this.customerSandhiTarget = userData.CustomerSandhiTarget;
                    this.customerSandhiIncentives = userData.CustomerSandhiIncentives
                }
            },
            async getTotalPurchaseChart() {
                try {
                    this.sandhiAchievement = 0;
                    this.totalPurchase = 0;
                    var l = localStorage.getItem("userdata");
                    if (l != null && l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { customercode: userData.CustomerCode };
                        const response = await axios.post(helper.URL() + 'selecttotalpurchasechart', requestBody);
                        const result = response.data;
                        self.barChart = [];
                        var inchartdatasets = [];
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                var stackBarDatas = result.Data[0];
                                //console.log(JSON.stringify(stackBarDatas));
                                for (var i = 0; i < stackBarDatas.length; i++) {
                                    if (i == 3) {
                                        self.sandhiAchievement = stackBarDatas[i].Total;
                                        self.totalPurchase = stackBarDatas[i].Total;
                                    }
                                    var data = {
                                        label: stackBarDatas[i].label,
                                        backgroundColor: stackBarDatas[i].backgroundColor,
                                        data: JSON.parse(stackBarDatas[i].data)
                                    };
                                    inchartdatasets.push(data);
                                }
                                self.barChart = inchartdatasets;
                            }
                            else {
                                Swal.fire('Alert!', "Response not found!!!", 'error');
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            async getPendingPoChart() {
                try {
                    var l = localStorage.getItem("userdata");
                    if (l != null && l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { customercode: userData.CustomerCode };
                        const response = await axios.post(helper.URL() + 'selectpendingpochart', requestBody);
                        const result = response.data;
                        self.pieChartPendingPo = [];
                        var inchartdatasets = [];
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                var stackPieDatas = result.Data[0];
                                for (var i = 0; i < stackPieDatas.length; i++) {
                                    var totalOrderVals = stackPieDatas[0].TotalOrderVal.split(', ').map(Number);
                                    //console.log(totalOrderVals)
                                    var data = {
                                        data: totalOrderVals,
                                        backgroundColor: ['#737373', '#F49136', '#A8DDF4', '#AFAFAF'],
                                        hoverBackgroundColor: ['#8C8C8C', '#FFA04D', '#87C5F9', '#B0B0B0']
                                        //backgroundColor: ['#A8DDF4', '#FAB1B1', '#737373', '#6CCC3F'],
                                        //hoverBackgroundColor: ['#87C5F9', '#FFB98A', '#8C8C8C', '#96DB78']
                                    };
                                    inchartdatasets.push(data);
                                }
                                self.pieChartPendingPo = inchartdatasets;
                            }
                            else {
                                Swal.fire('Alert!', "Response not found!!!", 'error');
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            async getPendingPaymentChart() {
                try {
                    var l = localStorage.getItem("userdata");
                    if (l != null && l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { customercode: userData.CustomerCode };
                        const response = await axios.post(helper.URL() + 'selectpendingpaymentchart', requestBody);
                        const result = response.data;
                        self.pieChartPendingPayment = [];
                        var inchartdatasets = [];
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                var stackPieDatas = result.Data[0];
                                //console.log(result.Data[0])
                                for (var i = 0; i < stackPieDatas.length; i++) {
                                    var totalPendingPayment = stackPieDatas[0].TotalPendingPayment.split(', ').map(Number);
                                    var data = {
                                        data: totalPendingPayment,
                                        backgroundColor: ['#737373', '#F49136', '#A8DDF4', '#AFAFAF'],
                                        hoverBackgroundColor: ['#8C8C8C', '#FFA04D', '#87C5F9', '#B0B0B0']
                                    };
                                    inchartdatasets.push(data);
                                }
                                self.pieChartPendingPayment = inchartdatasets;
                            }
                            else {
                                Swal.fire('Alert!', "Response not found!!!", 'error');
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            async getPurchaseHistoryChart() {
                try {
                    var l = localStorage.getItem("userdata");
                    if (l != null && l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { customercode: userData.CustomerCode };
                        const response = await axios.post(helper.URL() + 'selectpurchasehistorychart', requestBody);
                        const result = response.data;
                        self.barHistoryChart = [];
                        var inchartdatasets = [];
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                var stackPieDatas = result.Data[0];
                                for (var i = 0; i < stackPieDatas.length; i++) {
                                    var totalHistory = stackPieDatas[0].ChartData.split(', ').map(Number);
                                    var data = {
                                        data: totalHistory,
                                        backgroundColor: '#6CCC3F',
                                        hoverBackgroundColor: '#6CCC3F',
                                        barThickness: 20
                                    };
                                    inchartdatasets.push(data);
                                }
                                self.barHistoryChart = inchartdatasets;
                            }
                            else {
                                Swal.fire('Alert!', "Response not found!!!", 'error');
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            async getProductWisePurchaseChart() {
                try {
                    var l = localStorage.getItem("userdata");
                    if (l != null && l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { customercode: userData.CustomerCode, charttype: self.chartType };
                        const response = await axios.post(helper.URL() + 'selectproductwisepurchasechart', requestBody);
                        const result = response.data;
                        self.barProductChart = [];
                        var inchartdatasets = [];
                        const colors = ["6CCC3F", "2CA9DF", "F49136"];
                        const randColor = (index) => {
                            return "#" + colors[index];
                        }
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                var stackBarDatas = result.Data[0];
                                const prdArray = stackBarDatas[0].products.split(',');
                                this.Products = prdArray;
                                for (var i = 0; i < stackBarDatas.length; i++) {
                                    const arr = stackBarDatas[i].data.split(',');
                                    const datas = '[' + parseInt(arr[0]) + ', ' + parseInt(arr[1]) + ', ' + parseInt(arr[2]) + ', ' + parseInt(arr[3]) + ', ' + parseInt(arr[4]) + ']';
                                    var data = {
                                        label: stackBarDatas[i].label,
                                        data: JSON.parse(datas),
                                        backgroundColor: randColor(i),
                                        bordercolor: randColor(i),
                                        fill: false
                                    };
                                    inchartdatasets.push(data);
                                }
                                self.barProductChart = inchartdatasets;
                            }
                            else {
                                Swal.fire('Alert!', "Response not found!!!", 'error');
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            async createProgressTrackerSandhi() {
                if (this.progressTrackerInstance) {
                    this.progressTrackerInstance.destroy();
                }
                await this.getTotalPurchaseChart();
                if (this.customerSandhiTarget > 0) {
                    this.sandhiAchievementPercentage = ((this.sandhiAchievement / this.customerSandhiTarget) * 100).toFixed(2);
                } else {
                    this.sandhiAchievementPercentage = 0;
                }
                const ctx = document.getElementById('progressTracker').getContext('2d');
                this.progressTrackerInstance = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: ['Sales Goal', 'Acheivements'],
                        datasets: [{
                            data: [this.customerSandhiTarget, this.sandhiAchievement], 
                            backgroundColor: ['#FF5733', '#3380FF'], 
                            borderWidth: 1
                        }]
                    },
                    options: {
                        responsive: true,
                        scales: {
                            x: {
                                display: true,
                                grid: {
                                    display: false
                                },
                                ticks: {
                                    font: {
                                        size: 10 // Adjust the font size for x-axis labels
                                    }
                                }
                            },
                            y: {
                                beginAtZero: true,
                                ticks: {
                                    stepSize: 1000000,
                                    callback: function (value) {
                                        var ranges = [
                                            { divider: 1e5, suffix: 'L' },
                                            { divider: 1e3, suffix: 'k' }
                                        ];
                                        function formatNumber(n) {
                                            for (var i = 0; i < ranges.length; i++) {
                                                if (n >= ranges[i].divider) {
                                                    return (n / ranges[i].divider).toString() + ranges[i].suffix;
                                                }
                                            }
                                            return n;
                                        }
                                        return formatNumber(value);
                                    }
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: false
                            }
                        }
                    }
                });
            },
            async getDistributorTarget() {
                try {
                    this.sandhiAchievement = 0;
                    this.totalPurchase = 0;
                    var l = localStorage.getItem("userdata");
                    if (l != null && l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { customercode: userData.CustomerCode };
                        const response = await axios.post(helper.URL() + 'selectdistributortargetachievechart', requestBody);
                        const result = response.data;
                        self.barChart = [];
                        var inchartdatasets = [];

                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                var stackBarDatas = result.Data[0];
                                for (var i = 0; i < stackBarDatas.length; i++) {
                                    const trgArray = stackBarDatas[0].label.split(',');
                                    this.targetLabels = trgArray;
                                    const arr = stackBarDatas[i].data.split(',');
                                    const datas = '[' + parseInt(arr[0]) + ', ' + parseInt(arr[1]) + ', ' + parseInt(arr[2]) + ', ' + parseInt(arr[3]) + ']';
                                    var data = {
                                        data: JSON.parse(datas),
                                        backgroundColor: ["#6CCC3F", "#2CA9DF", "#F49136", "#D9008D"],
                                        fill: true
                                    };
                                    inchartdatasets.push(data);
                                }
                                self.barChart = inchartdatasets;
                            }
                            else {
                                Swal.fire('Alert!', "Response not found!!!", 'error');
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            async createDistributorTarget() {
                if (this.progressTrackerInstance) {
                    this.progressTrackerInstance.destroy();
                }
                await this.getDistributorTarget();

                const ctx = document.getElementById('progressTracker').getContext('2d');
                var datasets = [];
                datasets = this.barChart;
                this.bottomTitle1 = this.formatTitle(this.targetLabels[0], this.barChart[0].data[0]);
                this.bottomTitle2 = this.formatTitle(this.targetLabels[1], this.barChart[0].data[1]);
                this.bottomTitle3 = this.formatTitle(this.targetLabels[2], this.barChart[0].data[2]);
                this.bottomTitle4 = this.formatTitle(this.targetLabels[3], this.barChart[0].data[3]);
                this.progressTrackerInstance = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: ['', '', '', ''],
                        datasets: datasets
                    },
                    options: {
                        responsive: true,
                        scales: {
                            x: {
                                display: true,
                                grid: {
                                    display: false
                                },
                                ticks: {
                                    font: {
                                        size: 10,
                                        style: 'normal',
                                        weight: 'bold'
                                    }
                                }
                            },
                            y: {
                                beginAtZero: true,
                                ticks: {
                                    stepSize: 100000,
                                    callback: function (value) {
                                        var ranges = [
                                            { divider: 1e5, suffix: 'L' },
                                            { divider: 1e3, suffix: 'k' }
                                        ];
                                        function formatNumber(n) {
                                            for (var i = 0; i < ranges.length; i++) {
                                                if (n >= ranges[i].divider) {
                                                    return (n / ranges[i].divider).toString() + ranges[i].suffix;
                                                }
                                            }
                                            return n;
                                        }
                                        return formatNumber(value);
                                    }
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: false
                            }
                        }
                    }
                });
            },
            async createBarChart() {
                if (this.chartInstance) {
                    this.chartInstance.destroy();
                }
                await this.getTotalPurchaseChart();

                const ctx = document.getElementById('myChart2').getContext('2d');
                var datasets = [];
                datasets = this.barChart;
                const titleAmount = `Total : \u20B9${this.totalPurchase}`;
                this.chartInstance = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: ['', '', '', ''],
                        datasets: datasets
                    },
                    options: {
                        responsive: true,
                        scales: {
                            x: {
                                display: true,
                                grid: {
                                    display: false
                                },
                                ticks: {
                                    font: {
                                        size: 10,
                                        style: 'normal',
                                        weight: 'bold'
                                    }
                                }
                            },
                            y: {
                                beginAtZero: true,
                                ticks: {
                                    stepSize: 100000,
                                    callback: function (value) {
                                        var ranges = [
                                            { divider: 1e5, suffix: 'L' },
                                            { divider: 1e3, suffix: 'k' }
                                        ];
                                        function formatNumber(n) {
                                            for (var i = 0; i < ranges.length; i++) {
                                                if (n >= ranges[i].divider) {
                                                    return (n / ranges[i].divider).toString() + ranges[i].suffix;
                                                }
                                            }
                                            return n;
                                        }
                                        return formatNumber(value);
                                    }
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: true,
                                position: 'bottom',
                                align: 'center',
                                labels: {
                                    font: {
                                        size: 9
                                    },
                                    boxWidth: 16
                                }
                            },
                            title: {
                                display: true,
                                text: `${titleAmount}`,
                                font: {
                                    size: 18,
                                    family: 'Inter',
                                    style: 'normal',
                                    weight: 'bold'
                                },
                                padding: {
                                    top: 2,
                                    bottom: 5
                                },
                                color: '#454545'
                            }
                        }
                    }
                });
            },
            async createPoPieChart() {
                if (this.pieChartInstance) {
                    this.pieChartInstance.destroy();
                }
                const ctx = document.getElementById('myChart3');
                await this.getPendingPoChart();
                var datasets = [];
                datasets = this.pieChartPendingPo;
                //console.log(datasets)
                const totalSum = datasets.reduce((total, dataset) => total + dataset.data.reduce((sum, value) => sum + value, 0), 0);
                const titleAmount = `Total : \u20B9${totalSum}`;
                this.pieChartInstance = new Chart(ctx, {
                    type: 'pie',
                    data: {
                        labels: ['In Progress', '1-5 Days Delay', '6-10 Days Delay', '> 10 days delay'],
                        datasets: datasets
                    },
                    options: {
                        responsive: true,
                        plugins: {
                            datalabels: {
                                display: true,
                                color: 'white'
                            },
                            legend: {
                                display: true,
                                position: 'bottom',
                                align: 'center',
                                labels: {
                                    font: {
                                        size: 10
                                    },
                                    boxWidth: 16
                                }
                            },
                            title: {
                                display: true,
                                text: `${titleAmount}`,
                                font: {
                                    size: 18,
                                    family: 'Inter',
                                    style: 'normal',
                                    weight: 'bold'
                                },
                                color: '#454545',
                                padding: {
                                    top: 2,
                                    bottom: 5
                                }
                            }
                        }
                    }
                });
                const chartLegendOverrides = {
                    display: true
                };
                this.pieChartInstance.options.plugins.legend = chartLegendOverrides;
            },
            async createPaymentPieChart() {
                if (this.pieChartTwoInstance) {
                    this.pieChartTwoInstance.destroy();
                }
                const ctx = document.getElementById('myChart4');
                await this.getPendingPaymentChart();
                var datasets = [];
                datasets = this.pieChartPendingPayment;
                const totalSum = datasets.reduce((total, dataset) => total + dataset.data.reduce((sum, value) => sum + value, 0), 0);
                const titleAmount = `Total : \u20B9${totalSum}`;
                this.pieChartTwoInstance = new Chart(ctx, {
                    type: 'pie',
                    data: {
                        labels: ['In Credit Period', '1-5 days Delay', '6-10 Days Delay', '> 10 days delay'],
                        datasets: datasets
                    },
                    options: {
                        responsive: true,
                        plugins: {
                            datalabels: {
                                display: true,
                                color: 'white'
                            },
                            legend: {
                                display: true,
                                position: 'bottom',
                                align: 'center',
                                labels: {
                                    font: {
                                        size: 10
                                    },
                                    boxWidth: 16
                                }
                            },
                            title: {
                                display: true,
                                text: `${titleAmount}`,
                                font: {
                                    size: 18,
                                    family: 'Inter', // You can change the font family here
                                    style: 'normal', // You can change the font style here
                                    weight: 'bold' // You can change the font weight here
                                },
                                color: '#454545',
                                padding: {
                                    top: 2,
                                    bottom: 5
                                }
                            }
                        }
                    }
                });
                const chartLegendOverrides = {
                    display: true
                };
                this.pieChartTwoInstance.options.plugins.legend = chartLegendOverrides;
            },
            async createPurchaseHistoryChart() {
                if (this.chartInstanceTwo) {
                    this.chartInstanceTwo.destroy();
                }
                const ctx = document.getElementById('myChart5');
                await this.getPurchaseHistoryChart();
                var datasets = [];
                datasets = this.barHistoryChart;
                this.chartInstanceTwo = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'],
                        datasets: datasets
                    },
                    options: {
                        responsive: true,
                        lineTension: 1,
                        legend: {
                            display: false
                        },
                        scales: {
                            x: {
                                display: true,
                                grid: {
                                    display: false
                                }
                            },
                            y: {
                                display: true,
                                beginAtZero: true,
                                grid: {
                                    display: true
                                },
                                ticks: {
                                    stepSize: 100000,
                                    callback: function (value) {
                                        var ranges = [
                                            { divider: 1e5, suffix: 'L' },
                                            { divider: 1e3, suffix: 'k' }
                                        ];
                                        function formatNumber(n) {
                                            for (var i = 0; i < ranges.length; i++) {
                                                if (n >= ranges[i].divider) {
                                                    return (n / ranges[i].divider).toString() + ranges[i].suffix;
                                                }
                                            }
                                            return n;
                                        }
                                        return formatNumber(value);
                                    }
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: false,
                                position: 'bottom',
                                align: 'center',
                                labels: {
                                    font: {
                                        size: 12
                                    },
                                    boxWidth: 14
                                }
                            }
                        }
                    }
                });
            },
            async createProductWisePurchaseChart() {
                if (this.chartInstanceThree) {
                    this.chartInstanceThree.destroy();
                }
                this.showDropdown = false;
                const ctx = document.getElementById('myChart6');
                await this.getProductWisePurchaseChart();
                var datasets = [];
                datasets = this.barProductChart;
                this.chartInstanceThree = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: this.Products,
                        datasets: datasets
                    },
                    options: {
                        responsive: true,
                        scales: {
                            x: {
                                display: true,
                                grid: {
                                    display: false
                                }
                            },
                            y: {
                                display: true,
                                beginAtZero: true,
                                grid: {
                                    display: true
                                },
                                ticks: {
                                    stepSize: this.chartType == 1 ? 10000 : 10,
                                    callback: function (value) {
                                        var ranges = [
                                            { divider: 1e5, suffix: 'L' },
                                            { divider: 1e3, suffix: 'k' }
                                        ];
                                        function formatNumber(n) {
                                            for (var i = 0; i < ranges.length; i++) {
                                                if (n >= ranges[i].divider) {
                                                    return (n / ranges[i].divider).toString() + ranges[i].suffix;
                                                }
                                            }
                                            return n;
                                        }
                                        return formatNumber(value);
                                    }
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: true,
                                position: 'bottom',
                                align: 'start',
                                labels: {
                                    font: {
                                        size: 12
                                    },
                                    boxWidth: 14
                                }
                            }
                        }
                    }
                });
            },
            async loadProductWisePurchaseChart(p) {
                await this.setData(p.Type, parseInt(p.Type) - 1);
                this.createProductWisePurchaseChart();
            },
            setData(type, typeindex) {
                localStorage.removeItem("charttype");
                localStorage.removeItem("typeindex");
                this.chartType = type;
                localStorage.setItem("charttype", this.chartType);
                localStorage.setItem("typeindex", typeindex);
            },
            formatTitle(label, value) {
                const paddedLabel = label.padEnd(20, ' ');
                return `${paddedLabel} ${value}`;
            }
        }
    };
</script>

<style scoped>
    * {
        background: #fff;
        padding-top: 5px;
    }
    .custom-title {
        font-size: 10px;
        font-weight: bold;
        color: green;
        position: absolute;
        background-color: transparent;
        top: 43rem;
        width: 17%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-left: 1.6rem;
    }
        .custom-title p:nth-of-type(1) {
            color: #6CCC3F;
        }
        .custom-title p:nth-of-type(2) {
            color: #2CA9DF;
        }
        .custom-title p:nth-of-type(3) {
            color: #F49136;
        }
        .custom-title p:nth-of-type(4) {
            color: #D9008D;
        }
    .selectChartType {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        font-family: Inter;
        color: #fff;
        cursor: pointer;
        z-index: 9999;
        position: absolute;
        top: -93%;
        left: 325px;
        border: none;
        background-color: transparent;
        outline: none;
        display: flex;
    }

    .secondchartdesign {
        margin-top: 125px;
        border: 1px solid #ccc;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-right: 20px;
        margin-bottom: 40px;
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .thirdchartdesign {
        margin-top: 125px;
        border: 1px solid #ccc;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-right: 20px;
        margin-bottom: 40px;
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }


    .fourthchartdesign {
        margin-top: 125px;
        border: 1px solid #ccc;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-right: 0px;
        margin-bottom: 40px;
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }


    .chart-heading {
        color: #fff;
        padding: 12px;
        text-align: center;
        background: #2CA9DF;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: -31px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        font-family: Inter
    }



    .chart-container {
        margin-top: 125px;
        border: 1px solid #ccc;
        padding: 20px;
        flex: 1 0 calc(50% - 20px);
        margin-bottom: 40px;
        margin-left: 10px;
        margin-left: 40px;
        margin-right: 41px;
    }

    .peracheive {
        width: 100%;
        background: #F3F3F3;
        margin-bottom: 10px
    }
 
        .peracheive th, td {
            background: #F3F3F3;
            color: #737373;
            text-align: center;
            padding: 10px;
            font-family: Inter
        }



        .peracheive td {
            background: #F49136;
            border: none
        }

    .peracheive {
        width: 100%;
        background: #F3F3F3;
        margin-bottom: 10px;
    }

        .peracheive th, .peracheive td {
            background: #F3F3F3;
            color: #737373;
            text-align: center;
            padding: 5px; /* Adjust the padding to reduce cell height */
            font-family: Inter;
            font-size: 12px; /* Adjust the font size */
        }

        .peracheive td {
            background: #F49136;
            border: none;
        }


    /* charts css ends here */
    @media (max-width: 880px) {
        .flex-container {
            flex-direction: column;
        }
    }

    .navigationTop {
        display: flex;
        align-items: center;
        padding: 10px;
        font-family: Inter;
        font-size: 12px
    }

        .navigationTop a {
            text-decoration: none;
            color: #454545;
            font-family: Inter;
            font-size: 12px
        }

            .navigationTop a:hover {
                color: #2ca9df;
                font-family: Inter;
                font-size: 12px
            }

        .navigationTop span {
            margin: 0 5px;
            color: #454545;
            font-family: Inter;
            font-size: 12px
        }







</style>