<template>
    <div>
        <div>
            <div class="container" style="margin-top: 100px;width: 1025px;">
                <div class="navigationTop" style="padding: 20px; padding-left: 5px; margin-right: 859px; padding-bottom: 0px; margin-left: -23px;margin-top:10px">
                    <a href="#" style="text-decoration: none;" @click="navigateToHomeUser">Home</a>
                    <span style="margin: 0 5px;">&gt;</span>
                    <a href="#" style="text-decoration: none;white-space:nowrap">Payment Details</a>
                </div>
                <div style="text-align:center;padding-top:20px;margin-bottom:20px">
                    <h1 style="font-size:21px;color:#454545;font-weight:400">Payment Details</h1>
                </div>
                <div class="row font custom-text" style="padding:15px 5px 10px;background:#fff;border:solid 1px #c5c7d0;margin-right:-14px">
                    <div class="col-md-3 pay-det">
                        Approved Credit: <strong>{{ CustomerCreditLimit }}</strong>
                    </div>
                    <div class="col-md-3 pay-det">
                        Used credit:  <strong class="text-style-1">{{ AgingBalance }}</strong>
                    </div>
                    <div class="col-md-3 pay-det">
                        Overdue Amount:  <span class="text-danger text-style-2">{{ CustomerDue }}</span>
                    </div>
                    <div class="col-md-3 pay-det" style="text-align:right">
                        Available Credit: <span v-if="CustomerDue<=0">{{ CustomerCreditLimit-AgingBalance }}</span>
                        <span class="text-style-1" v-if="CustomerDue>0">0</span>
                    </div>
                </div>
                <p> </p>
                <div class="row font">
                    <div class="col-md-6" style="padding-left:0px;padding-right:0px;">
                        <div data-v-4f3b9b6c="" class="card border-light mb-3" style="box-shadow: 0 4px 16px 0 rgb(0 0 0 / 15%);border: solid 0.5px #78787c">
                            <div data-v-4f3b9b6c="" class="card-header" style="background: #a8ddf4;">
                                Pending Payment<span style="float:right">
                                    <span class="font-wei-nor">Total Dues -</span> {{ AgingBalance }}
                                </span>
                            </div>
                            <div data-v-4f3b9b6c="" class="card-body" style="padding: 5px;">
                                <div style="overflow: auto; height: 340px;">
                                    <div :class="getBorder(l.AgingDueDays)" v-for="l in AgingList" :key="l.AgingDocumentNumber"
                                         style="margin: 0px 0px 5px 0px; padding: 8px 0; border-radius: 4px; display: flex; align-items: center; border: 1px solid ;margin-right:5px">
                                        <div class="col-md-1" style="width: 5%"><input type="checkbox" v-model="l.isSelected" :disabled="l.InvoiceStatus === 'OnHold'"></div>
                                        <div :class="getBorderSub(l.AgingDueDays)"
                                             style="display: flex; width: 11%; align-items: center; justify-content: center; border-radius: 5px; font-weight: 600;text-align:center">
                                            {{ l.AgingDueDays }}
                                        </div>
                                        <div class="col-md-5">
                                            <span class="amtinvoice">Invoice No - {{ l.AgingDocumentNumber }}</span><br />
                                            <span style="font-size: 13px; color: #78787c;">Order No. - {{ l.KartInvoiceNo }} ({{ l.KartTotalItems }} Items)</span><br />
                                            <span class="amt">&#x20b9; {{ l.AgingBalance }}</span>
                                        </div>
                                        <div class="col-md-5" style="color: #78787c; text-align: right;">
                                            Invoice Date : {{ formatDate(l.AgingPostingDate) }}
                                        </div>
                                    </div>
                                </div>

                                <div class="totalamtstyle">
                                    Total Amount -
                                    <span style="font-weight: bold;color: #454545;font-size:14px">&#x20b9; {{ getSelectedTotal() }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6" style="padding-right:0">
                        <div data-v-4f3b9b6c="" class="card border-light mb-3" style="box-shadow: 0 4px 16px 0 rgb(0 0 0 / 15%);border: solid 0.5px #78787c">
                            <div data-v-4f3b9b6c="" class="card-header" style="background: rgb(168, 221, 244);">Payment Via</div>
                            <div data-v-4f3b9b6c="" class="card-body" style="height:393px">
                                <div style="border-bottom:solid 1px #000;margin-bottom:10px">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" name="PatmentType" v-model="NEFT">
                                        <label class="form-check-label" for="inlineCheckbox1">NEFT / RTGS</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" name="PatmentType" v-model="Cheque">
                                        <label class="form-check-label" for="inlineCheckbox2">Cheque</label>
                                    </div>
                                </div>
                                <div class="row lable-txt">
                                    <p> </p>
                                    <div class="col mb-3 box1-input">
                                        <label for="exampleFormControlInput1" class="form-label" v-if="!Cheque">UTR No.</label>
                                        <label for="exampleFormControlInput1" class="form-label" v-if="Cheque">Cheque No.</label>
                                        <input type="text" class="form-control" id="exampleFormControlInput1" v-model="PaymentUtrNo" placeholder="3444 222 333">
                                    </div>
                                    <div class="col mb-3 box2-input">
                                        <label for="exampleFormControlInput1" class="form-label">Enter Amount</label>
                                        <input type="number" class="form-control" id="exampleFormControlInput1" v-model="PaymentAmount" placeholder="? 0000.00">
                                    </div>
                                </div>
                                <div class="row lable-txt">
                                    <div class="col-md-6 mb-3 box3-input">
                                        <label for="exampleFormControlTextarea1" class="form-label">Date</label>
                                        <input type="text" class="form-control" id="datepicker">
                                    </div>
                                    <div class="col-md-6 mb-3 box1-input" v-show="Cheque">
                                        <label for="exampleFormControlTextarea1" class="form-label">Bank Name</label>
                                        <input type="text" class="form-control" v-model="PaymentBankName" id="BankName">
                                    </div>
                                </div>
                                <div class="mb-3 lable-txt">
                                    <label for="exampleFormControlTextarea1" class="form-label">Bank invoice Upload</label> <br />
                                    <vue-base64-file-upload style="width: 25%;padding:px;text-align:center"
                                                            class="v1"
                                                            accept="image/png,image/jpeg,application/pdf"
                                                            image-class="v1-image"
                                                            :max-size="customImageMaxSize"
                                                            @size-exceeded="onSizeExceeded"
                                                            :disable-preview="true"
                                                            :placeholder="'Attach File'"
                                                            @file="onFile"
                                                            @load="onLoad" />
                                </div>
                                <button class="btn btn-info btn-lg btn-w submit-btn1" @click="Submit">{{ loading }}</button>
                                <p> </p>
                                <p class="text-danger">* {{ Errors }} </p><br />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row font" style="background: white; padding: 0; box-shadow: 0px 2px 8px 0px #00000026;">
                    <div class="col-md-12" style="margin:15px 0">
                        <h6 style="font-size:21px;font-family:Inter;color:#454545">Your Last Payment details</h6>
                        <div style="overflow: auto;height:300px;margin:0;width:100%">
                            <table class="table table1" style="background:#fff">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Mode</th>
                                        <th>Amount</th>
                                        <th>Bank Name</th>
                                        <th>Reference No</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="d in CollectionList" :key="d.PaymentUtrNo">
                                        <td>{{ formatDateTS(d.CollectionInvoiceDate) }}</td>
                                        <td>{{ d.CollectionModeofPayment }}</td>
                                        <td>{{ d.CollectionTotal }}</td>
                                        <td>{{ d.PaymentBankName }}</td>
                                        <td>{{ d.PaymentUtrNo }}</td>
                                        <td :class="getPayInfoClass(d.CollectionStatus)">{{ d.CollectionStatus }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row" style="background: white; padding: 0; box-shadow: 0px 2px 8px 0px #00000026;margin-top:10px">
                    <div class="col-md-12" style="margin:15px 0">
                        <h6 style="font-size:21px;font-family:Inter;color:#454545">Your Order Details</h6>
                        <div style="overflow: auto;height:300px">
                            <table class="table table1">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Order ID</th>
                                        <th>Invoice No.</th>
                                        <th>No. of Boxes</th>
                                        <th>Order Value</th>
                                        <th>Order Status</th>
                                        <th>Download Invoice</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="o in orderDetails" :key="o.OrderDetItemCode">
                                        <td>{{ formatDateTS(o.KartDate) }}</td>
                                        <td>{{ o.OrderDocNo }}</td>
                                        <td>{{ o.KartInvoiceNo }}</td>
                                        <td>{{ o.BoxQty }}</td>
                                        <td>{{ o.KartNetAmount }}</td>
                                        <td>{{ o.OrderStatus }}</td>
                                        <td class="text-info">Download </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
        <div v-if="errorpopup">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <strong> {{ alertTitle }}</strong>
                                </div>
                                <div class="modal-body" align="center">
                                    {{ alertmsg }}
                                </div>
                                <div class="modal-footer">
                                    <button class=" btn btn-info" @click="errorpopup=false">
                                        OK
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import VueBase64FileUpload from 'vue-base64-file-upload';
    import helper from '../helper'
    import axios from "axios";
    export default {
        name: 'paymentDetails',
        components: {
            VueBase64FileUpload
        },
        data() {
            return {
                title: 'Payment Details',
                errorpopup: false,
                alertTitle: "",
                alertmsg: "",
                loading: "Update Payment Details",
                AgingList: [],
                CollectionList: [],
                orderDetails: [],
                CustomerCreditLimit: 0,
                AgingBalance: 0,
                CustomerDue: 0,
                NEFT: false,
                Cheque: true,
                PaymentType: "",
                PaymentUtrNo: "",
                PaymentAmount: 0,
                AdvanceAmount: 0,
                PaymentBankName: "",
                PaymentDate: this.formatDatePD(new Date()),
                PaymentInvoiceNo: "",
                Errors: "",
                customImageMaxSize: 10,
                PaymentDocument: "",
                PaymentDocumentExtension: ""
            }
        },
        watch: {
            Cheque: {
                handler(newValue, oldValue) {
                    if (newValue) {
                        this.NEFT = false
                    } else {
                        this.NEFT = true
                    }
                },
                deep: true
            },
            PaymentAmount: {
                handler(newValue, oldValue) {
                    if (newValue != oldValue) {
                        this.updateSelectedInvoice();
                    }
                },
                deep: true
            },
            NEFT: {
                handler(newValue, oldValue) {
                    if (newValue) {
                        this.Cheque = false
                    } else {
                        this.Cheque = true
                    }
                },
                deep: true
            }
        },
        created: function () {
            this.getAgingDetails();
            this.getCollectionDetails();
            this.getOrderDetails();
            $(function () {
                $("#datepicker").datepicker({ dateFormat: 'dd/mm/yy', maxDate: new Date() });
                $('#datepicker').datepicker('setDate', 'today');
            });
        },
        methods: {
            navigateToHomeUser(event) {
                event.preventDefault();
                this.$router.push('/homeUser');
            },
            getPayInfoClass(s) {
                if (s == "Verified") {
                    return "text-success"
                } else {
                    return ""
                }
            },
            onFile(file) {
                if (file.type == "application/pdf")
                    this.PaymentDocumentExtension = ".pdf"
                if (file.type == "image/png")
                    this.PaymentDocumentExtension = ".png"
                if (file.type == "image/jpeg")
                    this.PaymentDocumentExtension = ".jpg"
            },
            onLoad(dataUri) {
                this.PaymentDocument = dataUri
            },
            onSizeExceeded(size) {
                alert(`File ${size}Mb size exceeds limits of ${this.customImageMaxSize}Mb!`);
            },
            updateSelectedInvoice() {
                var amt = this.PaymentAmount
                var self = this
                self.AdvanceAmount = 0
                this.AgingList.forEach((element, index) => { self.AgingList[index].isSelected = false });
                this.AgingList.forEach((element, index) => {
                    if (amt > 0) {
                        self.AgingList[index].isSelected = true
                        if (amt >= element.AgingBalance) {
                            self.AgingList[index].Allocatedamt = element.AgingBalance
                        } else if (amt < element.AgingBalance) {
                            self.AgingList[index].Allocatedamt = amt
                        }
                        amt = amt - element.AgingBalance
                    }
                });
                if (amt > 0) {
                    self.AdvanceAmount = amt
                }
            },
            async Submit() {
                this.Errors = ""
                if (this.NEFT) {
                    this.PaymentType = "NEFT/RTGS"
                } else {
                    this.PaymentType = "Cheque"
                }
                var self = this
                var Invlist = []
                this.AgingList.forEach(element => {
                    if (element.isSelected) {
                        Invlist.push({ InvoiceNo: element.AgingDocumentNumber, Amt: element.Allocatedamt })
                    }
                });
                if (self.AdvanceAmount > 0) {
                    Invlist.push({ InvoiceNo: "Advance", Amt: self.AdvanceAmount })
                }
                self.PaymentInvoiceNo = Invlist
                if (this.PaymentUtrNo == "") {
                    this.Errors = "Payment UTR/Cheque No is required";
                    return false;
                } else if (this.PaymentAmount == 0) {
                    this.Errors = "Payment Amount is required";
                    return false;
                } else if (this.PaymentInvoiceNo.length == 0) {
                    this.Errors = "Select Any Invoice .";
                    return false;
                }
                var l = localStorage.getItem("userdata")
                var udata = ""
                if (l != "") {
                    var userData = JSON.parse(l);
                    var _PaymentDate = this.formatDateSD($("#datepicker").val())
                    this.loading = "Loading...."
                    const formattedInvoiceNos = this.PaymentInvoiceNo.map(item => `${item.InvoiceNo}-${item.Amt}`).join(', ');
                    const requestBody = {
                        Custname: userData.CustomerName, PaymentType: this.PaymentType, PaymentBankName: this.PaymentBankName,
                        PaymentUtrNo: this.PaymentUtrNo, PaymentAmount: this.PaymentAmount, PaymentDate: _PaymentDate,
                        PaymentInvoiceNo: formattedInvoiceNos, PaymentDocumentExtension: this.PaymentDocumentExtension,
                        PaymentDocument: this.PaymentDocument
                    }
                    const response = await axios.post(helper.URL() + 'insertpaymentdetails', requestBody);
                    const result = response.data;
                    if (result.Status == true) {
                        self.fire('Info!', "Saved Successfully!!!", 'success');
                        self.PaymentUtrNo = ""
                        self.PaymentAmount = 0
                        self.PaymentInvoiceNo = ""
                        self.PaymentDate = ""
                        self.PaymentBankName = ""
                        this.loading = "Update Payment Details"
                        this.getAgingDetails();
                    } else {
                        self.fire('Info!', "Saved Failed!!!", 'error');
                        self.PaymentUtrNo = ""
                        self.PaymentAmount = 0
                        self.PaymentInvoiceNo = ""
                        self.PaymentDate = ""
                        self.PaymentBankName = ""
                        this.loading = "Update Payment Details"
                    }
                }
            },
            fire(title, msg, type) {
                this.alertTitle = title
                this.alertmsg = msg
                this.errorpopup = true
            },
            days() {
                return 'days';
            },
            getBorderSub(ds) {
                var n = ds.replace(' Days', '');
                var d = parseInt(n)
                if (d > 15) {
                    return "col-md-1 border border-danger  text-danger";
                } else if (d > 6 && d < 16) {
                    return "col-md-1 border border-warning text-warning";
                } else if (d >= 0 && d < 7) {
                    return "col-md-1 border border-success text-success";
                } else {
                    return "col-md-1 border border-success text-success";
                }
            },
            getBorder(ds) {
                var n = ds.replace(' Days', '');
                var d = parseInt(n)
                if (d > 15) {
                    return "row align-items-center border-danger";
                } else if (d > 6 && d < 16) {
                    return "row align-items-center border-warning";
                } else if (d >= 0 && d < 7) {
                    return "row align-items-center border-success";
                } else {
                    return "row align-items-center border-success";
                }
            },
            formatDateTS(d) {
                var nd = new Date(d)
                return ("0" + nd.getDate()).slice(-2) + "/" + ("0" + (nd.getMonth() + 1)).slice(-2) + "/" + nd.getFullYear();
            },
            formatDateSD(dateString) {
                const d = dateString.split("/");
                const nd = new Date(d[2] + '/' + d[1] + '/' + d[0]);
                return nd.getFullYear() + "-" + ("0" + (nd.getMonth() + 1)).slice(-2) + "-" + ("0" + nd.getDate()).slice(-2)
            },
            formatDatePD(d) {
                var nd = d
                return ("0" + nd.getDate()).slice(-2) + "/" + ("0" + (nd.getMonth() + 1)).slice(-2) + "/" + nd.getFullYear();
            },
            formatDate(d) {
                var nd = new Date(d)
                return ("0" + nd.getDate()).slice(-2) + "/" + ("0" + (nd.getMonth() + 1)).slice(-2) + "/" + nd.getFullYear();
            },
            async getAgingDetails() {
                try {
                    var l = localStorage.getItem("userdata");
                    if (l != null && l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { CustomerLinkCustCode: userData.CustomerCode };
                        const response = await axios.post(helper.URL() + 'selectagingdetails', requestBody);
                        const result = response.data;
                        self.AgingList = [];
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                result.Data[0].forEach(element => {
                                    self.CustomerCreditLimit = self.CustomerCreditLimit + element.CustomerCreditLimit
                                    self.AgingBalance = self.AgingBalance + element.AgingBalance
                                    if (self.CustomerDue < element.CustomerDue) {
                                        self.CustomerDue = element.CustomerDue
                                    }
                                    element.isSelected = false
                                    self.AgingList.push(element)
                                });
                            }
                            else {
                                Swal.fire('Alert!', "Response not found!!!", 'error');
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            async getOrderDetails() {
                try {
                    var l = localStorage.getItem("userdata");
                    if (l != null && l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { OrderCustCode: userData.CustomerCode, OrderDocNo: 0, ShowOrderStatusOnly: "0" };
                        const response = await axios.post(helper.URL() + 'ordermasterdetails', requestBody);
                        const result = response.data;
                        self.orderDetails = [];
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                self.orderDetails = result.Data[0];
                            }
                            else {
                                Swal.fire('Alert!', "Response not found!!!", 'error');
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            async getCollectionDetails() {
                try {
                    var l = localStorage.getItem("userdata");
                    if (l != null && l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { custcode: userData.CustomerCode };
                        const response = await axios.post(helper.URL() + 'collectiondetails', requestBody);
                        const result = response.data;
                        self.CollectionList = [];
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                self.CollectionList = result.Data[0];
                            }
                            else {
                                Swal.fire('Alert!', "Response not found!!!", 'error');
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            getSelectedTotal() {
                var rv = 0
                this.AgingList.forEach(element => {
                    if (element.isSelected) {
                        rv = rv + element.AgingBalance
                    }
                });
                return rv
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .daddress {
        color: #78787C;
        line-height: 40px;
        border-top: 1px solid #C5C7D0;
        border-bottom: 1px solid #C5C7D0
    }

    .row img {
        max-height: 15em;
        width: 100%;
    }

    .lts {
        font-size: 12px;
    }

    .row h3 {
        cursor: pointer;
    }

    .hom-box {
        background: #FFFFFF;
        border: 1px solid #C5C7D0;
        box-shadow: 0px 2px 8px 0px #00000026;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 10px 15px 15px 15px;
        height: 60px;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: left;
    }

    .spanmyicon {
        display: flex;
        justify-content: space-between;
        float: right;
        margin-top: 5px;
    }

        .spanmyicon img {
            width: 20px;
        }

    .carouselbox {
        background-color: #fff;
        padding: 5px 20px 5px 20px;
    }

    .carousel-control-prev {
        justify-content: normal;
        color: #000;
    }

    .carousel-control-next {
        justify-content: right;
        color: #000;
    }

    .carouselinner {
        margin: 5px 25px 5px 25px;
        width: 100%;
    }

    #carousel-card .card {
        border: 0.5px solid #FFF !important;
        text-align: center;
    }

        #carousel-card .card:hover {
            border: 0.5px solid #00AEEF !important;
        }

    #carousel-card .card-title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
    }

    .img-fluid {
        width: 124px !important;
    }

    .homesmbanner1 {
        background-image: url("../assets/img/home_smbanner_1.png");
        background-repeat: no-repeat;
        width: 679px;
        height: 313px;
        margin-right: 5px;
    }

    .homesmbanner2 {
        background-image: url("../assets/img/home_smbanner_2.png");
        background-repeat: no-repeat;
        width: 679px;
        height: 313px;
        margin-left: 5px;
    }

    .texthomesmbanner {
        padding: 75px 75px 10px 75px;
        background: -moz-linear-gradient(top, rgba(255,255,255,0) 2%, rgba(255,255,255,0) 12%, rgba(255,255,255,0.55) 40%, rgba(255,255,255,1) 63%, rgba(255,255,255,1) 75%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 2%,rgba(255,255,255,0) 12%,rgba(255,255,255,0.55) 40%,rgba(255,255,255,1) 63%,rgba(255,255,255,1) 75%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255,255,255,0) 2%,rgba(255,255,255,0) 12%,rgba(255,255,255,0.55) 40%,rgba(255,255,255,1) 63%,rgba(255,255,255,1) 75%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    #ordertbl {
    }

        #ordertbl input {
            width: 35px;
            padding: 0px;
            text-align: center;
        }

        #ordertbl img {
            width: 58px;
            height: 58px;
            border: solid 1px #00AEEF;
            margin: 10px;
        }

        #ordertbl tr th {
            text-align: center;
            font-size: 12px;
            font-family: Roboto;
            font-style: normal;
        }

    .orderrow {
        background: #fff;
        font-size: 14px;
        font-family: Roboto;
        font-style: normal;
        border-top: solid 2px #E5E5E5;
    }

    .tabletitle {
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #00AEEF;
        padding: 5px;
    }

    .pqty {
        color: #00AEEF;
        text-align: center;
    }

    .orderrowbtn {
        border-top: none;
        background: #fff;
    }

        .orderrowbtn .btn-info {
            background-color: #0dcaf0;
            position: relative;
            top: -20px;
        }

    .sidebar {
        background: #fff;
        margin-top: 15px;
        padding: 15px;
        font-family: Roboto;
    }

    .mov {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        display: flex;
        align-items: center;
        line-height: 50px;
    }

    .freebox {
        background: #E4E7ED;
        border-radius: 4px;
        padding: 4px;
        font-family: 'Roboto';
        font-size: 21px;
    }

    .freeg {
        color: #13e748;
        font-weight: 600;
    }

    .sb-title {
        border-bottom: solid 1px #000;
    }

        .sb-title td {
            font-weight: 800;
        }

    .sb-table {
        font-family: Roboto;
        font-size: 14px;
        line-height: 35px;
    }

        .sb-table td:nth-child(2) {
            text-align: right;
        }

    .totalrow {
        line-height: 55px;
        border-top: dashed 1px #000;
        border-bottom: dashed 1px #000;
        font-weight: 800;
    }

    .btnbig {
        width: 100%;
        margin: 20px 0px;
    }

    .bigproduct {
        padding: 5px;
        background: #FFFFFF;
        border: solid 1px rgb(230, 230, 230);
        box-shadow: 0px 2px 8px 0px #00000026;
    }

        .bigproduct img {
            height: 421px;
            width: 421px;
            max-height: 460px !important;
        }

    .smproduct img {
        height: 75px;
        width: 75px;
    }

    .smproduct {
        height: 85px;
        width: 85px;
        padding: 5px;
        border: solid 1px rgb(230, 230, 230);
        margin-bottom: 20px;
    }

    .number-spin {
        position: relative;
        width: 126px;
    }

        .number-spin input {
            height: 32px;
            width: 100%;
            text-align: right;
            padding-right: 20px;
            box-sizing: border-box;
            font-size: 16px;
        }

        .number-spin .ns-plus {
            position: absolute;
            text-align: center;
            line-height: 16px;
            top: 10px;
            right: 10px;
            height: 16px;
            display: block;
            border-left: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            width: 16px;
        }

        .number-spin .ns-minus {
            position: absolute;
            text-align: center;
            display: block;
            line-height: 16px;
            height: 16px;
            border-left: 1px solid #ccc;
            bottom: 10px;
            right: 10px;
            width: 16px;
        }

    .font {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
    }

    .taxrow p {
        margin-bottom: -2px !important;
    }

    .sp-blue {
        color: #00AEEF;
    }

    .sp-bold {
        font-weight: bold;
    }

    .sp-dashed {
        text-decoration-line: line-through;
        padding-left: 15px;
    }

    .sp-tax {
        padding-left: 25px;
    }

    #accordionExample {
        margin-top: 25px;
    }

        #accordionExample .accordion-item {
            border: none
        }

    .amt {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #454545;
    }

    .amtinvoice {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #454545;
    }

    #accordionExample .accordion-button:not(.collapsed)::after {
        font-family: "Font Awesome 5 Brands";
        font-weight: 400;
        content: '\f068';
    }

    #accordionExample .accordion-button:not(.collapsed) {
        color: #000 !important;
        background-color: #FFF !important;
        box-shadow: none !important;
        border-bottom: solid 1px #666 !important;
        font-weight: 400;
        font-size: 24px;
    }

    #accordionExample .accordion-item:first-of-type .accordion-button {
        font-weight: 400;
        font-size: 24px;
    }

    .table-striped {
        padding: 10px;
    }

        .table-striped tr:nth-child(even) {
            background-color: #FFF;
        }

        .table-striped tr {
            border-bottom: solid 0.5px #666;
            line-height: 35px;
            font-family: Roboto;
        }

    .card-header {
        color: #454545;
        font-family:Inter;
        font-size:14px;
        font-weight:600
    }

    .days {
        border: 1px solid #C5C7D0;
        border-radius: 5px;
        font-weight: 400;
        z-index: 1;
        width:200px;
      
    }

    .totalamt {
        float: right;
        width: 50%;
        padding: 5px;
        border: 1px solid rgb(102, 102, 102);
        border-radius: 5px;
    }
    .totalamtstyle {
        float: right;
        width: 50%;
        padding: 5px;
        border: 1px solid rgb(102, 102, 102);
        border-radius: 5px;

        margin-right: 11px;
        font-size: 16px;
        color: #454545;
        border: solid 0.5px #78787c;
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
        margin-top: 6px;
        margin-bottom: 3px
    }

    /* updated style */
    .pay-det {
        font-size: 14px;
        font-weight: 500;
        color: #454545;
        font-family:Inter
    }

        .pay-det strong {
            font-weight: 500;
        }

        .pay-det .text-style-1 {
            font-weight: normal;
        }

        .pay-det .text-style-2 {
            font-weight: normal;
            color: #f00 !important;
        }
    .font-wei-nor {
        font-weight: normal;
    }
    .lable-txt label {
        font-size: 14px;
        font-weight: 500;
        color: #454545;
        margin-bottom: 10px;

    }




    table th {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1;
        background: rgb(255, 255, 255);
    }
    .box1-input input {
        color: #454545;
        font-size: 14px;
        font-weight: 500;
        width:200px
    }
    .box2-input input {
        color: #454545;
        font-size: 14px;
        font-weight: 500;
        width: 200px
    }
    .box3-input input {
        color: #707070;
        font-size: 14px;
        width: 200px
    }
    .submit-btn1 {
        border-radius: 4px;
        font-size: 14px;
        padding: 10px 24px;
        color:#fff;
        font-family:Inter
    }
    .table1 th {
        color: #111;
        font-family: Roboto;
    }
    .table1 td {
        color: #000;
        font-size: 18px;
        font-weight: 300;
        font-family: Roboto;
    }
    .modal-dialog {
        max-width: 350px
    }
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }
    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    /*.table::-webkit-scrollbar {
        width: 5px;
        background-color: #fff;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }*/

    /* Track */
    /*::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }*/

    /* Handle */
    /*::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 5px;
    }*/

        /* Handle on hover */
        /*::-webkit-scrollbar-thumb:hover {
            background: grey;
        }*/

    .table::-webkit-scrollbar {
        width: 5px;
        background-color: #fff;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 5px; /* Adjust the border-radius */
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 3px; /* Adjust the border-radius */
    }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: grey;
        }



    .custom-text {
        color: #454545;
        font-family: 'Inter', sans-serif;
    }


    .form-check-label {
        font-family:Inter;
        color:#454545;
        font-size:14px
    }


    .table th {
        font-size:14px;
        font-family:Inter;
        color:#454545;
        padding:10px

    }

    .table td {
        font-size: 14px;
        font-family: Inter;
        color: #454545;
        font-weight:500;
        padding:10px
    }

    .table th {
        background:  #a8ddf4
    }


    .navigationTop {
        display: flex;
        align-items: center;
        padding: 10px;
        font-family: Inter;
        font-size: 12px
    }

        .navigationTop a {
            text-decoration: none;
            color: #454545;
            font-family: Inter;
            font-size: 12px
        }

            .navigationTop a:hover {
                color: #2ca9df;
                font-family: Inter;
                font-size: 12px
            }

        .navigationTop span {
            margin: 0 5px;
            color: #454545;
            font-family: Inter;
            font-size: 12px
        }




</style>
