export default {
    template: `
        <div class="minusplusnumber" style="display: flex; align-items: center;">
            <div class="mpbtn minus" v-on:click="mpminus()" style="width: 20px; height: auto;text-align:center;background:#F3F3F3; border-top-left-radius: 5px;border-bottom-left-radius: 5px;">
                -
            </div>
            <div id="field_container" style="flex: 1; margin: 0 0px;">
            <input class="no-spinners" type="number" v-model="newValue" v-on:change="mpchange()" @focus="selectText" style="text-align:center;width:70px;background:#fff;border:none;margin-left: 5px; outline: none;"/>
            </div>

            <div class="mpbtn plus" v-on:click="mpplus()" style="width: 20px; height:auto; text-align:center;background:#F3F3F3; border-top-right-radius: 5px;border-bottom-right-radius: 5px;">
            +
            </div>
        </div>
     `,
    props: {
        value: {
            default: 0,
            type: Number
        },
        index: {
            required: false,
            type: Number
        },
        min: {
            default: 0,
            type: Number
        },
        max: {
            default: undefined,
            type: Number
        }
    },
    data: function () {
        return {
            newValue: 0
        }
    },
    methods: {
        selectText(event) {
            event.target.select();
        },
        mpplus: function () {
            if (this.max == undefined || (this.newValue < this.max)) {
                this.newValue = this.newValue + 1;
                this.$emit('input', this.newValue);
                this.$emit('changeevent', this.newValue, this.index);
            }
        },
        mpminus: function () {
            if ((this.newValue) > this.min) {
                this.newValue = this.newValue - 1;
                this.$emit('input', this.newValue);
                this.$emit('changeevent', this.newValue, this.index);
            }
        },
        mpchange: function () {
            if (this.max == undefined || (this.newValue < this.max)) {
                this.$emit('input', this.newValue);
                this.$emit('changeevent', this.newValue, this.index);
            }
        }
    },
    watch: {
        value: {
            handler: function (newVal, oldVal) {
                this.newValue = newVal;
            }
        }
    },
    created: function () {
        this.newValue = this.value;
    }
};





