<template>
    <div></div>
</template>

<script>
    export default {
        name: 'home',
        data() {
            return {
                Title: '',
                hideo: true
            }
        },
        created: async function () {
            this.nave();
        },
        beforeMount: async function () {
        },
        methods: {
            nave() {
                var p = this.$route.params
                this.$router.push({ name: 'homeProducts', params: p })
            }
        }
    }
</script>

<style scoped>
</style>