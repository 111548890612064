<template>
    <div class="container" style="margin-top: 101px;">
        <div class="containerMyOrders">
            <div class="main-column main-column-large">
                <div class="rowMyOrder" style=" margin-right: -12px; margin-left: 12px;">
                    <div class="navigationTop" style="padding-top: 20px; margin-left: 7px;">
                        <a href="#" @click="navigateToHomeUser">Home</a>
                        <span>&gt;</span>
                        <a href="#">My Orders</a>
                    </div>
                    <div class="row-item1">
                        <div class="search-box">
                            <input type="text" class="search-input" :placeholder="searchPlaceholder" v-model="searchText" />
                            <img src="../assets/img/quill_search-alt.png" style="width:30px;height:30px" />
                        </div>
                    </div>
                    <div class="row-item2">
                        <div class="custom-columnfirst" href="#/" @click="navigateToMyOrder" style="cursor:pointer">
                            <h2 :class="{ 'highlighted': isMyOrderActive }" style="display: flex; justify-content: center; align-items: center; background-color: blue; padding: 10px; border-radius: 5px;">
                                <a style="color: #fff; font-family: Inter; font-size: 18px; text-decoration: none;" href="#/" @click="navigateToMyOrder">{{ myOrdersTitle }}</a>
                            </h2>
                        </div>
                        <div class="custom-columnsecond" href="#/" @click="navigateToMyPendingOrder" style="cursor:pointer">
                            <h2 style="display: flex; justify-content: center; align-items: center; background-color: #2CA9DF; padding: 10px; border-radius: 5px;">
                                <a style="color: #fff; font-family: Inter; font-size: 18px; text-decoration: none;" href="#/" @click="navigateToMyPendingOrder">{{ myPendingOrdersTitle }}</a>
                            </h2>
                        </div>
                        <div class="custom-columnthird" href="#/" @click="navigateToMyReturns" style="cursor:pointer">
                            <h2 style="display: flex; justify-content: center; align-items: center; background-color:#2CA9DF; padding: 10px; border-radius: 5px;">
                                <a style="color: #fff; font-size: 18px; font-family:Inter;text-decoration: none;" href="#/" @click="navigateToMyReturns">{{ myReturnsTitle }}</a>
                            </h2>
                        </div>
                    </div>
                    <template v-for="(p,index) in filterBy(orderDetails, searchText)" :key="index">
                        <div class="row-item3">
                            <table class="custom-tableorder table-height" style="border-radius: 5px; overflow: hidden;">
                                <tr class="custom-roworder" style="background-color: #a8ddf4">
                                    <td class="custom-cellorder" style="color: #454545;font-weight: 600;font-family: inter;text-align: left;">
                                        Order ID:{{ p.OrderDocNo }}
                                    </td>
                                    <td class="custom-cellorder" style="color: #454545;font-weight: 600;font-family: inter;text-align:center;">P. O Reference:{{ p.OrderPoReference }}</td>
                                    <td class="custom-cellorder" style="color: #454545;font-weight: 600;font-family: inter;text-align:center;">P. O Remark:{{ p.OrderPoRemark }}</td>
                                    <td class="custom-cellorder custom-wide" style=" color: #454545;font-weight: 600;font-family: Inter;text-align: right;">
                                        Order Date: {{ p.OrderDocDate }}
                                    </td>
                                </tr>
                                <tr class="custom-roworder"
                                    style="background-color: #fff">
                                    <td class="custom-cellorder">
                                        Total No. of Boxes: {{p.BoxQty}}
                                    </td>
                                    <td class="custom-cellorder">
                                        <div @click="openModal">
                                            <button style="height: 40px; width: 102px;margin-left:36px;margin-top:0px;cursor:pointer"
                                                    class="custom-button" @click="popupMyOrder(p.OrderDocNo, true, p.OrderExpDelDate, false, true, false)">
                                                View Order
                                            </button>
                                        </div>
                                    </td>
                                    <td class="custom-cellorder"></td>
                                    <td class="custom-cellorder custom-wide"
                                        style="text-align: right">
                                        Ekart Ref No.: {{ p.KartInvoiceNo}}
                                    </td>
                                </tr>
                                <tr class="custom-roworder"
                                    style="background-color: #fff; ">
                                    <td class="custom-cellorder">
                                        Total No. of Items: {{ p.OrderQty }}
                                    </td>
                                    <td class="custom-cellorder custom-wide">
                                        <div class="custom-column-splited" colspan="3" style=" text-align: right; padding-left: 88px;">
                                            <div class="orderStatusR">
                                                <ul>
                                                    <li v-for="(TimeLine,index2) in p.Delivery" :key="index2" :data-text-before="TimeLine.OrderStatus" :data-text="formatedate(TimeLine.OrderStatusDate)" style="font-family:Inter"></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="custom-roworder"
                                    style="background-color: #fff">
                                    <td class="custom-cellorder">
                                        Order Value : {{ p.KartNetAmount }}
                                    </td>
                                    <td class="custom-cellorder">
                                        <div>
                                            <button style="height: 40px; width: 102px; margin-left: -57px; margin-top: -26px; cursor: pointer " class="custom-button" @click="popupMyOrder(p.OrderDocNo, true, p.OrderExpDelDate, false, false, true)">Track Order</button>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </template>
                </div>
            </div>
            <div class="main-column side-column">
                <div class="side-column-item">
                    <img src="../assets/img/ImageCartPage1.png" alt="side_image" style="height: 427px; width: 210px; margin-top: 40px;" />
                </div>
            </div>
            <div id="myModal" class="modal" v-show="modalViewOrder">
                <div class="modal-content" style="height:500px;overflow-x:auto;position:fixed">
                    <div class="table-container-vieworder" style="height: 500px; overflow-y: auto;">
                        <table style="width:100%">
                            <tr style="position: sticky; top: 0; background-color: white;">
                                <td colspan="4">
                                    <div class="custom-rowmodal">
                                        <p>Order ID: {{ OrderDocNo }} | {{ OrderDocDate }}</p>
                                        <p style="text-align:right">Expected Delivery Date: {{ OrderExpDelDate }}</p>
                                        <p style="text-align:right">Order Value: &#x20B9; {{ OrderValue }}  </p>
                                        <span class="close" @click="closeModal"><img src="../assets/img/iconamoon_close.png" style="width: 30px; height: 30px;" /></span>
                                    </div>
                                </td>
                            </tr>

                            <tr style="position: sticky; top: 50px; background-color: white;">
                                <td colspan="4">
                                    <div class="custom-rowmodal2" style="display: flex; justify-content: flex-start;">
                                        <p style="margin-right: 200px;">Total No. of Items: {{ OrderCount }}</p>
                                        <div class="search-inputmodal" style="display: flex; align-items: center;">
                                            <input type="text" placeholder="Search..." v-model="searchTextViewOrder" />
                                            <img src="../assets/img/quill_search-alt.png" style="width: 30px; height: 30px; margin-left: 10px;" />
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr v-for="(order, index) in filterBy(filteredData, searchTextViewOrder)" :key="index">
                                <td colspan="4">
                                    <div class="row-modal3" style="width:100%;">
                                        <div class="numberandprodet">
                                            <p class="serial-number">{{ index + 1 }}</p>
                                            <div class="modalprodet">
                                                <h4 style="font-size: 12px; font-weight: 600;">{{ order.ItemName }}</h4>
                                                <p>Product Code: {{ order.OrderDetItemCode }}</p>
                                                <p>
                                                    {{ order.ItemBoxQuantity }} pc/Box I Unit Price:
                                                    <span style="text-decoration: line-through">&#x20b9;{{ order.ItemUnitPrice }}</span>
                                                    <span style="font-size:12px;font-weight:600"> &#x20b9;{{ order.ItemDP }}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div style="text-align:center">
                                            <!--<p>Order Qty: {{ calculateOrderQty(order) }}</p>-->
                                            <p>Order Qty: {{ order.OrderDetQty / order.ItemBoxQuantity  }} Boxes / {{ order.OrderDetQty }} Pcs.</p>
                                            <p>
                                                Remarks: {{ order.OrderDetLrNo || order.OrderDetLrDate || order.OrderDetTransportation ? (order.OrderDetLrNo ? order.OrderDetLrNo + ' / ' : '') + (order.OrderDetLrDate ? order.OrderDetLrDate + ' / ' : '') + order.OrderDetTransportation : '' }}
                                            </p>
                                        </div>
                                        <div style="text-align:right;font-size:12px">
                                            <p>Amount: &#x20B9; {{ order.KartDetOrderVal }}</p>
                                            <p>Tax Amount: &#x20B9; {{ order.KartDetTaxAmount }}</p>
                                            <p>Net Amount: &#x20B9; {{ order.KartDetTotalAmount }}</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div v-if="modalTrackOrder" class="modal">
                <div class="modal-content"  style="height: 600px; overflow-x: auto;position:fixed">
                    <table id="header" >
                        <tr>
                            <td colspan="4">
                                <div class="custom-rowmodal">
                                    <p>Order ID: {{ OrderDocNo }} | {{ OrderDocDate }}</p>
                                    <p style="text-align:right">Expected Delivery Date: {{ OrderExpDelDate }}</p>
                                    <p style="text-align:right">Order Value: &#x20B9; {{ OrderValue }}  </p>
                                    <span class="close" @click="closeTrackModal"><img src="../assets/img/iconamoon_close.png" style="width: 30px; height: 30px;" /></span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <div class="custom-rowmodaltrack2">
                                    <div class="column-direction" style="width:348px;padding:5px">
                                        <p>Total No. of Items: {{ OrderCount }}</p>
                                        <div class="search-inputmodal">
                                            <input type="text" placeholder="Search..." v-model="searchTextTrackOrder" />
                                            <img src="../assets/img/quill_search-alt.png" style="width: 30px; height: 30px;" />
                                        </div>
                                    </div>
                                    <div class="status-container" style="margin-top:10px;margin-bottom:10px;margin-right:30px">
                                        <ul>
                                            <li v-for="(TimeLine,index2) in PIDATA[0].Delivery" :key="index2" :data-text-before="TimeLine.OrderStatus" :data-text="formatedate(TimeLine.OrderStatusDate)" style="font-family:Inter"></li>
                                        </ul>
                                    </div>
                                    <!--<img src="../assets/img/Download.png" style="width: 50px; height: 50px;padding:10px;" />-->
                                </div>
                            </td>
                        </tr>
                    </table>
                    <div class="table-container" style="height:500px;overflow-x:auto">
                        <!--<table id="body" style="width:1033px;height:80px;overflow-x:auto">-->
                        <table id="body" style="width:100%;height:80px;overflow-x:auto">
                            <tr style="background: #fff; margin-top: 40px">
                                <td>
                                    <div class="header-rowTrack" style="background:#F3F3F3;margin-top:10px">
                                        <div>S.No.</div>
                                        <div>Item</div>
                                        <div>Order Quantity</div>
                                        <div>Delivery Quantity</div>
                                        <div>Pending Quantity</div>
                                        <div>Invoice No./Date</div>
                                        <div>Transport Details</div>
                                        <div>Status</div>
                                        <div>Delivered Date</div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-for="(order, index) in filterBy(filteredData, searchTextTrackOrder)" :key="index">
                                <td>
                                    <div class="header-itemsTrack">
                                        <div>{{ index + 1 }}</div>
                                        <div>{{ order.ItemName }}</div>
                                        <div>{{ order.OrderDetQty }}</div>
                                        <div>{{ order.OrderDispatchedQty }}</div>
                                        <div>{{ order.OrderDetOpenQty }}</div>
                                        <div>
                                            <a v-if="order.OrderDetStatusInvoiceNo"
                                               @click.prevent="downloadInvoice(order.OrderDetStatusInvoiceNo)"
                                               href="#"
                                               class="invoice-link">
                                                {{ order.OrderDetStatusInvoiceNo }}
                                            </a>
                                            <span v-if="order.OrderDetStatusInvoiceNo && order.OrderDetStatusInvoiceDate">
                                                / {{ order.OrderDetStatusInvoiceDate }}
                                            </span>
                                            <!--{{ order.OrderDetStatusInvoiceNo || order.OrderDetStatusInvoiceDate ? (order.OrderDetStatusInvoiceNo ? order.OrderDetStatusInvoiceNo + ' / ' : '') + order.OrderDetStatusInvoiceDate  : ''}}-->
                                        </div>
                                        <div>
                                            {{ order.OrderDetLrNo || order.OrderDetLrDate || order.OrderDetTransportation ? (order.OrderDetLrNo ? order.OrderDetLrNo + ' / ' : '') + (order.OrderDetLrDate ? order.OrderDetLrDate + ' / ' : '') + order.OrderDetTransportation : '' }}
                                        </div>
                                        <div>{{ order.ItemStatus }}</div>
                                        <div>{{ order.OrderDetDelDate }}</div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue2Filters from "vue2-filters";
    import helper from "../helper";
    import axios from "axios";
    export default {
        mixins: [Vue2Filters.mixin],
        name: "myOrder",
        components: {

        },
        data() {
            return {
                PIDATA: [],
                isScandPopup: false,
                title: "My Order",
                orderDetails: [],
                ShowItems: false,
                GetUserInfo: "",
                isModalVisible: false,
                OrderDocNo: "",
                OrderDocDate: "",
                OrderExpDelDate: "",
                OrderValue: "",
                loading: false,
                searchText: "",
                searchTextViewOrder: "",
                searchTextTrackOrder: "",
                CustomerCode: "",
                searchPlaceholder: "Search by Order Number, Date, Ekart Refrence No...",
                myOrdersTitle: "My Orders",
                myPendingOrdersTitle: "My Pending Orders",
                myReturnsTitle: "My Returns",
                modalViewOrder: false,
                modalTrackOrder: false,
                isMyOrderActive: false
            };
        },
        created: function () {
            this.GetUserInfo = JSON.parse(localStorage.getItem("userdata"));
            this.getOrderDetails();
        },
        computed: {
            filteredData() {
                return this.PIDATA.filter(order => order.OrderDetQty > 0);
            },
            OrderCount() {
                return this.PIDATA.filter(order => order.OrderDetQty > 0).length;
            }
        },
        methods: {
            async downloadInvoice(invoiceNo) {
                try {
                    const response = await axios({
                        method: 'post',
                        url: helper.URL() + 'getinvoice',
                        data: { invoiceno: invoiceNo },
                        responseType: 'blob'
                    });

                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'invoice.pdf'); // Set the filename
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                } catch (error) {
                    console.error('Error downloading the invoice:', error);
                }
            },
            async downloadInvoiceOld(invoiceNo) {
                try {
                    const response = await fetch(helper.URL() + 'getinvoice', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ invoiceno: invoiceNo })
                    });

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'invoice.pdf');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                } catch (error) {
                    console.error('Error downloading the invoice:', error);
                }
            },
            navigateToHomeUser(event) {
                event.preventDefault();
                this.$router.push('/homeUser');
            },
            navigateToMyOrder(event) {
                event.preventDefault();
                this.$router.push('/myOrder');
            },
            navigateToMyPendingOrder(event) {
                event.preventDefault();
                this.$router.push('/myPendingOrder');
            },
            navigateToMyReturns(event) {
                event.preventDefault();
                this.$router.push('/myReturn');
            },
            formatedate(d) {
                var nd = new Date(d)
                return ("0" + nd.getDate()).slice(-2) + "/" + ("0" + (nd.getMonth() + 1)).slice(-2) + "/" + nd.getFullYear();
            },
            closeModal() {
                this.modalViewOrder = false;
            },
            closeTrackModal() {
                this.modalTrackOrder = false;
            },
            async getOrderDetails() {
                try {
                    this.loading = true;
                    var l = localStorage.getItem("userdata");
                    if (l != null && l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { OrderCustCode: userData.CustomerCode, OrderDocNo: 0, ShowOrderStatusOnly: "0" };
                        const response = await axios.post(helper.URL() + 'ordermasterdetails', requestBody);
                        const result = response.data;
                        self.orderDetails = [];
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                self.orderDetails = result.Data[0];
                            }
                            else {
                                Swal.fire('Alert!', "Response not found!!!", 'error');
                            }
                        }
                    }
                    this.loading = false;
                } catch (error) {
                    console.log(error);
                }
            },
            async popupMyOrder(oid, isScandPopup, deldate, filtered, modalViewOrder, modalTrackOrder) {
                try {
                    var self = this;
                    self.OrderDocNo = oid;
                    self.isScandPopup = isScandPopup;
                    self.OrderExpDelDate = deldate;
                    const requestBody = {
                        OrderCustCode: this.GetUserInfo.CustomerCode,
                        OrderDocNo: oid,
                        ShowOrderStatusOnly: "0"
                    };
                    const response = await axios.post(helper.URL() + 'ordermasterdetails', requestBody);
                    const result = response.data;
                    self.PIDATA = [];
                    if (result.Status == true) {
                        if (result.Data && result.Data.length > 0) {
                            self.OrderDocDate = response.data.Data[0][0].OrderDocDate;
                            self.OrderValue = response.data.Data[0][0].KartNetAmount;
                            if (filtered) {
                                self.PIDATA = response.data.Data[0];
                                self.PIDATA = self.PIDATA.filter(item => item.ItemStatus === 'Delivered');
                            } else {
                                self.PIDATA = response.data.Data[0];
                            }
                            self.modalViewOrder = modalViewOrder;
                            self.modalTrackOrder = modalTrackOrder;
                        }
                        else {
                            Swal.fire('Alert!', "Response not found!!!", 'error');
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            async popupMyPendingOrder(oid, isScandPopup, deldate, filtered) {
                try {
                    var self = this;
                    self.OrderDocNo = oid;
                    self.isScandPopup = isScandPopup;
                    self.OrderExpDelDate = deldate;
                    const requestBody = {
                        OrderCustCode: this.GetUserInfo.CustomerCode,
                        OrderDocNo: oid,
                        ShowOrderStatusOnly: "0"
                    };
                    const response = await axios.post(helper.URL() + 'ordermasterdetails', requestBody);
                    const result = response.data;
                    self.PIDATA = [];
                    if (result.Status == true) {
                        if (result.Data && result.Data.length > 0) {
                            self.OrderDocDate = response.data.Data[0].OrderDocDate;
                            self.OrderValue = response.data.Data[0].KartNetAmount;
                            if (filtered) {
                                self.PIDATA = response.data.Data[0];
                                self.PIDATA = self.PIDATA.filter(item => item.ItemStatus === 'Delivered');
                            } else {
                                self.PIDATA = response.data.Data[0];
                            }
                        }
                        else {
                            Swal.fire('Alert!', "Response not found!!!", 'error');
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            formatDate(date) {
                var d = new Date(date);
                var month = "" + (d.getMonth() + 1);
                var day = "" + d.getDate();
                var year = d.getFullYear();
                if (month.length < 2) month = "0" + month;
                if (day.length < 2) day = "0" + day;
                return [day, month, year].join("-");
            }
        }
    };
</script>

<style scoped>
    ul {
        list-style: none;
        margin: 0px 30px;
        padding: 0;
        display: inline-block;
        position: relative;
    }

        ul li {
            border-radius: 50%;
            width: 9px;
            height: 9px;
            float: left;
            position: relative;
            background-color: #00c213;
            z-index: 6;
            margin: 10px 150px 10px 0px;
        }

            ul li:last-child {
                margin: 10px 0px 10px 10px;
            }

            ul li:before {
                content: attr(data-text-before);
                display: inline;
                justify-content: space-between;
                font-size: 10px;
                position: absolute;
                margin-bottom: 100px;
                padding-left: 10px;
                width: 100px;
                text-align: center;
                margin-top: -9px;
                margin-left: -53px
            }

            ul li:after {
                content: attr(data-text);
                display: inline;
                font-size: 10px;
                position: absolute;
                padding-left: -25px;
                width: 100px;
                margin-left: -45px;
                text-align: center;
                margin-bottom: -6px;
            }

            ul li:before {
                top: -20px;
            }

            ul li:after {
                bottom: -20px;
            }

            ul li:nth-child(odd):before {
                display: block;
            }

            ul li:nth-child(even):after {
                display: block;
            }

        ul:before {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            background-color: #00c213;
            position: absolute;
            top: 14px;
            z-index: 3;
        }
    .containerMyOrders {
        display: flex;
    }

    .main-column {
        flex: 1;
        padding: 10px;
    }

    .main-column-large {
        flex-basis: 75%;
    }

    .side-column {
        flex-basis: 25%;
    }

    .rowMyOrder {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }

    .row-item1 {
        flex-basis: 100%;
        padding: 10px;
    }

    .row-item2 {
        flex-basis: 100%;
        padding: 10px 10px;
        display: flex;
        flex-direction: row;
    }

    .row-item3 {
        flex-basis: 100%;
        padding: 10px;
        display: flex;
    }

    .side-column-item {
        flex-basis: 100%;
        padding: 10px;
    }

    .row-item1 {
        flex-basis: 100%;
        border-radius: 5px;
    }

    .search-box {
        display: flex;
        align-items: center;
        border: 1px solid #fff;
        border-radius: 5px;
        padding: 1px;
        width: 100%;
    }

    .search-input {
        flex: 1;
        border: none;
        outline: none;
        padding: 5px;
        font-size: 11px;
        background-color: #fff;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        color:#454545;
        font-family:Inter;
        font-weight:500
    }

    .search-icon {
        padding: 3px;
        padding-bottom: 4px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        font-weight: bold;
        color: #454545;
        background-color: #e3e3e3;
    }

    .custom-columnfirst {
        flex-basis: 35%;
        padding: 10px;
        background: #fff;
    }

    .custom-columnsecond {
        flex-basis: 35%;
        padding: 10px;
        background: #fff;
    }

    .custom-columnthird {
        flex-basis: 35%;
        padding: 10px;
        background: #fff;
    }

    .custom-columnfirst h2 {
        margin: 0;
        text-align: center;
    }

    .custom-columnfirst a {
        text-decoration: none;
        color: white;
    }

    .custom-columnsecond h2 {
        margin: 0;
        text-align: center;
    }

    .custom-columnsecond a {
        text-decoration: none;
        color: white;
    }

    .custom-columnthird h2 {
        margin: 0;
        text-align: center;
    }

    .custom-columnthird a {
        text-decoration: none;
        color: white;
    }


    .row-item3 {
        flex-basis: 100%;
        padding: 10px;
        display: flex;
    }

    .custom-tableorder {
        width: 100%;
        border-collapse: collapse;
        margin: 0;
        padding: 0;
    }

    .custom-roworder {
        display: flex;
    }

    .custom-cellorder.custom-wide {
        flex: 4;
    }

    .custom-column-split {
        display: flex;
    }

    .custom-column-splited {
        flex: 1;
        text-align: left;
    }

    .custom-columnfirst a,
    .custom-columnsecond a,
    .custom-columnthird a
    {
        color: #454545;
        font-size: 18px;
        position: relative;
        text-decoration: none;
        font-weight: normal;
        margin: 5px;
     
    }

        .custom-columnfirst a::after,
        .custom-columnsecond a::after,
        .custom-columnthird a::after
        {
            content: "";
            position: absolute;
            left: 0;
            bottom: -2px;
            width: 100%;
            height: 2px;
     
            margin-bottom: -9px;
        }


       

    .custom-button {
        height: 44px;
        width: 142px;
        border: 1px solid #737373;
        border-radius: 5px;
        padding: 10px;
    }

    .orderStatusR {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        justify-content: flex-start;
        align-items: center;
    }

    .dotR {
        width: 12px;
        height: 12px;
        background-color: green;
        border-radius: 50%;
        margin: 2px;
    }

    .dotTwoR {
        width: 12px;
        height: 12px;
        background-color: #afafaf;
        border-radius: 50%;
        margin: 2px;
        align-items: center;
        justify-content: flex-end;
    }

    .lineR {
        width: 100px;
        height: 2px;
        background-color: #afafaf;
        align-self: center;
    }

    .custom-tableorder {
        width: 100%;
        border-collapse: collapse;
        margin: 0;
        padding: 0;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }

    .custom-roworder {
        display: flex;
    }

    .custom-cellorder {
        flex-basis: 34%;
        padding: 5px;
        text-align: left;
    }

        .custom-cellorder.custom-wide {
            flex-basis: 51%;
            text-align: center;
        }

    .side-column-item img {
        width: 100%;
        height: auto;
    }

    .table-height tr {
        height: 40px; 
    }

    .table-height {
        height: 160px; 
        overflow: auto;
    }

    .custom-roworder {
        height: 10px
    }


    .modal {
        display: flex;
        justify-content: center; 
        align-items: center;
        position: fixed;
        z-index: 9999;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.4);
    }


    .modal-content {
        background-color: #fefefe;
        margin: 15% auto;
        padding: 20px;
        border: 1px solid #888;
        width: 80%;
    }


    .close {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

        .close:hover,
        .close:focus {
            color: black;
            text-decoration: none;
            cursor: pointer;
        }

    .custom-rowmodal {
        background: #2ca9df;
        color: #454545;
        font-weight: 600;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 10px;
        display: flex;
        align-items: center;
        color: #454545;
        font-family: Inter
    }

        .custom-rowmodal p {
            margin: 0;
            color: #454545;
            font-family: Inter
        }

            .custom-rowmodal p:nth-child(1) {
                flex: 2;
                text-align: left
            }

            .custom-rowmodal p:nth-child(2) {
                flex: 3;
                text-align: right
            }


            .custom-rowmodal p:nth-child(3) {
                flex: 3;
                text-align: right;
            }


        .custom-rowmodal .close {
            color: white;
            cursor: pointer;
            margin-left: 10px; 
        }


            .custom-rowmodal .close:hover {
                color: black;
            }



    .custom-rowmodal2 {
        display: flex;
        align-items: center; 
        justify-content: space-between; 
        background: #F3F3F3;
        color: #454545;
        padding: 10px;
        color: #454545;
        font-family: Inter
    }

        .custom-rowmodal2 p {
            margin-right: 10px; 
        }

    .search-inputmodal {
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-right: 148px;
        width: 322px;
    }

      
        .search-inputmodal input[type="text"] {
            border: none;
            outline: none;
            flex: 1;
            padding-left: 5px;
            font-size: 16px;
        }


        .search-inputmodal .search-icon {
            color: #666;
            font-size: 18px;
            margin-right: 5px;
        }


    .row-modal3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width:1033px;
      
        padding: 10px;
        background: #fff;
        border-bottom: 1px solid #AFAFAF;
    }


        .row-modal3 div {
            flex: 1;
        }

        .row-modal3 p {
            margin: 0;
        }

    .numberandprodet {
        display: flex; 
        align-items: center; 
        gap: 20px; 
        max-width: 400px;
    }


    .serial-number {
        margin: 0;
        padding: 10px;
 
        width: 40px;
        height: 40px;
        background-color: #AFAFAF;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        color: white;
        font-weight: bold;
        font-size: 12px; /* Adjust the font size as needed */
    }

    .modalprodet {
        font-size: 14px;
    }
    .custom-rowmodaltrack2 {
        display: flex;
        align-items: center; 
        justify-content: space-between; 
        background:#F3F3F3;
   
    }

    .column-direction {
        display: flex;
        flex-direction: column; 
    }

    .status-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .status-text {
        text-align: center;
        margin-bottom: 10px;
        white-space: nowrap; 
        overflow: hidden; 
        text-overflow: ellipsis; 
        flex: 1; 
    }

        .status-text p {
            float: left;
            width: 33.33%;
            box-sizing: border-box; 
            padding: 0 50px;
            text-align :left
        }


    .line-dot-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .line-dot-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dotTrack
 {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #000;
        margin: 4px; 
    }

        .lineTrack{
        width:14px;
        height: 2px;

        background-color: #000;       
    }

    .lineTrack {
        height: 2px;
        background-color: #000;
      
    }

    .header-rowTrack {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
        .header-rowTrack div {
            text-align: center;
            flex: 1;
            color: #454545;
            font-weight: 600;
            font-family: Inter
        }

    .header-itemsTrack{
        display:flex;
        flex-direction:row;
        justify-content: space-between;
    }


        .header-itemsTrack div {
            text-align: center;
            flex: 1;
            color: #454545;
            font-size: 12px;
            font-family:Inter;
            padding:10px
        }

    .custom-table {
        border-collapse: collapse;
        width: 100%;
    }

        .custom-table td {
            border: 1px solid #AFAFAF;
            padding: 5px;
        }

    .header-rowTrack div,
    .header-itemsTrack div {
        border: 1px solid #AFAFAF;
        padding: 5px;
    }

    .navigationTop {
        display: flex;
        align-items: center;
        padding:10px;
        font-family:Inter;
        font-size:12px
    }

        .navigationTop a {
            text-decoration: none;
            color: #454545;
            font-family: Inter;
            font-size: 12px
        }

            .navigationTop a:hover {
                color: #2ca9df;
                font-family: Inter;
                font-size: 12px
            }

        .navigationTop span {
            margin: 0 5px;
            color: #454545;
            font-family: Inter;
            font-size: 12px
        }



    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 10px;
    }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: grey;
        }






</style>