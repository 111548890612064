<template>
    <footer id="footer" v-if="isFooter()">
        <div class="footer-top">
            <div class="container">
                <div class="row">
                    <div class="col-md-4" style="text-align: left;">
                        <div class="footer-contact">
                            <img src="../assets/img/logo.png" style="width: 150px; margin-top: 11px; margin-left: -35px;" alt="Amaryllis Healthcare Logo">
                            <p style="font-family: Inter; color: #454545; font-size: 14px;margin-left:-38px">
                                At Amaryllis Healthcare, we prioritize customer and end-user satisfaction. We are dedicated to providing uncompromised medical treatment and ensuring our products enhance patient care.
                            </p>
                        </div>
                    </div>



                    <div class="col-md-4" style="margin-top: -115px;">
                        <div class="footer-center-links" style="text-align: left; padding-left: 15px; margin-left: 4px;">
                            <h4 style="font-family: 'Inter', sans-serif; font-size: 14px; font-weight: 600; margin-top: 18px; margin-right: 4px;">Our Company</h4>
                            <ul style="list-style: none; padding-left: 0;">
                                <li style="margin-bottom: 5px;"><a href="#" @click="navigateToContactUs" style="font-size: 14px; margin-left: 12px;">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-md-4" style="margin-top: 9px; text-align: right;">
                        <div class="footer-links">
                            <h4 style="font-family: Inter, sans-serif; font-size: 14px; color: rgba(69, 69, 69, 1); font-weight: 600; margin-bottom: 10px;margin-left:12px">Contact Us</h4>
                            <ul class="cont-us" style="list-style: none; padding-left: 0;">
                                <li style="margin-bottom: 5px;"><img src="../assets/img/fluent_call-24-filled.png" style="margin-right: 5px;" /><a href="tel:080-27831600" style="font-size: 14px;">080-2783 1600</a></li>
                                <li style="margin-bottom: 5px;"><img src="../assets/img/vaadin_phone-landline.png" style="margin-right: 5px;" /><a href="tel:9169338338" style="font-size: 14px;">+91 9169 338 338</a></li>
                                <li style="margin-bottom: 5px;"><img src="../assets/img/ic_round-mail.png" style="margin-right: 5px;" /><a href="mailto:info@amaryllishealthcare.com" style="font-size: 14px;">info@amaryllishealthcare.com</a></li>
                                <li class="social-media-icons">
                                    <a href="#"><img src="../assets/img/ic_round-facebook.png" style="margin-right: 5px;" /></a>
                                    <a href="#"><img src="../assets/img/entypo-social_linkedin-with-circle.png" style="margin-right: 5px;" /></a>
                                    <a href="#"><img src="../assets/img/ri_youtube-fill.png" style="margin-right: 5px;" /></a>
                                </li>
                            </ul>
                        </div>
                    </div>



                </div>
            </div>
        </div>

        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <p style="font-family: 'Inter', sans-serif; margin-left: 38px; margin-top: -13px;font-size:14px ">
                            &copy; {{ currentYear }} Amaryllis Healthcare Pvt. Ltd. All Rights Reserved
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "FooterApp",
        //props: {
        //    isFooter: {
        //        type: Function,
        //        required: true
        //    }
        //},
        data() {
            return {
                currentYear: ''
            }
        },
        mounted() {
            const date = new Date();
            this.currentYear = date.getFullYear().toString();
        },
        methods: {
            isFooter() {
                if (this.$route.path == '/contactUs' || this.$route.path == '/login' || this.$route.path == '/registerNew' || this.$route.path == '/forgotPassword'
                     || this.$route.path == '/' || this.$route.path == '/aboutUs') {
                    return false
                } else {
                    return true
                }
            },
            navigateToContactUs(event) {
                event.preventDefault();
                this.$router.push('/contactUs');
            }
        }
    };
</script>

<style scoped>
    #footer {
        color: rgba(69, 69, 69, 1);
        
    }

    .footer-top {
        background: rgba(168, 221, 244, 1);
        padding-bottom: 30px;
        height:220px
    }

    .container {
        width: 100%;
    
        margin: 0 auto;
        padding: 0 15px;
        box-sizing: border-box;
    }

    .footer-contact img {
        max-width: 100%;
        height: auto;
    }

    .footer-links {
        margin-bottom: 20px;
        font-family:Inter
    }

        .footer-links ul {
            list-style: none;
            padding-left: 0;
        }

            .footer-links ul li {
                margin-bottom: 10px;
            }

                .footer-links ul li a {
                    color: rgba(69, 69, 69, 1);
                    text-decoration: none;
                }

    .cont-us {
        list-style: none;
        padding-left: 0;
       
    }

        .cont-us li {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
        }

            .cont-us li i {
                margin-right: 10px;
                color: rgba(69, 69, 69, 1);
            }

    .footer-bottom {
        background: rgba(168, 221, 244, 1);
        padding: 10px 0;
        text-align: center;
    }

        .footer-bottom p {
            margin: 0;
            font-size: 14px;
        }

    /* Media queries for mobile view */
    @media (max-width: 767px) {
        .footer-contact,
        .footer-links {
            text-align: center;
        }

            .footer-contact img {
                margin: 0 auto 10px;
            }
    }



    .col-md-4 {
        display: flex;
        justify-content: center;
    }

    .footer-contact {
        margin-left: 75px;
        text-align: left;
        margin-top: 20px;
        font-family: 'Inter', sans-serif; /* Change font family to Inter */
    }

        .footer-contact img {
            margin-bottom: 10px;
            max-width: 100%; 
        }

        .footer-contact p {
            white-space: normal;
            font-family: 'Inter', sans-serif; /* Change font family to Inter */
        }



    .col-md-4 {
        display: flex;
        justify-content: center;
    }



    .footer-center-links {
        margin-left: 131px;
        text-align: center;
        margin-top: 17px;

    }

        .footer-center-links ul {
            list-style: none; 
            padding: 0;
        }

        .footer-center-links li {
            margin-bottom: 10px; 
        }

        .footer-center-links a {
            text-decoration: none;
            color: rgba(69, 69, 69, 1);
            font-family: 'Inter', sans-serif; /* Change font family to Inter */
        }



    .footer-links {
        margin-left: -10px;
        text-align: left;
       
    }

        .footer-links ul {
            list-style: none;
            padding: 0;
            text-align: right;
 
            margin-left: auto;
            margin-top: 6px;
        }

        .footer-links li {
            margin-bottom: 10px;
            text-align: right; 
        }

        .footer-links a {
            text-decoration: none;
            color: white;
            font-family: 'Inter', sans-serif; /* Change font family to Inter */
        }

        .footer-links i {
            margin-right: 5px;
        }


        .footer-links img {
            width: 18px; 
            height: 18px; 
            margin-left:10px;
            margin-right:10px
        }

    .right-align {
        text-align: right;
       
    }

    .social-media-icons {
    text-align: right;
}


   






</style>

