<template>
    <div class="container" >
        <header id="header" class="fixed-top">
            <nav>
                <div v-if="isNotLogedIn()" style="background-color: #a8ddf4;">
                    <div class="container">
                        <nav class="navbarbl">
                            <!--<div class="navbar-logobl container" style=" margin-left: 103px;">-->
                            <div class="navbar-logobl" style="margin: 0 auto; margin-left: -1.9%;">
                                <img src="../assets/img/logo.png" alt="Logo" />
                            </div>
                            <ul class="navbar-menubl" style="margin: 0 1%; margin-left: 0%;">
                                <!--<li><router-link to="/aboutUs" class="nav-link">About Us</router-link></li>-->
                                <li><router-link to="/contactUs" class="nav-link" style="white-space: nowrap;">Contact Us</router-link></li>
                            </ul>
                            <router-link to="/login" class="nav-link" style="padding: 5px 0 0 1%;">
                                <button class="btn" type="button" id="button-addon2" style="background-color: rgba(44, 169, 223, 1); color: #fff; margin-left: -36%; border-radius: 1px; margin-bottom: 5px; line-height: 21.2px;">Login</button>
                            </router-link>
                        </nav>
                    </div>
                </div>
                <div v-if="!isnotlogedin()" style="background-color: #a8ddf4;">
                    <div class="container">
                        <nav class="navbarQ" style="margin: 0 auto">
                            <!--<div class="navbar-logoQ" style="margin: 0 auto; margin-left: 253px;">
                                <img src="../assets/img/logo.png" alt="Logo" />
                            </div>-->
                            <div class="navbar-logoQ" style="margin: 0 auto; margin-left: 0.72%; cursor:pointer;" @click="navigateToHomeUser">
                                <img src="../assets/img/logo.png" alt="Logo" />
                            </div>
                            <ul class="navbar-menuQ" style="margin: 0 auto 0% 61.5%; ">
                                <li>
                                    <router-link to="/quickOrder">
                                        <button class="btnQuick" type="button" id="button-quick">Quick Order</button>
                                    </router-link>
                                </li>
                                <li>
                                    <a href="#">
                                        <span style="position: relative;">
                                            <i style="font-size: 26px; color: rgba(69, 69, 69, 1);margin-top:11px" class="fa" @click="notification = !notification">&#xf0f3;</i>

                                            <span class="badgeQ" style="position: absolute;top: -10px;right: -10px;background-color: rgba(233, 39, 41, 1);font-size: 10px; " @click="notification = !notification">{{ notificationCount }}</span>
                                        </span>
                                        <div class="notificationbox" v-show="notification" @mouseleave="notification = false">
                                            <ul class="list-group">
                                                <li class="list-group-item d-flex justify-content-between align-items-center" v-for="n in notilist" :key="n.NotiId">
                                                    {{ n.NotiMessage }}
                                                    <span class="badge badge-primary badge-pill btn btn-info" v-if="n.NotiReadStatus==0" @click="UpdateNotification(n,'ok')">OK</span>
                                                    <span class="badge badge-primary badge-pill btn btn-danger" v-if="n.NotiReadStatus!=0" @click="UpdateNotification(n,'hide')">Hide</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span style="position: relative">
                                            <i style="font-size: 27px; color: rgba(69, 69, 69, 1);margin-top:11px" class="fa">&#xf07a;</i>
                                            <span class="badgeQ" style="position: absolute;top: -10px;right: -10px;background-color: rgba(233, 39, 41, 1);font-size: 10px;" @click="navigateToKart">{{ kartCount }}</span>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div>
                        <section class="navigationMenu" style="margin-bottom: 20px; margin-top: 0px;margin:0 auto">
                            <div class="nav-containerMenu container">
                                <div class="brandName" style="margin: 0 auto; margin-left: 3.3%; width: 26%">
                                    <strong style="color: #fff; ">Welcome {{ CustomerName }}</strong>
                                </div>
                                <nav style="color: #fff;white-space:nowrap;margin:0 auto 0% 9%">
                                    <ul class="nav-list_local" style=" margin-left: 176px;">
                                        <li class="navbar-dropdown_local" style="color:#fff">
                                            <a style="cursor: pointer;" href="#!" @click="navigateToHomeUser">Home</a>
                                        </li>
                                        <li class="navbar-dropdown_local">
                                            <a style="cursor: pointer;" href="#!" @click="navigateToDashBoard">Dashboard</a>
                                        </li>
                                        <li class="navbar-dropdown_local" @mouseenter="openDropdown('Product')" @mouseleave="closeDropdowns('Product')">
                                            <a style="cursor: pointer; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" href="#!" @click="toggleMenu('Product')">
                                                <span>Products</span>
                                            </a>
                                            <ul class="hover-menu" :class="{ open: isProductMenuOpen }">
                                                <li v-for="(ig, index) in itemgroup" :key="index">
                                                    <a style="cursor: pointer;" href="#!"  @click="productClick(ig.ItemGroupId, ig.ItemGroupName)" @mouseenter="highlightItem" @mouseleave="unhighlightItem">{{ ig.ItemGroupName }}</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a style="cursor: pointer;" href="#!" @click="navigateToMyOrder">My Order</a>
                                        </li>
                                        <li class="navbar-dropdown_local" @mouseenter="openDropdown('Profile')" @mouseleave="closeDropdowns('Profile')">
                                            <a style="cursor: pointer; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" href="#!" @click="toggleMenu('Profile')">
                                                <span>My Profile</span>
                                            </a>
                                            <ul class="hover-menu" :class="{ open: isProfileMenuOpen }">
                                                <li>
                                                    <a style="cursor: pointer; background-color: #ffffff;" @mouseenter="highlightItem" @mouseleave="unhighlightItem" href="#!" @click="navigateToPayment">Payment Details</a>
                                                </li>
                                                <li>
                                                    <a style="cursor: pointer; background-color: #ffffff;" @mouseenter="highlightItem" @mouseleave="unhighlightItem" href="#!" @click="navigateToKyc">KYC Details</a>
                                                </li>
                                                <!--<li>
                                                    <a style="cursor: pointer; background-color: #ffffff;" @mouseenter="highlightItem" @mouseleave="unhighlightItem" @click="navigateToItemReturn">Item Return</a>
                                                </li>-->
                                                <li>
                                                    <a style="cursor: pointer; background-color: #ffffff;" @mouseenter="highlightItem" @mouseleave="unhighlightItem" href="#!" @click="navigateToChangePassword">Password Change</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="#!" style=" cursor: pointer;" @click="navigateToLogout">Logout</a>
                                            <!--<a href="#!" style="cursor: pointer;" @click="navigateToLogout">Logout</a>-->
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </section>
                    </div>
                </div>
            </nav>
        </header>
    </div>
</template>
<script>
    import axios from 'axios';
    import helper from '../helper'
    export default {
        name: 'HeaderApp',
        data() {
            return {
                isProductMenuOpen: false,
                isProfileMenuOpen: false,
                kartCount: 0,
                notificationCount: 0,
                notilist: [],
                notification: false,
                itemgroup: [],
                CustomerName: "",
                CustomerCode: ""
            }
        },
        props: {
            isNotLogedIn: {
                type: Function,
                required: true
            }
        },
        mounted: function () {
            window.setInterval(() => {
                this.GetKartCount();
                this.GetNotification();
            }, 2000);
            this.loadUserData();
            this.selectProductGroup();
        },
        created: function () {
            //this.GetNotification();
            //this.selectProductGroup();
        },
        beforeMount: async function () {
            //this.loadUserData();
        },
        methods: {
            navigateToKart(event) {
                event.preventDefault();
                this.$router.push('/productCart');
            },
            nav(p) {
                this.$router.push(p)
            },
            isquickorder() {
                if (this.$route.path == '/quickorder') {
                    return true
                }
            },
            isnotlogedin() {
                if (this.$route.path == '/contactUs' || this.$route.path == '/login' || this.$route.path == '/registerNew' || this.$route.path == '/forgotPassword'
                    || this.$route.path == '/home' || this.$route.path == '/' || this.$route.path == '/aboutUs') {
                    return true
                } else {
                    return false
                }
            },
            async UpdateNotification(n, type) {
                try {
                    var self = this;
                    var notirorh = 'R'
                    if (type == 'hide')
                        notirorh = 'H'
                    const requestBody = { NotiId: n.NotiId, NotiReadOrHide: notirorh, NotiReadStatus: '1', NotiHideStatus: '1' };
                    const response = await axios.post(helper.URL() + 'updatenotifications', requestBody);
                    const result = response.data;
                    if (result.Status == true) {
                        self.GetNotification()
                    }
                    else {
                        Swal.fire('Alert!', "Response not found!!!", 'error');
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            async GetNotification() {
                try {
                    var l = localStorage.getItem("userdata")
                    var self = this;
                    if (l != null && l != "") {
                        var userData = JSON.parse(l);
                        const requestBody = { NotiCustomerCode: userData.CustomerCode };
                        const response = await axios.post(helper.URL() + 'selectnotifications', requestBody);
                        const result = response.data;
                        self.notilist = [];
                        self.notificationCount = 0;
                        if (result.Status == true) {
                            if (result.Data && result.Data[0].length > 0) {
                                self.notificationCount = result.Data[0].length
                                result.Data[0].forEach(element => {
                                    self.notilist.push(element)
                                });
                                self.notilist.sort(function (a, b) {
                                    return a.NotiReadStatus - b.NotiReadStatus;
                                });
                            }
                        }
                        else {
                            Swal.fire('Alert!', "Response not found!!!", 'error');
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            GetKartCount() {
                var rval = 0
                var k = localStorage.getItem("Kart");
                var l = localStorage.getItem("userdata");
                if (l != "" && l !== null) {
                    var userData = JSON.parse(l);
                    if (userData.CustomerCode != "") {
                        this.CustomerName = userData.CustomerName || '';
                        if (k != "" && k !== null) {
                            var kobj = JSON.parse(k)
                            if (kobj.CustId == userData.CustomerCode) {
                                rval = kobj.items.length
                            }
                        }
                    }
                }
                this.kartCount = rval;
            },
            async selectProductGroup() {
                try {
                    var l = localStorage.getItem("userdata")
                    if (l != null && l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { custcode: userData.CustomerCode };
                        const response = await axios.post(helper.URL() + 'selectcustomerproductgroup', requestBody);
                        const result = response.data;
                        self.itemgroup = [];
                        const groupSize = 6;
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                for (let i = 0; i < result.Data[0].length; i += groupSize) {
                                    self.itemgroup.push(result.Data[0].slice(i, i + groupSize));
                                }
                                self.itemgroup = [{ ItemGroupId: 0, ItemGroupName: "All Products" }]
                                result.Data[0].forEach(element => {
                                    self.itemgroup.push(element)
                                });
                            }
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            closeDropdowns() {
                this.isProductMenuOpen = false;
                this.isProfileMenuOpen = false;
            },
            navigateToHomeUser(event) {
                event.preventDefault();
                this.$router.push('/homeUser');
            },
            navigateToDashBoard(event) {
                event.preventDefault();
                this.$router.push('/dashBoard');
                //this.$router.push('/homeUser');
            },
            navigateToMyOrder(event) {
                event.preventDefault();
                this.$router.push('/myOrder');
            },
            navigateToPayment(event) {
                event.preventDefault();
                this.$router.push('/paymentDetails');
            },
            navigateToKyc(event) {
                event.preventDefault();
                this.$router.push('/kycDetails');
            },
            navigateToItemReturn(event) {
                event.preventDefault();
                this.$router.push('/myReturn');
            },
            navigateToChangePassword(event) {
                event.preventDefault();
                this.$router.push('/changePassword');
            },
            openDropdown(menu) {
                this[menu + 'MenuOpen'] = true;
            },
            productClick(groupId, groupName) {
                this.$router.push({ name: 'productRouter', params: { groupId, groupName } });
                this.isProductMenuOpen = false;
            },
            highlightItem(event) {
                event.target.style.backgroundColor = '#007bff';
            },
            unhighlightItem(event) {
                event.target.style.backgroundColor = '#ffffff';
            },
            toggleMenu(menuType) {
                if (menuType == 'Product') {
                    this.isProductMenuOpen = !this.isProductMenuOpen;
                    this.isProfileMenuOpen = false;
                } else {
                    this.isProfileMenuOpen = !this.isProfileMenuOpen;
                    this.isProductMenuOpen = false;
                }
            },
            navigateToLogout(event) {
                event.preventDefault();
                localStorage.removeItem("userdata");
                localStorage.removeItem("Kart");
                localStorage.removeItem("RetKart");
                this.$router.push('/home');
            },
            loadUserData() {
                var l = localStorage.getItem("userdata")
                if (l != null && l != "") {
                    var userData = JSON.parse(l);
                    this.userdata = userData;
                    this.custPreviousDues = this.userdata.CustomerDue
                    this.CustomerName = this.userdata.CustomerName
                }
            }
        }
    };
</script>
<style scopped>
    /*  **************/
    .no-spinners {
        -moz-appearance: textfield; /* Firefox */
        appearance: textfield; /* Other modern browsers */
    }

        .no-spinners::-webkit-inner-spin-button,
        .no-spinners::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

    .nav-menu {
        display: flex;
        align-items: center;
        margin-left: auto;
    }

        .nav-menu li {
            margin-left: 20px;
        }

        .nav-menu a {
            color: #fff;
            text-decoration: none;
            font-weight: bold;
            font-size: 14px;
            padding: 10px;
            border-radius: 5px;
            transition: background-color 0.3s;
        }

            .nav-menu a:hover {
                background-color: rgba(255, 255, 255, 0.2);
            }




    .navigationMenu {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 50px;
        background-color: #2CA9DF;
    }

    .nav-containerMenu {
        /* max-width: 1000px;*/
        /*    margin: 0 auto;*/
        display: flex;
        align-items: center;
    }

    .brandName {
        font-weight: 600;
        font-size: 15px;
        margin-right: 50px;
    }

    .nav-list_local {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
    }

        .nav-list_local > li {
            margin-left: 18px;
        }

        /* .nav-list_local {
        margin-left: 238px
    }*/

        .nav-list_local a {
            text-decoration: none;
            color: #fff;
        }

    .navbar-dropdown_local {
        position: relative;
    }

        .navbar-dropdown_local .hover-menu {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 1;
            min-width: 160px;
            padding: 8px 0;
            margin: 0;
            list-style: none;
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 4px;
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
            color: #000;
        }

        .navbar-dropdown_local:hover .hover-menu {
            display: block;
        }

        .navbar-dropdown_local ul {
            display: none;
        }

            .navbar-dropdown_local ul.open {
                display: block;
            }

        .navbar-dropdown_local li a {
            display: block;
            padding: 8px 16px;
            color: #000;
            text-decoration: none;
            background-color: #fff;
            transition: background-color 0.3s ease;
        }

            .navbar-dropdown_local li a:hover {
                background-color: #2CA9DF;
                color: #fff;
            }

    .open .submenu {
        display: block;
    }

    .open {
        display: block !important;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .navbarbl {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #a8ddf4;
        padding: 33px;
        height: 10px;
        padding-top: 31px
    }
    /* Logo styles */
    .navbar-logobl {
        margin-right: auto;
        margin-bottom: 5px;
        margin-left: 68px
    }

        .navbar-logobl img {
            width: 150px;
            height: 50px;
            margin-left: 20px;
        }
    /* Menu styles */
    .navbar-menubl {
        list-style-type: none;
        display: flex;
        margin-left: auto;
        margin-top: 10px
    }

        .navbar-menubl li {
            margin-left: 10px;
        }

            .navbar-menubl li a {
                text-decoration: none;
                color: rgba(69, 69, 69, 1);
                transition: color 0.3s ease;
                font-weight: 400;
                font-family: Inter;
                padding: 10px 15px;
            }

                .navbar-menubl li a:hover {
                    color: #fff;
                }
    .navbar-logoQ img {
        width: 150px;
        height: 50px;
    }
    /* Menu styles */
    .navbar-menuQ {
        list-style-type: none;
        display: flex;
        margin-left: auto;
        margin-top: 10px;
    }

        /*   .navbar-menuQ li {
            margin-left: 10px;
        }*/

        .navbar-menuQ li a {
            text-decoration: none;
            color: rgba(69, 69, 69, 1);
            transition: color 0.3s ease;
            font-weight: 400;
            font-family: Inter;
            padding: 10px 15px;
        }

    .btnQuick:hover {
        background-color: rgba(39, 66, 210, 1);
        cursor: pointer;
    }
    /* Quick Order button styles */
    .btnQuick {
        background-color: rgba(44, 169, 223, 1);
        color: #fff;
        font-size: 14px;
        border: none;
        border-radius: 4px;
        padding: 10px;
        cursor: pointer;
        border: none;
    }

        .btnQuick:focus {
            outline: none
        }
    /* Badge styles */
    .badgeQ {
        position: absolute;
        top: -10px;
        right: -10px;
        background-color: rgba(233, 39, 41, 1);
        font-size: 10px;
        padding: 3px 6px;
        color: #fff;
        border-radius: 50%;
    }
    /* Responsive styles */
    @media (max-width: 768px) {
        .navbarQ {
            flex-direction: column;
            align-items: center;
            padding: 10px;
        }

        .navbar-logoQ {
            margin-bottom: 10px;
            margin-right: 0;
        }

            .navbar-logoQ img {
                margin-left: 0;
            }

        .navbar-menuQ {
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 10px;
            margin-right: 80px;
        }

            .navbar-menuQ li {
                margin-left: 10px;
                margin-right: 10px;
                margin-bottom: 5px;
            }
    }

    /*   *********newly added down**********/



    .navbarQ {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #a8ddf4;
        padding: 2.8%;
        height: 10px;
    }



    .brandName {
        transform: scale(0.9); /* Adjust the scaling factor as needed */
        transform-origin: left;
    }



    .navigation-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        max-width: 1200px; /* Set your desired maximum width */
    }
</style>
