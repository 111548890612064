<template>
    <div class="bg">
        <div class="logincontainer">
            <div class="loginbox" id="loginbox">
                <p class="logintext">Register as</p>
                <div class="form-group">
                    <div class="businessIndividual">
                        <button :class="{ 'btn-selected': CustomerTypeId === 'Business' }" @click="currentform='B1'; CustomerTypeId = 'Business'">Business</button>
                        <button :class="{ 'btn-selected': CustomerTypeId === 'Individual' }" @click="currentform='I1'; CustomerTypeId = 'Individual'">Individual</button>
                    </div>
                </div>
                <form action="" v-if="currentform=='B1'" class="plhc">
                    <div class="row">
                        <label for="email" class="nameTagOne">Name Of The Proprietor / Director <span class="text-danger"> *</span></label>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control forInput" placeholder="First Name" v-model="CustomerFirstName" maxlength="200" id="CustomerFirstName">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control forInput" placeholder="Last Name" v-model="CustomerLastName" maxlength="200" id="CustomerLastName">
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="email" class="nameTagOne">Name Of The Company<span class="text-danger"> *</span></label>
                        <input type="text" class="form-control forInput " placeholder="Company Name" v-model="CustomerCompanyName" maxlength="200" id="CustomerCompanyName">
                    </div>
                    <div class="form-group">
                        <label for="email" class="nameTagOne">Contact Number<span class="text-danger"> *</span></label>
                        <input type="number" class="form-control forInput " placeholder="Contact Number" v-model="CustomerMobileNo" id="CustomerMobileNo" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="10">
                    </div>
                    <div class="form-group">
                        <label for="email" class="nameTagOne">Email<span class="text-danger"> *</span></label>
                        <input type="email" class="form-control forInput" placeholder="Email" v-model="CustomerEmailId" maxlength="25" id="CustomerEmailId">
                    </div>
                    <button type="button" class="continueBtn" @click="currentform='B2'" style="margin-top: 40px; margin-left: 0px; text-align: center; font-size: 14px; border-radius: 10px; border: 1px solid #737373; background-color: #2CA9DF; color: #FFFFFF;">Continue</button>

                    <p class="newcust" style="text-align: center;margin-top:40px">
                        By creating an account or logging in, you agree to Amaryllis Healthcare&rsquo;s <a href="#" style="color: #2CA9DF">Conditions of Use</a> and <a href="#" style="color: #2CA9DF">Privacy Policy</a>. <span></span>
                    </p>
                    <p class="newcust-row" style="margin-top:10px">
                        Already have an account?

                        <router-link to="/login" class="nav-link" >
                            <a style="color: #2CA9DF"> Sign in</a>
                        </router-link>
                    </p>
                </form>

               
                
                <form action="" v-if="currentform=='B2'" class="plhc">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="email">Nature Of Current Business<span class="text-danger"> *</span></label>
                                <input type="text" class="form-control forInput" placeholder="Small Industry / Trading " v-model="CustomerNOB" maxlength="500" id="CustomerNOB">
                            </div>
                            <div class="form-group">
                                <label for="email">Focus Specialties<span class="text-danger"> *</span></label>
                                <input type="text" class="form-control forInput" placeholder="Ortho / Gynec / Cardiac/ CSSD / General Surgery " v-model="CustomerSpeciality" maxlength="200" id="CustomerSpeciality">
                            </div>
                            <div class="form-group">
                                <label for="email">List Of Companies And Products<span class="text-danger"> *</span> </label>
                                <input type="text" class="form-control forInput" placeholder="Product Brand Name" v-model="CustomerProductName" id="CustomerProductName">
                            </div>
                            <div class="form-group">
                                <label for="email">Top 10 Customers In Private Sector<span class="text-danger"> *</span> </label>
                                <input type="text" class="form-control forInput" placeholder="Narayana, Apollo, Goverment Hospitals" v-model="CustomerTopTenPrivate" id="CustomerTopTenPrivate">
                            </div>
                            <div class="form-group">
                                <label for="email">Top 10 Customers In Govt Sector</label>

                                <input type="text" class="form-control forInput" placeholder="Sarojini hospital Delhi" v-model="CustomerTopTenGovt" id="CustomerTopTenGovt">
                            </div>
                            <div class="col-md-12">
                                <div class="row" style="margin-top: 0px;">
                                    <div class="col-md-5" style="padding:0">
                                        <label for="email">Office Space Available<span class="text-danger"> *</span></label>
                                        <input type="text" class="form-control forInput" placeholder="1200ft" v-model="CustomerOfficeSpace" maxlength="200" id="CustomerOfficeSpace">
                                    </div>
                                    <div class="col-md-7" style="padding-right:0">
                                        <label for="email">Drug Licence No.<span class="text-danger"> *</span></label>
                                        <input type="text" class="form-control forInput" placeholder="" v-model="CustomerDrugLicenceNo" maxlength="200" id="CustomerDrugLicenceNo">
                                    </div>
                                </div>
                            </div>


                            <div class="form-group">
                                <label for="email" style="margin-top:10px">City Covered Currently<span class="text-danger"> *</span></label>
                                <input type="text" class="form-control forInput" placeholder="Bangalore, Chennai, Mumbai" v-model="CustomerCityCovered" id="CustomerCityCovered">
                            </div>
                        </div>


                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="row" style="margin-top: -81px;display: flex; flex-direction: column;padding-left:0">
                                    <label for="annualTurnOver" style="padding: 11px 5px; margin-right: 158px; padding-left: 0">Annual Turnover In Last 3 Year (In lakhs) <span class="text-danger"> *</span></label>
                                    <div style="display:flex;flex-direction:row;margin-top:-10px;padding:1px">

                                        <div class="col-md-4"><input type="text" class="form-control forInput" placeholder="Year 1" v-model="CustomerTurnoverYear1" id="y1"></div>
                                        <div class="col-md-4"><input type="text" class="form-control forInput" placeholder="Year 2" v-model="CustomerTurnoverYear2" id="y2"></div>
                                        <div class="col-md-4"><input type="text" class="form-control forInput" placeholder="Year 3" v-model="CustomerTurnoverYear3" id="y3"></div>


                                    </div>
                                </div>
                            </div>


                            <div class="form-group">
                                <label for="text">Other Information - Details Of Experience</label>
                                <input type="text" class="form-control forInput" placeholder="Govt. and Private Hospitals Distribution" v-model="CustomerExperience" id="CustomerExperience">
                            </div>
                            <div class="form-group">
                                <label for="email">GST No.<span class="text-danger"> *</span></label>
                                <input type="text" class="form-control forInput" placeholder="GST No." v-model="CustomerGstNo" maxlength="25" id="CustomerGstNo">
                            </div>
                            <div class="form-group">
                                <label for="email">PAN Card No.<span class="text-danger"> *</span></label>
                                <input type="text" class="form-control forInput" placeholder="PAN Card No." v-model="CustomerPanNo" maxlength="25" id="CustomerPanNo">
                            </div>
                            <div class="form-group">
                                <label for="email">Total Experience In This Business</label>
                                <input type="text" class="form-control forInput" placeholder="4 Years" v-model="CustomerTotalExp" maxlength="25" id="CustomerTotalExp">
                            </div>
                            <div class="form-group">
                                <label for="email">Contact Person Name</label>
                                <input type="text" class="form-control forInput" placeholder="Person Name" v-model="CustomerContactPersonName" maxlength="200" id="CustomerContactPersonName">
                            </div>
                        </div>
                    </div>

                    <br />


                    <div style="position:relative">
                        <div align="center">2/3</div>
                        <div align="center" style="position:absolute;top:-6px;left:2px;cursor:pointer"><i style="font-size:24px" class="fa" @click="currentform='B1'">&#xf104;</i></div>
                        <div align="center" style="position:absolute;top:-6px;right:2px;cursor:pointer"><i style="font-size:24px" class="fa" @click="currentform='B3'">&#xf105;</i></div>


                        <!--<button type="button" class="btn btn-gray btn-full" @click="currentform='B3'">Next</button>-->
                    </div>

                    <br />

                    <p class="newcust" style="text-align:center"> By creating an account or logging in, you agree to Amaryllis Healthcare's <a href="#" style="color: #2CA9DF;">Conditions Of Use</a> and <a href="#" style="color: #2CA9DF;">Privacy Policy</a>. <span></span></p>
                    <!--<br/>-->
                    <p class="newcust" style="text-align:center">Already have an account? &nbsp; <a href="#/login" style="color: #2CA9DF;text-decoration:underline"> Sign in</a></p>
                </form>
                <form action="" v-if="currentform=='B3'" class="plhc">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="email"><strong> Upload Document </strong></label>
                            </div>
                            <div class="form-group">
                                <label for="formFile" class="form-label">GST Certificate<span class="text-danger"> *</span></label>
                                <vue-base64-file-upload class="v1"
                                                        accept="image/png,image/jpeg,application/pdf"
                                                        image-class="v1-image"
                                                        :max-size="customImageMaxSize"
                                                        @size-exceeded="onSizeExceeded"
                                                        :disable-preview="true"
                                                        :placeholder="'Attach File'"
                                                        @load="onLoadGST"
                                                        v-model="CustomerGstImageData" />

                            </div>
                            <div class="form-group">
                                <label for="formFile" class="form-label">PAN Card - Of Company / Directors / Partners<span class="text-danger"> *</span></label>
                                <vue-base64-file-upload class="v1"
                                                        accept="image/png,image/jpeg,application/pdf"
                                                        image-class="v1-image"
                                                        :max-size="customImageMaxSize"
                                                        @size-exceeded="onSizeExceeded"
                                                        :disable-preview="true"
                                                        :placeholder="'Attach File'"
                                                        @load="onLoadPAN"
                                                        v-model="CustomerPanImageData" />
                            </div>
                            <div class="form-group">
                                <label for="formFile" class="form-label">Udhyam Aadhar / MSME<span class="text-danger"> *</span></label>
                                <vue-base64-file-upload class="v1"
                                                        accept="image/png,image/jpeg,application/pdf"
                                                        image-class="v1-image"
                                                        :max-size="customImageMaxSize"
                                                        @size-exceeded="onSizeExceeded"
                                                        :disable-preview="true"
                                                        :placeholder="'Attach File'"
                                                        @load="onLoadAadhar"
                                                        v-model="CustomerAadharImageData" />
                            </div>
                            <div class="form-group">
                                <label for="formFile" class="form-label">IT Returns For Last 3 Years<span class="text-danger"> *</span></label>
                                <vue-base64-file-upload class="v1"
                                                        accept="image/png,image/jpeg,application/pdf"
                                                        image-class="v1-image"
                                                        :max-size="customImageMaxSize"
                                                        @size-exceeded="onSizeExceeded"
                                                        :disable-preview="true"
                                                        :placeholder="'Attach File'"
                                                        @load="onLoadIT"
                                                        v-model="CustomerItReturnsImageData" />
                            </div>


                            <!--<button type="button" class="btn btn-gray btn-full" @click="currentform='B3',Submit()">{{loading}}</button>-->
                            <button type="button" class="continueBtn" @click="currentform='B3',Submit()" style="margin-top: 40px; margin-left: 0px; text-align: center; font-size: 14px; border-radius: 10px; color: #FFFFFF;background:#2CA9DF">Submit</button>
                        </div>

    

                        <div style="display: flex; align-items: center; justify-content: space-between; position: relative; width: 600px;margin-top:10px">
                            <div align="center" style="cursor:pointer">
                                <i style="font-size:24px" class="fa" @click="currentform='B2'">&#xf104;</i>
                            </div>
                            <div align="center">3/3</div>
                            <div align="center" style="cursor:pointer">
                                <i style="font-size:24px" class="fa" @click="currentform='B1'">&#xf105;</i>
                            </div>
                        </div>
                    </div>
                    <br />

                    <p class="newcust" style="text-align:center"> By creating an account or logging in, you agree to Amaryllis Healthcare's <a href="#" style="color: #2CA9DF;">Conditions Of Use</a> and <a href="#" style="color: #2CA9DF;">Privacy Policy</a>. <span></span></p>
                    
                  
                    <p class="newcust" style="text-align:center;margin-top:10px">Already have an account?<a href="#/login" style="color: #2CA9DF;text-decoration:underline"> Sign In</a></p>
                </form>

                <form action="" v-if="currentform=='I1'" class="plhc">

                    <div class="col-md-6">

                        <div class="col-md-6" style="padding-left:0">
                            <label for="FirstName">First Name <span class="text-danger"> *</span></label>
                            <input type="text" class="form-control forInput" placeholder="First Name" id="cb">

                        </div>
                        <div class="col-md-6" style="padding-right:0">
                            <label for="LastName">Last Name <span class="text-danger"> *</span></label>
                            <input type="text" class="form-control forInput" placeholder="Last Name" id="cb">
                        </div>


                        <div class="form-group">
                            <label for="email" style="margin-top:10px">Email <span class="text-danger"> *</span></label>
                            <input type="email" class="form-control forInput" placeholder="Email" id="cb">
                        </div>
                        <div class="form-group">
                            <label for="MobileNo">Mobile No. <span class="text-danger"> *</span></label>
                            <input type="number" class="form-control forInput" placeholder="Mob. No." id="cb" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="10">
                        </div>
                        <div class="form-group">
                            <label for="GSTNo">GST No.</label>
                            <input type="text" class="form-control forInput" placeholder="GST No." id="cb">
                        </div>
                        <div class="form-group dob">
                            <label for="DOB">DOB</label>
                            <input type="date" class="form-control forInput" placeholder="DOB" id="dob" name="dob" required>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="RegisterNumber">Register Number</label>
                            <input type="text" class="form-control forInput" placeholder="Reg No." id="cname">
                        </div>

                        <div class="form-group">
                            <label for="Specialty">Specialty</label>
                            <input type="text" class="form-control forInput" placeholder="Specialty" id="cname">
                        </div>
                        <div class="form-group">
                            <label for="Password">Password <span class="text-danger"> *</span></label>
                            <input type="text" class="form-control forInput" placeholder="Password" id="cname">
                        </div>
                        <div class="form-group">
                            <label for="ConfirmPassword">Confirm Password <span class="text-danger"> *</span></label>
                            <input type="text" class="form-control forInput" placeholder="Confirm Password" id="cname">
                        </div>

                        <div class="form-group" style="margin-top: 90px; display: flex; justify-content: flex-end; align-items: center; ">
                            <button type="button" class="submitButton" style=" background-color: #2CA9DF; color: white;" @click="currentform='',Submit()">{{loading}}</button>
                        </div>


                    </div>
                

                    <div style="text-align: center;">
                        <p class="newcust" style="margin-top: 40px;">
                            By creating an account or logging in, you agree to Amaryllis Healthcare&rsquo;s <a href="#" style="color: #2CA9DF">Conditions of Use</a> and <a href="#" style="color: #2CA9DF">Privacy Policy</a>. <span></span>
                        </p>
                        <p class="newcust-row" style="margin-top: 10px;">
                            Already have an account?
                            <router-link to="/login" class="nav-link">
                                <a style="color: #2CA9DF;text-decoration:underline"> Sign in</a>
                            </router-link>
                        </p>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import VueBase64FileUpload from 'vue-base64-file-upload';
    import axios from 'axios';
    import helper from '../helper'
    export default {
        name: 'registerNew',
        components: {
            VueBase64FileUpload
        },
        data() {
            return {
                title: 'registerNew',
                currentform: 'B1',
                loading: "Submit",
                Errors: "",
                CustomerCode: "",
                CustomerTypeId: "Business",
                CustomerFirstName: "",
                CustomerLastName: "",
                CustomerMobileNo: "",
                CustomerEmailId: "",
                CustomerPassword: "",
                CustomerGstNo: "",
                CustomerDOB: new Date().toISOString().slice(0, 10),
                CustomerRegisterNo: "",
                CustomerSpeciality: "",
                CustomerCompanyName: "",
                CustomerNOB: "",
                CustomerProductName: "",
                CustomerTopTenPrivate: "",
                CustomerTopTenGovt: "",
                CustomerOfficeSpace: "",
                CustomerDrugLicenceNo: "",
                CustomerCityCovered: "",
                CustomerTurnoverYear1: "",
                CustomerTurnoverYear2: "",
                CustomerTurnoverYear3: "",
                CustomerExperience: "",
                CustomerPanNo: "",
                CustomerTotalExp: "",
                CustomerContactPersonName: "",
                CustomerGstImageData: null,
                CustomerPanImageData: null,
                CustomerAadharImageData: null,
                CustomerItReturnsImageData: null,
                customImageMaxSize: 10,
                GstImageContentType: "",
                PanImageContentType: "",
                AadharImageContentType: "",
                ItImageContentType: "",
                CustomerGstImageType: "",
                CustomerPanImageType: "",
                CustomerAadharImageType: "",
                CustomerItReturnsImageType: ""
            }
        },
        watch: {
            currentform: function (newValue_str, oldValue_str) {
                if (newValue_str != oldValue_str) {
                    if (newValue_str == "B1" || newValue_str == "B3") {
                        document.getElementById("loginbox").style.width = "483px";
                    } else {
                        document.getElementById("loginbox").style.width = "983px";
                    }
                }
            }
        },
        created: function () {
            $(function () {
                $("#dob").datepicker({ dateFormat: 'dd/mm/yyyy', maxDate: new Date() });
                $('#dob').datepicker('setDate', 'today');
            });
        },
        methods: {
            getDocumentContentType(dataUri) {
                var extension;
                var contentType = "";
                var lowerCase = dataUri.toLowerCase();
                if (lowerCase.split(',')[0].indexOf("png") !== -1) extension = "png"
                else if (lowerCase.split(',')[0].indexOf("jpg") !== -1 || lowerCase.split(',')[0].indexOf("jpeg") !== -1)
                    extension = "jpg"
                else extension = "pdf";
                if (extension == "pdf") {
                    contentType = "application/" + extension;
                }
                else {
                    contentType = "image/" + extension;
                }
                return contentType;
            },
            onLoadGST(dataUri) {
                this.GstImageContentType = this.getDocumentContentType(dataUri);
                this.CustomerGstImageData = dataUri.split(',')[1];
                this.CustomerGstImageType = dataUri.split(',')[0];
            },
            onLoadPAN(dataUri) {
                this.PanImageContentType = this.getDocumentContentType(dataUri);
                this.CustomerPanImageData = dataUri.split(',')[1];
                this.CustomerPanImageType = dataUri.split(',')[0];
            },
            onLoadAadhar(dataUri) {
                this.AadharImageContentType = this.getDocumentContentType(dataUri);
                this.CustomerAadharImageData = dataUri.split(',')[1];
                this.CustomerAadharImageType = dataUri.split(',')[0];
            },
            onLoadIT(dataUri) {
                this.ItImageContentType = this.getDocumentContentType(dataUri);
                this.CustomerItReturnsImageData = dataUri.split(',')[1];
                this.CustomerItReturnsImageType = dataUri.split(',')[0];
            },
            onSizeExceeded(size) {
                alert(`File ${size}Mb size exceeds limits of ${this.customImageMaxSize}Mb!`);
            },
            async Submit() {
                try {
                    this.Errors = ""
                    if (this.CustomerGstImageData == null || this.CustomerPanImageData == null || this.CustomerAadharImageData == null || this.CustomerItReturnsImageData == null) {
                        Swal.fire('Alert!', "All documents should be attached", 'error');
                        return false;
                    }
                    var self = this
                    var l = localStorage.getItem("userdata")
                    var udata = ""
                    if (l != "") {
                        udata = JSON.parse(l)
                    }
                    var TypeId = 0;
                    if (this.CustomerTypeId == "Business") {
                        TypeId = 2;
                    }
                    else {
                        TypeId = 3;
                    }
                    //Swal.fire('Info!', this.CustomerGstImageData, 'success');
                    var _DOB = this.formatedateSD(this.CustomerDOB)

                    const requestBody = {
                        CustomerCode: "", CustomerTypeId: TypeId, CustomerFirstName: this.CustomerFirstName,
                        CustomerLastName: this.CustomerLastName, CustomerMobileNo: this.CustomerMobileNo, CustomerEmailId: this.CustomerEmailId,
                        CustomerPassword: this.CustomerPassword, CustomerGstNo: this.CustomerGstNo, CustomerDOB: _DOB, CustomerRegisterNo: this.CustomerRegisterNo,
                        CustomerSpeciality: this.CustomerSpeciality, CustomerCompanyName: this.CustomerCompanyName, CustomerNOB: this.CustomerNOB, CustomerProductName: this.CustomerProductName,
                        CustomerTopTenPrivate: this.CustomerTopTenPrivate, CustomerTopTenGovt: this.CustomerTopTenGovt, CustomerOfficeSpace: this.CustomerOfficeSpace, CustomerDrugLicenceNo: this.CustomerDrugLicenceNo,
                        CustomerCityCovered: this.CustomerCityCovered, CustomerTurnoverYear1: this.CustomerTurnoverYear1, CustomerTurnoverYear2: this.CustomerTurnoverYear2, CustomerTurnoverYear3: this.CustomerTurnoverYear3,
                        CustomerExperience: this.CustomerExperience, CustomerPanNo: this.CustomerPanNo, CustomerTotalExp: this.CustomerTotalExp, CustomerContactPersonName: this.CustomerContactPersonName,
                        CustomerGstImageData: this.CustomerGstImageData, CustomerPanImageData: this.CustomerPanImageData, CustomerAadharImageData: this.CustomerAadharImageData, CustomerItReturnsImageData: this.CustomerItReturnsImageData,
                        GstImageContentType: this.GstImageContentType, PanImageContentType: this.PanImageContentType, AadharImageContentType: this.AadharImageContentType, ItImageContentType: this.ItImageContentType,
                        CustomerGstImageType: this.CustomerGstImageType, CustomerPanImageType: this.CustomerPanImageType, CustomerAadharImageType: this.CustomerAadharImageType, CustomerItReturnsImageType: this.CustomerItReturnsImageType,
                        CustomerGstImage: this.CustomerGstImageData, CustomerPanImage: this.CustomerPanImageData, CustomerAadharImage: this.CustomerAadharImageData, CustomerItReturnsImage: this.CustomerItReturnsImageData
                    }
                    const response = await axios.post(helper.URL() + 'inserttmpcustomermaster', requestBody);
                    const result = response.data;
                    if (result.CustomerCode != "") {
                        if (response.data.CustomerCode != "") {
                            this.CustomerCode = ""
                            this.CustomerTypeId = "Business"
                            this.currentform = "B1"
                            this.CustomerFirstName = ""
                            this.CustomerLastName = ""
                            this.CustomerMobileNo = ""
                            this.CustomerEmailId = ""
                            this.CustomerPassword = ""
                            this.CustomerGstNo = ""
                            this.CustomerDOB = new Date().toISOString().slice(0, 10)
                            this.CustomerRegisterNo = ""
                            this.CustomerSpeciality = ""
                            this.CustomerCompanyName = ""
                            this.CustomerNOB = ""
                            this.CustomerProductName = ""
                            this.CustomerTopTenPrivate = ""
                            this.CustomerTopTenGovt = ""
                            this.CustomerOfficeSpace = ""
                            this.CustomerDrugLicenceNo = ""
                            this.CustomerCityCovered = ""
                            this.CustomerTurnoverYear1 = 0
                            this.CustomerTurnoverYear2 = 0
                            this.CustomerTurnoverYear3 = 0
                            this.CustomerExperience = ""
                            this.CustomerPanNo = ""
                            this.CustomerTotalExp = ""
                            this.CustomerContactPersonName = ""
                            this.CustomerGstImageData = null
                            this.CustomerPanImageData = null
                            this.CustomerAadharImageData = null
                            this.CustomerItReturnsImageData = null
                            this.GstImageContentType = ""
                            this.PanImageContentType = ""
                            this.AadharImageContentType = ""
                            this.ItImageContentType = ""
                            this.CustomerGstImageType = ""
                            this.CustomerPanImageType = ""
                            this.CustomerAadharImageType = ""
                            this.CustomerItReturnsImageType = ""
                            Swal.fire('Info!', "Saved Successfully!!!", 'success');
                        } else {
                            Swal.fire('Info!', "Submit Failed!!!", 'failed');
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            formatedateSD(dateString) {
                const d = dateString.split("/");
                const nd = new Date(d[2] + '/' + d[1] + '/' + d[0]);
                return nd.getFullYear() + "-" + ("0" + (nd.getMonth() + 1)).slice(-2) + "-" + ("0" + nd.getDate()).slice(-2)
            }
        }
    }
</script>

<style scoped>
    .fas, 
    .fa-arrow-left:before {
        content: "\f060";
    }
    .fa-arrow-right:before {
        content: "\f061";
    }

    .newcust {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #111111;
    }

   /* .logincontainer {
        width: 100%;
        height: 100%;
        background: #FFFFFF;
    }*/

    .loginbox {
        font-size: 14px;
        margin: 120px auto;
        width: 483px;
        padding: 40px;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.7);
        border-radius: 16px;
        background-color: rgba(255, 255, 255, 0.8);
    }

    .logintext {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 21px;
        line-height: 38px;
        color: #454545;
    }

    .contact form .form-control {
        margin-bottom: 1em;
    }

    .contact form textarea {
        min-height: 20em;
    }
    /* updated styles */
    .plhc ::placeholder {
        color: rgb(187, 184, 184);
        font-weight: 300;
        opacity: 1;
    }

    .plhc input[type=date] {
        color: rgb(187, 184, 184);
        font-weight: 300;
        opacity: 1;
    }

    .arws i {
        color: gray;
        cursor: pointer;
    }

    .dob input {
        position: relative;
    }

        .dob input[type="date"]::-webkit-calendar-picker-indicator {
            background-position: right;
            background-size: auto;
            cursor: pointer;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 8px;
            width: auto;
        }

    input[type=date]:valid::-webkit-datetime-edit {
        color: #212529;
    }




    .form-group .businessIndividual {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;
    }

    button {
        padding:5px 15px;
        border: none;
        margin: 5px;
        cursor: pointer;
        gap:20px
    }

    .btn-selected {
        background-color: #2CA9DF;
        color: white;
        margin-top: -75px;
        font-size:12px;
        margin-right:10px;
        border-radius:4px;
    }

    button:not(.btn-selected) {
        background-color: #fff;
        border: 1px solid #AFAFAF;
        color: #454545;
        margin-top: -75px;
        font-size: 12px;
        margin-left: 10px;
        border-radius: 4px;
    }


    button:focus {
        outline: none;
    }

 

    .nameTagOne {
        margin-right: 200px;
        font-weight: normal;
        color: #454545;
    }




    .form-control.forInput {
        border-radius: 5px;
        color: #737373;
        padding: 6px 12px;
        border: 1px solid #454545;
     /*   height:36px*/
     height:32px
    }


    .continueBtn {
        transition: background-color 0.2s, color 0.2s, border-color 0.2s;
        width: 402px;
        background: #fff;
        padding: 12px 20px;
        font-size: 16px;
        padding: 5px 5px;
        border-radius: 10px;
        background-color: #2CA9DF;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
        width: 400px;
        height: 36px;
    }

       

        .continueBtn:focus {
            outline: none;
            margin-left: 0px;
            text-align: center;
            border-radius: 10px;
        }






    .newcust {
        text-align: center;
        font-family: 'Inter';
        font-size: 14px;
        color: #454545;
    }


    .newcust-row {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #454545;
        font-family: 'Inter';
    }


    .bg {
        width: 100%;
        height: 100%;
        background-color: #f2f2f2;
        background: url("../assets/img/loginbg.png") top center;
        background-size: cover;
        background-position: center;
        padding: 0;
        overflow: hidden;
        
    }

  label{
      font-weight:normal
  }


    /*.button-rowdown {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    .button-wrapperdown {
        display: flex;
        align-items: center;
    }

        .button-wrapperdown > div {
            margin-right: 10px;*/ /* Add some spacing between the arrows and the button */
        /*}

        .button-wrapperdown > .btn {
            flex: 0;
        }*/


    .button-rowdown {
        display: flex; 
        justify-content: space-between; 
    }

    .column {
        flex-basis: 50%; 
    }

    .form-group {
         margin-bottom: 10px; 
    }


   .submitButton {
       font-size: 14px;
       padding: 5px 15px;
       background-color: #2CA9DF; 
       color: white;
       border-radius:4px;
       border:none
    }

 .submitButton:focus{
        outline:none
    }
   
</style>