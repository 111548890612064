<template>
    <div class="bg">
        <div class="logincontainer"  style="margin:0 auto" >
            <div class="container">
                <div class="navigationTop" style="margin-top:120px;margin-bottom:0px;margin-left:35px;margin-bottom:30px">
                    <a href="#" @click="navigateToHomeUser">Home</a>
                    <span>&gt;</span>
                    <a href="#">Change Password</a>
                </div>
            </div>
           
            <div class="loginbox" style="margin-top: 30px; margin-bottom: 140px">
                <p class="ftext" style="font-family: Inter; color: #454545; font-size: 14px;">Change Password</p>
                <form action="/action_page.php">
                    <div class="form-group">
                        <input type="password" @keyup.enter.native="ChangePassword" v-model="CurrentPassword" style="height: 48px; font-family: Inter; color: #454545; font-size: 14px;" class="form-control" placeholder="Current Password" id="CurrentPassword">
                    </div>
                    <p></p>
                    <div class="form-group">
                        <input type="password" @keyup.enter.native="ChangePassword" v-model="NewPassword" style="height: 48px; font-family: Inter; color: #454545; font-size: 14px;" class="form-control" placeholder="New Password" id="NewPassword">
                    </div>
                    <p></p>
                    <div class="form-group">
                        <input type="password" @keyup.enter.native="ChangePassword" v-model="ConfirmPassword" style="height: 48px; font-family: Inter; color: #454545; font-size: 14px;" class="form-control" placeholder="Confirm Password" id="ConfirmPassword">
                    </div>
                    <p class="text-danger" style="text-align: center; font-family: Inter; color: #454545; font-size: 14px;">{{LoginErr}}</p>
                    <button type="button" class="btn btn-gray btn-full" @click="ChangePassword" style="font-family: Inter; color: #fff; font-size: 14px;outline:none;background: #2CA9DF">Submit</button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
    import helper from '../helper'
    export default {
        name: 'home',
        data() {
            return {
                title: 'contact',
                CurrentPassword: "",
                NewPassword: "",
                ConfirmPassword: "",
                LoginErr: "",
                CustomerCode: "",
                CurPwd: "",
                NewPwd: ""
            }
        },
        created() {

        },
        methods: {
            navigateToHomeUser(event) {
                event.preventDefault();
                this.$router.push('/homeUser');
            },
            async ChangePassword() {
                if (this.CurrentPassword == "") {
                    this.LoginErr = "Current Password Can't be empty!!!"
                    return false
                }
                if (this.NewPassword == "") {
                    this.LoginErr = "New Password Can't be empty!!!"
                    return false
                }
                if (this.ConfirmPassword == "") {
                    this.LoginErr = "Confirm Password Can't be empty!!!"
                    return false
                }
                if (this.ConfirmPassword != this.NewPassword) {
                    this.LoginErr = "Confirm Password is mismatched!!!"
                    return false
                }
                var l = localStorage.getItem("userdata")
                if (l != "" && l !== null) {
                    var self = this;
                    var userData = JSON.parse(l);
                    self.CustomerCode = userData.CustomerCode;
                    var requestBody = { username: self.CustomerCode, password: self.CurrentPassword }
                    var response = await axios.post(helper.URL() + 'getauth', requestBody);
                    var result = JSON.stringify(response.data);
                    if (result != "") {
                        self.CurPwd = result;
                        const requestBody = { username: self.CustomerCode, password: self.NewPassword }
                        const response = await axios.post(helper.URL() + 'getauth', requestBody);
                        result = JSON.stringify(response.data);
                        if (result != "") {
                            self.NewPwd = result;
                            const requestBody = { CustomerCode: self.CustomerCode, CurrentPassword: self.CurPwd, NewPassword: self.NewPwd }
                            const response = await axios.post(helper.URL() + 'changepassword', requestBody);
                            result = response.data;
                            if (result.length <= 0) {
                            }
                            else {
                                localStorage.removeItem("userdata");
                                self.$router.push("/login")
                                Swal.fire('Info', "Password changed successfully!!!", '');
                            }
                        } else {
                            self.LoginErr = "New Password Response not found!!!"
                        }
                    } else {
                        self.LoginErr = "Current Password Response not found!!!"
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .newcust {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #111111;
    }

    .logincontainer {
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        margin-bottom: -90px
    }

    .loginbox {
        font-size: 14px;
        margin: 120px auto;
        width: 483px;
        padding: 40px;
        background: #FFFFFF;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
    }

    .ftext {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 38px;
        color: #000000;
    }

    .contact form .form-control {
        margin-bottom: 1em;
    }

    .contact form textarea {
        min-height: 20em;
    }

    .navigationTop {
        display: flex;
        align-items: center;
        padding: 10px;
        font-family: Inter;
        font-size: 12px
    }

        .navigationTop a {
            text-decoration: none;
            color: #454545;
            font-family: Inter;
            font-size: 12px
        }

            .navigationTop a:hover {
                color: #2ca9df;
                font-family: Inter;
                font-size: 12px
            }

        .navigationTop span {
            margin: 0 5px;
            color: #454545;
            font-family: Inter;
            font-size: 12px
        }



</style>
