<template>
    <div>
        <div style="background: #F0F0F5;">
            <Transition name="modal">
                <div v-if="show" class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-container">
                            <div class="modal-header" style="text-align: center !important">
                                {{ ImageHeading }}
                            </div>
                            <div v-if="showimage" class="modal-body">
                                <slot name="bodyimage">
                                    <img :src=ImageBase64 style="width:450px;height:350px">
                                </slot>
                            </div>
                            <div v-if="showpdf" class="modal-body">
                                <slot name="bodypdf">
                                    <iframe :src=ImageBase64 style="width:450px;height:350px"></iframe>
                                </slot>
                            </div>
                            <div class="modal-footer" style="vertical-align:middle">
                                <slot name="footer">
                                    <button class="modal-default-button"
                                            @click="close()">
                                        Close
                                    </button>
                                </slot>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
            <div v-if="loadingKyc" class="loading" width="280">
            </div>
            <div v-else>
                <div class="row font" style="margin-bottom:-2rem ; margin-right: 1rem; margin-top: 80px;display:flex;flex-direction:column">
                    <div class="navigationTop" style=" padding-left: 5px; margin-right: 859px; padding-bottom: 0px; margin-left: -61px;margin-top:10px">
                      <a href="#" style="text-decoration: none;" @click="navigateToHomeUser">Home</a>
                      <span style="margin: 0 5px;">&gt;</span>
                      <a href="#" style="text-decoration: none;">Kyc</a>
                    </div>

                    <div style="text-align: center; padding-top: 10px; margin-bottom:10px;">
                        <h4 class="card-title" style="color:black">KYC</h4>
                    </div>


                    <div class="container" style="width: 100%; margin: 0 auto;margin-bottom:30px;margin-top:-20px">
                        <div class="row justify-content-center" style="margin-left:2px">
                            <div class="col-md-12">
                                <div class="card" id="kycInput" style="width: 1028px; margin-left: 40px;margin-bottom:40px; border: 1px solid #eee; box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;border-radius:5px ">
                                    <div class="card-body py-md-4" style="margin-top: -9px; background: #fff;border-radius:5px">
                                        <form _lpchecked="1" @submit.prevent="submitForm">

                                            <div class="row" >
                                                <div class="col-md-3">
                                                    <div class="form-group d-flex flex-wrap align-items-center">
                                                        <label for="firstname" class="col-form-label pr-3" style="margin-left: 0rem; margin-right: -1rem; white-space: nowrap">Name Of The Proprietor / Director</label>
                                                        <input type="text"
                                                               class="form-control"
                                                               id="firstname"
                                                               v-model.trim="CustomerFirstName"
                                                               placeholder=""
                                                               @blur="ValidateInput" />
                                                        <p v-if="inputValidity === 'invalid'">
                                                            Field Cannot Be Empty.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group d-flex flex-wrap align-items-center">
                                                        <label for="secondname" class="col-form-label pr-3 mb-4"></label>
                                                        <input type="text"
                                                               style="margin-top:8px"
                                                               class="form-control"
                                                               id="CustomerLastName"
                                                               v-model="CustomerLastName"
                                                               placeholder="" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group d-flex flex-wrap align-items-center">
                                                        <label for="gstnum" class="col-form-label pr-3">GST No.</label>
                                                        <input type="text"
                                                               class="form-control"
                                                               id="CustomerGstNo"
                                                               v-model="CustomerGstNo"
                                                               placeholder="" />
                                                    </div>
                                                </div>
                                            </div>







                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="form-group d-flex flex-wrap">
                                                            <label for="companyname" class="col-form-label pr-3">Name Of The Company</label>
                                                        </div>
                                                        <input type="text"
                                                               class="form-control"
                                                               id="companyname"
                                                               v-model="CustomerCompanyName"
                                                               placeholder="" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="form-group d-flex flex-wrap">
                                                            <label for="pancard" class="col-form-label pr-3">PAN Card No.</label>
                                                        </div>
                                                        <input type="text"
                                                               class="form-control"
                                                               id="pancard"
                                                               v-model="CustomerPanNo"
                                                               placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="form-group d-flex flex-wrap">
                                                            <label for="natureofbusiness" class="col-form-label pr-3">Nature Of Current Business</label>
                                                        </div>
                                                        <input type="text"
                                                               class="form-control"
                                                               id="natureofbusiness"
                                                               v-model="CustomerNOB"
                                                               placeholder=" Small Industry / Trading " />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="form-group d-flex flex-wrap">
                                                            <label for="totalexperience" class="col-form-label pr-3">Total Experience In This Business</label>
                                                        </div>
                                                        <input type="text"
                                                               class="form-control"
                                                               id="totalexperience"
                                                               v-model="CustomerTotalExp"
                                                               placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="form-group d-flex flex-wrap">
                                                            <label for="officespace" class="col-form-label pr-3">Office Space Available</label>
                                                        </div>
                                                        <input type="text"
                                                               class="form-control"
                                                               id="officespace"
                                                               v-model="CustomerOfficeSpace"
                                                               placeholder="" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div>
                                                            <label for="annualturnover1" class="col-form-label pr-4">Annual Turnover Last 3 years (In Lakhs)</label>
                                                        </div>
                                                        <!--<div class="row" style="align-items: flex-start; justify-content: flex-start;">
                                            <div class="col-md-2">
                                                <input type="number"
                                                       style="margin:5px;width:125px"
                                                       class="form-control"
                                                       id="annualturnover1"
                                                       v-model="CustomerTurnoverYear1"
                                                       placeholder="Year 1" />
                                            </div>
                                            <div class="col-md-2">
                                                <input type="number"
                                                       style="margin:5px; width: 120px"
                                                       class="form-control"
                                                       id="annualturnover2"
                                                       v-model="CustomerTurnoverYear2"
                                                       placeholder="Year 2" />
                                            </div>
                                            <div class="col-md-2">
                                                <input type="number"
                                                       style="margin:5px; width:120px"
                                                       class="form-control"
                                                       id="annualturnover3"
                                                       v-model="CustomerTurnoverYear3"
                                                       placeholder="Year 3" />
                                            </div>
                                        </div>-->

                                                        <div class="row" style="align-items: flex-start; justify-content: flex-start;">
                                                            <div class="col-md-3" style="margin: 0 5px 0 5px;">
                                                                <input type="number"
                                                                       class="form-control"
                                                                       id="annualturnover1"
                                                                       v-model="CustomerTurnoverYear1"
                                                                       placeholder="Year 1" />
                                                            </div>
                                                            <div class="col-md-3" style="margin: 0 5px 0 5px;">
                                                                <input type="number"
                                                                       class="form-control"
                                                                       id="annualturnover2"
                                                                       v-model="CustomerTurnoverYear2"
                                                                       placeholder="Year 2" />
                                                            </div>
                                                            <div class="col-md-3" style="margin: 0 5px 0 5px;">
                                                                <input type="number"
                                                                       class="form-control"
                                                                       id="annualturnover3"
                                                                       v-model="CustomerTurnoverYear3"
                                                                       placeholder="Year 3" />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="form-group d-flex flex-wrap">
                                                            <label for="focusspecialities" class="col-form-label pr-3">Focus Specialties</label>
                                                        </div>
                                                        <input type="text"
                                                               class="form-control"
                                                               id="focusspecialities"
                                                               v-model="CustomerSpeciality"
                                                               placeholder="Ortho / Gynec / Cardiac / CSSD / General Surgery" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="form-group d-flex flex-wrap">
                                                            <label for="listofcompany" class="col-form-label pr-3">List Of Companies And Products</label>
                                                        </div>
                                                        <input type="text"
                                                               class="form-control"
                                                               id="listofcompany"
                                                               v-model="CustomerProductName"
                                                               placeholder="Product Brand Name" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="form-group d-flex flex-wrap">
                                                            <label for="topcustomer" class="col-form-label pr-3">
                                                                Top 10 Customers In Private Sector
                                                            </label>
                                                        </div>
                                                        <input type="text"
                                                               class="form-control"
                                                               id="topcustomer"
                                                               v-model="CustomerTopTenPrivate"
                                                               placeholder="Narayana, Apollo, Goverment Hospitals" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="form-group d-flex flex-wrap">
                                                            <label for="topcustomergov" class="col-form-label pr-3">Top 10 Customers In Govt Sector</label>
                                                        </div>
                                                        <input type="text"
                                                               class="form-control"
                                                               id="topcustomergov"
                                                               v-model="CustomerTopTenGovt"
                                                               placeholder="Sarojini Hospital Delhi" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="form-group d-flex flex-wrap">
                                                            <label for="citycoveredcurrently" class="col-form-label pr-3">City Covered Currently</label>
                                                        </div>
                                                        <input type="text"
                                                               class="form-control"
                                                               id="citycoveredcurrently"
                                                               v-model="CustomerCityCovered"
                                                               placeholder="Bangalore, Chennai, Mumbai" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="form-group d-flex flex-wrap">
                                                            <label for="detailsofexperience" class="col-form-label pr-3" style="width:305px">Other Information - Details Of Experience</label>
                                                        </div>
                                                        <input type="text"
                                                               class="form-control"
                                                               id="detailsofexperience"
                                                               v-model="CustomerExperience"
                                                               placeholder="Govt. And Private Hospitals Distribution" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <div class="form-group d-flex flex-wrap">
                                                            <label for="gstcertificate" class="col-form-label pr-3">GST Certificate</label>
                                                        </div>
                                                        <input type="text"
                                                               class="form-control"
                                                               id="gstcertificate"
                                                               onMouseOver="this.style.cursor='Pointer'"
                                                               @click="DisplayImage(CustomerGstImage,'GST Certificate')"
                                                               placeholder="Click To View" />
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <div class="form-group d-flex flex-wrap">
                                                            <label for="companypancard" class="col-form-label pr-3">Company Pan Card</label>
                                                        </div>
                                                        <input type="text"
                                                               class="form-control"
                                                               id="companypancard"
                                                               onMouseOver="this.style.cursor='Pointer'"
                                                               @click="DisplayImage(CustomerPanImage,'Company Pan Card')"
                                                               placeholder="Click To View" />
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <div class="form-group d-flex flex-wrap">
                                                            <label for="udhayamaadhar" class="col-form-label pr-3">Udhyam Aadhar / MSME </label>
                                                        </div>
                                                        <input type="text"
                                                               class="form-control"
                                                               id="udhayamaadhar"
                                                               onMouseOver="this.style.cursor='Pointer'"
                                                               @click="DisplayImage(CustomerAadharImage,'Udhyam Aadhar/MSME')"
                                                               placeholder="Click To View" />
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <div class="form-group d-flex flex-wrap">
                                                            <label for="itreturns" class="col-form-label pr-3">IT Returns Of Last 3 Years</label>
                                                        </div>
                                                        <input type="text"
                                                               class="form-control"
                                                               id="itreturns"
                                                               onMouseOver="this.style.cursor='Pointer'"
                                                               @click="DisplayImage(CustomerItReturnsImage,'IT Returns Of Last 3 Years')"
                                                               placeholder="Click To View" />
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="row" style="display:flex;">
                                                <div class="col-md-12">
                                                    <span style="font-family:Inter;font-size:14px;color:#454545">Address</span>
                                                </div>
                                                <div class="col-md-4 " v-for="(c,index) in custAddressList" :key="index">

                                                    <div class="addressDiv" style="font-family:Inter;font-size:14px;color:#454545">

                                                        <div class="addressHeading">
                                                            <span style="font-family:Inter;font-size:14px;color:#454545">{{ c.AddressCaption}}</span>
                                                            <h6 class="addressName" style="font-family:Inter;font-size:14px;color:#454545">
                                                                {{ CustomerFirstName}} {{CustomerLastName}}
                                                            </h6>
                                                            <p class="addressParagraph" style="font-family:Inter;font-size:14px;color:#454545">
                                                                {{c.AddressDetails}}
                                                                Phone number:{{ CustomerMobileNo }}
                                                            </p>
                                                            <span class="tableBottom" style="font-family:Inter;font-size:14px;color:#454545">
                                                                <a href="#" style="color:#00AEEF">Request To Edit</a>
                                                            </span>
                                                            <span class="tableBottom" style="color: black;">
                                                                |
                                                            </span>
                                                            <span class="tableBottom">
                                                                <a href="#" style="font-family: Inter; font-size: 14px; color: #00AEEf">Remove</a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>


                                                <!--<div class="col-md-4">
                                    <div class="addressDiv">
                                        <div class="addressHeading" style="margin-top: 10px; text-align: center;">
                                            <div class="plusSign" style="margin-right: auto; margin-left: auto;">
                                                <a style="cursor: pointer; color: #454545; font-size: 32px;" @click="navigateToAddAddress">+</a>
                                            </div>
                                            <div class="addAddress" style="display: inline; margin-top: 5px;margin-right: 34px;">
                                                <a style="cursor: pointer; color: #454545; font-size: 16px;" @click="navigateToAddAddress">Add Address</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>-->
                                                <div class="col-md-4">
                                                    <div class="addressDiv">
                                                        <div class="addressHeading" style="margin-top: 20px; text-align: center;">
                                                            <div class="plusSign" style="margin-right: auto; margin-left: auto;">
                                                                <a style="cursor: pointer; color: #454545; font-size: 32px;" @click="navigateToAddAddress">+</a>
                                                            </div>
                                                            <div class="addAddress" style="display: inline; margin-top: 5px; margin-right: 34px;">
                                                                <a style="cursor: pointer; color: #454545; font-size: 16px;" @click="navigateToAddAddress">Add Address</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>


                                            <!--<div class="d-flex flex-row align-items-center justify-content-between">
                                <button class="btn btn-primary">Save Changes</button>
                            </div>-->

                                            <div class="d-flex flex-row align-items-center justify-content-center" style="margin:10px">
                                                <button class="btn btn-primary" style="width: 150px; height: 40px; font-family: Inter; color: #fff; background-color: #2CA9DF; outline: none; border: 1px solid #2CA9DF">Save Changes</button>
                                            </div>




                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>







                </div>
            </div>
        </div>
        <br />
    </div>
</template>

<script>
    import helper from "../helper";
    import axios from "axios";
    export default {
        name: "kycDetails",
        data() {
            return {
                title: 'KYC Details',
                Errors: "",
                CustomerCode: "",
                CustomerTypeId: "Business",
                CustomerFirstName: "",
                CustomerLastName: "",
                CustomerMobileNo: "",
                CustomerEmailId: "",
                CustomerPassword: "",
                CustomerGstNo: "",
                CustomerRegisterNo: "",
                CustomerSpeciality: "",
                CustomerCompanyName: "",
                CustomerNOB: "",
                CustomerProductName: "",
                CustomerTopTenPrivate: "",
                CustomerTopTenGovt: "",
                CustomerOfficeSpace: "",
                CustomerDrugLicenceNo: "",
                CustomerCityCovered: "",
                CustomerTurnoverYear1: "",
                CustomerTurnoverYear2: "",
                CustomerTurnoverYear3: "",
                CustomerExperience: "",
                CustomerPanNo: "",
                CustomerTotalExp: "",
                CustomerContactPersonName: "",
                CustomerGstImageData: null,
                CustomerPanImageData: null,
                CustomerAadharImageData: null,
                CustomerItReturnsImageData: null,
                customImageMaxSize: 10,
                GstImageContentType: "",
                PanImageContentType: "",
                AadharImageContentType: "",
                ItImageContentType: "",
                inputValidity: "",
                ImageBase64: "",
                show: Boolean,
                ImageHeading: "",
                CustomerGstImageType: "",
                CustomerPanImageType: "",
                CustomerAadharImageType: "",
                CustomerItReturnsImageType: "",
                CustomerGstImage: "",
                CustomerPanImage: "",
                CustomerAadharImage: "",
                CustomerItReturnsImage: "",
                showimage: Boolean,
                showpdf: Boolean,
                custAddressList: [],
                loadingKyc: Boolean
            };
        },
        created: async function () {
            this.loadKYC();
            this.getCustAddress();
            this.show = false;
            this.showimage = false;
            this.showpdf = false;
        },
        methods:
        {
            navigateToHomeUser(event) {
                event.preventDefault();
                this.$router.push('/homeUser');
            },
            onSizeExceeded(size) {
                alert(`File ${size}Mb size exceeds limits of ${this.customImageMaxSize}Mb!`);
            },
            navigateToAddAddress(event) {
                event.preventDefault();
                this.$router.push('/addAddress');
            },
            async getCustAddress() {
                try {
                var l = localStorage.getItem("userdata")
                    if (l != "")
                    {
                        var userData = JSON.parse(l)
                        var self = this;
                        self.CustomerMobileNo = userData.CustomerMobileNo;
                        const requestBody = { custcode: userData.CustomerCode, showcaption: "1" }
                        const response = await axios.post(helper.URL() + 'selectcustomeraddress', requestBody);
                        const result = response.data;
                        self.custAddressList = [];
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                self.custAddressList = result.Data[0];
                            }
                        } else {
                            Swal.fire('Alert!', "Response not found!!!", 'error');
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            close() {
                this.show = false;
                this.showimage = false;
                this.showpdf = false;
            },
            DisplayImage(ItemImage, H2) {
                this.ImageBase64 = ItemImage;
                this.show = true;
                if (ItemImage.split(',')[0].split('/')[1] == "pdf;base64") {
                    this.showpdf = true;
                }
                else {
                    this.showimage = true;
                }
                this.ImageHeading = H2;
            },
            async loadKYC() {
                try {
                    this.loadingKyc = true;
                    this.Errors = ""
                    var self = this
                    var l = localStorage.getItem("userdata")
                    if (l != "") {
                        const userData = JSON.parse(l)
                        const requestBody = { customercode: userData.CustomerEkartCode }
                        const response = await axios.post(helper.URL() + 'selectcustomerforkyc', requestBody);
                        const result = response.data;
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                result.Data[0].forEach(element => {
                                    self.CustomerFirstName = element.CustomerFirstName
                                    self.CustomerLastName = element.CustomerLastName
                                    self.CustomerMobileNo = element.CustomerMobileNo
                                    self.CustomerEmailId = element.CustomerEmailId
                                    self.CustomerPassword = element.CustomerPassword
                                    self.CustomerGstNo = element.CustomerGstNo
                                    self.CustomerDOB = element.CustomerDOB
                                    self.CustomerRegisterNo = element.CustomerRegisterNo
                                    self.CustomerSpeciality = element.CustomerSpeciality
                                    self.CustomerCompanyName = element.CustomerCompanyName
                                    self.CustomerNOB = element.CustomerNOB
                                    self.CustomerProductName = element.CustomerProductName
                                    self.CustomerTopTenPrivate = element.CustomerTopTenPrivate
                                    self.CustomerTopTenGovt = element.CustomerTopTenGovt
                                    self.CustomerOfficeSpace = element.CustomerOfficeSpace
                                    self.CustomerDrugLicenceNo = element.CustomerDrugLicenceNo
                                    self.CustomerCityCovered = element.CustomerCityCovered
                                    self.CustomerTurnoverYear1 = element.CustomerTurnoverYear1
                                    self.CustomerTurnoverYear2 = element.CustomerTurnoverYear2
                                    self.CustomerTurnoverYear3 = element.CustomerTurnoverYear3
                                    self.CustomerExperience = element.CustomerExperience
                                    self.CustomerPanNo = element.CustomerPanNo
                                    self.CustomerTotalExp = element.CustomerTotalExp
                                    self.CustomerContactPersonName = element.CustomerContactPersonName
                                    self.CustomerGstImage = element.CustomerGstImageType + ',' + element.CustomerGstImage
                                    self.CustomerPanImage = element.CustomerPanImageType + ',' + element.CustomerPanImage
                                    self.CustomerAadharImage = element.CustomerAadharImageType + ',' + element.CustomerAadharImage
                                    self.CustomerItReturnsImage = element.CustomerItReturnsImageType + ',' + element.CustomerItReturnsImage
                                    self.CustomerGstImageData = element.CustomerGstImageData
                                    self.CustomerPanImageData = element.CustomerPanImageData
                                    self.CustomerAadharImageData = element.CustomerAadharImageData
                                    self.CustomerItReturnsImageData = element.CustomerItReturnsImageData
                                    self.CustomerGstImageType = element.CustomerGstImageType
                                    self.CustomerPanImageType = element.CustomerPanImageType
                                    self.CustomerAadharImageType = element.CustomerAadharImageType
                                    self.CustomerItReturnsImageType = element.CustomerItReturnsImageType
                                });
                            }
                        }
                        else {
                            Swal.fire('Alert!', "Response not found!!!", 'error');
                        }
                        self.loadingKyc = false;
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            async submitForm() {
                try {
                    this.Errors = ""
                    var l = localStorage.getItem("userdata")
                    if (l != "") {
                        const userData = JSON.parse(l)
                        var TypeId = 0;
                        if (this.CustomerTypeId == "Business") {
                            TypeId = 2;
                        }
                        else {
                            TypeId = 3;
                        }
                        const requestBody = {
                            CustomerCode: userData.CustomerEkartCode, CustomerTypeId: TypeId, CustomerFirstName: this.CustomerFirstName,
                            CustomerLastName: this.CustomerLastName, CustomerMobileNo: this.CustomerMobileNo, CustomerEmailId: this.CustomerEmailId,
                            CustomerPassword: this.CustomerPassword, CustomerGstNo: this.CustomerGstNo, CustomerRegisterNo: this.CustomerRegisterNo,
                            CustomerSpeciality: this.CustomerSpeciality, CustomerCompanyName: this.CustomerCompanyName, CustomerNOB: this.CustomerNOB,
                            CustomerProductName: this.CustomerProductName, CustomerTopTenPrivate: this.CustomerTopTenPrivate, CustomerTopTenGovt: this.CustomerTopTenGovt,
                            CustomerOfficeSpace: this.CustomerOfficeSpace, CustomerDrugLicenceNo: this.CustomerDrugLicenceNo, CustomerCityCovered: this.CustomerCityCovered,
                            CustomerTurnoverYear1: this.CustomerTurnoverYear1, CustomerTurnoverYear2: this.CustomerTurnoverYear2, CustomerTurnoverYear3: this.CustomerTurnoverYear3,
                            CustomerExperience: this.CustomerExperience, CustomerPanNo: this.CustomerPanNo, CustomerTotalExp: this.CustomerTotalExp,
                            CustomerContactPersonName: this.CustomerContactPersonName
                        };
                        const response = await axios.post(helper.URL() + 'updatetmpcustomermaster', requestBody);
                        const result = response.data;
                        if (result.Status == true) {
                            if (result.CustomerCode != "") { 
                                this.CustomerCode = ""; this.CustomerTypeId = "Business"; this.currentform = "B1"; this.CustomerFirstName = ""; this.CustomerLastName = ""; 
                                this.CustomerMobileNo = ""; this.CustomerEmailId = ""; this.CustomerPassword = ""; this.CustomerGstNo = ""; this.CustomerDOB = new Date().toISOString().slice(0, 10); 
                                this.CustomerRegisterNo = ""; this.CustomerSpeciality = ""; this.CustomerCompanyName = ""; this.CustomerNOB = ""; this.CustomerProductName = ""; 
                                this.CustomerTopTenPrivate = ""; this.CustomerTopTenGovt = ""; this.CustomerOfficeSpace = ""; this.CustomerDrugLicenceNo = ""; 
                                this.CustomerCityCovered = ""; this.CustomerTurnoverYear1 = 0; this.CustomerTurnoverYear2 = 0; this.CustomerTurnoverYear3 = 0; 
                                this.CustomerExperience = ""; this.CustomerPanNo = ""; this.CustomerTotalExp = ""; this.CustomerContactPersonName = ""; 
                                this.CustomerGstImageData = null; this.CustomerPanImageData = null; this.CustomerAadharImageData = null; this.CustomerItReturnsImageData = null; 
                                this.GstImageContentType = ""; this.PanImageContentType = ""; this.AadharImageContentType = ""; this.ItImageContentType = ""; 
                                Swal.fire('Info!', "Saved Successfully!!!", 'success');
                                this.loadKYC();
                            } else {
                                Swal.fire('Info!', "Submit Failed!!!", 'failed');
                            }
                        }
                        else {
                            Swal.fire('Alert!', "Response not found!!!", 'error');
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            ValidateInput() {
                if (this.firstname === "") {
                    this.inputValidity = "invalid";
                } else {
                    this.inputValidity = "valid";
                }
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
       .loading {
           /*background: transparent url('https://miro.medium.com/max/882/1*9EBHIOzhE1XfMYoKz1JcsQ.gif') center no-repeat;*/
           background: transparent url('../assets/img/loading.gif') center no-repeat;
           height: 400px;
           width: 400px;
           margin: 0 auto;
       }

       .modal-mask {
           position: fixed;
           z-index: 9998;
           top: 0;
           left: 0;
           width: 100%;
           height: 100%;
           background-color: rgba(0, 0, 0, 0.5);
           display: table;
           transition: opacity 0.3s ease;
       }

       .modal-wrapper {
           display: table-cell;
           vertical-align: middle;
       }

       .modal-container {
           width: 550px;
           margin: 0px auto;
           padding: 20px 30px;
           background-color: #fff;
           border-radius: 2px;
           box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
           transition: all 0.3s ease;
           text-align: center;
       }

       .modal-header h3 {
           margin-top: 0;
           color: #42b983;
       }

       .modal-body {
           margin: 20px 0;
           height: 350px;
       }

       .modal-default-button {
           float: right;
       }

       /*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

       .modal-enter-from {
           opacity: 0;
       }

       .modal-leave-to {
           opacity: 0;
       }

           .modal-enter-from .modal-container,
           .modal-leave-to .modal-container {
               -webkit-transform: scale(1.1);
               transform: scale(1.1);
           }

       .no-border {
           border: 0 !important;
       }

       @import url("https://fonts.googleapis.com/css?family=PT+Sans");

       * {
           padding-top: 7px;
         
        
          
       }

       .row{
           color:#454545;
           font-family:Inter;
           font-size:14px
       }

       .row font {
           background-color: #e5e5e5;
       }



       .flex-container {
           align-items: center;
           display: flex;
           height: auto;
           margin: auto;
           flex-direction: row;
           justify-content: center;
           flex-wrap: wrap;
           /*margin-right: 0.1rem;*/
       }

       .flex-item1,
       .flex-item2 {
           margin-left: 0.1rem;
           flex-grow: 1;
           width: 185px;
           /* margin-right: .1rem; */
           padding-left: 0rem;
           padding-right: 1rem;
           margin-left: 0rem;
       }

       .flex-item3 {
           padding-left: 1rem;
           margin-left: 1rem;
       }

       .card .nameDetails {
           margin: 0;
           padding-top: 10%;
           font-size: 21px;
           height: 25px;
           align-items: center;
           font-weight: 400;
           line-height: 24.61px;
           font: "Roboto";
       }

       img {
           /*position: absolute;*/
           width: 180px;
           height: 180px;
           left: 8px;
           top: 48px;
           /*border-radius: 50%;*/
       }

       .changeImage {
           color: #00aeef;
           font-size: 18px;
           padding-top: 10px;
       }

       .sideMenu {
           margin-top: -1rem;
           margin-bottom: 0rem;
           padding-left: -1rem;
           min-height: 40px;
           line-height: 40px;
           width: 286px;
           padding-top: 10px;
           border-radius: 4px;
       }

           .sideMenu:hover {
               background-color: #c5c7d0;
               display: block;
               position: relative;
               top: 5px;
           }

       .card1 {
           box-sizing: border-box;
           left: 0%;
           right: 0%;
           top: 0%;
           bottom: 0%;
           display: flex;
           flex-direction: row;
           flex-wrap: wrap;
           background: #ffffff;
          /* border: 1px solid #ced4da;*/
          /* border-radius: 8px;*/
           width: 290px;
           height: 154px;
           margin-bottom: 4rem;
           border-radius: 8px;
           /* margin-top: 4px; */
           padding-top: 2px;
           /*margin-top: 4rem;*/
           margin-bottom: -11rem;
           margin-left: -5rem;
       }

       .item {
           flex-direction: row;
           flex-wrap: nowrap;
       }

       label {
           width: 294px;
       }

       h2 {
           position: absolute;
           width: 158px;
           height: 42px;
           left: 604px;
           top: 143px;
           font-family: "Roboto";
           font-style: normal;
           font-weight: 400;
           font-size: 36px;
           line-height: 42px;
           text-align: center;
           color: #000000;
       }

       .profileName h3 {
           /* Sumitra Sinha */
           position: absolute;
           width: 130px;
           height: 25px;
           left: 221px;
           top: 116px;
           font-family: "Roboto";
           font-style: normal;
           font-weight: 400;
           font-size: 21px;
           line-height: 25px;
           /* identical to box height */
           text-align: center;
           color: #000000;
       }

       .profileName a {
           color: #333;
           /* Change Profile Image */

           position: absolute;
           width: 153px;
           height: 19px;
           left: 221px;
           top: 209px;
           font-family: "Roboto";
           font-style: normal;
           font-weight: 400;
           font-size: 16px;
           line-height: 19px;
           /* identical to box height */
           text-align: center;
           color: #00aeef;
       }

       a:hover {
           /* color: #da5767; */
           text-decoration: none;
       }

       .card {
           /* border: 0.40rem solid #f8f9fa; */
           /* top: 10%; */
           top: -4%;
           /*margin-left: 14rem;*/
           margin-left: -5rem;
       }

       .form-control textarea {
           background: #fff;
           background-color: #f8f9fa;
           padding: 20px;
           padding: 25px 8px;
           margin-bottom: 1.3rem;
           border: 1px solid #c5c7d0;
           display: inline-block;
           vertical-align: top;
       }

           .form-control textarea:focus {
               outline: 0;
               box-shadow: none;
               color: #000000;
               background-color: #ffffff;
           }

       .form-control label {
           width: 38px;
           height: 16px;
           left: 399px;
           top: 488px;
           display: flex;
           flex-wrap: wrap;
           justify-content: space-between;
           align-items: center;
           font-family: "Roboto";
           font-style: normal;
           font-weight: 400;
           font-size: 14px;
           line-height: 16px;
           color: #323338;
           margin-left: 0rem;
           margin-right: -3rem;
       }

       input {
           margin-right: 2rem;
       }

       .addressDiv {
           width: 100%;
           height: 200px;
           border: 1px solid #eee;
           border-radius: 2px;
           padding-left: 0px;
           border: 1px solid #c5c7d0;
           /* overflow:hidden; */
           /* scroll-behavior: auto; */
           overflow-y: auto;
           font-family:Inter;
           color:#454545
       }

       .addressTitle1 {
           width: 90%;
           height: 200px;
           border: 1px solid;
           border-radius: 2px;
           padding-left: 0px;
           border: 1px solid #eee;
           /* overflow:hidden; */
           /* scroll-behavior: auto; */
       }

       .addressHeading1 {
           width: 100%;
           height: 30px;
           border-bottom: 1px solid #eee;
           color: #78787c;
           padding-top: 0px;
           padding-left: 10px;
       }

       .addressHeading {
           width: 100%;
           height: 30px;
           border-bottom: 1px solid #c5c7d0;
           color: #78787c;
           padding-top: 0px;
           padding-left: 10px;
       }

       .addressName {
           color: #323338;
           padding-bottom: 3px;
       }

       .addressParagraph {
           padding-top: 1px;
           margin-top: -11px;
           font-size: 13px;
           color: #323338;
           font-weight: 480;
           margin-bottom: 0px;
       }

       .plusSign {
           /* + */

           width: 27px;
           height: 56px;
           left: 1151px;
           top: 892px;
           font-family: "Roboto";
           font-style: normal;
           font-weight: 900;
           font-size: 48px;
           line-height: 56px;
       }

           .plusSign a {
               /* + */

               color: #78787c;
           }

       .addAddress {
           width: 300px;
           height: 100px;
           left: 1095px;
           top: 951px;
           font-family: "Roboto";
           font-style: normal;
           font-weight: 600;
           font-size: 20px;
           line-height: 28px;
           align-items: center;
       }

           .addAddress a {
               color: #78787c;
           }

       .table-hover {
           /* Rectangle 1162 */

           box-sizing: border-box;
           left: 0%;
           right: 1%;
           top: 0%;
           bottom: 0%;
           background: #ffffff;
           border-radius: 13px;
           margin-left: 5rem;
           margin-top: 4rem;
           border-collapse: collapse;
           border-radius: 1rem !important;
           overflow: hidden;
           border: 1px solid #c5c7d0;
       }

           .table-hover tr,
           td,
           span,
           i {
               /* Line 113 */
               left: 0.35%;
               right: 5%;
               top: 33.74%;
               bottom: 66.26%;
               border-bottom: #333;
           }

       .table {
           /* table box */

           box-sizing: border-box;
           width: 256px;
           height: 167px;
           left: 397px;
           top: 856px;
           border-bottom: #c5c7d0;
           background: #ffffff;
           border: 1px solid #ced4da;
           border-radius: 4px;
           color: #ced4da;
           float: left;
           margin-left: 2rem;
       }

           .table tr th {
               /* Default */

               width: 39px;
               height: 14px;
               left: 410px;
               top: 862px;
               padding-bottom: 5px;
               font-family: "Roboto";
               font-style: normal;
               font-weight: 400;
               font-size: 12px;
               line-height: 14px;
               text-align: left;
               border: 0.5px solid #ced4da;
               /* identica to box height */
           }

           .table td {
               /* Amit Jaiswal Shyam jeneral Enterprises Old Post Office Road, Patna PATNA, BIHAR 848101 India Phone number:993967091 */
               width: 256px;
               left: 410px;
               top: 892px;
               font-family: "Roboto";
               font-style: normal;
               font-weight: 400;
               font-size: 14px;
               line-height: 16px;
               border-top: none;
               border-bottom: none;
               color: #323338;
           }

       .plusSign {
           /* + */

           width: 27px;
           height: 56px;
           left: 1151px;
           top: 892px;
           font-family: "Roboto";
           font-style: normal;
           font-weight: 900;
           font-size: 48px;
           line-height: 56px;
           text-align: center;
           margin-left: 6rem;
       }

           .plusSign a {
               /* + */
               color: #78787c;
           }

       .addAddress {
           /* Add Address */
           /* width: 139px;
       height: 28px;
       left: 1095px;
       top: 951px;

       font-family: 'Roboto';
       font-style: normal;
       font-weight: 600;
       font-size: 20px;
       line-height: 28px;
       align-items: center; */
           /* Add Address */

           width: 139px;
           height: 28px;
           left: 1090px;
           top: 1266px;
           font-family: "Roboto";
           font-style: normal;
           font-weight: 600;
           font-size: 24px;
           line-height: 28px;
           color: #78787c;
           text-align: center;
           /* margin-right:-4.5rem !important; */

           margin-left: 2.5rem !important;
       }

           .addAddress a {
               /* Add Address */
               color: #78787c;
           }

       .tableBottom {
           /* Request to Edit | Remove */

           width: 151px;
           height: 14px;
           left: 410px;
           top: 1004px;
           font-family: "Roboto";
           font-style: normal;
           font-weight: 400;
           font-size: 12px;
           line-height: 14px;
           /* identical to box height */

           color: #00aeef;
       }

       .form-control input {
           /* Rectangle 171 */

           box-sizing: border-box;
           position: absolute;
           justify-content: space-between;
           width: 200px;
           height: 48px;
           left: 397px;
           top: 506px;
           margin-left: 4rem;
           background: #ffffff;
           opacity: 0.4;
           border: 1px solid #707070;
           border-radius: 4px;
       }

       .form-control:focus {
           color: #000000;
           background-color: #ffffff;
           outline: 0;
           box-shadow: none;
       }

       .col-md-3.invalid input {
           border: 1px solid red;
       }

       .col-md-3.invalid label {
           color: red;
       }

       .btn {
           padding: 0.6rem 1.2rem;
           background-color: hsl(209, 9%, 46%);
           width: 376px;
       }

       .btn-primary:hover {
           border-color: #6c757d;
           background-color: rgb(120, 120, 124);
           transition: 0.3s;
       }

       @media (max-width: 700px) {
           .card1 {
               flex-direction: column;
           }

           .card {
               flex-direction: column;
           }
       }

    .table-container::-webkit-scrollbar {
        width: 0;
        background-color: transparent;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 10px;
    }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: grey;
        }

    label {
        width: 294px;
        font-family:Inter;
        color:#454545;
        font-size:14px
    }


    input {
        margin-right: 0;
    }

    .navigationTop {
        display: flex;
        align-items: center;
        padding: 10px;
        font-family: Inter;
        font-size: 12px
    }

        .navigationTop a {
            text-decoration: none;
            color: #454545;
            font-family: Inter;
            font-size: 12px
        }

            .navigationTop a:hover {
                color: #2ca9df;
                font-family: Inter;
                font-size: 12px
            }

        .navigationTop span {
            margin: 0 5px;
            color: #454545;
            font-family: Inter;
            font-size: 12px
        }


        .form-group{
            margin-bottom:0px
        }

</style>
