<template>
    <div class="loginbg">
        <div class="loginbox">
            <p class="logintext">Log In</p>
            <form action="/action_page.php">
                <div class="form-group">
                    <input type="text" v-model="username" style="height: 36px; border-radius: 10px;color:#737373" class="form-control" placeholder="User ID" id="userid">
                </div>
                <p></p>
                <div class="form-group">
                    <input type="password" @keyup.enter.native="dologin" v-model="password" style="height:36px;border-radius:10px; color:#737373" class="form-control" placeholder="Password" id="password">
                </div>
                <p class="text-danger" style=" text-align: right; font-size: 12px;">{{LoginErr}}</p>
                <button type="button" class="btn btn-big btn-center" style="margin-top: 36px; margin-bottom: 20px; margin-top: 20px;" @click="doLogin">Log In</button>
                <p class="newcust">
                    <a style="color: #454545; cursor: pointer; float: right;" @click="loadForgotPassword">Forgot Password?</a>
                    <br />
                    <br />
                    <span style="color: #454545;">New Customer?</span> <a style="color: #454545; cursor: pointer;text-decoration:underline" @click="loadRegistration"> &nbsp;Register Here</a>
                </p>
            </form>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    import helper from '../helper'
    export default {
        name: 'login',
        data() {
            return {
                title: 'login',
                username: "",
                password: "",
                LoginErr: "",
                encryptedPassword: ""
            }
        },
        created() {
            localStorage.setItem("charttype", "1");
            localStorage.setItem("apiurl", helper.URL());
        },
        mounted: function () {
            localStorage.setItem("useridforpasswordreset", this.username);
        },
        methods: {
            loadForgotPassword() {
                this.LoginErr = "";
                if (this.username == "") {
                    this.LoginErr = "Please enter User Id to reset the password!!!"
                    return false
                }
                localStorage.setItem("useridforpasswordreset", this.username);
                this.$router.push("/forgotPassword");
            },
            loadRegistration() {
                this.$router.push("/registerNew");
            },
            async finalLogin(username, password) {
                try {
                    const requestBody = { UserName: username, Password: password };
                    const response = await axios.post(helper.URL() + 'login', requestBody);
                    const result = response.data;
                    if (result.Status == true) {
                        if (result.Data.length <= 0) {
                            this.LoginErr = "Invalid Credentials or User not found!!!"
                        }
                        else {
                            localStorage.setItem("userdata", JSON.stringify(result.Data[0]));
                            this.$router.push("/homeUser");
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            async doLogin() {
                try {
                    this.LoginErr = "";
                    if (this.username == "") {
                        this.LoginErr = "Username Can't be empty!!!"
                        return false
                    }
                    if (this.password == "") {
                        this.LoginErr = "Password Can't be empty!!!"
                        return false
                    }
                    var self = this;
                    const requestBody = { UserName: this.username, Password: this.password };
                    const response = await axios.post(helper.URL() + 'getauth', requestBody);
                    const result = response.data;
                    if (result != "") {
                        self.finalLogin(self.username, result.responseData)
                    }
                    else {
                        self.LoginErr = "Response not found!!!"
                    }
                } catch (error) {
                    console.error(error);
                }
                //var self = this;
                //self.finalLogin(self.username, "hq2Di7BMbx2s3bhJW/HImg=="); //C01549
                //self.finalLogin(self.username, "UATd+ubyVSJ5sh0okpiMYA=="); //C00740
                //self.finalLogin(self.username, "P5xCmN6DXavyZh23FKpFfg=="); //C00719
                //self.finalLogin(self.username, "iwa8+x1mHjEakv+Cwnh4BA=="); //C00112
                //self.finalLogin(self.username, "cKg16qbLfWV6acmsIaOAmg=="); //C01462
            }
        }
    }
</script>

<style scoped>



    .loginbg {
        width: 100%;
        height:1000px;
        background-color: #f2f2f2;
        background: url("../assets/img/loginbg.png") top center;
        background-size: cover;
        background-position: center;
        padding: 0;
        overflow: hidden;
       
    }


    .newcust {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #111111;
    }



    /*.loginbox {
        position: absolute;
        width: 483px;*/
       /* height: 376px;*/
       /*height:400px;
        left: 50%;
        top:350px;
        transform: translate(-50%, -50%);
        padding: 40px;
        background: #FDFDFD;
        background-color: #f5f5f5;
        border: 1px solid #ccc;
    }*/


    .loginbox {
        position: absolute;
        width: 483px;
        height: 374px;
        left: 50%;
        top: 350px;
        transform: translate(-50%, -50%);
        padding: 40px;
        border-radius: 16px;
        background-color: rgba(255, 255, 255, 0.8   );
    }


    .logintext {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 38px;
        color: #454545;
        margin-bottom: 10px;
    }


    .contact form .form-control {
        margin-bottom: 1em;
    }

    .contact form textarea {
        min-height: 20em;
    }


    input[type="text"]::placeholder {
        color: #737373;
    }

    input[type="password"]::placeholder {
        color: #737373;
    }



    .btn-big {
        font-size: 16px;
        padding: 5px 5px;
        border-radius: 10px;
        background-color: #2CA9DF;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
        width: 400px;
        height: 36px;
         /*transition: background-color 0.2s, color 0.2s, border-color 0.2s;*/
    }



    .btn-big:focus {
        outline: none;
    }


      

        .btn-big:focus {
            outline: none;
            margin-left: 0px;
            text-align: center;
            border-radius: 10px;
        }



    .btn-center {
        display: block;
      
        margin: 0 auto;
    }
    .newcust {
        font-size: 14px;
        color: #454545;
    }
    .form-control {
        border: 1px solid #737373;
        color: #737373;
    }

</style>
