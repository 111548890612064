<template>
    <div class="bg">
        <div class="logincontainer">
            <div class="loginbox">
                <p class="ftext">Forgot Password</p>
                <form action="/action_page.php">
                    <div class="form-group">
                        <input type="text" v-model="CustomerCode" style="height: 48px;" class="form-control" placeholder="User ID" id="CustomerCode">
                    </div>
                    <p></p>
                    <div class="form-group">
                        <input type="password" @keyup.enter.native="ForgotPassword" v-model="NewPassword" style="height: 48px;" class="form-control" placeholder="New Password" id="NewPassword">
                    </div>
                    <p></p>
                    <div class="form-group">
                        <input type="password" @keyup.enter.native="ForgotPassword" v-model="ConfirmPassword" style="height: 48px;" class="form-control" placeholder="Confirm Password" id="ConfirmPassword">
                    </div>
                    <div class="form-group">
                        <input type="password" @keyup.enter.native="ForgotPassword" v-model="CustomerOTP" style="height: 48px;" class="form-control" placeholder="OTP" id="CustomerOTP">
                    </div>
                    <p class="text-danger" style="text-align: center;">{{Error}}</p>
                    <button type="button" class="btn btn-gray btn-full" @click="ForgotPassword">Submit</button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    import helper from '../helper'
    export default {
        name: 'home',
        data() {
            return {
                title: 'contact',
                NewPassword: "",
                ConfirmPassword: "",
                Error: "",
                CustomerCode: "",
                NewPwd: "",
                CustomerName: "",
                CustomerMobileNo: "",
                CustomerEmailId: "",
                Message: "",
                OTP: "",
                CustomerOTP: ""
            }
        },
        created() {
            this.GetCustomerDetails();
        },
        methods: {
            async GetCustomerDetails() {
                try {
                    var UserId = localStorage.getItem("useridforpasswordreset")
                    this.CustomerCode = UserId;
                    const requestBody = { UserName: UserId };
                    const response = await axios.post(helper.URL() + 'getcustomerdetails', requestBody);
                    const result = response.data;
                    if (result.length <= 0) {
                        this.Error = "Invalid credentials User not found!!!";
                    }
                    else {
                        localStorage.setItem("customerdetails", JSON.stringify(result));
                        this.sendOtp();
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            async ForgotPassword() {
                try
                {
                    this.Error = "";
                    if (this.CustomerCode == "") {
                        this.Error = "User ID Can't be empty!!!"
                        return false
                    }
                    if (this.NewPassword == "") {
                        this.Error = "New Password Can't be empty!!!"
                        return false
                    }
                    if (this.ConfirmPassword == "") {
                        this.Error = "Confirm Password Can't be empty!!!"
                        return false
                    }
                    if (this.ConfirmPassword != this.NewPassword) {
                        this.Error = "Confirm Password is mismatched!!!"
                        return false
                    }
                    if (this.CustomerOTP == "") {
                        this.Error = "OTP Can't be empty!!!"
                        return false
                    }
                    if (this.OTP != this.CustomerOTP) {
                        this.Error = "OTP is mismatched!!!"
                        return false
                    }
                    var l = localStorage.getItem("customerdetails")
                    if (l != "" && l !== null) {
                        var self = this;
                        var udata = JSON.parse(l)
                        self.CustomerCode = udata[0].CustomerCode;
                        const requestBody = { UserName: self.CustomerCode, Password: self.NewPassword }
                        const response = await axios.post(helper.URL() + 'getauth', requestBody);
                        const result = response.data;
                        //alert(result.responseData)
                        if (result != "") {
                            self.NewPwd = result.responseData;
                            const requestBody2 = { CustomerCode: self.CustomerCode, UserPassword: self.NewPwd };
                            const response2 = await axios.post(helper.URL() + 'forgotpassword', requestBody2);
                            const result2 = response2.data;
                            if (result2.responseData == "1") {
                                localStorage.removeItem("customerdetails");
                                self.$router.push("/login")
                                Swal.fire('Info', "Password reset successfully!!!", '');
                            } else {
                                self.LoginErr = "Password Response not found!!!"
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            sendOtp() {
                var l = localStorage.getItem("customerdetails")
                if (l != "" && l !== null) {
                    var self = this;
                    var udata = JSON.parse(l)
                    self.CustomerCode = udata[0].CustomerCode;
                    self.CustomerName = udata[0].CustomerName;
                    self.CustomerMobileNo = udata[0].CustomerMobileNo;
                    self.CustomerEmailId = udata[0].CustomerEmailId;
                    //self.CustomerEmailId = "manojmokavoor@gmail.com";
                    //self.CustomerMobileNo = "9400972822";
                    if (self.CustomerCode == "") {
                        self.Error = "* User ID Can't be empty!";
                        return false
                    }
                    var phoneno = /^\d{10}$/;
                    if (!self.CustomerMobileNo.match(phoneno)) {
                        self.Error = "* Phone Number should be valid, Contact our backend team!";
                        return false
                    }
                    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(self.CustomerEmailId)) {

                    } else {
                        self.Error = "* Email should be valid, Contact our backend team!";
                        return false
                    }
                    self.OTP = Math.floor(100000 + Math.random() * 900000).toString();
                    self.Message = self.OTP;
                    //alert(self.OTP)
                    if (self.Message == "") {
                        self.Error = "* Message Can't be empty!";
                        return false
                    }
                    const requestBody = { name: this.CustomerName, phone: this.CustomerMobileNo, email: this.CustomerEmailId, message: this.Message }
                    const response = axios.post(helper.URL() + 'sendforgotpasswordotp', requestBody);
                    const result = response.data;
                    if (result.Status == true) {
                        if (result.length <= 0) {
                            Swal.fire('Error', "Response not found!!!", '');
                        }
                        else {
                            self.CustomerName = ""
                            self.CustomerMobileNo = 0
                            self.CustomerEmailId = ""
                            self.Message = ""
                            self.Error = "";
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .newcust {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #111111;
    }

    .logincontainer {
        width: 100%;
        height: 100%;
        background: #FFFFFF;
    }
    .loginbox {
        font-size: 14px;
        margin: 120px auto;
        width: 483px;
        padding: 40px;
        background: #FFFFFF;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
    }
    .ftext {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 38px;
        color: #000000;
    }

    .contact form .form-control {
        margin-bottom: 1em;
    }

    .contact form textarea {
        min-height: 20em;
    }
</style>
