import { createRouter, createWebHistory } from 'vue-router';
import homeUser from '@/components/homeUser'
import home from '@/components/home'
import login from '@/components/login'
import forgotPassword from '@/components/forgotPassword'
import registerNew from '@/components/registerNew'
import quote from '@/components/quote'
import homeProducts from '@/components/homeProducts'
import product from '@/components/product'
import productRouter from '@/components/productRouter'
import contactUs from '@/components/contactUs'
import aboutUs from '@/components/aboutUs'
import dashBoard from '@/components/dashBoard'
import productCart from '@/components/productCart'
import confirmation from '@/components/confirmation'
import quickOrder from '@/components/quickOrder'
import myOrder from '@/components/myOrder'
import changePassword from '@/components/changePassword'
import paymentDetails from '@/components/paymentDetails'
import itemReturn from '@/components/itemReturn'
import returnCart from '@/components/returnCart'
import kycDetails from '@/components/kycDetails'
import returnPolicy from '@/components/returnPolicy'
import addAddress from '@/components/addAddress'
import myPendingOrder from '@/components/myPendingOrder'
import myReturn from '@/components/myReturn'


const routes = [
    {
        path: '/',
        redirect: '/home',
        component: home
    },
    {
        path: '/home',
        name: 'home',
        component: home
    },
    {
        path: '/homeUser',
        name: 'homeUser',
        component: homeUser
    },
    {
        path: '/login',
        name: 'login',
        component: login
    },
    {
        path: '/forgotPassword',
        name: 'forgotPassword',
        component: forgotPassword
    },
    {
        path: '/registerNew',
        name: 'registerNew',
        component: registerNew
    },
    {
        path: '/quote',
        name: 'quote',
        component: quote
    },
    {
        path: '/homeProducts/:groupId/:groupName',
        name: 'homeProducts',
        component: homeProducts
    },
    {
        path: '/product/:productCode',
        name: 'product',
        component: product
    },
    {
        path: '/productRouter/:groupId/:groupName',
        name: 'productRouter',
        component: productRouter
    },
    {
        path: '/contactUs',
        name: 'contactUs',
        component: contactUs
    },
    {
        path: '/aboutUs',
        name: 'aboutUs',
        component: aboutUs
    },
    {
        path: '/dashBoard',
        name: 'dashBoard',
        component: dashBoard
    },
    {
        path: '/productCart',
        name: 'productCart',
        component: productCart
    },
    {
        path: '/confirmation/:InvoiceNo/:TotalSaving/:AddressS/:AddressB',
        name: 'confirmation',
        component: confirmation
    },
    {
        path: '/quickOrder',
        name: 'quickOrder',
        component: quickOrder
    },
    {
        path: '/myOrder',
        name: 'myOrder',
        component: myOrder
    },
    {
        path: '/changePassword',
        name: 'changePassword',
        component: changePassword
    },
    {
        path: '/paymentDetails',
        name: 'paymentDetails',
        component: paymentDetails
    },
    {
        path: '/itemReturn',
        name: 'itemReturn',
        component: itemReturn
    },
    {
        path: '/returnCart',
        name: 'returnCart',
        component: returnCart
    },
    {
        path: '/kycDetails',
        name: 'kycDetails',
        component: kycDetails
    },
    {
        path: '/returnPolicy',
        name: 'returnPolicy',
        component: returnPolicy
    },
    {
        path: '/addAddress',
        name: 'addAddress',
        component: addAddress
    },
    {
        path: '/myPendingOrder',
        name: 'myPendingOrder',
        component: myPendingOrder
    },
    {
        path: '/myReturn',
        name: 'myReturn',
        component: myReturn
    }
];
const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }; 
    },
    history: createWebHistory(),
    routes
});

export default router;