<template>
    <div style="padding-top: 55px">
        <div class="containermyorder">
            <div class="headerMyorder">Your Order Detalis</div>
            <div class="columnsofMyorder">
                <div class="columnMyorder1">
                    <div class="columnHeading">Order Date: {{ formatDate(KartSummary.KartDate) }}</div>
                    <div class="columnContent">
                        <p class="info">Total No. of Boxes: {{ KartSummary.BoxQty }}</p>
                        <p class="info">Total No. Items: {{ KartSummary.KartDetOrderQty }} pcs</p>
                        <p class="info">Tender Order</p>
                    </div>
                </div>
                <div class="columnMyorder2">
                    <div class="columnHeading">Order ID: {{ KartSummary.KartInvoiceNo }}</div>
                    <div class="columnContent">
                        <p class="info">Order value: {{ this.formatter.format(KartSummary.KartNetAmount) }}</p>
                        <p class="info">Saving on this Order: {{ this.formatter.format(TotalSaving) }}</p>
                    </div>
                </div>
                <div class="columnMyorder3">
                    <div class="columnHeading">&nbsp;</div>

                    <div class="orderDates">
                        <p class="confirmationDate" style="flex: 2">
                            Order Confirmed<br />{{ formatDate(KartSummary.KartDate) }}
                        </p>
                        <p class="deliveryDate" style="flex: 2; text-align: right">
                            Expected on<br />{{ formatDate(KartSummary.KartDeliveryDate) }}
                        </p>
                    </div>
                    <div class="orderStatus">
                        <div class="dot"></div>
                        <div class="line"></div>
                        <div class="dotTwo"></div>
                    </div>

                    <div class="deliveryAddress">
                        <p>
                            Delivery Address: <br />
                            {{ AddressS }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="myorderDiv1">
            <div class="myordericon"><img src="../assets//img/tickicon.png" style="width:39px" /></div>
            <div class="myorderheading" style="font-size: 22px;">Your Order Placed Successfully</div>
        </div>

        <div class="myorderDiv2">
            <router-link :to="{ name: 'homeProducts', params: { groupId: 0, groupName: 'All Products' } }">
                <a href="#" class="continueShopping">Continue Shopping ...</a>
            </router-link>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    import helper from '../helper'
    export default
        {
            name: 'confirmation',
            data() {
                return {
                    formatter: new Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                        minimumFractionDigits: 2
                    }),
                    InvoiceNo: '',
                    TotalSaving: 0,
                    AddressS: '',
                    AddressB: '',
                    KartSummary: {}
                }
            },
            mounted() {

            },
            created: function () {
                const inv = this.$route.params.InvoiceNo;
                const sav = this.$route.params.TotalSaving;
                const addrs = this.$route.params.AddressS;
                const addrb = this.$route.params.AddressB;
                this.InvoiceNo = inv;
                this.TotalSaving = sav;
                this.AddressS = addrs;
                this.AddressB = addrb;
                this.getKartsummary();
            },
            methods: {
                formatDate(d) {
                    var nd = new Date(d)
                    return nd.getDate() + "/" + (nd.getMonth() + 1) + "/" + nd.getFullYear();
                },
                async getKartsummary() {
                    var self = this;
                    const requestBody = { InvoiceNo: this.InvoiceNo }
                    const response = await axios.post(helper.URL() + 'selectkartsummary', requestBody);
                    const result = response.data;
                    self.KartSummary = {};
                    if (result.Status == true) {
                        if (result.Data && result.Data.length > 0) {
                            self.KartSummary = result.Data[0][0];
                        }
                    }
                }
            }
        }
</script>
<style scoped>
    .containermyorder {
        display: flex;
        flex-direction: column;
        margin: 80px 40px;
        padding: 40px 40px;
        background-color: #fff;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .headerMyorder {
        color: #737373;
        font-size: 18px;
        font-family: inter;
    }

    .columnsofMyorder {
        display: flex;
    }

    .columnMyorder1 {
        flex: 1;
    }

    .columnMyorder2 {
        flex: 1;
    }

    .columnMyorder3 {
        flex: 2;
        position: relative;
    }

    .columnHeading {
        font-weight: bold;
        background-color: #f3f3f3;
        padding: 10px 10px;
        margin: 10px 0px;
        color: #454545;
    }

    .columnContent {
        padding: 10px;
        color: #454545;
    }

    .info {
        /* margin: 10px 0; */
        font-size: 18px;
        color: #454545;
    }

    .orderStatus {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }

    .dot {
        width: 12px;
        height: 12px;
        background-color: green;
        border-radius: 50%;
        margin: 2px;
    }

    .dotTwo {
        width: 12px;
        height: 12px;
        background-color: #afafaf;
        border-radius: 50%;
        margin: 2px;
    }

    .line {
        width: 530px;
        height: 2px;
        background-color: #afafaf;
        align-self: center;
    }

    .orderDates {
        padding: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 20px;
    }

    .confirmationDate,
    .deliveryDate {
        font-size: 14px;
        color: #454545;
        margin: 0;
        padding-right: 20px;
    }

    .deliveryAddress {
        margin-top: 0px;
        padding: 10px 20px;
        font-size: 18px;
        color: #454545;
    }


    .myorderDiv1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        /*margin-top: 40px;*/
        margin-top: -45px;
        text-align: center;
    }



    .myorderDiv2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        /*margin-top: 40px;*/
        margin-top: -10px;
        text-align: center;
        /*margin-bottom: 50px;*/
        padding: 20px
    }

    .myorderDiv1 .myordericon {
        font-size: 24px;
    }

    .myorderDiv1 .myorderheading,
    .myorderDiv2 .myorderheading {
        margin-top: 10px;
        font-weight: bold;
    }

    .myorderDiv2 .continueShopping {
        text-decoration: none;
        color: blue;
    }

        .myorderDiv2 .continueShopping:hover {
            text-decoration: underline;
        }
    @media (max-width: 600px) {
        .containermyorder {
            flex-direction: column;
        }

        .columnMyorder1,
        .columnMyorder2 {
            flex-basis: auto;
            flex-grow: 1;
        }

        .orderStatus {
            flex-direction: row;
        }

        .line {
            width: 102px;
            height: 2px;
        }

        .columnMyorder3 {
            flex-basis: auto;
            flex-grow: 1;
        }

        .deliveryAddress {
            font-size: 12px;
        }
    }

    @media (max-width: 601px) {
        .columnMyorder1 {
            flex-basis: 50%;
        }

        .columnMyorder2 {
            flex-basis: 50%;
        }

        .columnMyorder3 {
            flex-basis: 100%;
        }

        .orderStatus {
            flex-direction: row;
        }

        .line {
            width: 180px;
            height: 2px;
        }
    }
</style>

