<template>
    <div>
        <div class="container">
            <div style="width: 1033px; margin-top: 125px;" id="carouselExampleControls" class="carousel slide" data-ride="carousel;">
                <div class="navigationTop" style="margin-top: 115px;padding:10px;margin-left:30px">
                    <a href="#" @click="navigateToHomeUser">Home</a>
                    <span>&gt;</span>
                    <a href="#">Products</a>
                    <span>&gt;</span>
                    <a href="#">{{ groupName }}</a>
                </div>
                <div class="carousel-inner" style="padding:0px;margin-left:35px">
                    <div class="carousel-item active">
                        <img class="d-block w-100"
                             src="../assets/img/Banner_1.png"
                             alt="First slide"
                             style="width: 1256px; height: 223px; margin-top: 10px; " />
                    </div>
                    <div class="carousel-item">
                        <img class="d-block w-100"
                             src="../assets/img/Banner_2.png"
                             alt="Second slide"
                             style="width: 1256px; height: 223px; margin-top: 10px;" />
                    </div>
                    <div class="carousel-item">
                        <img class="d-block w-100"
                             src="../assets/img/Banner_3.png"
                             alt="Third slide"
                             style="width: 1256px; height: 223px; margin-top: 10px;" />
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev" style="margin-left: -0px; margin-top: 50px; width: fit-content; ">
                    <span class="carousel-control-prev-icon" aria-hidden="true" style="margin-left: 45px;"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next" style="margin-right: -0px; margin-top: 50px; width: fit-content; ">
                    <span class="carousel-control-next-icon" aria-hidden="true" style=" margin-right: -14px;"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
            <div style="background: #f5f5f5; width: 1033px;margin-left:33px">
                <div style="background: #fff;background: #fff;margin-top: 20px;margin-bottom: 20px; padding-left: 40px;padding-right: 40px;padding-bottom: 20px;padding-top: -10px;">
                    <div id="carouselExampleControlsGroup" class="carousel slide" v-if="itemgroup.length > 0">
                        <div class="carousel-inner" style="padding: 0px; ">
                            <div class="carousel-item" :class="{ active: index==0 }" v-for="(group,index) in itemgroup" :key="index">
                                <div id="carousel-card" class="row  align-items-center justify-content-center" style="margin-top:0px">
                                    <div class="tab-container">
                                        <div class="scrollable-tabs">
                                            <div class="tabs-carousel">
                                                <ul ref="tabsList" class="nav nav-tabs">
                                                    <li v-for="ig in group" :key="ig.ItemGroupId" @click="productMenuClick(ig.ItemGroupId, ig.ItemGroupName)" style="margin-left:29px">
                                                        <a :class="['nav-link', { active: activeTab === ig.ItemGroupId }]"
                                                           :style="{ backgroundColor: getTabBackgroundColor(ig.ItemGroupId), color: 'black', }">{{ ig.ItemGroupName }}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#carouselExampleControlsGroup" role="button" data-slide="prev" style="margin-left: -4px; width: fit-content; height: fit-content; margin-top: 6px">
                            <span class="carousel-control-prev-icon" style="margin-left: -18px;" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleControlsGroup" role="button" data-slide="next" style="margin-right: -4px; width: fit-content; height: fit-content; margin-top: 6px ">
                            <span class="carousel-control-next-icon" aria-hidden="true" style=" margin-right: -14px; "></span>
                            <span class="sr-only">Next</span>
                        </a>
                        <hr style="border: none; height: 1px; background-color: black; margin-top: 0px; margin-right: 0px;">
                    </div>
                    <div v-if="products.length > 0">
                        <input type="text" class="form-control" style="padding: 0px 5px;font-family:Roboto" placeholder="Search for products" v-model="searchText" aria-label="Text input with dropdown button">
                        <div v-for="(row, rowIndex) in Math.ceil(filterBy(products, searchText).length / 6)" :key="rowIndex" class="row" style="padding-top: 25px;padding-right:20px;margin-bottom:-20px">
                            <div v-for="(item, itemIndex) in filterBy(products, searchText).slice(rowIndex * 6, (rowIndex * 6) + 6)" :key="itemIndex" class="col-md-4 col-lg-2 col-sm-6">
                                <div class="hom-box13" style="padding: 2px;
                                                text-align: center;
                                                box-shadow: 0 5px 4px #d6d4d4;
                                                width: 150px;
                                                height: 300px;
                                                display: flex;
                                                flex-direction: column;
                                                align-items: center;
                                                position: relative;">
                                    <div class="spanmyicon13">
                                        <div>
                                            <img style="cursor: pointer; background: #eee; width: 100%; height: 150px; border-radius: 0px;" :src="'data:image/png;base64,' + item.CustomerLinkItemImageData " ratio="1" @click="productClick(item, item.CustomerLinkItemCode)">
                                        </div>
                                    </div>
                                    <div style="color: rgba(69, 69, 69, 1);
                                            font-weight: bold;
                                            margin-top: 20px;
                                            line-height: 1.2;
                                            font-size:9px;

                                            text-transform:uppercase;

                                        ">
                                        {{ item.CustomerLinkItemName }}
                                    </div>
                                    <div style="color: rgba(69, 69, 69, 1);
                                        font-size: 10px;
                                        margin-top: 4px; ">
                                        Product Code: {{ item.CustomerLinkItemCode }}
                                    </div>

                                    <div>
                                        <div style="display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        margin-top: 1px;">
                                            <!--<span style="display: flex;justify-content: space-between;align-items: center;">
                                        <p style="font-size: 9px">
                                            <span style="width: 35px; text-align: left">
                                                <span style="font-weight: bold">&#8377;{{ item.CustomerLinkItemDP }}&nbsp;</span>
                                                <span style="text-decoration: line-through">&nbsp;&#8377;{{ item.CustomerLinkItemPrice }}</span><span> &nbsp;</span>
                                                {{ item.CustomerLinkItemPrice !== 0 ? (((item.CustomerLinkItemPrice - item.CustomerLinkItemDP) / item.CustomerLinkItemPrice) * 100).toFixed(2) : 0 }}
                                                % Off
                                            </span>
                                            <button class="btnAddKartPro" @click="addToKart(item)">Add to Cart</button>
                                        </p>
                                    </span>-->

                                            <span style="justify-content: space-between;align-items: center;">
                                                <span style="font-weight: bold;">
                                                    &#8377; {{ item.CustomerLinkItemDP }}&nbsp;
                                                </span>
                                                <span style="text-decoration: line-through">
                                                    &nbsp;&#8377; {{ item.CustomerLinkItemPrice }}
                                                </span>
                                                <span>
                                                    &nbsp;
                                                    {{ item.CustomerLinkItemPrice !== 0 ? (((item.CustomerLinkItemPrice - item.CustomerLinkItemDP) / item.CustomerLinkItemPrice) * 100).toFixed(2) : 0 }}% Off
                                                </span>
                                            </span>

                                            <div style="position: absolute;
                                            bottom:10px;
                                            left: 44%;
                                            transform: translateX(-50%);">
                                                <button class="btnAddKart"
                                                        @click="productClick(item, item.CustomerLinkItemCode)">
                                                   Buy Now
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import helper from '../helper';
    import Swal from 'sweetalert2';
    import Vue2Filters from 'vue2-filters'
    export default {
        mixins: [Vue2Filters.mixin],
        name: 'homeProducts',
        components: {
            
        },
        data() {
            return {
                searchText: "",
                activeTab: null,
                isProductMenuOpen: false,
                isProfileMenuOpen: false,
                showImagePopup: false,
                title: 'HomeProducts',
                hideo: true,
                products: [],
                OfferProducts: [],
                StandardProducts: [],
                KartProducts: [],
                emailcontent: "",
                customeremail: "",
                customerphone: "",
                Error: "",
                loadingProducts: false,
                loadingOffer: false,
                loadingStandardProducts: false,
                SendDate: new Date().toISOString().slice(0, 10),
                CustomerCode: "",
                CustomerName: "",
                ReportData: null,
                ReportDataContentType: "",
                customImageMaxSize: 10,
                CampaignImage: "",
                dealproducts: [],
                missedproducts: [],
                itemgroup: [],
                groupId: 0,
                groupName: ""
            }
        },
        computed: {
            getTabBackgroundColor() {
                return (itemGroupId) => {
                    if (this.activeTab == itemGroupId) {
                        return '#a8ddf4';
                    }
                    return '';
                };
            }
        },
        created: async function () {
            this.selectProductGroup();
        },
        beforeMount: async function () {
            this.loadUserData();
        },
        mounted() {
            const grpId = this.$route.params.groupId;
            const grpName = this.$route.params.groupName;
            this.groupId = grpId;
            this.groupName = grpName;
            this.activeTab = grpId;
            this.getCustProducts();
        },
        methods:
        {
            navigateToHomeUser(event) {
                event.preventDefault();
                this.$router.push('/homeUser');
            },
            productMenuClick(groupId, grpName) {
                this.activeTab = groupId;
                this.isProductMenuOpen = false;
                this.groupId = groupId;
                this.groupName = grpName;
                this.getCustProducts();
            },
            productClick(productDetails, productCode) {
                //console.log(JSON.stringify(productDetails));
                localStorage.setItem("SelectedProductDetails", JSON.stringify(productDetails))
                this.$router.push({ name: 'product', params: { productCode } })
            },
            highlightItem(event) {
                event.target.style.backgroundColor = '#007bff';
            },
            unhighlightItem(event) {
                event.target.style.backgroundColor = '#ffffff';
            },
            toggleMenu(menuType) {
                if (menuType == 'Product') {
                    this.isProductMenuOpen = !this.isProductMenuOpen;
                    this.isProfileMenuOpen = false;
                } else {
                    this.isProfileMenuOpen = !this.isProfileMenuOpen;
                    this.isProductMenuOpen = false;
                }
            },
            openDropdown(menu) {
                this[menu + 'MenuOpen'] = true;
            },
            closeDropdown(menu) {
                this[menu + 'MenuOpen'] = false;
            },
            closeDropdowns() {
                this.isProductMenuOpen = false;
                this.isProfileMenuOpen = false;
            },
            async getCustProducts() {
                try {
                    var l = localStorage.getItem("userdata")
                    if (l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { custcode: userData.CustomerCode, itemgroupid: this.groupId }
                        const response = await axios.post(helper.URL() + 'customerlinkedproducts', requestBody);
                        const result = response.data;
                        self.products = [];
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                result.Data.forEach(element => {
                                    element.BoxOrderQty = 0
                                    element.PcsOrderQty = 0
                                    element.Remark = ''
                                    self.products.push(element)
                                });
                            }
                        }
                        else {
                            Swal.fire('Alert!', "Response not found!!!", 'error');
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            loadUserData() {
                var l = localStorage.getItem("userdata")
                if (l != "") {
                    var userData = JSON.parse(l);
                    this.userdata = userData;
                    this.custPreviousDues = this.userdata.CustomerDue
                    this.CustomerName = this.userdata.CustomerName
                }
                var k = localStorage.getItem("Kart");
                if (l != "" && k != "" && k !== null) {
                    var kobj = JSON.parse(k)
                    if (kobj.CustId == this.userdata.CustomerCode) {
                        this.KartProducts = kobj.items
                    }
                }
            },
            async selectProductGroup() {
                try {
                    var l = localStorage.getItem("userdata")
                    if (l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { custcode: userData.CustomerCode };
                        const response = await axios.post(helper.URL() + 'selectcustomerproductgroup', requestBody);
                        const result = response.data;
                        self.itemgroup = [];
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                const groupSize = 9;
                                const newRow = {
                                    ItemGroupId: 0,
                                    ItemGroupName: "All Products",
                                    ItemImageData: ""
                                };
                                result.Data[0].splice(0, 0, newRow);
                                for (let i = 0; i < result.Data[0].length + 1; i += groupSize) {
                                    self.itemgroup.push(result.Data[0].slice(i, i + groupSize));
                                }
                            }
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            getSubStr(str) {
                if (str.length > 33) {
                    var tstr = str.substr(1, 33);
                    return tstr + "..."
                } else {
                    return str
                }
            },
            addToKart(p) {
                var bqty = p.CustomerLinkItemBoxQty
                p.PcsOrderQty = 1 * bqty
                p.BoxOrderQty = 1
                var found = false
                var foundindex = 0
                this.KartProducts.forEach((element, index) => {
                    if (element.CustomerLinkItemCode == p.CustomerLinkItemCode) {
                        found = true
                        foundindex = index
                    }
                });
                if (found) {
                    this.KartProducts[foundindex].BoxOrderQty += p.BoxOrderQty
                    this.KartProducts[foundindex].PcsOrderQty += p.PcsOrderQty
                } else {
                    this.KartProducts.push(JSON.parse(JSON.stringify(p)))
                }
                this.storeKart()
                this.$router.push('/productCart');
                //Swal.fire({
                //    type: 'success', title: 'Successfully Added.',
                //    showConfirmButton: false, timer: 1000
                //}).then((result) => {
                //    if (result.dismiss === Swal.DismissReason.timer) {
                //        $("#new_reminder").modal("hide");
                //    }
                //});
            },
            storeKart() {
                var j = { CustId: this.userdata.CustomerCode, items: this.KartProducts }
                localStorage.setItem("Kart", JSON.stringify(j))
            }
        }
    }
</script>

<style scoped>
    .carousel-control-prev .carousel-control-prev-icon,
    .carousel-control-next .carousel-control-next-icon {
        filter: invert(50%);
    }
    .tab-container {
        overflow: hidden;
    }

    .scrollable-tabs {
        overflow: hidden;
        white-space: nowrap;
    }

    .tabs-carousel {
        display: flex;
        width: 1000px;
    }
    .carousel-container {
        position: relative;
    }
    .carousel-content {
        margin-bottom: 20px; 
    }

    .carousel-prev-next {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }
    .carousel-prev-button,
    .carousel-next-button {
    }
    .nav.nav-tabs .active-tab {
        background-color: #a8ddf4;
    }
    .nav-tabs {
        display: flex;
        flex-wrap: nowrap;
        list-style-type: none;
        padding: 0;
        margin: 0;
        margin-left: -19px
    }
        .nav-tabs li {
            flex: 0 0 auto;
            width: auto;
        }

    .nav-link {
        display: block;
        padding: 5px 10px;
        background-color: #fff;
        border-radius: 4px;
        margin-right: 5px;
        margin-left: 20px;
        cursor: pointer;
    }

        .nav-link.active {
            background-color: #a8ddf4;
            color: black;
        }
    
    .hover-menu {
        display: none;
    }

    .navbar-dropdown:hover .hover-menu {
        display: block;
    }
    /* css for nav-bar new 21-06-2023*/
    .row img {
        max-height: 15em;
    }

    .row h3 {
        cursor: pointer;
    }


    .headerOne {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #2ca9df;
        color: white;
        padding: 21px;
        position: relative; /* Add relative positioning */
        z-index: 3;
        margin-left: 20px;
    }

    .navbarclass ul {
        list-style-type: none;
        margin: 0px;
        padding: 0px;
        margin-right: 20px;
        margin-left: 15px
    }

        .navbarclass ul li {
            display: inline-block;
            position: relative;
            padding: 10px;
            text-align: center;
            justify-content: space-between;
        }

            .navbarclass ul li a {
                color: #fff;
                text-decoration: none;
                padding: 12px 12px;
                text-align: left;
            }

        .navbarclass ul ul {
            display: none;
            text-align: left;
            position: absolute;
            background: #eee;
            min-width: 30px;
            padding: 20px 0;
            margin-left: 10px;
            z-index: 2;
        }

            .navbarclass ul ul li {
                width: 100%;
                white-space: nowrap;
                margin-bottom: 5px; /* Prevent line break */
            }

    nav ul ul li a {
        color: #000;
        padding: 8px 15px;
    }

    .navbarclass ul ul li a:hover {
        background-color: #0779e4;
    }

    .navbarclass ul li:hover ul {
        display: block;
        background-color: #fff; /* Change background color */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add box shadow */
        border-radius: 4px;
        position: absolute;
        top: 100%; /* Position the dropdown below the parent */
        left: 0;
        width: 150px; /* Set a fixed width for the dropdown */
        margin-top: 8px;
        padding: 8px 0; /* Add padding */
        text-align: left;
    }

        .navbarclass ul li:hover ul li {
            display: block;
            margin: 8px 0; /* Add margin between items */
        }

            .navbarclass ul li:hover ul li a {
                display: block;
                padding: 8px 16px;
                color: #333;
                text-decoration: none;
            }

                .navbarclass ul li:hover ul li a:hover {
                    background-color: #eee;
                    color: #333;
                }

    .navbarclass ul li:hover {
        background-color: #0779e4;
        padding: 10px;
    }

        .navbarclass ul li:hover a {
            color: #fff; /* Change link color on hover */
        }
    /* End*/
    .home-link {
        cursor: pointer;
    }

    .navbar-dropdown ul {
        display: none;
    }

        .navbar-dropdown ul.open {
            display: block;
        }

    .nav-list {
        list-style-type: none;
        padding: 0;
    }

        .nav-list > li {
            display: inline-block;
            margin-right: 10px;
        }

        .nav-list a {
            text-decoration: none;
            color: #333;
        }

    .submenu {
        display: none;
    }

    .open .submenu {
        display: block;
    }

    .open {
        display: block !important;
    }

    .btnAddKartPro {
        background: rgba(44, 169, 223, 1);
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        padding:2px;
        margin-top:3px;
        margin-bottom:4px;
        font-size: 9px;
      
        
    }

    .row img {
        max-height: 15em;
    }

    .lts {
        font-size: 12px;
    }

    .row h3 {
        cursor: pointer;
    }

    .hom-box13 {
        background: #ffffff;
        box-shadow: #94dcfb 0px 0px 10px;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        line-height: 15px;
        letter-spacing: 0px;
        text-align: center;
        color: #454545;
        height: 212px;
        padding:18px
    }

    .spanmyicon {
        /* height: 80px; */
        height: auto;
        display: grid;
        place-items: center;
        align-items: end;
        border-radius: 50%;
        /* border: 2px solid rgba(239, 174, 174, 1); */
    }

        .spanmyicon img {
            margin-top: 4px;
            margin-bottom: 7.7px;
        }

        .spanmyicon:hover {
            height: auto;
            display: grid;
            place-items: center;
            align-items: end;
            border-radius: 50%;
            border: 2px solid rgba(239, 174, 174, 1);
        }

    .carouselboxbanner {
        background-image: url("../assets/img/MaskGroup.jpg");
        background-repeat: no-repeat;
        height: 274px;
        display: flex;
        justify-content: center;
        box-shadow: #083742 0px 6px 24px 0px, #083742 0px 0px 0px 1px;
    }

    .carouselboxbannerbg {
        /* background-color: rgba(0, 0, 0, 0.336); */
        text-align: center;
        color: #fff;
        height: 274px;
        text-align: center;
        padding: 80px 0px;
    }

    .carouselbox {
        background-color: #fff;
        padding: 5px 20px 5px 20px;
    }

    .carousel-control-prev {
        justify-content: normal;
        color: #000;
    }

    .carousel-control-next {
        justify-content: right;
        color: #000;
    }

    .carouselinner {
        margin: 5px 25px 5px 25px;
        width: 100%;
    }

    #carousel-card .card {
        border: 0.5px solid #fff !important;
        text-align: center;
    }

        #carousel-card .card:hover {
            border: 0.5px solid #00aeef !important;
        }

    #carousel-card .card-title {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
    }

    .homtbox {
        padding: 25px;
    }

    .lbar {
        border-right: solid 2px #00aeef;
    }

    .img-fluid {
        width: 124px !important;
    }

    .texthomesmbanner {
        padding: 75px 75px 10px 75px;
        background: -moz-linear-gradient( top, rgba(255, 255, 255, 0) 2%, rgba(255, 255, 255, 0) 12%, rgba(255, 255, 255, 0.55) 40%, rgba(255, 255, 255, 1) 63%, rgba(255, 255, 255, 1) 75% ); /* FF3.6-15 */
        background: -webkit-linear-gradient( top, rgba(255, 255, 255, 0) 2%, rgba(255, 255, 255, 0) 12%, rgba(255, 255, 255, 0.55) 40%, rgba(255, 255, 255, 1) 63%, rgba(255, 255, 255, 1) 75% ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient( to bottom, rgba(255, 255, 255, 0) 2%, rgba(255, 255, 255, 0) 12%, rgba(255, 255, 255, 0.55) 40%, rgba(255, 255, 255, 1) 63%, rgba(255, 255, 255, 1) 75% ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }

    .new-user,
    .exi-user {
        font-size: 24px;
        color: #000;
    }

    .req-for-reg,
    .sign-in {
        font-family: Roboto;
        font-size: 18px;
        font-weight: 400;
        background-color: #707071;
        padding: 10px 30px;
    }

    .btn-sign-in {
        font-size: 16px;
        font-family: Roboto;
        background-color: #00aeef !important;
        border-radius: 4px;
        padding: 12px 25px;
    }

    .homtbox p {
        font-weight: 500;
        font-family: Roboto;
    }

    .btn-cont-us {
        font-family: Roboto;
        font-size: 18px;
        font-weight: 500;
        border-radius: 4px;
        padding: 10px 65px;
        background: #00aaef !important;
    }

    /* *******************************************newly addedd css but some changes are done in top also********************************************************** */
    .container {
        /* height: 934px; */
        height: auto;
        width: 1440px;
        padding-left: -1px;
        /* padding-top: 90px; */
        border-radius: 0px;
        background-color: #f5f5f5;
    }

    .hom-boxcircle {
        height: 123px;
        width: 105px;
        margin-left: 32px;
        margin-top: 30px;
        border-radius: 0px;
        text-align: center;
    }

    .circleimg {
        height: 83px;
        width: 83px;
        border-radius: 50%;
    }


  /*  .hom-box13 {
        box-sizing: border-box;
        border-radius: 5px;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        line-height: 15px;
        letter-spacing: 0px;
        text-align: center;
        color: #000;
        height: 212px;
        display: grid;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }*/

    .hom-box1 {
        background: #ffffff;
        border: 1px solid #fff;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        line-height: 15px;
        letter-spacing: 0px;
        text-align: center;
        color: #000;
        height: 230px;
        display: grid;
        box-shadow: #94dcfb 0px 0px 10px;
    }

 


    .btnGetQuote {
        /* width: 82px; */
        height: 20px;
        background-color: rgba(168, 221, 244, 1);
        color: rgba(69, 69, 69, 1);
        border: none;
        font-family: Inter, sans-serif;
        font-size: 16px;
        cursor: pointer;
        margin-top: 10px;
        height: 20px;
        opacity: 0.89;
        font-size: 14px;
    }

    .product-title {
        display: inline-block;
        white-space: normal;
        background: rgba(255, 255, 255, 1);
        /* width: 142px; */
        width: 100%;
        height: 30px;
    }

    @media (min-width: 992px) {
        .hom-box13 {
            color: rgba(69, 69, 69, 1);
            margin-bottom: 10px;
            font-family: Inter;
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: center;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
        }
    }

    @media (max-width: 500px) {
        .hom-box13 {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            width: 150px;
            align-items: center;
            box-shadow: #94dcfb 0px 0px 10px;
        }
    }

    .lbar {
        border-right: none;
    }

    .circle {
        margin-top: 27px;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        overflow: hidden;
        margin-left: 4px;
    }

        .circle img {
            margin-top: 40px;
            margin-bottom: 5px;
        }

    .textsoln {
        display: flex;
        flex-direction: column;
        height: 15px;
        width: 809px;
        margin-left: -5px;
        border-radius: 0px;
        margin-top: -6px;
        height: 15px;
        width: 809px;
        color: rgba(69, 69, 69, 1);
        font-family: "Inter", sans-serif;
    }

    .textsoln1 {
        display: flex;
        flex-direction: column;
        height: 15px;
        width: 809px;
        margin-left: 31px;
        border-radius: 0px;
        margin-top: -6px;
        height: 15px;
        width: 809px;
        color: rgba(69, 69, 69, 1);
        font-family: "Inter", sans-serif;
        width: 900px;
    }

    .spanmyicon13 {
        /* height: 80px; */
        height: auto;
        display: grid;
        place-items: center;
        align-items: end;
        border-radius: 50%;
        /* border: 2px solid rgb(194, 34, 34); */
    }

        .spanmyicon13 img {
            margin-top: 4px;
            margin-bottom: 7.7px;
            width: 133px;
            margin-top: -6px;
        }

    .spanmyicon2 {
        /* height: 80px; */
        height: auto;
        display: grid;
        place-items: center;
        align-items: end;
        border-radius: 50%;
    }

        .spanmyicon2 img {
            margin-top: 4px;
            margin-bottom: 7.7px;
        }

    .homtbox h4 {
        font-size: 24px;
    }

    .homtbox p {
        font-size: 14px;
        line-height: 1.2;
    }

    .carouselboxbanner {
        background-image: url("../assets/img/MaskGroup.jpg");
        background-repeat: no-repeat;
        height: 274px;
        display: flex;
        justify-content: center;
        box-shadow: #083742 0px 6px 24px 0px, #083742 0px 0px 0px 1px;
        position: relative;
        opacity: 0.7;
    }

    .carouselboxbanner-overlay {
        background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity value as desired */
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .carouselboxbannerbg {
        text-align: center;
        color: #fff;
        height: 274px;
        text-align: center;
        padding: 80px 0px;
    }

    .carouselboxbanner h1 {
        font-size: 16px;
        background: rgba(233, 39, 41, 1);
        color: #ffffff;
        width: 134px;
        height: 34px;
        border-radius: 8px;
        text-align: center;
        line-height: 34px;
        font-family: Inter;
        font-weight: 500;
    }
    /* .carousel-control-prev-icon {
      background-color: red;
    }

    .sr-only {
      color: blue;
    } */


    .navigation {
        height: 55px;
        background: #2CA9DF;
        margin: 0 auto;
    }

    .brand {
        /*position: absolute;*/
        float: left;
        line-height: 55px;
        font-weight: 600;
        float: left;
        font-size: 13px;
    }

        .brand a, .brand a:visited {
            color: #ffffff;
            text-decoration: none;
        }

    .nav-container {
        max-width: 1000px;
        margin: 0 auto;
    }







    nav {
        float: left;
    }

        nav ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

            nav ul li {
                /* float: left;*/
                position: relative;
                height: auto;
                font-family: Inter;
                font-size: 12px;
                font-weight: 400;
            }

                nav ul li a, nav ul li a:visited {
                    display: block;
                    padding: 0 20px;
                    line-height: 55px;
                    color: #fff;
                    background: #262626;
                    text-decoration: none;
                }

                nav ul li a {
                    background: transparent;
                    color: #FFF;
                }

                    nav ul li a:hover, nav ul li a:visited:hover {
                        background: #2581DC;
                        color: #ffffff;
                    }

    .navbar-dropdown li a {
        background: #2581DC;
        cursor: pointer;
    }

    nav ul li a:not(:only-child):after, nav ul li a:visited:not(:only-child):after {
        padding-left: 4px;
        content: ' \025BE';
    }

    nav ul li ul li {
        min-width: 190px;
    }

        nav ul li ul li a {
            padding: 8px;
            line-height: 20px;
        }



    .navbar-dropdown {
        position: absolute;
        display: none;
        z-index: 1;
        background: #2CA9DF;
        color: #fff;
        /* box-shadow: 0 0 35px 0 rgba(0,0,0,0.25);*/
    }





    /* Mobile navigation */
    .nav-mobile {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        background: transparent;
        height: 55px;
        width: 70px;
    }

    @media only screen and (max-width: 800px) {
        .nav-mobile {
            display: block;
        }

        nav {
            width: 100%;
            padding: 55px 0 15px;
        }

            nav ul {
                display: none;
            }

                nav ul li {
                    float: none;
                }

                    nav ul li a {
                        padding: 15px;
                        line-height: 20px;
                        background: #262626;
                    }

                    nav ul li ul li a {
                        padding-left: 30px;
                        background: #262626;
                    }

        .navbar-dropdown {
            position: static;
        }

        @media screen and (min-width:800px) {
            .nav-list {
                display: block !important;
            }
        }

        #navbar-toggle {
            position: absolute;
            left: 18px;
            top: 15px;
            cursor: pointer;
            padding: 10px 35px 16px 0px;
        }

            #navbar-toggle span, #navbar-toggle span:before, #navbar-toggle span:after {
                cursor: pointer;
                border-radius: 1px;
                height: 3px;
                width: 30px;
                /*background: #ffffff;*/
                position: absolute;
                display: block;
                content: '';
                transition: all 300ms ease-in-out;
            }

                #navbar-toggle span:before {
                    top: -10px;
                }

                #navbar-toggle span:after {
                    bottom: -10px;
                }

            #navbar-toggle.active span {
                background-color: transparent;
            }

                #navbar-toggle.active span:before, #navbar-toggle.active span:after {
                    top: 0;
                }

                #navbar-toggle.active span:before {
                    transform: rotate(45deg);
                }

                #navbar-toggle.active span:after {
                    transform: rotate(-45deg);
                }
    }

    .popup-container {
        position: fixed;
        top: 49.7%;
        left: 50%;
        width: 850px;
        height: 446px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate(-50%, -50%);
        z-index: 9999;
        background-color: rgba(0, 0, 0, 0.5);
        perspective: 1000px;
        transition: transform 0.5s;
        box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.3);
    }

        /*.popup {*/
        /* Add a transition effect to make the rotation smoother */
        /*transition: transform 0.5s;
    }*/

        .popup-container img {
            max-width: 100%;
            max-height: 100%;
        }

        .popup-container button {
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: transparent;
            border: none;
        }

    .overlay {
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 9997 !important;
    }

    .popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        animation: popup 5s ease-in-out;
    }

    .popup-content {
        transform: translate3d(0, 0, 0);
        transform-style: preserve-3d;
        transform-origin: center;
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        animation: popup-content 5s ease-in-out;
    }

    @keyframes popup {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes popup-content {
        from {
            transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
        }

        to {
            transform: translate3d(0, 0, 200px) scale3d(1.5, 1.5, 1.5) rotateX(45deg) rotateY(45deg) rotateZ(45deg);
        }
    }



    .btnAddKart {
        background: rgba(44, 169, 223, 1);
        color: white;
        border: none;
        cursor: pointer;
        margin-bottom: 5px;
        font-size: 11px;
        padding: 5px;
        width: 85px;
        margin-left: 19px;
    }

    .breadcrumb a {
        color: black;
        margin-left: 34px;
        list-style: none;
        background-color: #f5f5f5;
       
        padding: 0;
    }

    .navigationTop {
        display: flex;
        align-items: center;
        padding: 10px;
        font-family: Inter;
        font-size: 12px
    }

        .navigationTop a {
            text-decoration: none;
            color: #454545;
            font-family: Inter;
            font-size: 12px
        }

            .navigationTop a:hover {
                color: #2ca9df;
                font-family: Inter;
                font-size: 12px
            }

        .navigationTop span {
            margin: 0 5px;
            color: #454545;
            font-family: Inter;
            font-size: 12px
        }

</style>
