<template>
    <div class="container">
        <div class="navigationTop" style="padding: 20px; margin-left: 30px;margin-top:120px">
            <a href="#" @click="navigateToHomeUser">Home</a>
            <span>&gt;</span>
            <a href="#" @click="navigateToMyReturn" >My Return</a>
            <span>&gt;</span>
            <a href="#">Return Cart</a>
        </div>
        <div style="background: #F5F5F5;">
            <Transition name="modal">
                <div v-if="show" class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-container">
                            <div class="modal-header" style="text-align: center !important">
                                Returned Product
                            </div>
                            <div class="modal-body">
                                <slot name="body">
                                    <img :src=ImageBase64 style="width:450px;height:350px !important">
                                </slot>
                            </div>
                            <div class="modal-footer" style="vertical-align:middle">
                                <slot name="footer">
                                    <button class="modal-default-button"
                                            @click="close()">
                                        Close
                                    </button>
                                </slot>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
            <div>
                <div class="row font">
                    <div class="col-md-5">
                        <p class="bold-fonts">Customer Details</p>
                        <p class="black-fonts">{{ CustomerName }}</p>
                        <p class="lightBlack-fonts">{{ custAddressB }}</p>
                        <p class="black-fonts">Phone Number:</p>
                        <p class="lightBlack-fonts">{{ CustomerPhone }}</p>
                    </div>
                    <div class="col-md-3">
                        <p class="black-fonts" style="padding: 3px;">PO No.:</p>
                        <p class="lightBlack-fonts" style="padding: 3px;">PO Date:</p>
                        <p class="black-fonts" style="padding: 3px;">Invoice No.: {{ OrderDocNo }}</p>
                        <p class="lightBlack-fonts" style="padding: 3px;">Invoice Date: {{ InvDate }}</p>
                        <p class="black-fonts" style="padding: 3px;">Invoice Amount: &#x20b9; {{ formattedInvAmount}} </p>
                        <p class="lightBlack-fonts" style="padding: 3px;">No. of Boxes: {{ InvBoxQty }}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="black-fonts" style="padding: 3px;">{{ CustomerContactDesignation }}</p>
                        <p class="lightBlack-fonts" style="padding: 3px;">{{ CustomerContactName }}</p>
                        <p class="black-fonts" style="padding: 3px;">Application Status</p>
                        <p class="lightBlack-fonts" style="padding: 3px;">RGA</p>
                        <br>
                        <a href="#/" @click="navigateToReturnPolicy" class="link-style">Return Policy</a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="row rowp15">
                            <div v-if="loading" class="loading" width="280">
                            </div>
                            <div v-else>
                                <div style="margin-left: -4rem;margin-top:3rem">
                                    <div class="scrollit ">
                                        <table id="ordertbl" class="table cart-table" style="max-width: 82%; margin-left: 105px;">
                                            <tr>
                                                <th style="font-size: 18px; font-weight: 500; text-align: left; color: #454545;font-family:Inter">Items in Invoice</th>
                                                <th class="table-fonts">Return Quantity<br />(In Boxes)</th>
                                                <th class="table-fonts">Batch Number</th>
                                                <th class="table-fonts">Reason For Return</th>
                                                <th class="table-fonts">Amount<br /> (Incl. Of Taxes)</th>
                                                <th></th>
                                            </tr>

                                            <template v-for="(p, index) in invoice" :key="index">
                                                <tr class="orderrow" id="'orderrow' + index">
                                                    <td>
                                                        <table>
                                                            <tr><td colspan="3" style="font-family:Inter;font-size:14px;font-weight:400;color:#454545;padding-top: 1rem;padding-left:0.7rem">{{ p.CustomerLinkItemName }}</td></tr>
                                                            <tr>
                                                                <td width="60px"><img :src="'data:image/png;base64,' + p.CustomerLinkItemImageData " ratio="1"></td>
                                                                <td class="lhorder">
                                                                    <span style="font-weight:400;color:#454545;font-family:Inter">Order Quantity in Boxes: {{ p.BoxOrderQty }}</span><br />
                                                                    <span style="font-weight: 400; color: #454545; font-family: Inter">Order Quantity in Pcs: {{ p.PcsOrderQty }}</span><br />
                                                                    <span style="font-size: 14px; color: #78787c; font-family: Inter">Order Value:</span>
                                                                    <strong style="color:#454545;font-size:16px;font-family:inter">{{ twofix(p.OrderDetVal) }}</strong>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td>
                                                        <div class="counter" style="border: 1px solid #454545; border-radius: 5px; margin-top: 53px;">
                                                            <Minplus :value="p.BoxOrderQty" v-model.number="p.BoxOrderQty" :index="index" :min="1" :max="p.MaxReturnQty" @changeevent="boxChange" style="cursor: pointer;"></Minplus>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control" id="KartDetReturnBatchNo" :v-value="p.KartDetReturnBatchNo" v-model="p.KartDetReturnBatchNo" style="text-align: left; width: 120px; margin-top: 47px;">
                                                    </td>
                                                    <td>
                                                        <textarea type="text" class="form-control" id="KartDetReturnReason" :v-value="p.KartDetReturnReason" v-model="p.KartDetReturnReason" style="height: 60px; margin-top: 33px;"></textarea>
                                                    </td>
                                                    <td style="display: none">{{ getOrderVal(p) }}</td>
                                                    <td style="display: none">{{ getTax(p) }}</td>
                                                    <td style="text-align: center; padding-top:60px;font-family:inter;color:#454545">{{ getTotal(p)}}</td>
                                                    <td style="text-align: center; padding-right: 9px;">
                                                        <vue-base64-file-upload style="font-family: Inter; vertical-align: inherit; width: 101px; border: 1px solid #737373; margin-top: 34px; border-radius: 3px; font-size: 14px; margin-left: 0px; text-align: center;color:#454545"
                                                                                accept="image/png,image/jpeg,image/jpg,application/pdf"
                                                                                image-class="v1-image"
                                                                                :max-size="customImageMaxSize"
                                                                                @size-exceeded="onSizeExceeded"
                                                                                :disable-preview="true"
                                                                                :placeholder="'Upload Image'"
                                                                                @load="onLoad"
                                                                                v-model="p.KartDetReturnItemImage"
                                                                                id="'ReturnItemImage'+index"
                                                                                :key="'ReturnItemImage'+index" />
                                                        <br>
                                                        <br>
                                                        <button type="button" style="font-family: Inter; vertical-align: inherit; width: 101px;padding:1px; border: 1px solid #737373; top: -124px; font-size: 14px;margin-top:-28px; border-radius: 3px;" @click="addToKart(p)">Add To Return</button>
                                                    </td>
                                                </tr>
                                            </template>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <p></p>

                            <div style="width:994px;">
                                <p style="font-size:18px ;font-weight:600;margin-left:5px;text-align:left;color:#454545;margin-top: 3rem;">Items for Return</p>
                                <table id="ordertblReturn" class="table cart-table" style="margin-left: 5px; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;">
                                    <tr>
                                        <th colspan="1" class="black-fontsnew" style="text-align:left;width: 208px;">Product Name</th>
                                        <th colspan="1" class="black-fontsnew" style="color:#111 !important;text-align:center"> Return Quantity <br />(In Boxes)</th>
                                        <th colspan="1" class="black-fontsnew" style="text-align:left;padding-left:2rem">Batch <br>Number</th>
                                        <th colspan="1" class="black-fontsnew" style="width: 180px; padding-left: 2rem; text-align: left;">Reason<br />For Return</th>
                                        <th colspan="1" class="black-fontsnew" style="text-align: left;">Amount<br /> (Incl. Of Taxes)</th>
                                        <th colspan="1" class="black-fontsnew" style="text-align:left;">Images</th>
                                        <th></th>
                                    </tr>
                                    <template v-for="(p, index) in KartProducts" :key="index">
                                        <tr class="orderrow" id="'orderrow' + index">
                                            <td style="text-align: center;font-weight:500;color: #212121;text-align: justify;padding-left: 1rem;padding-top: 1rem;white-space:nowrap">{{ p.CustomerLinkItemName }}</td>
                                            <td style="text-align: center; font-weight: 500; color: #212121; white-space: nowrap">{{ p.BoxOrderQty }}</td>
                                            <td style="text-align: left; font-weight: 500; color: #212121; white-space: nowrap">{{ p.KartDetReturnBatchNo }}</td>
                                            <td style="width: 500px; height: 78px; margin-top: 1rem; margin-bottom: 1rem; white-space: nowrap">{{ p.KartDetReturnReason }}</td>
                                            <td style="text-align: left; font-weight: 500; color: #212121; white-space: nowrap">{{ (getTotal(p)) }}</td>
                                            <td class="black-fonts" style="font-size: 18px; font-family: Inter; color: #00AEEF; cursor :Pointer" @click="DisplayImage(p.KartDetReturnItemImage)">View</td>
                                            <td style="display:none">{{ p.KartDetReturnItemImage }}</td>
                                            <td style="text-align: right;padding-right:15px;margin-bottom:8rem">
                                                <button type="button" style="font-family:Roboto " class="btn btn-default btn-dsm" @click="removeFromKart(index)">Delete</button>
                                            </td>
                                        </tr>
                                    </template>
                                </table>
                            </div>
                            <br />
                            <p></p>
                            <div class="otherTable" style="background: #fff; padding: 10px; width: 87.5%; margin-left: 78px; display: flex; justify-content: space-between; align-items: center;">
                                <div style="font-size: 14px; color: #454545; font-family: Inter;">
                                    No. of Items: {{ KartProducts.length }} <br />
                                    No. of Boxes: {{ getTotalBox() }} <br />
                                    Net. Return Amount: {{ NetAmount }}
                                </div>
                                <button type="button" style="width: 108px; border-radius: 4px; font-size: 14px; text-align:left;font-family: Inter;" class="btn btn-info btn-w btn-md" @click="ReturnKartDetails()">Place Return</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    </div>
</template>

<script>
    import Minplus from "../v-minusplusfield";
    import Vue2Filters from 'vue2-filters'
    import helper from '../helper'
    import VueBase64FileUpload from 'vue-base64-file-upload';
    import Swal from "sweetalert2";
    import axios from 'axios';
    //import { openDB, deleteDB, wrap, unwrap } from 'idb';

    export default {
        mixins: [Vue2Filters.mixin],
        name: 'returnCart',
        components: {
            Minplus,
            VueBase64FileUpload
        },
        props: {
        },
        data() {
            return {
                show: false,
                add: "",
                searchText: "",
                itemgroup: [],
                custAddressList: [],
                custAddressListB: [],
                custAddressListS: [],
                custAddressB: "",
                custAddressS: "",
                selectedCustAddressIdB: 0,
                selectedCustAddressIdS: 0,
                custTrspChrgPerBox: 0,
                custPreviousDues: 0,
                custTotaldue: 0,
                selectedcutaddressid: 0,
                userdata: {},
                freeshipping: false,
                totalAmount: 0,
                addmore: 0,
                STransportation: 0,
                Discount: 0,
                Savings: 0,
                Transportation: 0,
                TaxAmount: 0,
                RoundOff: 0,
                NetAmount: 0,
                title: 'Return Cart',
                invoice: [],
                KartProducts: [],
                OrderDocNo: "",
                KartInvoiceNo: "",
                KartDetReturnRequest: "0",
                KartDetReturnQty: 0,
                KartDetReturnApproved: 0,
                KartDetReturnBatchNo: "",
                KartDetReturnReason: "",
                InvBoxQty: 0,
                InvAmount: 0,
                InvDate: "",
                CustomerName: "",
                CustomerPhone: "",
                CustomerContactName: "",
                CustomerContactDesignation: "",
                KartDetReturnItemImage: "",
                CustomerLinkItemName: "",
                ReturnItemAmount: "",
                NoOfItemsReturn: 0,
                NoOfBoxesReturn: 0,
                ReturnTotalAmount: "",
                selectedFile: null,
                customImageMaxSize: 10,
                ImageContentType: "",
                loading: false,
                ImageBase64: "",
                file: "",
                CustomerDue: 0,
                CustomerMOV: 0
            }
        },
        created: function () {
            this.getCustAddress();
            this.popupItems();
            this.loadUserData();
        },
        computed: {
            formattedInvAmount() {
                return this.InvAmount.toString().replace(/\B(?=(\d{2})*\d{3}(?!\d))/g, ",");
            }
        },
        methods: {
            //async openImageDatabase() {
            //    const db = await openDB('imageDatabase', 1, {
            //        upgrade(db) {
            //            if (!db.objectStoreNames.contains('images')) {
            //                const store = db.createObjectStore('images', { keyPath: 'imageKey' });
            //            }
            //        }
            //    });
            //    return db;
            //},
            //async addImage(imageKey, imageData) {
            //    const db = await this.openImageDatabase();
            //    const tx = db.transaction('images', 'readwrite');
            //    const store = tx.objectStore('images');
            //    await store.put({ imageKey, imageData });
            //    await tx.done;
            //},
            //async getImage(imageKey) {
            //    const db = await this.openImageDatabase();
            //    const tx = db.transaction('images', 'readonly');
            //    const store = tx.objectStore('images');
            //    const image = await store.get(imageKey);
            //    await tx.done;
            //    return image ? image.imageData : null;
            //},
            //async deleteImage(imageKey) {
            //    const db = await this.openImageDatabase();
            //    const tx = db.transaction('images', 'readwrite');
            //    const store = tx.objectStore('images');
            //    await store.delete(imageKey);
            //    await tx.done;
            //},
            navigateToHomeUser(event) {
                event.preventDefault();
                this.$router.push('/homeUser');
            },
            navigateToMyReturn(event) {
                event.preventDefault();
                this.$router.push('/myReturn');
            },
            navigateToReturnPolicy(event) {
                event.preventDefault();
                this.$router.push('/returnPolicy');
            },
            close() {
                this.show = false;
            },
            DisplayImage(ItemImage) {
                this.ImageBase64 = ItemImage;
                this.show = true;
            },
            onLoad(dataUri, key) {
                var lowerCase = dataUri.toLowerCase();
                var contenttype = lowerCase.split(',')[0];
                if (contenttype != "data:image/jpeg;base64" && contenttype != "data:image/jpg;base64" && contenttype != "data:image/png;base64" && contenttype != "data:application/pdf;base64") {
                    Swal.fire("info", "Invalid file selected, please select proper image or pdf file", "error");
                    dataUri = null;
                    return false;
                }
                else {
                    this.KartDetReturnItemImage = dataUri
                }
            },
            onSizeExceeded(size) {
                alert(`File ${size}Mb size exceeds limits of ${this.customImageMaxSize}Mb!`);
            },
            onFileSelected(event) {
                this.selectedFile = event.target.files[0];
            },
            async popupItems() {
                try {
                    this.loading = true;
                    var order = localStorage.getItem("orderno")
                    var kartinvno = localStorage.getItem("kartinvno")
                    const self = this;
                    self.OrderDocNo = order;
                    self.KartInvoiceNo = kartinvno;
                    self.InvBoxQty = localStorage.getItem("kartinvqty");
                    self.InvAmount = localStorage.getItem("kartinvamt");
                    self.InvDate = localStorage.getItem("kartinvdt");
                    var l = localStorage.getItem("userdata")
                    if (l != "") {
                        var userData = JSON.parse(l)
                        self.CustomerName = userData.CustomerName;
                        self.CustomerPhone = userData.CustomerMobileNo;
                        self.CustomerContactDesignation = userData.DesignationName;
                        self.CustomerContactName = userData.CustContactName;
                        const requestBody = { OrderCustCode: userData.CustomerCode, OrderDocNo: self.OrderDocNo };
                        const response = await axios.post(helper.URL() + 'selectkartdetailsforreturn', requestBody);
                        const result = response.data;
                        self.invoice = [];
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                self.invoice = result.Data[0];
                            } else {
                                Swal.fire('Alert!', "Response not found!!!", 'error');
                            }
                        }
                        self.loading = false;
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            addToKart(p) {
                if (this.KartDetReturnItemImage == "" || this.KartDetReturnItemImage == null) {
                    Swal.fire('Alert!', "Select damaged item or cart image to proceed!!!", 'error');
                    return false
                }
                this.$nextTick(() => {
                    p.KartDetReturnItemImage = this.KartDetReturnItemImage;
                    var extension;
                    var lowerCase = p.KartDetReturnItemImage.toLowerCase();
                    if (lowerCase.split(',')[0].indexOf("png") !== -1) extension = "png"
                    else if (lowerCase.split(',')[0].indexOf("jpg") !== -1 || lowerCase.split(',')[0].indexOf("jpeg") !== -1)
                        extension = "jpg"
                    else extension = "pdf";
                    if (extension == "pdf") {
                        p.ImageContentType = "application/" + extension;
                    }
                    else {
                        p.ImageContentType = "image/" + extension;
                    }
                    //await this.addImage(p.OrderDocNo + '-' + p.CustomerLinkItemCode, p.KartDetReturnItemImage);
                    this.KartDetReturnItemImage = "";
                    var bqty = p.CustomerLinkItemBoxQty
                    p.PcsOrderQty = p.BoxOrderQty * bqty
                    var found = false
                    var foundindex = 0
                    this.KartProducts.forEach((element, index) => {
                        if (element.CustomerLinkItemCode == p.CustomerLinkItemCode) {
                            found = true
                            foundindex = index
                        }
                    });
                    if (found) {
                        this.KartProducts[foundindex].BoxOrderQty += p.BoxOrderQty
                        this.KartProducts[foundindex].PcsOrderQty += p.PcsOrderQty
                    } else {
                        this.KartProducts.push(JSON.parse(JSON.stringify(p)))
                    }
                    this.calcTotal();
                    Swal.fire({
                        type: 'success', title: 'Successfully Added.',
                        showConfirmButton: false, timer: 1000
                    }).then((result) => {
                        if (result.dismiss === Swal.DismissReason.timer) {
                            $("#new_reminder").modal("hide");
                        }
                    });
                });
            },
            loadUserData() {
                var l = localStorage.getItem("userdata")
                if (l != null && l != "") {
                    var userData = JSON.parse(l);
                    this.userdata = userData;
                    this.custPreviousDues = this.userdata.CustomerDue
                    this.CustomerName = this.userdata.CustomerName
                }
                var k = localStorage.getItem("RetKart");
                if (l != "" && k != "" && k !== null) {
                    var kobj = JSON.parse(k)
                    if (kobj.CustId == this.userdata.CustomerCode) {
                        this.KartProducts = kobj.items
                    }
                }
            },
            storeKart() {
                this.KartProducts.forEach((element, index) => {
                    delete element.CustomerItemImageData;
                    delete element.CustomerLinkItemImageData;
                });
                var j = { CustId: this.userdata.CustomerCode, items: this.KartProducts }
                localStorage.setItem("RetKart", JSON.stringify(j))
            },
            getTotalBox() {
                var t = 0
                this.KartProducts.forEach(p => {
                    t = (t + parseInt(p.BoxOrderQty))
                });
                return t
            },
            boxChange(value, i) {
                var bqty = this.invoice[i].CustomerLinkItemBoxQty;
                this.invoice[i].BoxOrderQty = value;
                this.invoice[i].PcsOrderQty = value * bqty;
            },
            twofix(n) {
                return n.toFixed(2)
            },
            calcTotal() {
                var ta = 0
                var self = this
                var dis = 0
                var trtotal = 0
                var ttax = 0
                this.KartProducts.forEach(p => {
                    var t = p.PcsOrderQty * p.CustomerLinkItemDP
                    ta = ta + t
                    var clMRP = p.PcsOrderQty * p.CustomerLinkItemPrice
                    dis = dis + (clMRP - t)
                    trtotal = trtotal + p.BoxOrderQty * self.custTrspChrgPerBox
                    ttax = ttax + (t / 100) * p.CustomerLinkItemTaxPer;
                });

                self.totalAmount = ta.toFixed(2)
                self.Discount = dis.toFixed(2)
                self.TaxAmount = ttax.toFixed(2)
                var taddmore = ((self.userdata.CustomerMOV - ta) - trtotal)

                if (taddmore <= 0) {
                    self.freeshipping = true
                    self.addMore = taddmore
                } else {
                    self.freeshipping = false
                    self.addMore = taddmore
                }
                var nettratotal = 0
                if (self.freeshipping) {
                    self.STransportation = trtotal.toFixed(2)
                    self.Transportation = 0
                    var ss = trtotal + dis
                    self.Savings = ss.toFixed(2)
                } else {
                    self.STransportation = 0
                    self.Transportation = trtotal.toFixed(2)
                    nettratotal = trtotal
                    self.Savings = dis.toFixed(2)
                }

                var troundoff = ta + ttax + nettratotal
                var r = Math.round(troundoff)
                var tempr = r - troundoff
                self.RoundOff = this.formattedRoundOff(tempr)
                self.NetAmount = r
                self.custTotalDue = self.custPreviousDues + self.NetAmount
                this.storeKart();
            },
            formattedRoundOff(roundoff) {
                const roundeOff = Math.ceil(roundoff * 100) / 100;
                return roundeOff.toLocaleString('en-IN', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            },
            async getCustTrspCharge() {
                try {
                    var self = this;
                    const requestBody = { CustAddressId: self.selectedCustAddressIdB }
                    const response = await axios.post(helper.URL() + 'gettransportationcharge', requestBody);
                    const result = response.data;
                    if (result.Status == true) {
                        if (result.Data && result.Data.length > 0) {
                            self.custTrspChrgPerBox = response.data.Data[0][0].TcPricePerBox;
                            self.calcTotal();
                        } else {
                            self.calcTotal();
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            removeFromKart(idex) {
                //await this.deleteImage(this.KartProducts[idex].OrderDocNo + '-' + this.KartProducts[idex].CustomerLinkItemCode)
                this.KartProducts.splice(idex, 1);
                this.calcTotal()
            },
            getOrderVal(p) {
                var rval = 0
                rval = p.PcsOrderQty * p.CustomerLinkItemDP
                return rval
            },
            getTax(p) {
                var rval = 0
                rval = (p.PcsOrderQty * p.CustomerLinkItemDP / 100) * p.CustomerLinkItemTaxPer;
                return rval.toFixed(2);
            },
            getTotal(p) {
                var rval = 0
                var t = p.PcsOrderQty * p.CustomerLinkItemDP
                var tax = (t / 100) * p.CustomerLinkItemTaxPer;
                rval = t + tax
                return rval.toLocaleString('en-IN', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            },
            nav(p) {
                this.$router.push(p)
            },
            convertImage(img) {
                const file = img;
                const reader = new FileReader()
                let rawImg;
                reader.onloadend = () => {
                    rawImg = reader.result;
                }
                reader.readAsDataURL(file);
                return file;
            },
            async ReturnKartDetails() {
                const requestBody = {}
                var order = localStorage.getItem("orderno")
                var kartinvno = localStorage.getItem("kartinvno")
                const self = this;
                self.OrderDocNo = order;
                self.KartInvoiceNo = kartinvno;
                var k = localStorage.getItem("RetKart");
                if (k != "") {
                    var kobj = JSON.parse(k)
                    var items = []
                    var retrievedImageData = ""
                    self.NoOfItemsReturn = kobj.items.length;
                    self.NoOfBoxesReturn = self.getTotalBox();
                    self.ReturnTotalAmount = self.NetAmount;
                    for (const element of kobj.items) {
                        element.KartInvoiceNo = self.KartInvoiceNo;
                        element.KartDetReturnRequest = "1";
                        element.KartDetReturnQty = element.BoxOrderQty;
                        element.ReturnItemAmount = self.getTotal(element);
                        //retrievedImageData = await this.getImage(element.OrderDocNo + '-' + element.CustomerLinkItemCode);
                        //if (retrievedImageData != "") {
                        //    element.KartDetReturnItemImage = retrievedImageData;
                        //}
                        items.push(element);
                    }
                    requestBody.items = items
                    requestBody.CustomerName = self.CustomerName;
                    requestBody.custaddress = self.custAddressB;
                    requestBody.CustomerPhone = self.CustomerPhone;
                    requestBody.OrderDocNo = self.OrderDocNo;
                    requestBody.InvDate = self.InvDate;
                    requestBody.InvAmount = self.InvAmount;
                    requestBody.InvBoxQty = self.InvBoxQty;
                    requestBody.CustomerContactName = self.CustomerContactName;
                    requestBody.CustomerContactDesignation = self.CustomerContactDesignation;
                    requestBody.NoOfItemsReturn = self.NoOfItemsReturn;
                    requestBody.NoOfBoxesReturn = self.NoOfBoxesReturn;
                    requestBody.ReturnTotalAmount = self.ReturnTotalAmount;
                } else {
                    console.log("no item found");
                    return false
                }
                const response = await axios.post(helper.URL() + 'insertreturnkartdetails', requestBody);
                const result = response.data;
                if (result.Status == true) {
                    Swal.fire('info!', "The return request has been sent successfully. Our backend team will review it and get back to you.", 'success');
                    self.NetAmount = 0;
                    self.KartProducts = []
                    localStorage.removeItem("RetKart");
                }
                else {
                    self.notsaveditems = response.data
                    Swal.fire('Info!', "Failed!!!", 'error');
                    self.NetAmount = 0;
                    self.KartProducts = []
                    self.calcTotal();
                }
            },
            async getCustAddress() {
                try {
                    var l = localStorage.getItem("userdata")
                    if (l != null && l != "") {
                        var userData = JSON.parse(l);
                        var self = this;
                        const requestBody = { custcode: userData.CustomerCode }
                        const response = await axios.post(helper.URL() + 'selectcustomeraddress', requestBody);
                        const result = response.data;
                        self.custAddressList = [];
                        if (result.Status == true) {
                            if (result.Data && result.Data.length > 0) {
                                self.custAddressList = response.data.Data[0];
                                const filteredDataB = self.custAddressList.filter(item => item.AddressType === 'B');
                                const filteredDataS = self.custAddressList.filter(item => item.AddressType === 'S');
                                const custAddressB = filteredDataB.map(item => item.AddressDetails);
                                const custAddressS = filteredDataS.map(item => item.AddressDetails);
                                self.custAddressListB = filteredDataB;
                                self.custAddressListS = filteredDataS;
                                if (custAddressB.length > 0) {
                                    self.custAddressB = custAddressB.shift();
                                } else {
                                    self.custAddressB = "";
                                }
                                if (custAddressS.length > 0) {
                                    self.custAddressS = custAddressS.shift();
                                } else {
                                    self.custAddressS = "";
                                }
                                self.selectedCustAddressIdB = filteredDataB.map(item => item.AddressIdB);
                                self.selectedCustAddressIdS = filteredDataS.map(item => item.AddressIdS);
                                self.getCustTrspCharge();
                            } else {
                                Swal.fire('Alert!', "Response not found!!!", 'error');
                            }
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            addressClickB(c) {
                var self = this
                self.custAddressB = c.AddressDetails
                self.selectedCustAddressIdB = c.AddressId
                this.isDropdownOpenB = false;
                self.getCustTrspCharge();
            },
            addressClickS(c) {
                var self = this
                self.custAddressS = c.AddressDetails
                self.selectedCustAddressIdS = c.AddressId
                this.isDropdownOpenS = false;
                self.getCustTrspCharge();
            }
        }
    }
</script>

<style scoped>
    .counter {
        display: flex;
        align-items: center;
    }
       .modal-mask {
           position: fixed;
           z-index: 9998;
           top: 0;
           left: 0;
           width: 100%;
           height: 100%;
           background-color: rgba(0, 0, 0, 0.5);
           display: table;
           transition: opacity 0.3s ease;
       }

       .modal-wrapper {
           display: table-cell;
           vertical-align: middle;
       }

       .modal-container {
           width: 550px;
           margin: 0px auto;
           padding: 20px 30px;
           background-color: #fff;
           border-radius: 2px;
           box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
           transition: all 0.3s ease;
           text-align: center;
       }

       .modal-header h3 {
           margin-top: 0;
           color: #42b983;
       }

       .modal-body {
           margin: 20px 0;
       }

       .modal-default-button {
           float: right;
       }

       .modal-enter-from {
           opacity: 0;
       }

       .modal-leave-to {
           opacity: 0;
       }

           .modal-enter-from .modal-container,
           .modal-leave-to .modal-container {
               -webkit-transform: scale(1.1);
               transform: scale(1.1);
           }

       .no-border {
           border: 0 !important;
       }

       .loading {
           background: transparent url('../assets/img/loading.gif') center no-repeat;
           height: 400px;
           width: 400px;
           margin: 0 auto;
       }

       .row img {
           max-height: 15em;
           width: 100%;
       }

       .lts {
           font-size: 12px;
       }

       .row h3 {
           cursor: pointer;
       }

       /*.scrollit {*/
           /*overflow-y: scroll;*/
           /*height: 500px;
           scrollbar-width: thin;
           direction: ltr;*/
           /*width: 107%;*/
           /*width: 101%;*/
           /*overflow-x: hidden;
       }*/

       .hom-box {
           background: #FFFFFF;
           border: 1px solid #C5C7D0;
           box-shadow: 0px 2px 8px 0px #00000026;
           box-sizing: border-box;
           border-radius: 8px;
           padding: 10px 15px 15px 15px;
           height: 60px;
           font-family: Roboto;
           font-size: 16px;
           font-style: normal;
           line-height: 21px;
           letter-spacing: 0px;
           text-align: left;
       }

       .otherTable {
           width: 1340px;
           margin-left: -0.1rem
       }



       .black-fonts {
           font-family: Roboto;
           font-size: 14px;
           font-weight: 400;
           color: #000
       }

       .ordertblTwo {
           width: 1234px;
           left: -10px;
       }

       #ordertblReturn {
           width: 1140px;
           padding-right: 17rem;
           margin-left: -.8rem;
           font-weight:700
       }

       .spanmyicon {
           display: flex;
           justify-content: space-between;
           float: right;
           margin-top: 5px;
       }

           .spanmyicon img {
               width: 20px;
           }

       .carouselbox {
           background-color: #fff;
           padding: 5px 20px 5px 20px;
       }

       .carousel-control-prev {
           justify-content: normal;
           color: #000;
       }

       .carousel-control-next {
           justify-content: right;
           color: #000;
       }

       .carouselinner {
           margin: 5px 25px 5px 25px;
           width: 100%;
       }

       #carousel-card .card {
           border: 0.5px solid #FFF !important;
           text-align: center;
       }

           #carousel-card .card:hover {
               border: 0.5px solid #00AEEF !important;
           }

       #carousel-card .card-title {
           font-family: 'Roboto';
           font-style: normal;
           font-weight: 500;
           font-size: 14px;
           line-height: 19px;
       }

       .img-fluid {
           width: 124px !important;
       }

       .homesmbanner1 {
           background-image: url("../assets/img/home_smbanner_1.png");
           background-repeat: no-repeat;
           width: 679px;
           height: 313px;
           margin-right: 5px;
       }

       .homesmbanner2 {
           background-image: url("../assets/img/home_smbanner_2.png");
           background-repeat: no-repeat;
           width: 679px;
           height: 313px;
           margin-left: 5px;
       }

       .texthomesmbanner {
           padding: 75px 75px 10px 75px;
           background: -moz-linear-gradient(top, rgba(255,255,255,0) 2%, rgba(255,255,255,0) 12%, rgba(255,255,255,0.55) 40%, rgba(255,255,255,1) 63%, rgba(255,255,255,1) 75%); /* FF3.6-15 */
           background: -webkit-linear-gradient(top, rgba(255,255,255,0) 2%,rgba(255,255,255,0) 12%,rgba(255,255,255,0.55) 40%,rgba(255,255,255,1) 63%,rgba(255,255,255,1) 75%); /* Chrome10-25,Safari5.1-6 */
           background: linear-gradient(to bottom, rgba(255,255,255,0) 2%,rgba(255,255,255,0) 12%,rgba(255,255,255,0.55) 40%,rgba(255,255,255,1) 63%,rgba(255,255,255,1) 75%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
           filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
       }
       /* Chrome, Safari, Edge, Opera */
       input::-webkit-outer-spin-button,
       input::-webkit-inner-spin-button {
           -webkit-appearance: none;
           margin: 0;
       }

       /* Firefox */
       input[type=number] {
           -moz-appearance: textfield;
       }

       #ordertbl {
           /*width: 1314px;*/
           width: 1145px;
           padding-right: 17rem;
           margin-left: 3rem;
       }

           #ordertbl input {
               width: 35px;
               padding: 0px;
               text-align: center;
           }

           #ordertbl img {
               width: 58px;
               height: 58px;
               border: solid 1px #00AEEF;
               margin: 0 10px 0 10px;
               padding: 3px;
           }

      /*     #ordertbl tr th {
               text-align: center;
               font-size: 12px;
               font-family: Roboto;
               font-style: normal;
           }*/

       .orderrow {
           background: #fff;
           font-size: 14px;
           font-family: Inter;
           font-style: normal;
           border-top: solid 2px #E5E5E5;
       }

       .tabletitle {
           font-family: Roboto;
           font-size: 16px;
           font-style: normal;
           font-weight: 400;
           line-height: 19px;
           letter-spacing: 0em;
           text-align: left;
           color: #00AEEF;
           padding: 10px 0 2px 10px;
       }

       .pqty {
           color: #00AEEF;
           text-align: center;
       }

       .orderrowbtn {
           border-top: none;
           background: #fff;
       }

           .orderrowbtn .btn-info {
               background-color: #0dcaf0;
               position: relative;
               top: -20px;
           }

           .orderrowbtn .btn-default {
               position: relative;
               top: -20px;
           }

           .orderrowbtn .btn-add {
               position: relative;
               top: -20px;
               left: -10px;
           }

           .orderrowbtn .btn-image {
               position: relative;
               /*top: -10rem;*/
               left: -0.5px;
           }

       .lightBlack-fonts {
           font-family: "Roboto";
           font-size: 14px;
           font-weight: 500;
           color: #454545;
       }

       table th {
           position: -webkit-sticky;
           position: sticky;
           top: 0;
           z-index: 1;
           background: rgb(240, 240, 245);
       }
       /* Updated styles below */
      /* .cart-table tr th {
           font-size: 14px !important;
           color: #323338;
           font-weight: normal;
       }*/

       .return-table {
           border-spacing: 0;
       }

       .saving-this-order tr:nth-child(1) td {
           color: #000;
       }

       .saving-this-order tr:nth-child(2) td {
           font-weight: 500;
           color: #000;
       }

       .saving-this-order tr:nth-child(3) td {
           font-weight: 500;
           color: #000;
       }

       .saving-this-order tr:nth-child(4) td {
           color: #323338;
       }

       .price-details tr:nth-child(2) td {
           font-weight: 500;
           color: #000;
       }

       .price-details tr:nth-child(3) td {
           font-weight: 500;
           color: #000;
       }

       .price-details tr:nth-child(4) td {
           font-weight: 500;
           color: #000;
       }

       .price-details tr:nth-child(5) td {
           font-weight: 500;
           color: #000;
       }

       .account-summary tr:nth-child(2) td {
           font-weight: 500;
           color: #000;
       }

       .account-summary tr:nth-child(3) td {
           font-weight: 500;
           color: #000;
       }

    .table-container::-webkit-scrollbar {
        width: 0;
        background-color: transparent;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 10px;
    }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: grey;
        }


    .vue-base64-file-upload ::placeholder {
        cursor: pointer;
    }






    .row.font {
        display: flex; /* Set the display to flex */
        align-items: stretch; /* Make columns stretch to the same height */
        margin-bottom: 20px; /* Add some bottom margin */
      /*  margin-top: 136px;*/
        margin-left: 30px;
        margin-right: 29px;
        padding:10px;
        font-family:Inter;
        color:#454545
    }

    .col-md-5,
    .col-md-3,
    .col-md-4 {
        background: #fff;
        padding: 10px;
    }

    .col-md-5 {
        flex: 1; /* Make the column expand to take available space */
        border-right: solid 1px #C5C7D0;
        padding-left: 1rem;
    }

    .col-md-3 {
        flex: 1; /* Make the column expand to take available space */
        border-right: solid 1px #C5C7D0;
     
    }

    .col-md-4 {
        flex: 1; /* Make the column expand to take available space */
    
    }

    .black-fonts {
        margin: 0;
        font-family: Inter;
        font-weight: 400;
        font-size: 14px;
        color:#454545;
        padding:5px
    }

    .lightBlack-fonts {
        margin: 0;
        font-family: Inter;
        font-weight: 400;
        font-size: 14px;
        color: #737373;
        padding: 5px
    }

    .bold-fonts {
        margin: 0;
        font-family: Inter;
        font-weight: 500;
        font-size: 16px;
        color: #454545;
        padding: 5px
    }

    .link-style {
        font-family: Inter;
        font-weight: 500;
        font-size: 14px;
        color: #2CA9DF;
        text-decoration: underline;
    }




    .table-fonts {
        margin: 0;
        font-family: Inter;
        font-weight: 400;
        font-size: 12px;
        color: #454545;
        padding: 5px;
        text-align:center
    }



    .black-fontsnew {
        margin: 0;
        font-family: Inter;
        font-weight: 400;
        font-size: 12px;
        color: #454545;
        padding: 5px;
        text-align: center;
        margin-left:10px
    }

    .table td, .table th {
        padding: 0.75rem;
        vertical-align: top;
        border-top: none;
    }

    .navigationTop {
        display: flex;
        align-items: center;
        padding: 10px;
        font-family: Inter;
        font-size: 12px
    }

        .navigationTop a {
            text-decoration: none;
            color: #454545;
            font-family: Inter;
            font-size: 12px
        }

            .navigationTop a:hover {
                color: #2ca9df;
                font-family: Inter;
                font-size: 12px
            }

        .navigationTop span {
            margin: 0 5px;
            color: #454545;
            font-family: Inter;
            font-size: 12px
        }


</style>
